import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Media } from '@core/models';

@Component({
  selector: 'app-mobile-talent-photoalbum-preview',
  templateUrl: './mobile-talent-photoalbum-preview.component.html',
  styleUrls: ['./mobile-talent-photoalbum-preview.component.scss'],
})
export class MobileTalentPhotoalbumPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('itemElement') itemElement: ElementRef;

  constructor(
    private el: ElementRef,
    @Optional() private dialogRef: MatDialogRef<MobileTalentPhotoalbumPreviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { mediaPosition: number; mediaList: Media[] },
  ) {}

  ngOnInit(): void {}

  public ngAfterViewInit(): void {
    // scroll to selected photo
    const myElement = this.el.nativeElement.querySelector('#selectedPhoto');
    setTimeout(() => {
      myElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, 500);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
