<div class="d-flex flex-column w-100 text-white p-5">
  <h2 translate>Request refund for job</h2>
  <p [innerHTML]="'requestRefund' | translate: { profileName: jobApplication.user.profileName }"></p>
  <p translate>
    <span translate>Refund amount:</span>
    &nbsp;
    <app-money [amount]="jobApplication.payment.amount"></app-money>
  </p>
  <ng-container *ngIf="!formSend">
    <form (ngSubmit)="requestRefund()" [formGroup]="form" class="mt-5 d-flex flex-column">
      <div class="row mb-3">
        <div class="col-12">
          <label translate>Reason</label>
          <app-textarea
            class="bg-gray w-100 d-flex flex-fill border-0 rounded-sm mb-1"
            formControlName="reason"></app-textarea>
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col-5">
          <app-button [isBusy]="isBusy" buttonStyle="white-outline">
            <button
              (click)="closeModal()"
              class="w-100 h-100 bg-transparent border-0 text-white"
              translate
              type="button">
              Close
            </button>
          </app-button>
        </div>
        <div class="col-5">
          <app-button [isBusy]="isBusy" [isDisabled]="form.invalid" buttonStyle="orange">
            <button
              [disabled]="form.invalid"
              class="w-100 h-100 bg-transparent border-0 text-white"
              translate
              type="submit">
              Request refund
            </button>
          </app-button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="formSend">
    <p translate>We received your refund request. You will get a notification when the refund is approved or denied.</p>
    <app-button class="orange-bright gradient-x mb-4 text-center submit">
      <button (click)="closeModal()" class="w-100 h-100 bg-transparent border-0 text-white" translate type="button">
        Close
      </button>
    </app-button>
  </ng-container>
</div>
