import { init, configureScope } from '@sentry/angular';
import { environment } from '@environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

export const setupSentry = () => {
  init({
    dsn: 'https://7f7dda99c8934beab7ece44520b01a0f@o162096.ingest.sentry.io/5739639',
    environment: 'frontend',
    enabled: environment.production,
    maxValueLength: 1500,
    beforeSend: (event, hint) => {
      try {
        if (hint?.originalException instanceof HttpErrorResponse) {
          return null;
        }
        // Note: issue with double entries during http exceptions:
        // https://github.com/getsentry/sentry-javascript/issues/2169
        // Fix found here: https://github.com/getsentry/sentry-javascript/issues/2169#issuecomment-525722319
        if (event.extra['__serialized__']['error'] === 'ProgressEvent') {
          return null;
        }

        // Note: issue with a second entry not being set correctly
        // (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
        const isNonErrorException = event.exception.values[0].value.startsWith('Non-Error exception captured');
        if (isNonErrorException) {
          if (!event.extra['__serialized__']) {
            return null;
          }
          let realErrMsg = event.extra['__serialized__']['error']
            ? event.extra['__serialized__']['error']['message']
            : null;
          realErrMsg = realErrMsg || event.extra['__serialized__']['message'];
          // this is a useless error message that masks the actual error.  Lets try to set it properly
          event.exception.values[0].value = realErrMsg;
          event.message = realErrMsg;
        }
      } catch (e) {}
      return event;
    },
  });

  configureScope((scope) => {
    scope.setTag('flavor', environment.flavor);
  });
};
