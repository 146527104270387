<app-mobile-modal-dialog #appCancelBookingModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!isSuccess; else success">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <div class="modal-body">
        <div class="cancel-booking-info">
          <h6 [ngStyle]="{'font-size': jobDetail === undefined ? '16px' : '18px'}" class="mb-2" translate>
            {{title | translate}}
          </h6>
          <div class="text-center">
            <p *ngIf="jobDetail === undefined" translate>*Please note:</p>
          </div>
          <ol *ngIf="jobDetail === undefined">
            <li translate>You cancel 4 days or more before the shoot, 100% refund.</li>
            <li translate>You cancel 2-3 days before the shoot, 50% refund.</li>
            <li translate>You cancel 1 day before the shoot, 0% refund</li>
          </ol>
          <div class="talents" [ngStyle]="{'border': jobDetail === undefined ? 'none': 'auto' }">
            <div class="talent mt-2" *ngFor="let applicant of applicants; let i = index;">
              <button type="button" *ngIf="jobDetail !== undefined" (click)="removeApplicant(i)">
                <img src="/assets/img/mobile/close.svg" alt="close" />
              </button>
              <div class="info">
                <img *ngIf="applicant?.user?.photo" [src]="applicant.user?.photo.url" alt="" />
                <div class="profile">
                  <p>{{applicant?.user?.profileName | truncate:10}}</p>
                  <p>
                    {{applicant?.user?.country?.translateKey | translate}}, {{applicant?.user?.country?.alpha2Code}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <textarea
            formControlName="reason"
            class="textarea-control"
            style="height: 156px !important"
            placeholder="{{'Enter your reason' | translate}}"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-bg-gray"
          [disabled]="!form.valid"
          (click)="submit()"
          style="border-radius: 100px; width: 182px; margin: auto">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ jobDetail !== undefined ? 'Reject' : 'Cancel booking' | translate }}</span>
        </button>
        <button type="button" class="btn btn-block" (click)="close()">{{'Cancel' | translate}}</button>
      </div>
    </form>
  </ng-container>
  <ng-template #success>
    <div class="modal-body">
      <app-mobile-success
        *ngIf="jobDetail === undefined"
        [message]="'Your booking has been canceled'"></app-mobile-success>
      <app-mobile-success *ngIf="jobDetail !== undefined" [message]="'The booking is rejected'"></app-mobile-success>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
