<app-mobile-modal-dialog #appMobileFilterModal>
  <form *ngIf="filterFB" [formGroup]="filterFB" (ngSubmit)="submitFilter()">
    <div class="modal-body">
      <!-- Category -->
      <div class="wrap">
        <h6 class="title" translate>Category</h6>
        <ng-container *ngIf="categories$ | async | translateAndSort: 'translateKey':'slug' | async as categories">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input custom-control"
              id="all"
              [checked]="categorySelected == null"
              (click)="categoryIsChecked(null)"
              [value]="null" />
            <label
              class="custom-control-label"
              for="all"
              [value]="null"
              [innerHtml]="'all_' + page | translate"></label>
          </div>
          <ng-container *ngFor="let category of categories; let i = index;">
            <div class="custom-control custom-checkbox" *ngIf="i < 4;">
              <input
                type="checkbox"
                class="custom-control-input custom-control"
                id="{{category.value}}"
                [value]="category.value"
                [checked]="categorySelected == category.value"
                (click)="categoryIsChecked(category.value)" />
              <label class="custom-control-label" for="{{category.value}}">
                {{ category.translateKey | translate }}
              </label>
            </div>
          </ng-container>
          <ng-container *ngIf="categories.length > 4">
            <div class="collapse" id="moreCategory">
              <ng-container *ngFor="let category of categories; let i = index;">
                <div class="custom-control custom-checkbox" *ngIf="i > 4;">
                  <input
                    type="checkbox"
                    class="custom-control-input custom-control"
                    id="{{category.value}}"
                    [value]="category.value"
                    [checked]="categorySelected == category.value"
                    (click)="categoryIsChecked(category.value)" />
                  <label class="custom-control-label" for="{{category.value}}">
                    {{ category.translateKey | translate }}
                  </label>
                </div>
              </ng-container>
            </div>
            <button
              type="button"
              data-id="#moreCategory"
              class="button-readmore"
              data-open="View more"
              data-close="Show less"
              data-toggle="collapse"
              role="button"
              aria-expanded="false"
              (click)="viewMore($event)">
              View more
            </button>
          </ng-container>
        </ng-container>
      </div>

      <!-- Sub Category -->
      <div class="wrap" *ngIf="categorySelected && talentCategories !== null && talentCategories.length > 0">
        <h6 class="title" translate>Select subcategory</h6>
        <ng-container>
          <ng-container *ngFor="let subCategory of talentCategories; let i = index;">
            <div class="custom-control custom-checkbox" *ngIf="i < 4;">
              <input
                type="checkbox"
                class="custom-control-input custom-control"
                id="{{subCategory.slug}}"
                [checked]="talentCategorySelected == subCategory.slug"
                (click)="talentCategoryIsChecked(subCategory.slug)"
                [value]="subCategory.slug" />
              <label class="custom-control-label" for="{{subCategory.slug}}">
                {{ subCategory.translateKey | translate }}
              </label>
            </div>
          </ng-container>
          <ng-container *ngIf="talentCategories.length > 4">
            <div class="collapse" id="moreSubCategory">
              <ng-container *ngFor="let subCategory of talentCategories; let i = index;">
                <div class="custom-control custom-checkbox" *ngIf="i > 4;">
                  <input
                    type="checkbox"
                    class="custom-control-input custom-control"
                    id="{{subCategory.slug}}"
                    [checked]="talentCategorySelected == subCategory.slug"
                    (click)="talentCategoryIsChecked(subCategory.slug)"
                    [value]="subCategory.slug" />
                  <label class="custom-control-label" for="{{subCategory.slug}}">
                    {{ subCategory.translateKey | translate }}
                  </label>
                </div>
              </ng-container>
            </div>
            <button
              type="button"
              data-id="#moreSubCategory"
              class="button-readmore"
              data-open="View more"
              data-close="Show less"
              data-toggle="collapse"
              role="button"
              aria-expanded="false"
              (click)="viewMore($event)">
              View more
            </button>
          </ng-container>
        </ng-container>
      </div>

      <!-- Gender -->
      <div class="wrap">
        <h6 class="title" translate>Gender</h6>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input custom-control" id="male" formControlName="male" />
          <label class="custom-control-label" for="male">{{'Male' | translate}}</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input custom-control" id="female" formControlName="female" />
          <label class="custom-control-label" for="female">{{'Female' | translate}}</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input custom-control"
            id="transgender"
            formControlName="transgender" />
          <label class="custom-control-label" for="transgender">{{'Non-binary' | translate}}</label>
        </div>
      </div>

      <!-- Age -->
      <div class="wrap">
        <h6 class="title" translate>Age</h6>
        <div class="form-group-custom-inline">
          <input
            type="number"
            class="form-control form-control-custom-sm"
            style="width: 100px"
            formControlName="fromAge"
            placeholder="From" />
          <input
            type="number"
            class="form-control form-control-custom-sm"
            style="width: 100px"
            formControlName="toAge"
            placeholder="To" />
        </div>
      </div>

      <!-- Rate -->
      <div class="wrap">
        <h6 class="title" translate>Day Fee</h6>
        <div class="form-group-custom-inline">
          <input
            type="number"
            class="form-control form-control-custom-sm input-euro"
            style="width: 100px"
            formControlName="fromRate"
            placeholder="From" />
          <input
            type="number"
            class="form-control form-control-custom-sm input-euro"
            style="width: 100px"
            formControlName="toRate"
            placeholder="To" />
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input custom-control" formControlName="freebie" id="freebie" />
          <label class="custom-control-label" for="freebie">Freebie</label>
        </div>
      </div>

      <!-- Location -->
      <div class="wrap">
        <h6 class="title" translate>Location</h6>
        <app-geolocate-field
          class="bg-gray w-100 rounded-sm mb-1"
          formControlName="description"
          [placeholder]="'Location' | translate"></app-geolocate-field>
        <select class="form-control" formControlName="distance" style="width: 100px">
          <option value="0" translate>+ 0 km</option>
          <option value="1" translate>+ 1 km</option>
          <option value="2" translate>+ 2 km</option>
          <option value="5" translate>+ 5 km</option>
          <option value="10" translate>+ 10 km</option>
          <option value="15" translate>+ 15 km</option>
          <option value="30" translate>+ 30 km</option>
          <option value="50" translate>+ 50 km</option>
          <option value="100" translate>+ 100 km</option>
        </select>
      </div>

      <!-- Languange -->
      <div class="wrap">
        <h6 class="title" translate>Language</h6>
        <ng-container *ngIf="languages$ | async as languages">
          <ng-container *ngFor="let language of languages.results; let i = index;">
            <div class="custom-control custom-checkbox" *ngIf="i < 4;">
              <input
                type="checkbox"
                class="custom-control-input custom-control"
                id="{{language.isoCode}}"
                [value]="language.isoCode"
                [checked]="languangeSelected == language.isoCode"
                (click)="languangeIsChecked(language.isoCode)" />
              <label class="custom-control-label" for="{{language.isoCode}}">
                {{ language.translateKey | translate }}
              </label>
            </div>
          </ng-container>
          <ng-container *ngIf="languages.results.length > 4">
            <div class="collapse" id="moreLanguange">
              <ng-container *ngFor="let language of languages.results; let i = index;">
                <div class="custom-control custom-checkbox" *ngIf="i > 4;">
                  <input
                    type="checkbox"
                    class="custom-control-input custom-control"
                    id="{{language.isoCode}}"
                    [value]="language.isoCode"
                    [checked]="languangeSelected == language.isoCode"
                    (click)="languangeIsChecked(language.isoCode)" />
                  <label class="custom-control-label" for="{{language.isoCode}}">
                    {{ language.translateKey | translate }}
                  </label>
                </div>
              </ng-container>
            </div>
            <button
              type="button"
              data-id="#moreLanguange"
              class="button-readmore"
              data-open="View more"
              data-close="Show less"
              data-toggle="collapse"
              role="button"
              aria-expanded="false"
              (click)="viewMore($event)">
              View more
            </button>
          </ng-container>
        </ng-container>
      </div>

      <!-- More Filter -->
      <!-- <ng-container *ngIf="filterCategories !== null && filterCategories.length > 0">
        <div class="wrap">
          <div class="collapse" id="moreFilter" formGroupName="filters">
            <h6 class="title" translate>More Filters</h6>
            <div *ngFor="let filterCategory of filterCategories" class="row mb-3">
              <app-label class="col-12 col-sm-2 align-self-start mt-2" translate>
                {{ filterCategory.translateKey | translate }}
              </app-label>

              <div class="col-12 col-sm-10">
                <div *ngIf="filterCategory.type === 'checkbox'" class="bg-gray rounded-sm py-2 px-3">
                  <app-checkbox-group [formControlName]="filterCategory.slug" class="row w-100">
                    <label
                      *ngFor="let option of filterCategory.filterOptions"
                      class="col-6 col-sm-4 my-1 d-flex align-items-center">
                      <app-checkbox [value]="option.id" class="mr-2" class="mr-2"></app-checkbox>
                      {{ option.translateKey | translate }}
                    </label>
                  </app-checkbox-group>
                </div>

                <div *ngIf="filterCategory.type === 'slider'" class="bg-gray rounded-sm px-3 py-2">
                  <app-weight-slider
                    *ngIf="filterCategory.unit === 'kg'"
                    [formControlName]="filterCategory.slug"
                    [options]="{ floor: filterCategory.min, ceil: filterCategory.max }"
                    [slidersRefresh]="slidersRefresh"></app-weight-slider>

                  <app-metric-slider
                    *ngIf="filterCategory.unit === 'cm'"
                    [formControlName]="filterCategory.slug"
                    [options]="{ floor: filterCategory.min, ceil: filterCategory.max }"
                    [slidersRefresh]="slidersRefresh"></app-metric-slider>
                </div>

                <div *ngIf="filterCategory.type === 'color'" class="bg-gray rounded-sm px-3 pb-2">
                  <app-color-slider
                    [formControlName]="filterCategory.slug"
                    [slidersRefresh]="slidersRefresh"></app-color-slider>
                </div>
                <div *ngIf="filterCategory.type === 'number'" class="bg-gray rounded-sm px-3">
                  <app-text-field
                    class="bg-gray flex-fill border-0 rounded-sm mb-1"
                    [formControlName]="filterCategory.slug"></app-text-field>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            data-id="#moreFilter"
            class="button-readmore"
            data-open="View more"
            data-close="Show less"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            (click)="viewMore($event)">
            View more
          </button>
        </div>
      </ng-container> -->
    </div>
    <div class="modal-footer">
      <button type="button" (click)="clearFilter()" class="btn btn-block">{{'Clear' | translate}}</button>
      <button type="submit" class="btn-submit">Show result</button>
    </div>
  </form>
</app-mobile-modal-dialog>
