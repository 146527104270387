import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingComponent } from './components/rating/rating.component';
import { IconsModule } from '../generic/icons/icons.module';

const components = [RatingComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule],
  exports: components,
})
export class RatingModule {}
