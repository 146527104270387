<section class="partner">
  <div class="description">
    <p>
      {{'Whatever your production needs, we connect you with skilled professionals to enhance and bring your projects to
      life. Outstanding performances make exceptional projects.' | translate}}
    </p>
    <h6 translate>Proudly delivered for</h6>
  </div>
  <div class="swiper swiper-partner">
    <div class="swiper-wrapper">
      <div class="swiper-slide logo" style="background-image: url(/assets/img/mobile/partner/partner-1.png)"></div>
      <div class="swiper-slide logo" style="background-image: url(/assets/img/mobile/partner/partner-2.png)"></div>
      <div class="swiper-slide logo" style="background-image: url(/assets/img/mobile/partner/partner-3.png)"></div>
      <div class="swiper-slide logo" style="background-image: url(/assets/img/mobile/partner/partner-4.png)"></div>
    </div>
  </div>
</section>
