import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCommaToDot]',
})
export class CommaToDotDirective {
  @Input() appCommaToDot: string;

  private regexMap = {
    numberAndDot: /[^0-9.]/g,
    // Add more patterns here as needed
  };

  private allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Escape'];

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const regex = this.regexMap[this.appCommaToDot];

    if (regex) {
      const value = input.value.replace(regex, '');
      input.value = value;
      if (input.value !== value) {
        event.stopPropagation();
      }
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const regex = this.regexMap[this.appCommaToDot];
    if (regex && regex.test(event.key) && !this.allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
