import { BaseOppObjectDTO } from './base-opp-object.dto';

export enum SettlementStatus {
  current = 'current',
  transfer = 'transfer',
  paid = 'paid',
  cancelled = 'cancelled',
}
export class SettlementDTO extends BaseOppObjectDTO {
  public status: SettlementStatus;
  public paid: number;
  public period_start: number;
  public period_end: number;
  public total_number_of_transactions: number;
  public number_of_transactions: number;
  public number_of_refunds: number;
  public number_of_withdrawals: number;
  public number_of_mandates: number;
  public number_of_internal_transfers: number;
  public number_of_multi_transactions: number;
  public total_volume: number;
  public transaction_volume: number;
  public refund_volume: number;
  public withdrawal_volume: number;
  public mandate_volume: number;
  public internal_transfer_volume: number;
  public multi_transaction_volume: number;
  public total_transaction_costs: number;
  public transaction_costs: number;
  public refund_costs: number;
  public withdrawal_costs: number;
  public mandate_costs: number;
  public internal_transfer_costs: number;
  public multi_transaction_costs: number;
  public total_order_fees: number;
  public total_refund_fees: number;
  public total_gateway_fees: number;
  public total_amount: number;
  public amount_paid: number;
  public amount_payable: number;
  public payout_type: string;
  public po_number: string;
  public specifications: SettlementDTO[];
  public source: any;
}
