import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Language, PaginatedResults } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  public constructor(private http: HttpClient) {}

  public findAll(): Observable<PaginatedResults<Language>> {
    return this.http.get(`${environment.apiBaseUrl}/languages`).pipe(
      map((data: PaginatedResults<Language>) => {
        data.results = data.results.map((item: Language) => plainToClass(Language, item));
        return data;
      }),
    );
  }
}
