import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NotificationsStateModel } from './notifications.state-model';
import {
  ClearNotifications,
  FetchAllNotifications,
  MarkAllNotificationAsRead,
  MarkNotificationAsRead,
} from './notifications.actions';
import { patch, removeItem } from '@ngxs/store/operators';
import { INotification } from '@core/interfaces';
import { Notification } from '@core/models';
import { NotificationsService } from '@core/services';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthState } from '../auth';
import { JwtToken } from '@auth/models';
import { SseState } from '../sse';

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults: {
    notifications: [],
  },
})
@Injectable()
export class NotificationsState implements NgxsOnInit {
  public constructor(private store: Store, private notificationsService: NotificationsService) {}

  @Selector()
  static notifications(state: NotificationsStateModel): Notification[] {
    return state.notifications;
  }

  @Action(FetchAllNotifications)
  public async fetchAll(ctx: StateContext<NotificationsStateModel>): Promise<void> {
    const notifications = await this.notificationsService.fetchAll().toPromise();
    ctx.setState({ notifications });
  }

  @Action(ClearNotifications)
  public async ClearNotifications(ctx: StateContext<NotificationsStateModel>): Promise<void> {
    ctx.setState({ notifications: [] });
  }

  @Action(MarkNotificationAsRead)
  public markNotificationAsRead(
    ctx: StateContext<NotificationsStateModel>,
    { notification }: MarkNotificationAsRead,
  ): any {
    return this.notificationsService.markNotificationAsRead(notification.id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            notifications: removeItem((n: INotification) => n === notification),
          }),
        );
      }),
    );
  }

  @Action(MarkAllNotificationAsRead)
  public async markAllNotificationAsRead(ctx: StateContext<NotificationsStateModel>): Promise<any> {
    await this.notificationsService.markAllNotificationAsRead().toPromise();

    ctx.setState({ notifications: [] });
  }

  public ngxsOnInit(ctx: StateContext<NotificationsStateModel>): void {
    this.store
      .select(AuthState.jwtToken)
      .pipe(
        filter((token: JwtToken) => !!token),
        switchMap(() => ctx.dispatch(new FetchAllNotifications())),
      )
      .subscribe();

    this.store
      .select(SseState.event())
      .pipe(
        filter(
          (event: any) =>
            !!event &&
            ['notifications.notification.created', 'notifications.notification.updated'].includes(event.type),
        ),
        switchMap(() => this.store.dispatch(new FetchAllNotifications())),
      )
      .subscribe();
  }
}
