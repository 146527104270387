import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Job } from '@core/models';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { MobileJobDetailComponent } from '../mobile-job-detail/mobile-job-detail.component';
import { JobRequestsService, PaymentsService } from '@core/services';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-mobile-job-applicant',
  templateUrl: './mobile-job-applicant.component.html',
  styleUrls: ['./mobile-job-applicant.component.scss'],
})
export class MobileJobApplicantComponent implements OnInit {
  @Input()
  public jobDetail: Job;

  @Output()
  public edit = new EventEmitter<boolean>();

  @Output()
  public notification = new EventEmitter<boolean>();

  @Output()
  public duplicate = new EventEmitter<boolean>();

  @Output()
  public delete = new EventEmitter<boolean>();

  @ViewChild('detailJobModal') detailJobModal: MobileJobDetailComponent;
  @ViewChild('optionsModal') private modalComponent: MobileModalDialogComponent;

  constructor(
    private router: Router,
    private jobRequestService: JobRequestsService,
    private paymentsService: PaymentsService,
  ) {}

  ngOnInit(): void {}

  async openDialog() {
    return await this.modalComponent.open();
  }

  public closeDialog() {
    this.modalComponent.close();
  }

  public async openDetailJob(): Promise<void> {
    this.detailJobModal.openDialog(this.jobDetail);
  }

  public editJob() {
    this.modalComponent.close();
    this.edit.emit(true);
  }

  public notificationJob() {
    this.modalComponent.close();
    this.notification.emit(true);
  }

  public duplicateJob() {
    this.modalComponent.close();
    this.duplicate.emit(true);
  }

  public deleteJob() {
    this.modalComponent.close();
    this.delete.emit(true);
  }

  public async userWillPromoteJob(isPromoteJob: boolean): Promise<void> {
    if (isPromoteJob) {
      try {
        await this.jobRequestService.promotedJob(this.jobDetail.id).toPromise();
        const transaction = await this.paymentsService
          .createJobPromotedPayment(this.jobDetail.id, `${environment.baseUrl}` + this.router.url)
          .toPromise();
        window.location.href = transaction.redirect_url;
      } catch (error) {
        console.error('Failed to submit job request', error);
      }
    }
  }
}
