<section class="job">
  <div class="job-horizontal swiper">
    <div class="swiper-wrapper">
      <div class="card-job swiper-slide" *ngFor="let job of jobs" (click)="jobDetail(job)">
        <div class="content">
          <div class="header">
            <img [src]="job.media.length === 0 ? '/assets/img/caster-default.png': job.media[0].url" alt="" />
            <div>
              <p>{{job.title | truncate: 100:true}}</p>
            </div>
          </div>
          <div class="body">
            <ol>
              <li *ngIf="!job.hideRate">€ {{job.totalRate}}</li>
              <li translate>{{ job.category.translateKey }}</li>
              <li>
                {{ job.firstDate | dateRange: { exact: job.exactDate, withTime: false, dateFormatStartDate:"DD MMM",
                dateFormat: "DD MMM 'YY"} }}
              </li>
            </ol>
            <ol>
              <li *ngIf="!job.extraFees && (!job.hideRate || (userAlreadyApplied$ | async) === true)">
                <span translate>Buyout</span>
                &nbsp;
                <span translate>Included</span>
              </li>
              <li>
                <span *ngIf="job?.formattedGenders?.length >= 3; else genderList">{{'All Gender' | translate}}</span>
                <ng-template #genderList>
                  <span *ngFor="let gender of job?.formattedGenders; i as index" translate class="mr-1">
                    {{ gender }},
                  </span>
                </ng-template>
              </li>
              <li *ngIf="job.countries.length>0 && job.countries[0].city != ''">
                {{job.countries[0].city}}, {{job.countries[0].country.isoCode}}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-mobile-job-detail #detailJobModal></app-mobile-job-detail>
