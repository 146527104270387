export class InitializeAuth {
  public static readonly type = '[Auth] initialize';

  public constructor() {}
}

export class RefreshToken {
  public static readonly type = '[Auth] Refresh token';

  public constructor(public token: string) {}
}

export class SignIn {
  public static readonly type = '[Auth] Sign in';

  public constructor(public username: string, public password: string, public redirectUrl?: string) {}
}

export class VerifyAndSignIn {
  public static readonly type = '[Auth] Verify anbd Sign in';

  public constructor(public verificationCode: string) {}
}

export class SignOut {
  public static readonly type = '[Auth] Sign out';
}

export class SetRedirectUrl {
  public static readonly type = '[Auth] Set redirect url';

  public constructor(public redirectUrl: string) {}
}
