import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FiltersService } from '@core/services';

import { FiltersStateModel } from './filters.state-model';

import { LoadFilters } from './filters.actions';
import { Filter } from '@core/models';

@State<FiltersStateModel>({
  name: 'filters',
  defaults: {
    filters: null,
  },
})
@Injectable()
export class FiltersState {
  public constructor(private store: Store, private filtersService: FiltersService) {}

  @Selector()
  static filters(state: FiltersStateModel): Filter[] {
    return state.filters;
  }

  @Action(LoadFilters)
  public async loadFilters(ctx: StateContext<FiltersStateModel>): Promise<void> {
    const filters = await this.filtersService.findAll().toPromise();
    ctx.patchState({ filters });
  }
}
