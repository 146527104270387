import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Category, PaginatedResults } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  public constructor(private http: HttpClient) {}

  public findAll(): Observable<PaginatedResults<Category>> {
    return this.http.get(`${environment.apiBaseUrl}/categories`).pipe(
      map((data: PaginatedResults<Category>) => {
        data.results = data.results.map((item: Category) => plainToClass(Category, item));
        return data;
      }),
    );
  }

  public getById(id: string): Observable<Category> {
    return this.http
      .get(`${environment.apiBaseUrl}/categories/${id}`)
      .pipe(map((data: Category) => plainToClass(Category, data)));
  }

  public getBySlug(slug: string): Observable<Category> {
    return this.http
      .get(`${environment.apiBaseUrl}/categories/slug/${slug}`)
      .pipe(map((data: Category) => plainToClass(Category, data)));
  }
}
