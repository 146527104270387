import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { LocalFile } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  public constructor(private http: HttpClient) {}

  public readFile(file: File): Promise<string> {
    if (!file) {
      return;
    }

    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result.toString());
      reader.readAsDataURL(file);
    });
  }

  public upload(file: any): Observable<LocalFile> {
    return this.http.post<LocalFile>(`${environment.apiBaseUrl}/media/${file.id}`, file);
  }

  public uploadAndResize(file: any, size: number): Observable<LocalFile> {
    return this.http.post<LocalFile>(`${environment.apiBaseUrl}/media/${file.id}/resize/${size}`, file);
  }
}
