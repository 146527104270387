import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent {
  @Input()
  public value: any;

  @Input()
  public searchValue: string;

  @Input()
  public selected: any;

  @Input()
  public isDisabled: boolean;

  public constructor(public elementRef: ElementRef) {}

  @HostListener('click')
  public onClick(): void {
    if (!this.isDisabled) {
      this.click(this);
    }
  }

  public click = (_: any) => {};
}
