import { Component, OnInit } from '@angular/core';
import SwiperCore from 'swiper';

@Component({
  selector: 'app-mobile-cases-slide',
  templateUrl: './mobile-cases-slide.component.html',
  styleUrls: ['./mobile-cases-slide.component.scss'],
})
export class MobileCasesSlideComponent implements OnInit {
  public swiper: SwiperCore;

  constructor() {}

  ngOnInit(): void {
    this.swiper = new SwiperCore('.swiper', {
      slidesPerView: 'auto',
    });
  }
}
