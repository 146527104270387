import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { IMedia, MediaType } from '@core/interfaces';
import { Media } from '@core/models';
import { EmbedVideoService, SseService } from '@core/services';
import { FileService } from '@src/ui/generic/images/services/file.service';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { faChevronDown, faChevronUp, faUpload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-media-item-field',
  templateUrl: './media-item-field.component.html',
  styleUrls: ['./media-item-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaItemFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MediaItemFieldComponent),
      multi: true,
    },
  ],
})
export class MediaItemFieldComponent implements ControlValueAccessor, Validator {
  @Output()
  public delete: EventEmitter<any> = new EventEmitter();

  @Output()
  public moveUp = new EventEmitter();

  @Output()
  public moveDown = new EventEmitter();

  @Input()
  public albumId: string;

  @Input()
  public uploadType?: string;

  @Input()
  public disableDelete = false;

  @Input()
  public isProfilePhoto = false;

  @Input()
  public allowSorting = false;

  public accept = 'image/*';
  public thumbUrl$: BehaviorSubject<any> = new BehaviorSubject(null);
  public videoUrl = '';

  public isProcessing = false;
  public isUploading = false;

  public fileControl: AbstractControl;
  public value: IMedia;

  public chevronDownIcon = faChevronDown;
  public chevronUpIcon = faChevronUp;
  public uploadIcon = faUpload;

  public constructor(
    private fb: FormBuilder,
    private fileService: FileService,
    private sseService: SseService,
    private embedVideoService: EmbedVideoService,
  ) {
    this.fileControl = this.fb.control(null);
    this.fileControl.valueChanges.subscribe((file: File) => this.uploadFile(file));
  }

  public onChange = (_: any): void => {};
  public onTouched = (): void => {};

  public async uploadFile(file: File): Promise<void> {
    const id: string = this.value.id || v4();

    this.value.externalThumbUrl = null;
    this.value.link = null;

    this.updateThumbUrl({ item: this.value, state: 'uploading', file });

    this.isUploading = true;
    this.value.uploaded = false;
    this.onChange(this.value);

    await this.fileService
      .upload({
        id,
        name: file.name,
        type: file.type,
        size: file.size,
        target: {
          type: this.uploadType ?? 'userAlbum',
          payload: this.albumId,
        },
        data: await this.readFile(file),
      })
      .toPromise();

    this.value.uploaded = true;
    this.isUploading = false;
    this.updateThumbUrl({ item: this.value, state: 'processing', file });
    this.onChange(this.value);
  }

  public writeValue(value: IMedia): void {
    this.value = value;
    this.accept = value.mediaType === MediaType.photo ? 'image/*' : 'video/*';
    this.updateThumbUrl({ item: value, state: 'initial' });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (!this.value.link && !this.value.uploaded) {
      return { uploaded: false };
    }

    return;
  }

  public async onPasteUrl(event: any): Promise<void> {
    const customUrl = event.clipboardData.getData('text/plain');
    const imageUrl = await this.embedVideoService.embedImage(customUrl);
    if (!!imageUrl) {
      this.thumbUrl$.next(imageUrl.link);
      this.value.link = customUrl;
      this.value.externalThumbUrl = imageUrl.link;
      this.value.path = '';
      this.value.uploaded = true;
      this.onChange(this.value);
    }
  }

  public async updateVideoUrl(customUrl: string): Promise<void> {
    if (customUrl === '') {
      if (this.value.path === '') {
        this.value.link = null;
        this.value.externalThumbUrl = null;
        this.value.uploaded = false;
        this.onChange(this.value);
        this.thumbUrl$.next('');
      }
    } else {
      const placeholder = '/assets/img/processing_video.gif';
      this.thumbUrl$.next(placeholder);
      const imageUrl = await this.embedVideoService.embedImage(customUrl);
      if (!!imageUrl) {
        this.value.link = customUrl;
        this.value.externalThumbUrl = imageUrl.link;
        this.value.path = '';
        this.value.uploaded = true;
        this.onChange(this.value);
        this.thumbUrl$.next(this.value.externalThumbUrl);
      } else {
        this.value.link = null;
        this.value.externalThumbUrl = null;
        this.value.uploaded = false;
        this.onChange(this.value);
        this.thumbUrl$.next('');
      }
    }
  }

  private readFile(file: File): Promise<string> {
    return new Promise((resolve, _): void => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => resolve(e.target.result.toString());
      reader.readAsDataURL(file);
    });
  }

  private updateThumbUrl(data: { item: IMedia; state?: string; file?: File }): void {
    if (!!data.item.link) {
      this.updateVideoUrl(data.item.link);
      this.videoUrl = data.item.link;
      return;
    }
    const placeholder = '/assets/img/processing_video.gif';
    const checkmark = '/assets/img/purple-checklist.png';
    const unprocessedThumbUrl$ = this.sseService.events$.pipe(
      filter((ev: any): boolean => ev.type === 'media.transcoding.finished'),
      map((ev: any) => ev.payload),
      map((m: IMedia) => plainToClass(Media, m)),
      filter((m: IMedia): boolean => m.mediaType === MediaType.video),
      filter((m: IMedia): boolean => m.id === data.item.id),
      map((m: IMedia) => m.thumbUrl),
    );

    if (data.state === 'initial') {
      if (data.item.processed) {
        this.thumbUrl$.next(data.item.thumbUrl);
        return;
      }

      if (data.item.uploaded) {
        this.thumbUrl$.next(placeholder);
      }
    }

    if (data.state === 'uploading') {
      if (data.item.mediaType !== MediaType.video) {
        this.thumbUrl$.next(data.file);
        return;
      }

      this.thumbUrl$.next(placeholder);
    }

    if (data.state === 'processing') {
      if (data.item.mediaType !== MediaType.video) {
        this.value.processed = true;
        this.value.link = null;
        this.onChange(this.value);
        this.thumbUrl$.next(data.file);
      } else {
        this.thumbUrl$.next(checkmark);
      }
    }

    unprocessedThumbUrl$
      .pipe(
        tap((url: string) => this.thumbUrl$.next(url)),
        tap((): void => {
          this.value.processed = true;
          this.onChange(this.value);
        }),
      )
      .subscribe();
  }
}
