import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PackagesService } from '@core/services';

import { PackagesStateModel } from './packages.state-model';
import { DeletePackage, SavePackage } from './packages.actions';

@State<PackagesStateModel>({
  name: 'packages',
})
@Injectable()
export class PackagesState {
  public constructor(private packagesService: PackagesService) {}

  @Action(DeletePackage)
  public async deletePackage(_: StateContext<PackagesStateModel>, { p }: DeletePackage): Promise<void> {
    await this.packagesService.delete(p.id).toPromise();
  }

  @Action(SavePackage)
  public async savePackages(_: StateContext<PackagesStateModel>, { p }: SavePackage): Promise<void> {
    await this.packagesService.save(p).toPromise();
  }
}
