import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IUserProfile } from '@core/interfaces/user-profile';
import { SplideOptions } from '@splidejs/splide';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';

@Component({
  selector: 'app-talents-carousel',
  templateUrl: './talents-carousel.component.html',
  styleUrls: ['./talents-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalentsCarouselComponent implements OnChanges {
  @Input()
  public talents: IUserProfile[];

  public tileSize: any;

  public options: SplideOptions = {
    autoHeight: true,
    autoWidth: true,
    keyboard: 'focused',
    pagination: false,
    padding: 0,
    perPage: 12,
    preloadPages: 1,
    waitForTransition: false,
  };

  public constructor(private cd: ChangeDetectorRef, private layoutCalculator: LayoutCalculatorService) {
    this.layoutCalculator.layoutChanged$.subscribe((data) => this.updateParams(data));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('talents' in changes) {
      this.updateParams(this.layoutCalculator.data);
    }
  }

  private updateParams(data: any): void {
    const perRow = this.layoutCalculator.getTalentsPerRow(data.breakpointName);
    this.tileSize = this.layoutCalculator.getTileSize(perRow, 9 / 16);

    this.options = {
      ...this.options,
      padding: data.padding,
      gap: data.gap,
      perPage: perRow,
      perMove: perRow - 1,
    };

    this.cd.detectChanges();
  }
}
