import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Invoice } from '@core/models';
import { JobInvoice } from '@core/models/job-invoice';

@Injectable({
  providedIn: 'root',
})
export class JobInvoiceService {
  public constructor(private http: HttpClient) {}

  public getByJobApplicantId(jobApplicationId: string): Observable<JobInvoice> {
    return this.http
      .get(`${environment.apiBaseUrl}/job-invoice/applicant/${jobApplicationId}`)
      .pipe(map((data: JobInvoice) => plainToClass(JobInvoice, data)));
  }

  public sync(jobApplicationId: string): Observable<JobInvoice> {
    return this.http
      .put(`${environment.apiBaseUrl}/job-invoice/sync/${jobApplicationId}`, {})
      .pipe(map((data: JobInvoice) => plainToClass(JobInvoice, data)));
  }

  public decline(jobApplicationId: string, reason: string): Observable<JobInvoice> {
    return this.http
      .put(`${environment.apiBaseUrl}/job-invoice/decline/${jobApplicationId}`, { reason })
      .pipe(map((data: JobInvoice) => plainToClass(JobInvoice, data)));
  }
}
