import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnsPipe } from './pipes/columns.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { DateRangePipe } from './pipes/date-range.pipe';
import { TranslateSortPipe } from './pipes/translate-sort.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { AsPipe } from '@shared/pipes/pipes/as.pipe';
import { TimeRemainingPipe } from './pipes/time-remaining.pipe';

@NgModule({
  declarations: [
    SortPipe,
    ColumnsPipe,
    DateRangePipe,
    TranslateSortPipe,
    TruncatePipe,
    DateAgoPipe,
    AsPipe,
    TimeRemainingPipe,
  ],
  imports: [CommonModule],
  exports: [
    SortPipe,
    ColumnsPipe,
    DateRangePipe,
    TranslateSortPipe,
    TruncatePipe,
    DateAgoPipe,
    AsPipe,
    TimeRemainingPipe,
  ],
})
export class PipesModule {}
