import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FeeTypesService } from '@core/services';

import { FeeTypesStateModel } from './fee-types.state-model';

import { LoadFeeTypes } from './fee-types.actions';
import { IFeeType } from '@core/interfaces/fee-type';

@State<FeeTypesStateModel>({
  name: 'feeTypes',
  defaults: {
    feeTypes: null,
  },
})
@Injectable()
export class FeeTypesState implements NgxsOnInit {
  public constructor(private feeTypesService: FeeTypesService) {}

  @Selector()
  static feeTypes(state: FeeTypesStateModel): IFeeType[] {
    return state.feeTypes;
  }

  @Action(LoadFeeTypes)
  public async loadFeeTypes(ctx: StateContext<FeeTypesStateModel>): Promise<void> {
    const feeTypes = await this.feeTypesService.findAll().toPromise();
    ctx.patchState({ feeTypes });
  }

  public ngxsOnInit(ctx: StateContext<FeeTypesState>): void {
    ctx.dispatch(new LoadFeeTypes());
  }
}
