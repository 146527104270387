import { User } from '@core/models';

export class StartVerifyEmail {
  public static readonly type = '[VerifyEmail] Start email verification';

  public constructor(public verificationCode: string) {}
}

export class ResendEmailVerification {
  public static readonly type = '[VerifyEmail] Resend email verification';

  public constructor(public user: User) {}
}
