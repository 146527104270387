import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Note } from '@core/models';
import { INote } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  public constructor(private http: HttpClient) {}

  public getNoteByTalent(talentId: string): Observable<Note> {
    return this.http
      .get(`${environment.apiBaseUrl}/notes/talent/${talentId}`)
      .pipe(map((data: Note) => plainToClass(Note, data)));
  }

  public upsert(note: INote): Observable<Note> {
    return this.http
      .post(`${environment.apiBaseUrl}/notes/${note.id}`, note)
      .pipe(map((data: INote) => plainToClass(Note, data)));
  }

  public delete(id: string): Observable<void> {
    return this.http.delete(`${environment.apiBaseUrl}/notes/${id}`).pipe(map((_) => {}));
  }
}
