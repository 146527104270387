<div class="d-flex flex-column w-100 text-white">
  <div class="mx-5 mt-5">
    <h1 class="h3 text-center" translate>Payment failed</h1>
    <div class="my-5 text-center">
      <span translate>There was an error with your payment. Please try again or contact us at</span>
      <a href="mailto:info@casterbee.nl">info@casterbee.nl</a>
    </div>
    <app-button (click)="closeModal()" buttonStyle="orange" class="mb-5">
      <span class="px-2" translate>OK</span>
    </app-button>
  </div>
</div>
