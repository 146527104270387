import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { ClearNotifications, SignOut } from '@core/states';
import { MobileCreditModalComponent } from '../mobile-credit-modal/mobile-credit-modal.component';
import { MobileStorageKey, MobileStorageService } from '../services/mobile-storage.service';
import { MobileLanguangeModalComponent } from '../mobile-languange-modal/mobile-languange-modal.component';
import { Router } from '@angular/router';

export interface IListMenu {
  name: string;
  notif?: boolean;
  type: 'url' | 'logout' | 'credit' | 'languange';
  value?: any;
  totalData?: number;
}

@Component({
  selector: 'app-mobile-list',
  templateUrl: './mobile-list.component.html',
  styleUrls: ['./mobile-list.component.scss'],
})
export class MobileListComponent implements OnInit {
  @Input()
  public menus: Array<IListMenu>;

  @ViewChild('creditModal') public creditModal: MobileCreditModalComponent;
  @ViewChild('langModal') private languangeModal: MobileLanguangeModalComponent;

  constructor(private store: Store, private router: Router, private mobileStorage: MobileStorageService) {}

  ngOnInit(): void {}

  public get isTalent(): boolean {
    if (this.mobileStorage.get(MobileStorageKey.activeRole) === 'talent') {
      return true;
    }
    return false;
  }

  public actionIdentifier(menu: IListMenu) {
    switch (menu.type) {
      case 'logout':
        localStorage.clear();
        this.store.dispatch([new SignOut(), new ClearNotifications()]);
        break;
      case 'credit':
        this.creditModal.openDialog();
        break;
      case 'languange':
        this.languangeModal.openDialog();
        break;
    }
  }

  public openUrl(url: string) {
    this.router.navigateByUrl(url);
  }
}
