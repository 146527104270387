import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { SplideOptions } from '@splidejs/splide';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';
import { UserProfile } from '@core/models';

@Component({
  selector: 'app-talent-card',
  templateUrl: './talent-card.component.html',
  styleUrls: ['./talent-card.component.scss'],
})
export class TalentCardComponent implements OnChanges {
  @Input()
  public talent: UserProfile;

  @Input()
  public showAddToDeck = true;

  @HostBinding('class')
  public hover = '';

  public imageSize: any;
  public tileSize: any;
  public footerHeight = 64;

  public options: SplideOptions = {
    keyboard: false,
    perPage: 1,
    perMove: 1,
    pagination: true,
    waitForTransition: false,
    type: 'loop',
    lazyLoad: 'nearby',
    preloadPages: 1,
  };

  public constructor(
    private cd: ChangeDetectorRef,
    private layoutCalculator: LayoutCalculatorService,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.layoutCalculator.layoutChanged$.subscribe((data) => this.updateParams(data));
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.updateParams(this.layoutCalculator.data);
    this.hover = 'hover';
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.hover = '';
  }

  // @HostListener('click', ['$event'])
  public onClick(e: any): void {
    const parentDatasetKeys = Object.keys(e.target.parentNode.dataset);

    if (parentDatasetKeys.includes('splideArrow') || parentDatasetKeys.includes('addToDeck')) {
      return;
    }

    this.store.dispatch(
      new Navigate(['profile', this.talent.user.id, this.talent.id], this.route.snapshot.queryParams, {
        relativeTo: this.route,
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('talent' in changes) {
      this.updateParams(this.layoutCalculator.data);
    }
  }

  public get isSelected(): boolean {
    const profileId = this.talent.id;
    const bulkMessageStorage = localStorage.getItem('bulkMessage');
    if (bulkMessageStorage) {
      const bulkMessage = JSON.parse(bulkMessageStorage);
      return bulkMessage.includes(profileId);
    }
    return false;
  }

  public onSelect(profileId: string, event: any): void {
    event.preventDefault();
    event.stopPropagation();

    const bulkMessageStorage = localStorage.getItem('bulkMessage');
    if (bulkMessageStorage) {
      const bulkMessage = JSON.parse(bulkMessageStorage);
      const index = bulkMessage.indexOf(profileId);
      if (index > -1) {
        // Remove profileId if it exists
        bulkMessage.splice(index, 1);
      } else {
        // Add profileId if it doesn't exist
        bulkMessage.push(profileId);
      }
      localStorage.setItem('bulkMessage', JSON.stringify(bulkMessage));
    } else {
      localStorage.setItem('bulkMessage', JSON.stringify([profileId]));
    }

    // Toggle the checkbox
    event.target.checked = !event.target.checked;
  }

  public get isBulkMessage(): boolean {
    const bulkMessageStorage = localStorage.getItem('bulkMessage');
    return bulkMessageStorage !== null;
  }

  private updateParams(data: any): void {
    const perRow = this.layoutCalculator.getTalentsPerRow(data.breakpointName);
    this.tileSize = this.layoutCalculator.getTileSize(perRow, 9 / 16);
    this.imageSize = this.layoutCalculator.getImageSize(this.tileSize);

    this.options = {
      ...this.options,
      width: this.imageSize.width,
      height: this.imageSize.height,
    };

    this.cd.detectChanges();
  }
}
