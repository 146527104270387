import { ICategory } from '@core/interfaces/category';
import { Type } from 'class-transformer';
import { TalentCategory } from './talent-category.model';
import { Filter } from './filter.model';

export class Category implements ICategory {
  @Type(() => Filter)
  public filters?: Filter[];
  @Type(() => TalentCategory)
  public talentCategories?: TalentCategory[];

  public id: string;
  public slug: string;
  public label: string;
  public description?: string;

  get translateKey(): string {
    return `CATEGORY.${this.slug}`;
  }

  get translateKeySingular(): string {
    return `CATEGORY.${this.slug}.singular`;
  }
}
