import { Action, State, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersService } from '@core/services';
import { patch, updateItem } from '@ngxs/store/operators';

import { FetchAll, SetUserVisibility, SetUserBypassPayment } from './users.actions';
import { plainToClass } from 'class-transformer';
import { User, PaginatedResults } from '@core/models';
import { UsersStateModel } from './users.state-model';

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: null,
  },
})
@Injectable()
export class UsersState {
  public constructor(private usersService: UsersService) {}

  @Selector()
  static users(state: UsersStateModel): PaginatedResults<User> {
    return state.users;
  }

  @Action(FetchAll)
  public async fetchAll(
    ctx: StateContext<UsersStateModel>,
    { query, userRole, page, pageSize, sortColumn, sortDirection }: FetchAll,
  ): Promise<void> {
    const users = await this.usersService
      .findAll(query, userRole, page, pageSize, sortColumn, sortDirection)
      .toPromise();
    ctx.setState({ users });
  }

  @Action(SetUserVisibility)
  public async setUserVisibility(
    ctx: StateContext<UsersStateModel>,
    { hidden, user }: SetUserVisibility,
  ): Promise<void> {
    await this.usersService.setVisibility(user.id, hidden).toPromise();

    ctx.setState(
      patch({
        users: patch({
          results: updateItem<User>((u) => u.id === user.id, plainToClass(User, { ...user, hidden })),
        }),
      }),
    );
  }

  @Action(SetUserBypassPayment)
  public async setUserBypassPayment(
    ctx: StateContext<UsersStateModel>,
    { bypassPayment, user }: SetUserBypassPayment,
  ): Promise<void> {
    await this.usersService.setBypassPayment(user.id, bypassPayment).toPromise();

    ctx.setState(
      patch({
        users: patch({
          results: updateItem<User>((u) => u.id === user.id, plainToClass(User, { ...user, bypassPayment })),
        }),
      }),
    );
  }
}
