import { Component, Input } from '@angular/core';
import { IJob } from '@core/interfaces';

@Component({
  selector: 'app-job-rows-list',
  templateUrl: './job-rows-list.component.html',
  styleUrls: ['./job-rows-list.component.scss'],
})
export class JobRowsListComponent {
  @Input()
  public jobs: IJob[];

  @Input()
  public promoted = false;
}
