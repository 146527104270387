import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileService } from '@src/ui/generic/images/services/file.service';

@Component({
  selector: 'app-mobile-upload-file',
  templateUrl: './mobile-upload-file.component.html',
  styleUrls: ['./mobile-upload-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobileUploadFileComponent),
      multi: true,
    },
  ],
})
export class MobileUploadFileComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public file: string;

  @Input()
  public allowMimeType: string;

  public fileURL: string;
  private value: string;

  constructor(private fileService: FileService) {}

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('file' in changes) {
      if (changes.file.currentValue instanceof File) {
        const base64 = await this.readFile(changes.file.currentValue);
        if (base64) {
          this.fileURL = base64;
          return;
        }
      } else if (changes.file.currentValue !== 'file') {
        this.fileURL = this.file;
      }
    }
  }

  public onValueChange(value: FileList): void {
    this.onChange(value[0]);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private updateField(value: string): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.value = value;
  }

  private readFile(file: File): Promise<string> {
    return this.fileService.readFile(file);
  }
}
