import { Component, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IUserProfile, IUser } from '@core/interfaces';
import { DeckSelectionModalComponent } from '../deck-selection-modal/deck-selection-modal.component';
import { JoinModalComponent } from '@src/visitor/modals/components/join-modal/join-modal.component';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from '@core/states';
import { Navigate } from '@ngxs/router-plugin';
import { AuthService } from '@auth/services';

@Component({
  selector: 'app-add-to-deck-button',
  templateUrl: './add-to-deck-button.component.html',
  styleUrls: ['./add-to-deck-button.component.scss'],
})
export class AddToDeckButtonComponent {
  @Select(UserState.user)
  public user$: Observable<IUser>;

  @Input()
  public userProfile: IUserProfile;

  @Input()
  public iconOnly = true;

  private loggedIn = false;

  public constructor(private dialog: MatDialog, private store: Store, private authService: AuthService) {
    this.user$.subscribe((user) => {
      this.loggedIn = user != null;
    });
  }

  @HostListener('click')
  public async openDeckSelectionModal(): Promise<void> {
    if (this.authService.isDisabled()) {
      await this.store.dispatch(new Navigate(['/account/disabled'])).toPromise();
      return;
    }
    if (!this.loggedIn) {
      this.dialog.open(JoinModalComponent, {});
      return;
    }
    const dialogRef = this.dialog.open(DeckSelectionModalComponent, {
      data: {
        userProfile: this.userProfile,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      return;
    }
  }
}
