<div
  class="d-flex flex-column w-100 popup position-relative"
  [ngClass]="{
    bgtalent: activeTemplate === 'signin' || activeTemplate === 'join',
    bggradient: activeTemplate === 'signup' || activeTemplate === 'forgot_password_success' || activeTemplate === 'signup_confirmation_email'
  }">
  <div
    *ngIf="['forgot_password'].includes(activeTemplate) || (initalForm === 'signin' && activeTemplate === 'signup')"
    class="d-flex flex-column align-items-end w-100">
    <div (click)="handleBack()" class="back-btn align-items-center justify-content-center d-flex flex-column">
      <fa-icon
        [styles]="{color: activeTemplate === 'forgot_password' || activeTemplate === 'forgot_password_success' ? '#5E1DED' : '#FFFFFF'}"
        [icon]="backIcon"></fa-icon>
    </div>
  </div>
  <div class="close-btn position-absolute align-self-end">
    <fa-icon
      class="close-icon cursor-pointer"
      [ngClass]="{'purple-icon': activeTemplate === 'forgot_password' || activeTemplate === 'forgot_password_success', 'white-icon': activeTemplate !== 'forgot_password' && activeTemplate !== 'forgot_password_success'}"
      [icon]="closeIcon"
      (click)="closeModal()"></fa-icon>
  </div>
  <div
    class="d-flex flex-column align-items-center logo-container"
    [ngClass]="{ 'flex-grow-1': activeTemplate === 'join' || activeTemplate === 'signin' || activeTemplate === 'signup'  }"
    [ngStyle]="activeTemplate === 'forgot_password' && { 'padding-top': '0px' }">
    <img src="/assets/img/logo-white.png" *ngIf="activeTemplate !== 'forgot_password'" alt="logo" width="83px" />
    <img src="/assets/img/logo-purple.png" *ngIf="activeTemplate === 'forgot_password'" alt="logo" width="83px" />
  </div>

  <app-getting-started-join
    *ngIf="activeTemplate === 'join'"
    (pressLogin)="switchTemplate('signin')"
    (pressSignup)="switchTemplate('signup')"></app-getting-started-join>

  <app-getting-started-signin
    *ngIf="activeTemplate === 'signin'"
    (pressSignup)="switchTemplate('signup')"
    (pressForgotPassword)="switchTemplate('forgot_password')"></app-getting-started-signin>

  <app-getting-started-forgot-password
    *ngIf="activeTemplate === 'forgot_password'"
    (forgotPasswordSuccess)="switchTemplate('forgot_password_success')"></app-getting-started-forgot-password>

  <app-getting-started-forgot-password-success
    *ngIf="activeTemplate === 'forgot_password_success'"
    (pressLogin)="switchTemplate('signin')"></app-getting-started-forgot-password-success>

  <app-getting-started-signup
    *ngIf="activeTemplate === 'signup'"
    (signupSuccess)="signupSuccess($event)"></app-getting-started-signup>

  <app-getting-started-signup-success
    *ngIf="activeTemplate === 'signup_confirmation_email'"
    [email]="email"></app-getting-started-signup-success>
</div>
