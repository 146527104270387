import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { VatResultDTO } from '@core/dto';

@Injectable({
  providedIn: 'root',
})
export class VatService {
  public constructor(private http: HttpClient) {}

  public checkVat(country: string, vatNumber: string): Observable<VatResultDTO> {
    return this.http
      .get(`${environment.apiBaseUrl}/vat`, {
        params: {
          country,
          vatNumber,
        },
      })
      .pipe(map((item: VatResultDTO) => plainToClass(VatResultDTO, item)));
  }
}
