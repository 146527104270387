import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { VerificationsStateModel } from './verifications.state-model';
import { LoadPendingVerifications, MarkAsVerified, MarkAsUnverified } from './verifications.actions';
import { UsersService } from '@core/services';
import { patch, removeItem } from '@ngxs/store/operators';
import { IUser } from '@core/interfaces/user';

@State<VerificationsStateModel>({
  name: 'verfications',
  defaults: {
    pending: [],
  },
})
@Injectable()
export class VerificationsState {
  public constructor(private usersService: UsersService) {}

  @Selector()
  static pending(state: VerificationsStateModel): any[] {
    return state.pending;
  }

  @Action(LoadPendingVerifications)
  public async loadPendingVerifications(ctx: StateContext<VerificationsStateModel>): Promise<void> {
    const pending = await this.usersService.findPendingVerifications().toPromise();
    ctx.patchState({ pending });
  }

  @Action(MarkAsVerified)
  public async markAsVerified(ctx: StateContext<VerificationsStateModel>, { user }: MarkAsVerified): Promise<void> {
    await this.usersService.markAsVerified(user).toPromise();
    ctx.setState(patch({ pending: removeItem<IUser>((u) => u.id === user.id) }));
  }

  @Action(MarkAsUnverified)
  public async markAsUnverified(ctx: StateContext<VerificationsStateModel>, { user }: MarkAsUnverified): Promise<void> {
    await this.usersService.markAsUnverified(user).toPromise();
    ctx.setState(patch({ pending: removeItem<IUser>((u) => u.id === user.id) }));
  }
}
