import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { CategoriesState, GeocoderState, LanguagesState } from '@core/states';
import { EMPTY, Observable, of } from 'rxjs';
import { Category, Filter, GeoLocation, Language, PaginatedResults, TalentCategory } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UnsubscribeComponent } from '@core/helpers';
import { SearchPageHelper } from '@src/visitor/components/forms/search-form/search-page.helper';
import { parse } from 'qs';
import { distinctUntilChanged, filter, startWith, switchMap, takeUntil, throttleTime } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { SearchState, SetCategory, SetFilters } from '@src/visitor/states/search';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesService, FiltersService } from '@core/services';

@Component({
  selector: 'app-mobile-filter-modal',
  templateUrl: './mobile-filter-modal.component.html',
  styleUrls: ['./mobile-filter-modal.component.scss'],
})
export class MobileFilterModalComponent extends UnsubscribeComponent implements OnInit, OnChanges {
  @Input()
  public dataFilter: any = {};

  @Select(CategoriesState.categories)
  public categories$: Observable<PaginatedResults<Category>>;

  @Select(SearchState.categoryFilters)
  public filters$: Observable<Filter>;

  @Select(GeocoderState.geolocation)
  public geolocation$: Observable<GeoLocation>;

  @Select(LanguagesState.languages)
  public languages$: Observable<PaginatedResults<Language>>;

  @ViewChild('appMobileFilterModal') private modalComponent: MobileModalDialogComponent;

  public page: string;
  public filterForm: FormGroup;
  public subforms: any;
  public talentCategories: Array<TalentCategory> = [];
  public filterCategories: Array<Filter> = [];
  public languangeSelected: string;
  public categorySelected: string;
  public talentCategorySelected: string;
  public effectiveFilters: any;
  public slidersRefresh: EventEmitter<void> = new EventEmitter<void>();
  public filterFB = new FormGroup({
    male: new FormControl(null),
    female: new FormControl(null),
    transgender: new FormControl(null),
    toAge: new FormControl(null),
    fromAge: new FormControl(null),
    toRate: new FormControl(null),
    fromRate: new FormControl(null),
    categorySlug: new FormControl(null),
    description: new FormControl(null),
    freebie: new FormControl(null),
    x: new FormControl(null),
    y: new FormControl(null),
    distance: new FormControl(null),
    filters: new FormGroup({}),
  });
  protected searchPageHelper: SearchPageHelper;

  constructor(
    protected translateService: TranslateService,
    protected categoryService: CategoriesService,
    protected filterService: FiltersService,
    protected route: ActivatedRoute,
    protected store: Store,
    protected fb: FormBuilder,
  ) {
    super();

    route.url.subscribe(() => {
      this.page = route.snapshot.url.join('');
    });

    this.searchPageHelper = new SearchPageHelper(this.store, this.translateService);
    this.subforms = this.searchPageHelper.getSubforms();

    const effectiveFilters = this.getFiltersFromQueryString();
    const filtersInState = this.store.selectSnapshot(SearchState.filters);

    if (effectiveFilters?.categorySlug == null || effectiveFilters?.categorySlug !== filtersInState?.categorySlug) {
      this.store.dispatch([new SetCategory(effectiveFilters.categorySlug)]);
    }

    const filterFormChanged$ = this.filters$.pipe(
      takeUntil(this.ngDestroyed$),
      filter((filters) => filters !== null),
      switchMap((filters) => this.processFilters(this.getFiltersFromQueryString(), filters)),
      switchMap((filters) => this.setupForm(filters)),
      switchMap((formGroup: FormGroup) => formGroup.valueChanges.pipe(startWith(formGroup.value))),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      throttleTime(100),
      switchMap((data) =>
        this.store
          .selectOnce(SearchState.filters)
          .pipe(
            switchMap((currentFilter) =>
              isEqual(currentFilter, data) ? EMPTY : this.store.dispatch(new SetFilters(data, this.page, this.route)),
            ),
          ),
      ),
    );

    this.geolocation$.subscribe((geoLocation: GeoLocation) => {
      if (geoLocation != null) {
        this.filterFB.patchValue({
          description: geoLocation.description,
          x: geoLocation.coordinates.x,
          y: geoLocation.coordinates.y,
        });
      }
    });

    filterFormChanged$.subscribe();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('dataFilter' in changes) {
      if (
        this.filterForm !== undefined &&
        this.dataFilter !== undefined &&
        this.categorySelected !== this.dataFilter.slug
      ) {
        if (this.dataFilter.slug === 'host-and-promotors') {
          // This condition must hardcode, because since now from backend can't provide data category presenter
          // This code will filter actor and sub is presenter
          this.filterForm.patchValue({
            categorySlug: 'actors',
          });
          return;
        }
        this.filterForm.patchValue({
          categorySlug: this.dataFilter.slug,
          talentCategorySlug: null,
        });
      }
    }
  }

  public refreshSliders(): void {
    this.slidersRefresh.emit();
  }

  public viewMore(e: any) {
    const selectorID = e.target.getAttribute('data-id');
    const body = e.target.parentElement.querySelector(selectorID);

    if (e.target.getAttribute('aria-expanded') === 'false') {
      e.target.innerHTML = e.target.getAttribute('data-close');
      e.target.setAttribute('aria-expanded', true);
      body.classList.add('show');
    } else {
      e.target.setAttribute('aria-expanded', false);
      e.target.innerHTML = e.target.getAttribute('data-open');
      body.classList.remove('show');
    }
  }

  public submitFilter() {
    const patchValues = {
      gender: {
        male: this.filterFB.get('male') !== null ? this.filterFB.get('male').value : null,
        female: this.filterFB.get('female') !== null ? this.filterFB.get('female').value : null,
      },
      transgender: this.filterFB.get('transgender') !== null ? this.filterFB.get('transgender').value : null,
      age: [
        this.filterFB.get('fromAge') !== null ? this.filterFB.get('fromAge').value : null,
        this.filterFB.get('toAge') !== null ? this.filterFB.get('toAge').value : null,
      ],
      rate: [
        this.filterFB.get('fromRate') !== null ? this.filterFB.get('fromRate').value : null,
        this.filterFB.get('toRate') !== null ? this.filterFB.get('toRate').value : null,
      ],
      location: {
        description: this.filterFB.get('description') !== null ? this.filterFB.get('description').value : null,
        x: this.filterFB.get('x') !== null ? this.filterFB.get('x').value : null,
        y: this.filterFB.get('y') !== null ? this.filterFB.get('y').value : null,
        distance: this.filterFB.get('distance') !== null ? this.filterFB.get('distance').value : 0,
      },
      categorySlug: this.categorySelected,
      talentCategorySlug: this.talentCategorySelected,
      language: { isoCode: this.languangeSelected },
      // filters: {},
      tfp: {},
      xchange: {},
    };

    const freebie = this.filterFB.get('freebie').value;
    if (freebie !== undefined && freebie) {
      patchValues.xchange = true;
      patchValues.tfp = true;
    }

    // const result = this.filterFB.get('filters').value;
    // if (result !== undefined) {
    //   const effectiveFilters = {};
    //   for (const filterKey of Object.keys(result)) {
    //     const match = this.filterCategories.find((f) => f.slug === filterKey);
    //     const filterValue = result[filterKey];
    //     if (match.type === 'checkbox') {
    //       effectiveFilters[filterKey] = filterValue
    //         .filter((item: any) => item.checked === true)
    //         .map((item: any) => item.value);
    //       continue;
    //     }
    //     effectiveFilters[filterKey] = filterValue;
    //   }
    //   patchValues.filters = effectiveFilters;
    // }

    this.filterForm.patchValue(patchValues);
    this.modalComponent.close();
  }

  public languangeIsChecked(value: string) {
    if (this.languangeSelected === value) {
      this.languangeSelected = null;
    } else {
      this.languangeSelected = value;
    }
    // this.languangeSelected = value;
    // const findLanguange = this.languangeSelected.find((res, _) => value.id === res.id);
    // if (findLanguange === undefined) {
    //   this.languangeSelected.push(value)
    // } else {
    //   this.languangeSelected = this.languangeSelected.filter((res) => res.id !== findLanguange.id)
    // }
  }

  public categoryIsChecked(value: string) {
    this.talentCategories = [];
    if (this.categorySelected === value) {
      this.categorySelected = null;
    } else {
      this.categorySelected = value;
      this.fetchTalentCategory();
    }
    // const findCategory = this.categorySelected.find((res, _) => value.translateKey === res.translateKey);
    // if (findCategory === undefined) {
    //   this.categorySelected.push(value)
    // } else {
    //   this.categorySelected = this.categorySelected.filter((res) => res.translateKey !== findCategory.translateKey)
    // }
  }

  public talentCategoryIsChecked(value: string) {
    if (this.talentCategorySelected === value) {
      this.talentCategorySelected = null;
    } else {
      this.talentCategorySelected = value;
    }
  }

  public fetchTalentCategory() {
    if (this.categorySelected !== null) {
      this.talentCategories = [];
      this.categoryService
        .getBySlug(this.categorySelected)
        .toPromise()
        .then((res) => {
          if (res.talentCategories !== null) {
            this.filterCategories.push(...res.filters);
            this.talentCategories.push(...res.talentCategories);
            // this.setupFormMoreFilter();
          }
        });
    }
  }

  async close() {
    return await this.modalComponent.close();
  }

  public clearFilter() {
    this.searchPageHelper.patchSubform('location', { description: null, x: null, y: null, distance: 0 });
    this.searchPageHelper.patchSubform('gender', { male: null, female: null, transgender: null });
    this.searchPageHelper.patchSubform('age', [null, null]);
    this.searchPageHelper.patchSubform('language', { isoCode: null });
    this.searchPageHelper.patchSubform('rate', { rate: [null, null], tfp: null, xchange: null });
    this.filterForm.patchValue({
      gender: { male: null, female: null },
      transgender: null,
      age: [null, null],
      rate: [null, null],
      tfp: null,
      xchange: null,
      location: { description: null, x: null, y: null, distance: 0 },
      language: { isoCode: null },
      categorySlug: null,
      talentCategorySlug: null,
    });

    this.filterFB.reset();
    this.categorySelected = null;
    this.languangeSelected = null;
    this.talentCategorySelected = null;
    this.modalComponent.close();
  }

  async openDialog() {
    return await this.modalComponent.open();
  }

  protected processFilters(effectiveFilters: any, filters: any): Observable<any> {
    const filterControls = {};
    for (const f of filters) {
      const values = Object.keys(effectiveFilters.filters || {}).includes(f.slug)
        ? effectiveFilters.filters[f.slug]
        : [];
      filterControls[f.slug] = [values];
    }

    const controls = {
      categorySlug: effectiveFilters.categorySlug || null,
      talentCategorySlug: effectiveFilters.talentCategorySlug || null,
      gender: {
        male: effectiveFilters?.gender?.male || false,
        female: effectiveFilters?.gender?.female || false,
      },
      transgender: effectiveFilters.transgender || false,
      age: effectiveFilters.age || [null, null],
      rate: effectiveFilters.rate || [null, null],
      tfp: effectiveFilters.tfp || false,
      xchange: effectiveFilters.xchange || false,
      location: {
        description: effectiveFilters?.location?.description || null,
        x: effectiveFilters?.location?.x || null,
        y: effectiveFilters?.location?.y || null,
        distance: effectiveFilters?.location?.distance || 0,
      },
      filters: filterControls,
      language: { isoCode: effectiveFilters?.language || null },
      orderBy: effectiveFilters.orderBy || (this.page === 'find-jobs' ? 'created;desc' : 'shuffle'),
    };
    return of(controls);
  }

  protected getFiltersFromQueryString(): any {
    const params = this.route.snapshot.queryParamMap;
    const queryString = params.keys
      .reduce((acc: any[], cur: string) => {
        const values = params.getAll(cur);

        for (const value of values) {
          acc.push(`${cur}=${value}`);
        }

        return acc;
      }, [])
      .join('&');
    const filters = parse(queryString);
    Object.keys(filters.filters || {}).forEach((key) => {
      const value = filters.filters[key];
      const values = !Array.isArray(value) ? [value] : value;

      filters.filters[key] = values.map((v) => {
        if (!isNaN(parseFloat(v)) && isFinite(v)) {
          return parseInt(v, 10);
        }

        return v;
      });
    });

    const allFilters = { categorySlug: null, ...filters } as any;

    if ('gender' in allFilters) {
      allFilters.gender.male = allFilters.gender.male === 'true';
      allFilters.gender.female = allFilters.gender.female === 'true';
    }

    if ('transgender' in allFilters) {
      allFilters.transgender = allFilters.transgender === 'true';
    }

    if ('age' in allFilters && Array.isArray(allFilters['age'])) {
      allFilters['age'] = allFilters['age'].map((v: string) => parseInt(v, 10));
    }

    if ('rate' in allFilters && Array.isArray(allFilters['rate'])) {
      allFilters['rate'] = allFilters['rate'].map((v: string) => parseInt(v, 10));
    }

    if ('tfp' in allFilters) {
      allFilters.tfp = allFilters.tfp === 'true';
    }
    if ('xchange' in allFilters) {
      allFilters.xchange = allFilters.xchange === 'true';
    }

    return allFilters;
  }

  protected setupForm(filters: any): Observable<FormGroup> {
    this.filterForm = this.fb.group({
      ...filters,
      gender: this.fb.group(filters.gender),
      age: this.fb.control(filters.age),
      rate: this.fb.control(filters.rate),
      location: this.fb.group(filters.location),
      language: this.fb.group(filters?.language),
      filters: this.fb.group(filters.filters),
    });

    if ('gender' in filters) {
      this.searchPageHelper.patchSubform('gender', filters.gender);
    }

    if ('transgender' in filters) {
      this.searchPageHelper.patchSubform('gender', filters.transgender, 'transgender');
    }

    if ('rate' in filters) {
      this.searchPageHelper.patchSubform('rate', [...filters.rate]);
    }

    if ('tfp' in filters) {
      this.searchPageHelper.patchSubform('rate', filters.tfp, 'tfp');
    }

    if ('xchange' in filters) {
      this.searchPageHelper.patchSubform('rate', filters.xchange, 'xchange');
    }

    if ('age' in filters) {
      this.searchPageHelper.patchSubform('age', [...filters.age]);
    }

    if ('location' in filters) {
      this.searchPageHelper.patchSubform('location', filters.location);
    }

    if ('language' in filters) {
      this.searchPageHelper.patchSubform('language', filters.language, 'isoCode');
    }

    if ('orderBy' in filters) {
      this.filterForm.patchValue({ orderBy: filters.orderBy });
    }

    this.filterFB.patchValue({
      male: filters.gender.male,
      female: filters.gender.female,
      transgender: filters.transgender,
      toAge: filters.age[0],
      fromAge: filters.age[1],
      toRate: filters.rate[0],
      fromRate: filters.age[1],
      description: filters.location.description,
      x: filters.location.x,
      y: filters.location.y,
      distance: filters.location.distance,
      freebie: filters.xchange || filters.tfp ? true : false,
    });

    this.categorySelected = filters.categorySlug;
    this.languangeSelected = filters.language.isoCode;
    this.talentCategorySelected = filters.talentCategorySlug;
    this.fetchTalentCategory();
    return of(this.filterForm);
  }

  // protected setupFormMoreFilter(): void {
  //   if (this.filterCategories === null) {
  //     return;
  //   }

  //   const filterFB = this.filterFB.get('filters') as FormGroup;
  //   const filterForm = this.filterForm.get('filters') as FormGroup;
  //   this.filterCategories.forEach((moreFilter) => {
  //     let value = this.filterForm.value.filters[moreFilter.slug] || [];
  //     if (filterFB.get(moreFilter.slug) === null) {
  //       if (value.length === 0 && moreFilter.type === 'number') {
  //         value = [null];
  //       }
  //       if (value.length === 0 && (moreFilter.type === 'slider' || moreFilter.type === 'color')) {
  //         value = [null, null];
  //       }
  //       if (moreFilter.type === 'checkbox') {
  //         value = value.map((item: any) => ({ value: item, checked: true }));
  //       }
  //       filterFB.addControl(moreFilter.slug, new FormControl(value, []));
  //       filterForm.addControl(moreFilter.slug, new FormControl(value, []));
  //     }
  //   });

  //   this.filterForm.addControl('filters', filterForm);
  //   this.filterFB.addControl('filters', filterFB);
  // }
}
