import { Component, Input } from '@angular/core';
import { User } from '@core/models';
import { SplideOptions } from '@splidejs/splide';

@Component({
  selector: 'app-talent-ratings',
  templateUrl: './talent-ratings.component.html',
  styleUrls: ['./talent-ratings.component.scss'],
})
export class TalentRatingsComponent {
  @Input()
  public talent: User;

  public initialized = false;

  public options: SplideOptions = {
    keyboard: false,
    perPage: 1,
    perMove: 1,
    pagination: false,
    waitForTransition: false,
    type: 'loop',
    lazyLoad: 'nearby',
    preloadPages: 1,
  };

  constructor() {
    setTimeout(() => {
      this.initialized = true;
    }, 500);
  }
}
