<div class="selected-option d-flex w-100 align-items-center" (click)="toggleOpen()">
  <div #selectedOptionContainer></div>
  <app-icon [fill]="iconColor" [stroke]="iconColor" icon="/assets/img/arrow-down.svg"></app-icon>
</div>

<div *ngIf="isOpen" class="options-container">
  <input
    *ngIf="searchable"
    type="search"
    class="d-flex w-100 bg-transparent pl-1 mb-1"
    (keyup)="search($event)"
    (click)="$event.preventDefault()"
    [placeholder]="'Search' | translate" />
  <ng-content></ng-content>
  <!--  <app-option-->
  <!--    *ngFor="let item of items"-->
  <!--    [classList]="item.elementRef.nativeElement.classList"-->
  <!--    [value]="item.value"-->
  <!--    [selected]="item.selected"-->
  <!--    [title]="item.elementRef.nativeElement.title"-->
  <!--    [innerHTML]="item.elementRef.nativeElement.innerHTML">-->
  <!--  </app-option>-->
</div>
