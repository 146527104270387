import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-visibility-indicator',
  templateUrl: './visibility-indicator.component.html',
  styleUrls: ['./visibility-indicator.component.scss'],
})
export class VisibilityIndicatorComponent {
  @Input()
  @HostBinding('class.visible')
  public visible: boolean;
}
