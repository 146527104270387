import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToDeckButtonComponent } from './components/add-to-deck-button/add-to-deck-button.component';
import { IconsModule } from '../generic/icons/icons.module';
import { DeckSelectionModalComponent } from './components/deck-selection-modal/deck-selection-modal.component';
import { DeckComponent } from './components/deck/deck.component';
import { ImagesModule } from '../generic/images/images.module';
import { SpinnersModule } from '@src/ui/generic/spinners/spinners.module';
import { ButtonsModule } from '@src/ui/generic/buttons/buttons.module';

const components = [AddToDeckButtonComponent, DeckSelectionModalComponent, DeckComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule, ImagesModule, SpinnersModule, ButtonsModule],
  exports: components,
})
export class DecksModule {}
