import { Component, HostListener, Input, OnDestroy, ViewChildren, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OTPComponent,
      multi: true,
    },
  ],
})
export class OTPComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input()
  public phonenumber: string;

  @ViewChildren('digit')
  public digits: any;

  public isBusy = false;

  public value = '';

  public valid = false;
  public onChange: (_: any) => Record<string, unknown>;
  public onTouch: (_: any) => Record<string, unknown>;
  public form = new FormGroup({
    code: new FormControl(null, [Validators.required]),
  });

  @HostListener('input', ['$event'])
  private(e: InputEvent): void {
    let value = '';

    const digitFields = this.digits.toArray();
    for (const field of digitFields) {
      value += field.nativeElement?.value;

      field.nativeElement.value = null;
    }

    for (let index = 0; index < 4; index++) {
      if (digitFields[index] && value[index] !== undefined) {
        digitFields[index].nativeElement.value = value[index];
      }
    }

    if (value.length <= 4) {
      this.value = value;
      this.onChange(this.value);
      this.onTouch(this.value);
    }

    if (e.inputType === 'deleteContentBackward') {
      digitFields[this.value.length - 1]?.nativeElement?.focus();
      return;
    }

    digitFields[this.value.length]?.nativeElement?.focus();
  }

  public ngAfterViewInit(): void {
    if (!this.value && this.digits.length) {
      const digitFields = this.digits.toArray();

      digitFields[0]?.nativeElement?.focus();
    }
  }

  public writeValue(_: null): void {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public ngOnDestroy(): void {
    this.onChange(null);
  }
}
