import { IJobRequest } from '@core/interfaces';
import { Transform } from 'class-transformer';
import * as moment from 'moment';

export class JobRequest implements IJobRequest {
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;

  public id: string;
  public name: string;
  public email: string;
  public phonenumber: string;
  public message: string;
}
