import { IMessage, IUser } from '@core/interfaces';
import { Type } from 'class-transformer';
import { User } from './user.model';

export class Message implements IMessage {
  @Type(() => User)
  public sender: IUser;

  @Type(() => User)
  public recipient: IUser;

  public id?: string;
  public message: string;
  public isSender?: boolean;
  public chatId: string;
  public senderId: string;
  public recipientId: string;
}
