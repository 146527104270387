import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { VatService } from '@core/services';
import { VatResultDTO } from '@core/dto';

import { VerifyVatStateModel } from './verify-vat.state-model';

import { VerifyVatNumber } from './verify-vat.actions';

@State<VerifyVatStateModel>({
  name: 'verifyVat',
  defaults: {
    vatResult: null,
  },
})
@Injectable()
export class VerifyVatState {
  public constructor(private vatService: VatService) {}

  @Selector()
  static vatResult(state: VerifyVatStateModel): VatResultDTO {
    return state.vatResult;
  }

  @Action(VerifyVatNumber)
  public async sendMobileVerificationCode(
    ctx: StateContext<VerifyVatStateModel>,
    { countryId, vatNumber }: VerifyVatNumber,
  ): Promise<void> {
    ctx.patchState({ vatResult: null });
    const vatResult = await this.vatService.checkVat(countryId, vatNumber).toPromise();
    ctx.patchState({ vatResult });
  }
}
