<h3 class="mb-2" translate>Notes by you</h3>

<div *ngIf="!editing">
  <div *ngIf="note$ | async as note">
    <p class="newlines">{{ note.comment }}</p>
  </div>

  <app-button (click)="editing = true" buttonStyle="white" class="bg-white d-inline-block py-1 px-2">
    <app-icon icon="/assets/img/edit.svg"></app-icon>
  </app-button>
</div>

<div *ngIf="editing" [formGroup]="form">
  <textarea class="my-2 p-2" formControlName="comment"></textarea>
  <app-button (click)="save()" buttonStyle="white" class="bg-white d-inline-block py-1 px-2">
    <app-icon icon="/assets/img/save_alt.svg"></app-icon>
  </app-button>
</div>
