import { ActivatedRoute, CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthState } from '@core/states';
import { Store } from '@ngxs/store';

export abstract class RoleGuard implements CanActivate {
  protected constructor(private roles: string[], private store: Store, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const jwtToken = this.store.selectSnapshot(AuthState.jwtToken);
    if (jwtToken == null) {
      this.router.navigateByUrl(`/login?route=${encodeURIComponent(state.url)}`);
      return false;
    } else {
      return jwtToken?.payload?.roles?.some((r) => this.roles.includes(r));
    }
  }
}
