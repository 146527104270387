<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #mobileUploadVideo>
  <ng-container *ngIf="!showNote">
    <ng-container *ngIf="data != undefined && data.userID !== undefined && isApply; else uploadVideo">
      <div class="modal-body">
        <div class="text-center">
          <h6 style="font-size: 26px" class="text-bold mb-4" translate>This job requires an audition video</h6>
          <textarea class="textarea-control" [value]="data.job.requestTalentVideoDescription" readonly></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <div class="wrap-btn mb-3">
          <button
            (click)="applyWithVideo()"
            class="btn btn-bg-purple"
            style="border-radius: 100px; margin: auto; width: max-content">
            {{'Upload Video/insert link' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3">
          <button (click)="skipAndApply()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
            {{'Skip and apply' | translate}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #uploadVideo>
      <ng-container *ngIf="!isApply && !isSuccess; else success">
        <div class="modal-body form-job">
          <div style="text-align: center">
            <p>
              Your video is exceeding 20mb. Please upload your video to YouTube and copy/paste the link in here. In
              YouTube choose 'Unlisted' so your video is not visible on your account.
            </p>
            <p>*Casterbee is currently working on an improve video-upload module</p>
          </div>
          <div class="preview-video" *ngIf="video !== undefined && video !== null">
            <button class="button" (click)="removeVideo()">
              <img src="/assets/img/mobile/trash.svg" alt="" />
            </button>
            <img *ngIf="video.type === 'url'" src="{{video.thumbUrl}}" alt="" />
            <video controls *ngIf="video.type === 'upload'">
              <source [src]="sanitizer.bypassSecurityTrustUrl(video.link)" [type]="video.fileType" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="mt-5">
            <input
              #linkInput
              type="text"
              placeholder="Insert YouTube"
              (change)="setValue($event,'url')"
              (paste)="setValue($event,'url')"
              class="form-control" />
          </div>
          <div class="text-center">
            <div class="upload-video">
              <button
                type="button"
                class="btn btn-add"
                style="width: max-content !important; border-color: #ffffff; background-color: transparent"
                (click)="openMedia()"
                translate>
                Upload video from gallery
              </button>
              <input
                #fileInput
                accept="video/mp4,video/x-m4v,video/*"
                type="file"
                (change)="setValue($event,'upload')" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="wrap-btn mb-3">
            <button
              type="button"
              class="btn btn-bg-purple"
              style="border-radius: 100px; margin: auto; min-width: 182px; width: max-content"
              (click)="!isBusy && (video !== null || video !== undefined) ? submitVideo() : null"
              [disabled]="video === null || video === undefined">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
              <span *ngIf="!isBusy">
                {{ (data !== null && data !== undefined && data.userID !== undefined) ? 'Apply with Audition Video' :
                'Apply Video' | translate }}
              </span>
            </button>
          </div>
          <div class="wrap-btn mb-3" *ngIf="(data !== undefined && data !== null) && data.userID !== undefined">
            <button (click)="skipAndApply()" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
              {{'Skip and apply' | translate}}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="showNote">
    <ng-container *ngIf="!isApply && !isSuccess; else success">
      <div style="max-height: 430px !important" class="modal-body">
        <div class="text-center">
          <h6 style="font-size: 16px" class="text-bold mb-4" translate>Write a note to the Caster</h6>
        </div>
        <div class="note-rule">
          <div class="note-section">
            <h6>Do's</h6>
            <ul>
              <li translate>Only give info when relevant to job post</li>
              <li translate>Avoid giving info, just by giving info</li>
            </ul>
          </div>
          <div class="note-section">
            <h6>Don'ts</h6>
            <ul>
              <li translate>Forbidden to provide contact details</li>
              <li translate>Forbidden to negotiate about the fee</li>
              <li translate>Forbidden to ‘beg’ for the job’</li>
            </ul>
          </div>
        </div>
        <textarea class="textarea-control" [(ngModel)]="noteModel"></textarea>
      </div>
      <div class="modal-footer">
        <div class="wrap-btn mb-3">
          <button
            [disabled]="!noteModel"
            (click)="submit(true)"
            class="btn btn-bg-purple"
            style="border-radius: 100px; margin: auto; width: max-content">
            {{'Apply with note' | translate}}
          </button>
        </div>
        <div class="wrap-btn mb-3">
          <button (click)="submit(true)" class="btn btn-bg-gray" style="border-radius: 100px; margin: auto">
            {{'Skip and apply' | translate}}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #success>
    <div class="modal-body">
      <app-mobile-success *ngIf="data.userID !== undefined" [message]="'You applied to the job.'">
        <p class="text-center" translate>When the Caster accepts your application you will get a notification</p>
      </app-mobile-success>
      <app-mobile-success
        *ngIf="data.userID === undefined"
        [message]="'You have successfully applied video'"></app-mobile-success>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
