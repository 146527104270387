import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IMedia } from '@core/interfaces';
import { IUserProfile } from '@core/interfaces/user-profile';
import { Language, User, UserProfile } from '@core/models';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { MobileAccountMessagingListComponent } from '../mobile-account-messaging-list/mobile-account-messaging-list.component';
import { Select, Store } from '@ngxs/store';
import { AuthState, InitiateChat, UserState } from '@core/states';
import { Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MobileStorageKey, MobileStorageService } from '../services/mobile-storage.service';
import { MobileTalentBulkMessageComponent } from '../mobile-talent-bulk-message/mobile-talent-bulk-message.component';

@Component({
  selector: 'app-mobile-talent-card',
  templateUrl: './mobile-talent-card.component.html',
  styleUrls: ['./mobile-talent-card.component.scss'],
})
export class MobileTalentCardComponent implements OnInit, OnChanges {
  @ViewChild('bulkMessageModal') public bulkMessageModal: MobileTalentBulkMessageComponent;
  @ViewChild('talentsElement') public talentsElement: ElementRef;

  @Input()
  public talents: IUserProfile[];

  @Select(UserState.user)
  public user$: Observable<User>;

  public scrollToUser = '';

  public seletedTalents: UserProfile[] = [];

  public isBusy = false;

  public showBulkMessage = false;

  private storageName = 'bulkMessage';

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private el: ElementRef,
    private router: Router,
    private mobileStorage: MobileStorageService,
  ) {}

  ngOnInit(): void {
    const modalParam = this.activatedRoute.snapshot.queryParamMap.get('goTo');
    if (modalParam) {
      this.scrollToUser = modalParam;
      setTimeout(() => {
        const myElement = this.el.nativeElement.querySelector('#selectedTalent');
        myElement.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'nearest' });
      }, 100);

      // removing param on url immediatly
      const queryParams: Params = {};
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
      });
    }

    this.user$.subscribe((user) => {
      this.showBulkMessage = user.email === 'hicasterbee@gmail.com' || user.email === 'randy.rajavi2@gmail.com';
    });

    this.initBulkMessageStorage();
  }

  public get activeRole(): string {
    return this.mobileStorage.get(MobileStorageKey.activeRole);
  }

  public get authenticated(): boolean {
    return this.store.selectSnapshot(AuthState.authenticated);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('talents' in changes) {
    }
  }

  public nativeLanguage(userProfile: IUserProfile): Language {
    return userProfile.user.languages.filter((userLanguage) => userLanguage.native)?.[0];
  }

  public isHaveVideo(userProfile: IUserProfile): IMedia {
    const userAlbum = userProfile.albums.filter((userMedia) => {
      if (userMedia.albumType === 'video' && userMedia.media.length >= 0 && userMedia.media[0]?.link !== null) {
        return userMedia.media;
      }
    })?.[0];
    if (userAlbum !== undefined && userAlbum.media.length > 0) {
      return userAlbum.media[0];
    }
  }

  public playVideo(media: IMedia) {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        mediaId: undefined,
        media,
        width: 400,
        height: 250,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async viewProfile(userId, profileId): Promise<void> {
    this.store.dispatch(new Navigate(['talents/profile', userId, profileId]));
  }

  public async sendMessage(recipientId, senderId): Promise<void> {
    await this.store.dispatch(new InitiateChat(senderId, recipientId)).toPromise();
    const dialogRef = this.dialog.open(MobileAccountMessagingListComponent, {
      data: {
        userId: senderId,
        recipientId,
      },
    });
  }

  public initBulkMessageStorage(): void {
    const storageExists = localStorage.getItem(this.storageName) !== null;

    // Check if storage exists
    if (storageExists) {
      this.seletedTalents = JSON.parse(localStorage.getItem(this.storageName));
    }
  }

  public updateTalentToBulkMessage(talent: UserProfile): void {
    const isTalentExist = this.seletedTalents.find((value) => value.id === talent.id);
    if (isTalentExist) {
      this.seletedTalents = this.seletedTalents.filter((value) => value.id !== talent.id);
    } else {
      this.seletedTalents.push(talent);
    }

    localStorage.setItem(this.storageName, JSON.stringify(this.seletedTalents));
  }

  public isTalentSelected(talent: UserProfile): boolean {
    const isTalentExist = this.seletedTalents.find((value) => value.id === talent.id);
    return isTalentExist ? true : false;
  }

  public openBulkMessageModal(): void {
    this.bulkMessageModal.openDialog(this.seletedTalents);
  }

  public resetSelection(): void {
    this.seletedTalents = [];
  }
}
