import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LanguagesService } from '@core/services';

import { LanguagesStateModel } from './languages.state-model';

import { LoadLanguages } from './languages.actions';
import { Language, PaginatedResults } from '@core/models';

@State<LanguagesStateModel>({
  name: 'languages',
  defaults: {
    languages: null,
  },
})
@Injectable()
export class LanguagesState implements NgxsOnInit {
  public constructor(private languagesService: LanguagesService) {}

  @Selector()
  static languages(state: LanguagesStateModel): PaginatedResults<Language> {
    return state.languages;
  }

  @Action(LoadLanguages)
  public async loadLanguages(ctx: StateContext<LanguagesStateModel>): Promise<void> {
    const languages = await this.languagesService.findAll().toPromise();
    ctx.patchState({ languages });
  }

  public ngxsOnInit(ctx: StateContext<LanguagesState>): void {
    ctx.dispatch(new LoadLanguages());
  }
}
