<div class="d-flex flex-column w-100 text-white p-3">
  <div class="container">
    <div class="justify-content-center">
      <h4 class="text-center">{{title | translate}}</h4>
    </div>
    <div class="mt-3 text-center h5" translate>*Please note:</div>

    <div class="mt-2 text-center light-font" translate>You will be refunded 100% of your fee*</div>

    <div class="text-left border-bottom p-2 light-font" translate>You cancel 4 days or more before the shoot</div>

    <div class="mt-3 text-center" translate>Are you sure want to cancel this booked talent?</div>

    <div class="mt-3">
      <div class="talent-card w-100 p-3">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <app-image
              *ngIf="jobApplication?.user?.photo"
              [image]="jobApplication.user?.photo.url"
              class="profile-pic"></app-image>
          </div>
          <div class="d-flex flex-column ml-2">
            <div>{{jobApplication?.user?.profileName}}</div>
            <div>
              {{jobApplication?.user?.country?.translateKey | translate}}, {{jobApplication?.user?.country?.alpha2Code}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <form (ngSubmit)="cancelBooking()" [formGroup]="form">
      <div class="mt-3">
        <app-textarea
          class="w-100 d-flex flex-fill border-0 rounded-sm mb-1"
          minHeight="100"
          placeholder="{{'Enter your reason' | translate}}"
          formControlName="reason"></app-textarea>
      </div>
      <div class="mt-3 d-flex flex-column align-items-center">
        <app-button
          [isBusy]="isBusy"
          [isDisabled]="isBusy || form.invalid"
          buttonStyle="artyclick-purple"
          class="mb-3 w-50">
          <button class="w-100 h-100 px-2 bg-transparent border-0 text-white" type="submit">
            {{title | translate}}
          </button>
        </app-button>
      </div>
    </form>

    <div class="d-flex flex-column align-items-center">
      <app-button [isDisabled]="isBusy" (click)="closeModal()" buttonStyle="transparent-white" class="mb-3 w-50">
        <div class="px-2" translate>Cancel</div>
      </app-button>
    </div>
  </div>
</div>
