import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPackage } from '@core/interfaces/package';
import { IUserProfile } from '@core/interfaces/user-profile';

@Component({
  selector: 'app-packages-list',
  templateUrl: './packages-list.component.html',
  styleUrls: ['./packages-list.component.scss'],
})
export class PackagesListComponent {
  @Input()
  public profile: IUserProfile;

  @Output()
  public packageSelected = new EventEmitter();

  public onPackageSelected(p: IPackage): void {
    this.packageSelected.emit(p);
  }
}
