<app-gradient
  [gradientName]="themes[themeIndex]"
  gradientDirection="vertical"
  class="content rounded-sm panel d-flex p-3 flex-column mb-4 w-100"
  *ngIf="job">
  <div class="d-flex">
    <app-image *ngIf="job.media.length > 0" [image]="job.media[0]?.thumbUrl" class="mr-2 mt-1"></app-image>
    <app-image
      *ngIf="job.media.length == 0"
      image="/assets/img/placeholders/job-default-image.png"
      class="mr-2 mt-1"></app-image>
    <h3 class="h5">{{ job.title }}</h3>
  </div>
  <p class="mt-3">{{ job.shortDescription }}</p>
  <h4 class="h4 mt-3"><app-money *ngIf="!job.hideRate" [amount]="job.totalRate"></app-money></h4>
  <div class="mt-3">
    <div class="d-flex align-items-center mt-2">
      <app-icon class="mr-2" fill="white" icon="/assets/img/briefcase.svg"></app-icon>
      <p class="m-0 font-weight-light">{{ job.category.translateKey | translate }}</p>
    </div>
    <div class="d-flex align-items-center mt-2">
      <app-icon class="mr-2" fill="white" icon="/assets/img/clock.svg"></app-icon>
      <p class="m-0 font-weight-light">
        <ng-container *ngIf="job.dates.length > 0">
          <span class="d-block">
            {{ job.firstDate | dateRange: { exact: job.exactDate, withTime: false } }}
            <ng-container *ngIf="job.dates.length > 1">
              - {{ job.lastDate | dateRange: { exact: job.exactDate, withTime: false } }}
            </ng-container>
          </span>
        </ng-container>
      </p>
    </div>
    <div class="d-flex mt-2">
      <app-icon class="mr-2 mt-1" fill="white" icon="/assets/img/location.svg"></app-icon>
      <div class="m-0 font-weight-light">
        <div *ngIf="job?.workFromHome">Online (work from home)</div>
        <div *ngFor="let jobCountry of job?.countries">
          {{ jobCountry.city }},&nbsp;
          <span translate>{{ jobCountry.country.translateKey }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="spacer flex-grow-1"></div>
  <app-button (click)="open()" buttonStyle="black" class="mt-3 rounded"><span translate>View job</span></app-button>
</app-gradient>
