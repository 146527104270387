import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-request-video-modal',
  templateUrl: './mobile-request-video-modal.component.html',
  styleUrls: ['./mobile-request-video-modal.component.scss'],
})
export class MobileRequestVideoModalComponent implements OnInit {
  @ViewChild('appRequestVideoModal') private modalComponent: MobileModalDialogComponent;
  constructor() {}

  ngOnInit(): void {}

  async openDialog() {
    return await this.modalComponent.open().then(() => {});
  }

  async close() {
    return await this.modalComponent.close();
  }
}
