<div
  class="mobile-account-profile-media-field-container"
  *ngIf="(value.processed && value.uploaded) || value?.mediaType == 'video'">
  <app-mobile-image-preview
    *ngIf="thumbUrl$ | async as thumbUrl"
    [image]="thumbUrl"
    [ngClass]="{ isVideo: value.mediaType == 'video' }"
    [lazy]="false"></app-mobile-image-preview>
  <app-spinner *ngIf="isUploading"></app-spinner>
  <div class="profile-photo-label postion-absolute" *ngIf="isProfilePhoto && value?.mediaType == 'photo'" translate>
    Profile photo
  </div>
  <div class="overlay" [ngClass]="{ isVideo: value?.mediaType == 'video' }">
    <app-mobile-account-profile-media-form
      [maxSize]="maxSize"
      [accept]="accept"
      class="mt-auto mr-auto"
      [formControl]="fileControl"></app-mobile-account-profile-media-form>
  </div>

  <div class="order-button-container" *ngIf="allowSorting">
    <ng-conainer *ngIf="value.order !== 1" (click)="moveUp.emit()">
      <img src="/assets/img/mobile/button-up.svg" alt="close" />
    </ng-conainer>
    <ng-conainer (click)="moveDown.emit()">
      <img src="/assets/img/mobile/button-down.svg" alt="close" />
    </ng-conainer>
  </div>
  <div
    (click)="delete.emit()"
    class="delete"
    *ngIf="(!disableDelete && value.order !== 1) || value?.mediaType == 'video'">
    <img src="/assets/img/mobile/button-delete.svg" alt="close" />
  </div>
</div>

<input
  hidden
  #fileInput
  (change)="onValueChange($event.target.files)"
  [accept]="accept"
  class="form-control-file upload-button"
  type="file" />

<!-- <input
  class="form-control youtube-link-field"
  *ngIf="value?.mediaType == 'video'"
  [placeholder]="'Youtube/Video-link' | translate"
  type="text"
  [value]="videoUrl"
  (paste)="onPasteUrl($event)"
  (blur)="updateVideoUrl($event.target.value)" /> -->
