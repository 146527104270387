import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
  @ContentChildren(OptionComponent)
  public items: QueryList<OptionComponent>;

  @ViewChild('selectedOptionContainer')
  public selectedOptionContainer: ElementRef;

  @HostBinding('class.open')
  public isOpen: boolean;

  @Input()
  public iconColor = 'white';

  @Input()
  public searchable = false;

  @Input()
  public isLocked = false;

  private selectedOption: OptionComponent;

  private value: string;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public clickListener(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  // @HostListener('click')
  public toggleOpen(): void {
    if (!this.isLocked) {
      if (!this.isOpen) {
        this.onChange(this.value);
      }
      this.isOpen = !this.isOpen;
    }
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    const options = this.items.toArray();
    if (options) {
      this.setOnClickOnOptions(options);
    }

    this.items.changes.subscribe((data) => {
      this.setOnClickOnOptions(data);
    });
  }

  public setOnClickOnOptions(options: any[]): void {
    for (const option of options) {
      option.click = (optionComponent: OptionComponent) => this.onItemClicked(optionComponent.value);
    }

    this.updateSelectedOption(this.value);
  }

  public updateSelectedOption(value: string, triggerOnChange?: boolean): void {
    if (!this.items) {
      return;
    }

    if (this.selectedOption) {
      this.selectedOption.selected = false;
    }

    const items = this.items.toArray();
    this.selectedOption = items.find((o: OptionComponent) => o.value === value);

    if (!this.selectedOption) {
      return;
    }

    this.selectedOption.selected = true;
    this.selectedOptionContainer.nativeElement.innerHTML = this.selectedOption.elementRef.nativeElement.outerHTML;

    if (triggerOnChange) {
      this.onChange(this.selectedOption.value);
    }
  }

  public onItemClicked(value: string): void {
    this.updateSelectedOption(value, true);
    this.toggleOpen();
  }

  public writeValue(value: any): void {
    this.value = value;
    this.updateSelectedOption(value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(_: any): void {}

  public search(event: any): void {
    const searchTerm = event.target.value.toLowerCase();
    this.items.forEach((item: OptionComponent) => {
      const classList = item.elementRef.nativeElement.classList;
      if (item.searchValue?.toLowerCase().includes(searchTerm)) {
        classList.add('d-flex');
        classList.remove('d-none');
      } else {
        classList.remove('d-flex');
        classList.add('d-none');
      }
    });
  }
}
