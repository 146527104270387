import { AfterViewInit, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appScreenSize]',
})
export class ScreenSizeDirective implements OnInit {
  size: string;
  config = {
    mobile: 500,
    dekstop: 1200,
  };

  @Input() set appScreenSize(size: string) {
    this.size = size;
  }

  constructor(private viewRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngOnInit() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.viewRef.clear();
    if (this.config[this.size] === this.config.mobile && this.config[this.size] >= window.innerWidth) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else if (this.config[this.size] === this.config.dekstop && this.config.mobile < window.innerWidth) {
      this.viewRef.createEmbeddedView(this.templateRef);
    }
  }
}
