<div style="height:{{ tileSize?.height }}">
  <ng-container *ngIf="talents$ | async as talents">
    <app-carousel [items]="talents" [options]="options" class="d-block">
      <ng-template #carouselFirstSlideTpl>
        <a
          [queryParams]="{ categorySlug: category.slug }"
          [routerLink]="['/talents']"
          class="d-block position-relative"
          style="width:{{ startTileSize?.width }}px; height: {{ startTileSize?.height }}px">
          <app-image
            class="cover d-block rounded-sm"
            image="/assets/img/categories/start-tiles/{{ category.slug }}.jpg"
            style="width:{{ startTileSize?.width }}px; height: {{ startTileSize?.height }}px"></app-image>

          <div class="slide-content d-flex">
            <div class="w-50 h-100 d-flex flex-column pl-4 pt-5 pb-3">
              <h3 class="h5">{{ category.description | translate }}</h3>
              <app-button buttonStyle="white-outline" class="mt-auto outlined-button align-self-start">
                <span class="px-2">See all</span>
              </app-button>
            </div>
          </div>
        </a>
      </ng-template>

      <ng-template #carouselSlideTpl let-item="item" let-visible="visible">
        <div style="width: {{ tileSize?.width }}px; height: {{ tileSize?.height }}px">
          <app-talent-card *ngIf="visible" [talent]="item"></app-talent-card>
        </div>
      </ng-template>
    </app-carousel>
  </ng-container>
</div>
