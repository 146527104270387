import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICountry, IUser } from '@core/interfaces';
import { CountriesState, UserState } from '@core/states';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-offer-job-modal',
  templateUrl: './offer-job-modal.component.html',
  styleUrls: ['./offer-job-modal.component.scss'],
})
export class OfferJobModalComponent {
  @Select(CountriesState.countries)
  public countries$: Observable<ICountry[]>;

  @Select(UserState.user)
  public currentUser$: Observable<IUser>;

  public isSubmitted = false;
  public needsPayment = false;

  public constructor(
    @Optional() private dialogRef: MatDialogRef<OfferJobModalComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
  ) {}

  public onSubmitted(needsPayment: boolean): void {
    this.needsPayment = needsPayment;
    this.isSubmitted = true;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
