import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-success-modal',
  templateUrl: './mobile-success-modal.component.html',
  styleUrls: ['./mobile-success-modal.component.scss'],
})
export class MobileSuccessModalComponent implements OnInit {
  @Input() maxHeight = '400px';
  @Input() height = '100vh';

  @ViewChild('appSuccessModal') private modalComponent: MobileModalDialogComponent;

  enableFooter = true;
  reloadOnClose = false;

  constructor() {}

  ngOnInit(): void {}

  async openDialog(enableFooter: boolean = true, reloadOnClose: boolean = false) {
    this.enableFooter = enableFooter;
    this.reloadOnClose = reloadOnClose;
    return await this.modalComponent.open().then(() => {});
  }

  async close() {
    if (this.reloadOnClose) {
      return window.location.reload();
    }
    return await this.modalComponent.close();
  }
}
