import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Job, JobApplication } from '@core/models';
import { Store } from '@ngxs/store';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { CasterCancelBookingJobApplication, RejectJobApplications } from '@core/states';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-cancel-booking-modal',
  templateUrl: './mobile-cancel-booking-modal.component.html',
  styleUrls: ['./mobile-cancel-booking-modal.component.scss'],
})
export class MobileCancelBookingModalComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public jobDetail?: Job;

  @ViewChild('appCancelBookingModal') private modalComponent: MobileModalDialogComponent;

  public applicants: Array<JobApplication> = [];
  public isBusy = false;
  public isSuccess = false;
  public form = new FormGroup({
    reason: new FormControl(null, []),
  });

  constructor(private store: Store, private translate: TranslateService) {}

  ngOnInit(): void {}

  async openDialog(applicants: Array<JobApplication>) {
    if (!applicants.length) {
      const failTranslation = await this.translate.get('Select at least 1 talent to reject').toPromise();
      alert(failTranslation);
      return;
    }

    this.applicants.push(...applicants);
    return await this.modalComponent.open().then(() => {
      this.applicants = [];
      this.isSuccess = false;
    });
  }

  async close() {
    return await this.modalComponent.close();
  }

  public removeApplicant(i: number) {
    this.applicants.splice(i, 1);
    if (this.applicants.length === 0) {
      this.close();
    }
  }

  public async submit(): Promise<void> {
    this.isBusy = true;
    if (this.jobDetail === null || (this.jobDetail === undefined && this.applicants.length === 1)) {
      await this.store
        .dispatch(
          new CasterCancelBookingJobApplication(
            this.applicants[0].jobId,
            this.applicants[0].id,
            this.form.value.reason,
          ),
        )
        .toPromise();
      this.isBusy = false;
      this.isSuccess = true;
    } else if (this.jobDetail !== null || (this.jobDetail !== undefined && this.applicants.length > 0)) {
      await this.store
        .dispatch(
          new RejectJobApplications(
            this.jobDetail.id,
            this.applicants.map((a: JobApplication) => a.id),
            this.form.value.reason,
          ),
        )
        .toPromise();
      this.isBusy = false;
      this.isSuccess = true;
    }
  }
}
