<app-mobile-modal-dialog #appPostTravelCostModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!isLoading && jobApplication !== null && (form !== undefined || form !== null)">
    <ng-container *ngIf="!isSuccess; else success">
      <ng-container *ngIf="!jobApplication?.withoutTc; else showNoTravelCost">
        <form
          (ngSubmit)="submit()"
          [formGroup]="form"
          class="form-job"
          *ngIf="jobApplication?.status !== 'travelCostPending'; else pendingTravelCost">
          <div class="modal-body">
            <h6 class="text-center text-bold mb-3" style="font-size: 22px">
              {{'Check the info and when enabled fill in your Travel/Parking cost' | translate}}
            </h6>
            <div class="mb-3" style="background-color: #f9531f; border-radius: 12px; padding: 10px">
              <div class="row">
                <div class="col-1">
                  <img
                    src="/assets/img/mobile/lamp.svg"
                    style="width: 18px; height: 18px; position: relative; bottom: 2px"
                    alt="" />
                </div>
                <div class="col-11" translate>
                  When enabled. After your travel/parking expenses is approved, enter the exact same amount to your
                  invoice/receipt
                </div>
              </div>
            </div>
            <ng-container *ngIf="user$ | async as userDetail">
              <ng-container *ngIf="jobInvoice !== null && jobInvoice !== undefined; else feeJob">
                <div class="job-fee">
                  <ol>
                    <li>{{'Fee' | translate}}</li>
                    <li>:</li>
                    <li>
                      €{{jobInvoice.fee}}
                      <span
                        style="color: red"
                        *ngIf="(jobInvoice.fee !==
                      (jobApplication?.job?.rate))">
                        ({{'Changed' | translate}})
                      </span>
                    </li>
                  </ol>
                  <ol>
                    <li>{{'Buy-out' | translate}}</li>
                    <li>:</li>
                    <li>
                      €{{jobInvoice.buyOut}}
                      <span
                        style="color: red"
                        *ngIf="(jobInvoice.buyOut !==
                      (jobApplication?.job?.extraFees === null ? 0 : jobApplication?.job?.extraFees))">
                        ({{'Changed' | translate}})
                      </span>
                    </li>
                  </ol>
                  <ol>
                    <li>{{'Comission' | translate}} ({{userDetail.membership.commission}}%)</li>
                    <li>:</li>
                    <li>€{{getComission((jobInvoice.fee+jobInvoice.buyOut),userDetail.membership.commission)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Travel cost' | translate}}</li>
                    <li>:</li>
                    <li *ngIf="!jobInvoice.tc; else enableTravelCost">{{'Disabled' | translate}}</li>
                    <ng-template #enableTravelCost>
                      <li>
                        {{getTravelCost() | translate}}
                        <span
                          style="color: red"
                          *ngIf="(jobApplication?.job?.travelingCost === null && jobInvoice.tc) ||
                        (jobApplication?.job?.travelingCost !== null && !jobInvoice.tc) ||
                        (jobApplication?.job?.travelingCost === null ? 0 : jobApplication?.job?.travelingCost !== jobInvoice.tcAmount)">
                          ({{'Changed' | translate}})
                        </span>
                      </li>
                    </ng-template>
                  </ol>
                  <ol>
                    <li>{{'Parking cost' | translate}}</li>
                    <li>:</li>
                    <li *ngIf="!jobInvoice.pc; else enableParkingCost">{{'Disabled' | translate}}</li>
                    <ng-template #enableParkingCost>
                      <li>
                        {{getParkingCost() | translate}}
                        <span
                          style="color: red"
                          *ngIf="(jobApplication?.job?.parkingCost && !jobInvoice.pc) ||
                        (!jobApplication?.job?.parkingCost && jobInvoice.pc) ||
                        (jobApplication?.job?.maxParkingCost === null ? 0 : jobApplication?.job?.maxParkingCost  !== jobInvoice.pcMaxAmount)">
                          ({{'Changed' | translate}})
                        </span>
                      </li>
                    </ng-template>
                  </ol>
                  <ol>
                    <li>{{'Fee excl. VAT' | translate}}</li>
                    <li>:</li>
                    <li>€{{totalFeeExcl((jobInvoice.fee+jobInvoice.buyOut),userDetail.membership.commission)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Optional 21% VAT' | translate}}</li>
                    <li>:</li>
                    <li>€{{totalVAT((jobInvoice.fee+jobInvoice.buyOut),userDetail.membership.commission)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Fee incl. VAT' | translate}}</li>
                    <li>:</li>
                    <li>€{{totalFeeIncl((jobInvoice.fee+jobInvoice.buyOut),userDetail.membership.commission)}}</li>
                  </ol>
                  <small translate>15/12/8% commission will be deducted; see membership rules</small>
                  <br />
                  <small translate>If this is not correct please email to administratie@casterbee.nl Immediately</small>
                </div>
              </ng-container>
              <ng-template #feeJob>
                <div class="job-fee">
                  <ol>
                    <li>{{'Fee' | translate}}</li>
                    <li>:</li>
                    <li>€{{(jobApplication?.job?.totalRate-jobApplication?.job?.extraFees)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Buy-out' | translate}}</li>
                    <li>:</li>
                    <li>€{{jobApplication?.job?.extraFees === null ? 0 : jobApplication?.job?.extraFees}}</li>
                  </ol>
                  <ol>
                    <li>{{'Comission' | translate}} ({{userDetail.membership.commission}}%)</li>
                    <li>:</li>
                    <li>€{{getComission((jobApplication?.job?.totalRate),userDetail.membership.commission)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Travel cost' | translate}}</li>
                    <li>:</li>
                    <li>{{getTravelCost() | translate}}</li>
                  </ol>
                  <ol>
                    <li>{{'Parking cost' | translate}}</li>
                    <li>:</li>
                    <li>{{getParkingCost() | translate}}</li>
                  </ol>
                  <ol>
                    <li>{{'Fee excl. VAT' | translate}}</li>
                    <li>:</li>
                    <li>€{{totalFeeExcl((jobApplication?.job?.totalRate),userDetail.membership.commission)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Optional 21% VAT' | translate}}</li>
                    <li>:</li>
                    <li>€{{totalVAT((jobApplication?.job?.totalRate),userDetail.membership.commission)}}</li>
                  </ol>
                  <ol>
                    <li>{{'Fee incl. VAT' | translate}}</li>
                    <li>:</li>
                    <li>€{{totalFeeIncl((jobApplication?.job?.totalRate),userDetail.membership.commission)}}</li>
                  </ol>
                  <small translate>15/12/8% commission will be deducted; see membership rules</small>
                  <br />
                  <small translate>If this is not correct please email to administratie@casterbee.nl Immediately</small>
                </div>
              </ng-template>
            </ng-container>
            <ng-container
              *ngIf="(jobInvoice !== null && jobInvoice !== undefined) && (jobInvoice?.tc || jobInvoice.pc);">
              <div class="mb-3" style="background-color: #313131; border-radius: 12px; padding: 12px">
                <div class="row">
                  <div class="col-auto" style="width: 120px; max-width: 120px">
                    <p translate>Job title :</p>
                  </div>
                  <div class="col">
                    <p>{{jobApplication?.job?.title}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto" style="width: 120px; max-width: 120px">
                    <p translate>Important :</p>
                  </div>
                  <div class="col">
                    <p translate>
                      Only when enabled, fill in your Travel and/or Parking cost. You have 3 days to fill in. Be on time
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto" style="width: 120px; max-width: 120px">
                    <p translate>How to :</p>
                  </div>
                  <div class="col">
                    <p translate>
                      After you travel expenses have been approved, enter the exact same ammount on your
                      invoice/receipt. Any other amount will not be processed taken
                    </p>
                    <ul class="p-3 mt-0">
                      <li translate>Start and end location</li>
                      <li translate>
                        If enabled. Total kilometers traveled, there and back (or Public Transport 2nd class)
                      </li>
                      <li translate>If enabled. Parking cost + receipt</li>
                      <li translate>The Caster will approve/reject your travel and/or parking costs</li>
                    </ul>
                  </div>
                </div>
              </div>
              <ng-container formArrayName="travelExpenses">
                <ng-container *ngFor="let travelExpense of travelExpenses.controls; let i = index">
                  <div *ngIf="travelExpenses.controls.length > 1" class="separator"></div>
                  <ng-container [formGroupName]="i">
                    <div class="row">
                      <div class="col">
                        <select formControlName="type" class="form-control" (change)="calculationPrice(i)">
                          <option value="kilometers">{{'KM' | translate}}</option>
                          <option value="parking">{{'Parking' | translate}}</option>
                          <option value="other">{{'Other (i.e. public transport)' | translate}}</option>
                        </select>
                      </div>
                      <div class="col">
                        <input
                          *ngIf="travelExpenses.controls[i].get('type').value === 'parking'"
                          type="number"
                          step="0.1"
                          class="form-control"
                          formControlName="time"
                          placeholder="{{'EST Hours > Duration' | translate}}" />
                        <input
                          *ngIf="travelExpenses.controls[i].get('type').value === 'kilometers'"
                          type="number"
                          step="0.1"
                          class="form-control"
                          formControlName="distance"
                          placeholder="{{'Ex. 1 km' | translate}}" />
                      </div>
                    </div>
                    <div class="separator mt-0"></div>
                    <textarea
                      placeholder="{{
                                        (
                                          travelExpenses.controls[i].get('type').value == 'kilometers'
                                            ? 'Ex. Driving to & from location'
                                            : travelExpenses.controls[i].get('type').value == 'parking'
                                              ? 'Ex. Where did you park your car'
                                              : 'Ex. Transportation by train'
                                        )
                                        | translate
                                      }}"
                      formControlName="description"
                      class="textarea-control"
                      style="height: 100px !important"></textarea>
                    <div class="separator"></div>
                    <input
                      class="form-control input-euro"
                      placeholder="{{'Price' | translate}}"
                      type="number"
                      formControlName="price"
                      step="0.01"
                      [readonly]="travelExpenses.controls[i].get('type').value == 'kilometers'"
                      style="width: 45%" />
                  </ng-container>
                </ng-container>
                <div class="text-center mb-3" *ngIf="travelExpenses.controls.length > 1">
                  <button
                    type="button"
                    (click)="removeTravelExpense(travelExpenses.controls.length -1)"
                    class="btn btn-remove"
                    translate>
                    Remove
                  </button>
                </div>
                <div class="text-center">
                  <button type="button" (click)="addTravelExpense()" class="btn btn-add" translate>+ Add more</button>
                </div>
              </ng-container>
              <div class="separator"></div>
              <ng-container formArrayName="travelReceipts">
                <div class="mediaItems">
                  <div
                    *ngFor="let receipt of travelReceipts.controls; let i = index"
                    [formGroupName]="i"
                    [style.background-image]="receipt?.get('url')?.value != null ? 'url(' + receipt?.get('url')?.value + ')' : ''"
                    class="media mb-3"
                    style="margin: auto">
                    <div class="overlay">
                      <app-media-field
                        accept="image/png,image/x-png,image/gif,image/jpeg"
                        (input)="uploadReceipt($event.target.files, receipt.value.id, i)"></app-media-field>
                      <button class="btn-delete-img" type="button" (click)="removeReceipt(i)">
                        <img src="/assets/img/mobile/trash.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="text-center">
                <button type="button" (click)="addReceipt()" class="btn btn-add" translate>Upload Receipt</button>
              </div>
            </ng-container>
          </div>
          <div class="modal-footer">
            <ng-container
              *ngIf="(jobInvoice !== null && jobInvoice !== undefined) && (jobInvoice?.tc || jobInvoice.pc);">
              <div class="wrap-btn mb-2">
                <button
                  type="submit"
                  class="btn-submit"
                  style="border-radius: 100px; margin: auto auto 2px auto; width: 182px"
                  (click)="submit()"
                  [disabled]="!form.valid">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
                  <span *ngIf="!isBusy">{{ 'Send' | translate }}</span>
                </button>
              </div>
              <button
                type="button"
                (click)="openNoTravelCost()"
                class="btn-submit btn-bg-gray"
                style="border-radius: 100px; margin: auto auto 2px auto; width: 182px"
                translate>
                No Travel Cost
              </button>
            </ng-container>
            <button type="button" (click)="close()" class="btn btn-block">{{'Cancel' | translate}}</button>
          </div>
        </form>
      </ng-container>
    </ng-container>
    <ng-template #success>
      <div class="modal-body">
        <app-mobile-success [message]="'Thank you, it has been submitted'">
          <ng-container>
            <p class="text-center mt-3" translate>
              You already filled in your travel costs. The Caster will now confirm or reject the travel costs.
            </p>
            <div class="wrap-btn" style="margin-top: 40px">
              <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
            </div>
          </ng-container>
        </app-mobile-success>
      </div>
    </ng-template>
    <ng-template #showNoTravelCost>
      <div class="modal-body">
        <app-mobile-success [message]="'You have no travel costs'">
          <ng-container>
            <div class="wrap-btn" style="margin-top: 40px">
              <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
            </div>
          </ng-container>
        </app-mobile-success>
      </div>
    </ng-template>
    <ng-template #pendingTravelCost>
      <div class="modal-body">
        <app-mobile-success
          [message]="'You have already submitted your Traveling Cost. Please wait until Caster has made a decision.'">
          <ng-container>
            <div class="wrap-btn" style="margin-top: 40px">
              <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
            </div>
          </ng-container>
        </app-mobile-success>
      </div>
    </ng-template>
  </ng-container>
</app-mobile-modal-dialog>
<app-mobile-without-travel-cost #appWithoutTravelCost></app-mobile-without-travel-cost>
