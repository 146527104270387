import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { DiscountCode } from '@core/models';
import { IDiscountCode } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DiscountCodesService {
  public constructor(private http: HttpClient) {}

  public findByCode(code: string): Observable<IDiscountCode> {
    return this.http
      .get(`${environment.apiBaseUrl}/discount-codes/valid/${code}`)
      .pipe(map((data: IDiscountCode) => plainToClass(DiscountCode, data)));
  }
}
