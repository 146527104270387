import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobApplication } from '@core/models';
import { Store } from '@ngxs/store';
import { AcceptJobApplication } from '@core/states';

@Component({
  selector: 'app-job-application-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  @Output()
  public cancelClicked = new EventEmitter();

  @Input()
  public jobApplication: JobApplication;

  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<ConfirmComponent>,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.jobApplication = this.data.jobApplication;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public acceptJob(): void {
    this.store.dispatch(
      new AcceptJobApplication(this.jobApplication.user.id, this.jobApplication.job.id, this.jobApplication.id),
    );
    this.closeModal();
  }
}
