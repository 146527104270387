import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { plainToClass } from 'class-transformer';

import { JobApplication, User, UserAlbum, UserProfile } from '@core/models';
import { IUserProfile, MediaType } from '@core/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-talent-pdf-card',
  templateUrl: './talent-pdf-card.component.html',
  styleUrls: ['./talent-pdf-card.component.scss'],
})
export class TalentPDFCardComponent implements OnInit, OnChanges {
  @Input()
  public jobApplication?: JobApplication;

  @Input()
  public talentProfile?: UserProfile;

  @Input()
  public jobCategoryId: string;

  public profile: UserProfile;

  public photoAlbum: UserAlbum;

  public videoAlbum: UserAlbum;

  public photos = [];

  public details = [];

  public profilePicture: string;

  public constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    if (!!this.jobApplication) {
      this.setProfile(this.jobApplication.user);
      this.setUserProfilePicture(this.jobApplication.user);
    } else if (!!this.talentProfile) {
      this.profile = this.talentProfile;
      this.setUserProfilePicture(this.talentProfile.user);
      this.setAlbum(this.talentProfile);
      this.createDetails(this.talentProfile);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('jobApplication' in changes) {
      const user = plainToClass(User, changes.jobApplication.currentValue.user);
      this.setProfile(user);
      this.setUserProfilePicture(user);
    }

    if ('talentProfile' in changes) {
      this.profile = this.talentProfile;
      this.setUserProfilePicture(this.talentProfile.user);
      this.setAlbum(this.talentProfile);
      this.createDetails(this.talentProfile);
    }
  }

  public setProfile(user: User): void {
    if (!user.profiles || user.profiles.length <= 0) {
      return;
    }

    const groupProfilesActors = ['actors', 'models', 'extras', 'dancers', 'showmodels', 'influencers'];
    let indexProfile: number;
    const profileTalent = user.profiles.filter((v) => v.category.id === this.jobCategoryId);
    if (profileTalent.length > 0) {
      indexProfile = profileTalent.findIndex(
        (profile: IUserProfile): boolean => profile.category.id === this.jobCategoryId,
      );
    } else {
      const findByActorGroup = user.profiles.filter((v) => groupProfilesActors.includes(v.category.slug));
      if (findByActorGroup.length > 0) {
        indexProfile = user.profiles.findIndex((profile: IUserProfile): boolean =>
          groupProfilesActors.includes(profile.category.slug),
        );
      } else {
        indexProfile = user.profiles.findIndex(
          (profile: IUserProfile): boolean => profile.category.id === this.jobCategoryId,
        );
      }
    }

    // const indexProfile: number = user.profiles.findIndex(
    //   (profile: IUserProfile): boolean => profile.category.id === this.jobCategoryId,
    // );

    this.profile = plainToClass(UserProfile, user.profiles[indexProfile >= 0 ? indexProfile : 0]);

    this.setAlbum(this.profile);
    this.createDetails(this.profile);
  }

  public setPhotos(album: UserAlbum): void {
    this.photos = album.media;
  }

  public setUserProfilePicture(user: User): void {
    this.profilePicture = user.photo.thumbUrl;
  }

  public async setAlbum(profile: UserProfile): Promise<void> {
    this.photoAlbum = profile.albums.find((album: UserAlbum): boolean => album.albumType === MediaType.photo);
    this.videoAlbum = profile.albums.find((album: UserAlbum): boolean => album.albumType === MediaType.video);

    await this.setPhotos(this.photoAlbum);
  }

  private async createDetails(profile: UserProfile): Promise<any> {
    if (!profile) {
      return;
    }

    const selectedDetails = ['height', 'hair-color', 'dress', 'ethnicity'];
    const groupedDetails = [];

    for (const f of profile.filters) {
      if (selectedDetails.includes(f.filter.slug)) {
        let value = f.value;

        if (f.filterOption != null) {
          value = await this.translate.get(f.filterOption.translateKey).toPromise();
        }

        let filterDetails = groupedDetails.find((d): boolean => d.label === f.filter.label);

        if (!filterDetails) {
          filterDetails = {
            label: f.filter.label,
            translateKey: f.filter.translateKey,
            order: f.filter.order,
            type: f.filter.type,
            unit: f.filter.unit,
            values: [],
          };

          groupedDetails.push(filterDetails);
        }

        filterDetails.values.push(value);
      }
    }

    this.details = groupedDetails.sort((a: any, b: any): number =>
      a.order === b.order ? 0 : a.order > b.order ? 1 : -1,
    );
  }

  public get user(): User {
    if (!!this.talentProfile) {
      return this.talentProfile.user;
    } else {
      return this.jobApplication.user;
    }
  }
}
