import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ProfilesStateModel } from './profiles.state-model';
import { UserProfilesService, UsersService } from '@core/services';

import { AddUserProfile, DeleteUserProfile, LoadUserProfile, SaveUserProfile } from './profiles.actions';
import { PaginatedResults, User, UserProfile } from '@core/models';
import { AddProfileToUser, UpdateUserDetails } from '../user';
import { Navigate } from '@ngxs/router-plugin';

@State<ProfilesStateModel>({
  name: 'userprofiles',
  defaults: {
    talent: null,
    talentProfile: null,
  },
})
@Injectable()
export class ProfilesState {
  public constructor(private userProfilesService: UserProfilesService, private usersService: UsersService) {}

  @Selector()
  static talent(state: ProfilesStateModel): User {
    return state.talent;
  }

  @Selector()
  static talentProfile(state: ProfilesStateModel): UserProfile {
    return state.talentProfile;
  }

  @Action(AddUserProfile)
  public async addUserProfile(
    ctx: StateContext<ProfilesStateModel>,
    { id, profileData, dontNavigate = false }: AddUserProfile,
  ): Promise<void> {
    const talentProfile = await this.userProfilesService.createNewProfile(id, profileData).toPromise();
    ctx.patchState({ talentProfile });

    if (dontNavigate) {
      ctx.dispatch([new AddProfileToUser(talentProfile)]);
    } else {
      ctx.dispatch([new AddProfileToUser(talentProfile), new Navigate(['/account/roles', talentProfile.id])]);
    }
  }

  @Action(LoadUserProfile)
  public async loadUserProfile(
    ctx: StateContext<ProfilesStateModel>,
    { id, profileId }: LoadUserProfile,
  ): Promise<void> {
    const talentProfile = await this.userProfilesService.getEditProfileById(profileId).toPromise();
    ctx.patchState({ talentProfile });
  }

  @Action(SaveUserProfile)
  public async saveUserProfile(
    ctx: StateContext<ProfilesStateModel>,
    { profileId, talentProfileData }: SaveUserProfile,
  ): Promise<void> {
    const talentProfile = await this.userProfilesService.saveProfile(profileId, talentProfileData).toPromise();
    if (talentProfile.id === ctx.getState().talentProfile.id) {
      ctx.patchState({ talentProfile });
    }
  }

  @Action(DeleteUserProfile)
  public async deleteUserProfile(
    ctx: StateContext<ProfilesStateModel>,
    { profileId }: DeleteUserProfile,
  ): Promise<void> {
    await this.userProfilesService.deleteProfile(profileId).toPromise();
    if (profileId === ctx.getState().talentProfile?.id) {
      ctx.dispatch([new UpdateUserDetails(ctx.getState().talentProfile?.user)]);
      // , new Navigate(['/account/roles/add'])
      ctx.patchState({ talentProfile: null });
    }
  }
}
