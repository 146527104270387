import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@core/models';
import { IUser } from '@core/interfaces';
import { plainToClass } from 'class-transformer';

import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnChanges {
  @Input()
  public user: IUser;

  @Input()
  public checked = false;

  @Input()
  public onSelectId: string;

  @Output()
  public handleSelect = new EventEmitter<any>();

  @Input()
  public showCheckbox = true;

  @HostBinding('class')
  public hover = '';

  public talent: User;
  public imageSize: any;
  public tileSize: any;
  public footerHeight = 64;

  public constructor(
    private cd: ChangeDetectorRef,
    private layoutCalculator: LayoutCalculatorService,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.layoutCalculator.layoutChanged$.subscribe((data) => this.updateParams(data));
  }

  public onClick(e: any): void {
    this.store.dispatch(
      new Navigate(['profile', this.talent.id, this.talent.id], this.route.snapshot.queryParams, {
        relativeTo: this.route,
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes) {
      this.talent = plainToClass(User, this.user);
      this.updateParams(this.layoutCalculator.data);
      // } else if ('checked' in changes) {
      //   this.checked = changes['checked'].currentValue;
      //   this.cd.detectChanges();
    }
  }

  public select(checked: boolean): void {
    this.handleSelect.emit({ checked, id: this.onSelectId });
  }

  private updateParams(data: any): void {
    const perRow = this.getPerRow(data.breakpointName);
    this.tileSize = this.layoutCalculator.getTileSize(perRow, 9 / 16);
    this.imageSize = this.layoutCalculator.getImageSize(this.tileSize);

    this.cd.detectChanges();
  }

  private getPerRow(breakpointName: string): number {
    switch (breakpointName) {
      case 'sm':
        return 4;
      case 'md':
        return 6;
      case 'lg':
        return 7;
      case 'xl':
        return 8;
      default:
        return 3;
    }
  }
}
