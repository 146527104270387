<div class="d-flex flex-column w-100 text-white p-5" *ngIf="jobApplication">
  <h2 translate>ACCEPT JOB APPLICATION?</h2>
  <p
    translate
    [innerHTML]="
      'Awesome, you are about to accept the job application from' | translate: { user: jobApplication.user.profileName }
    "></p>
  <p translate>When accepted you have a binding agreement with the talent about this specific job.</p>
  <p translate>
    Cancelling from your end gives the ability to the talent to review you a low score, affecting future job
    applications.
  </p>
  <p *ngIf="job">
    <span translate>Total costs</span>
    &nbsp;
    <app-money [amount]="job.totalRateInclVat"></app-money>
  </p>
  <div>
    <app-button (click)="acceptJobApplication()" [isBusy]="isBusy" buttonStyle="purple" class="mb-3">
      <div class="px-2" translate>Accept application & create payment</div>
    </app-button>
    <app-button (click)="closeModal()" buttonStyle="white-outline" class="mb-3">
      <div class="px-2" translate>Cancel</div>
    </app-button>
  </div>
  <p translate>When accepted, you will be redirected to the payment provider to pay the fee for this job.</p>
</div>
