export class FetchPayment {
  public static readonly type = '[Payments] Fetch payment by id';

  public constructor(public paymentId: string) {}
}

export class FetchPayments {
  public static readonly type = '[Payments] Fetch payments for user';

  public constructor(public userId: string) {}
}

export class FetchSettlements {
  public static readonly type = '[Payments] Fetch settlements for user';

  public constructor(public userId: string) {}
}

export class FetchTransactionsForUser {
  public static readonly type = '[Payments] Fetch transactions for user';

  public constructor(public userId: string) {}
}

export class FetchSettlementSpecifications {
  public static readonly type = '[Payments] Fetch settlement rows';

  public constructor(public userId: string, public settlementUid: string) {}
}

export class FetchMerchant {
  public static readonly type = '[Payments] Fetch user merchant details';

  public constructor(public userId: string) {}
}

export class FetchBankAccount {
  public static readonly type = '[Payments] Fetch user bankaccount details';

  public constructor(public userId: string) {}
}

export class CreateMerchant {
  public static readonly type = '[Payments] Create user merchant';

  public constructor(public userId: string) {}
}

export class CreateBankAccount {
  public static readonly type = '[Payments] Create a bankaccount for an user';

  public constructor(public userId: string) {}
}

export class CreatePayment {
  public static readonly type = '[Payments] Create an payment for a job application';

  public constructor(public userId: string, public jobApplicationId: string, public returnUrl: string) {}
}

export class CreateTravelExpensePayment {
  public static readonly type = '[Payments] Create an payment for travel costs';

  public constructor(public jobApplicationId: string, public returnUrl: string) {}
}

export class UpdateEscrow {
  public static readonly type = '[Payments] Update escrow period for a payment';

  public constructor(public userId: string, public paymentId: string, public days: number) {}
}

export class RequestRefundJobApplication {
  public static readonly type = '[Payments] Request refund for a payment';

  public constructor(public jobApplicationId: string, public paymentId: string, public reason: string) {}
}

export class ApproveRefundRequest {
  public static readonly type = '[Payments] Approve refund request';

  public constructor(public paymentId: string) {}
}

export class DenyRefundRequest {
  public static readonly type = '[Payments] Deny refund request';

  public constructor(public paymentId: string, public reason: string) {}
}

export class FetchRefunds {
  public static readonly type = '[Payments] Fetch refunds';

  public constructor(
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'asc',
  ) {}
}

export class FetchAllPayments {
  public static readonly type = '[Payments] Fetch all payments';

  public constructor(
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'desc',
  ) {}
}
