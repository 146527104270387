import { IUserMembership } from '@core/interfaces';
import { Type } from 'class-transformer';

export class UserMembership implements IUserMembership {
  @Type(() => Date)
  public startDate: Date;
  @Type(() => Date)
  public endDate: Date;
  public id: string;
  public interval: 'month' | 'year';
  public status: MandateStatus | null;

  public membershipId: string;
  public userId: string;
}

export enum MandateStatus {
  created = 'created',
  pending = 'pending',
  expired = 'expired',
  cancelled = 'cancelled',
  failed = 'failed',
  completed = 'completed',
  revoked = 'revoked',
}
