import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MobileLanguangeModalComponent } from '@shared/mobile-components/mobile-languange-modal/mobile-languange-modal.component';
import { MobileFilterModalComponent } from '@shared/mobile-components/mobile-filter-modal/mobile-filter-modal.component';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { IUser } from '@core/interfaces';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthState, SearchByName, SearchState, UserState, UserStateModel } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { NavigationEnd, Router } from '@angular/router';
import { MobileStorageKey, MobileStorageService } from '../services/mobile-storage.service';
import { User } from '@sentry/angular';
import { environment } from '@environments/environment';
import { Location } from '@angular/common';
import { AuthService } from '@auth/services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit, OnDestroy {
  @Select(AuthState.authenticated)
  public authenticated$: Observable<boolean>;

  @Select(UserState.user)
  public user$: Observable<IUser>;

  @Select(UserState)
  public userState$: Observable<UserStateModel>;

  @Input()
  public showFilter = false;

  @Input()
  public dataFilter: any = {};

  @Input()
  public totalNotification = 0;

  @ViewChild('langModal') private languangeModal: MobileLanguangeModalComponent;
  @ViewChild('filterModal') private filterModal: MobileFilterModalComponent;

  public performSearch$ = new Subject<string>();
  public results$: Subject<IUser[]> = new Subject<IUser[]>();
  public query = new FormControl(null);
  public photo$: Observable<string>;
  public isProfilePage = false;
  public isSettingPage = false;
  private destroyed$ = new Subject();

  constructor(
    private mobileStorage: MobileStorageService,
    protected translateService: TranslateService,
    private store: Store,
    public authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
  ) {
    this.performSearch$
      .pipe(
        tap(() => this.results$.next(null)),
        takeUntil(this.destroyed$),
        debounceTime(1000),
        filter((query) => query?.length > 0),
        switchMap((query: string) => this.store.dispatch(new SearchByName(query))),
        switchMap(() => this.store.select(SearchState.users)),
        tap(this.results$),
      )
      .subscribe();

    this.query.valueChanges.pipe(takeUntil(this.destroyed$), tap(this.performSearch$)).subscribe();

    this.photo$ = this.user$.pipe(
      filter((user: User) => !!user && !!user.photo),
      map(
        (user: User): string => `${environment.apiBaseUrl}/media/${user.photo?.id}?size=80&t=${new Date().getTime()}`,
      ),
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const fullUrl = this.router.url;
        this.isProfilePage = fullUrl.includes('/account/roles/');
        this.isSettingPage = fullUrl.includes('account/details');
      }
    });
    this.isProfilePage = this.location.path().includes('/account/roles/');
    this.isSettingPage = this.location.path().includes('account/details');
  }

  ngOnInit(): void {}

  changeLanguange() {
    this.languangeModal.openDialog();
  }

  currentLanguage(): string {
    return this.translateService.currentLang.toUpperCase();
  }

  get activeRole(): string {
    return this.mobileStorage.get(MobileStorageKey.activeRole);
  }

  public navigateToProfile() {
    this.userState$.subscribe(async (state: UserStateModel): Promise<void> => {
      const profileId = state.profiles[0].id;
      if (profileId) {
        this.router.navigateByUrl(`/account/roles/${profileId}`);
      }
    });
  }

  public filterData() {
    this.filterModal.openDialog();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(null);
  }

  public onFocus(): void {
    this.performSearch$.next(this.query.value);
  }

  public onBlur(): void {
    setTimeout(() => {
      this.results$.next(null);
    }, 250);
  }

  public openUser(user: IUser): void {
    this.router.navigateByUrl(`talents/profile/${user.id}/${user.profiles[0].id}`);
  }

  public openSetting() {
    this.dialog.closeAll();
    this.router.navigate(['/account/details/dashboard']);
  }
}
