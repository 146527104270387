import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InvoicesService } from '@core/services';

import { FetchInvoices, FetchInvoice, SaveInvoice } from './invoices.actions';
import { InvoicesStateModel } from './invoices.state-model';
import { PaginatedResults, Invoice } from '@core/models';

@State<InvoicesStateModel>({
  name: 'Invoices',
  defaults: {
    invoices: null,
    invoice: null,
  },
})
@Injectable()
export class InvoicesState {
  public constructor(private invoicesService: InvoicesService) {}

  @Selector()
  static invoices(state: InvoicesStateModel): PaginatedResults<Invoice> {
    return state.invoices;
  }

  @Selector()
  static invoice(state: InvoicesStateModel): Invoice {
    return state.invoice;
  }

  @Action(FetchInvoices)
  public async fetchInvoices(
    ctx: StateContext<InvoicesStateModel>,
    { page, pageSize, sortColumn, sortDirection, infintyScrollMode }: FetchInvoices,
  ): Promise<void> {
    const invoices = await this.invoicesService.findAll(page, pageSize, sortColumn, sortDirection).toPromise();

    if (infintyScrollMode && invoices) {
      const currentInvoices = ctx.getState().invoices;
      if (page > 0 && currentInvoices != null) {
        const updatedInvoice = new PaginatedResults<Invoice>();
        updatedInvoice.results = [...currentInvoices.results, ...invoices.results];
        updatedInvoice.total = invoices.total;
        ctx.patchState({
          invoices: updatedInvoice,
        });
      } else {
        ctx.patchState({ invoices });
      }
    } else {
      ctx.patchState({ invoices });
    }
  }

  @Action(FetchInvoice)
  public async fetchInvoice(ctx: StateContext<InvoicesStateModel>, { id }: FetchInvoice): Promise<void> {
    const invoice = await this.invoicesService.getById(id).toPromise();
    ctx.patchState({ invoice });
  }

  @Action(SaveInvoice)
  public async saveInvoice(ctx: StateContext<InvoicesStateModel>, { invoice }: SaveInvoice): Promise<void> {
    const res = await this.invoicesService.saveInvoice(invoice).toPromise();
    ctx.patchState({ invoice: res });
  }
}
