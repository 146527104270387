import { IDeckUser, IUserProfile } from '@core/interfaces';
import { Type } from 'class-transformer';
import { UserProfile } from './user-profile.model';

export class DeckUser implements IDeckUser {
  @Type(() => UserProfile)
  public userProfile: IUserProfile;

  public id: string;
  public deckId: string;
  public userProfileId: string;
  public comment: string;
}
