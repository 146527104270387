import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import {
  CategoriesState,
  CountriesState,
  FeeTypesState,
  FetchAllCasters,
  FetchEditJob,
  GeocoderState,
  LanguagesState,
  LoadCategory,
  SaveJob,
  UserState,
} from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Country, Filter, GeoLocation, JobDate, JobTalentCountry, PaginatedResults, User } from '@core/models';
import { Observable, of } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoriesService } from '@core/services';
import {
  ICategory,
  ICountry,
  IFeeType,
  IFilter,
  IJob,
  IJobCountry,
  IJobTalentCountry,
  ILanguage,
  IMedia,
  IUser,
  MediaType,
} from '@core/interfaces';
import { map, switchMap, tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import moment, { Moment } from 'moment/moment';
import { FileService } from '@src/ui/generic/images/services/file.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-post-job-modal',
  templateUrl: './mobile-post-job-modal.component.html',
  styleUrls: ['./mobile-post-job-modal.component.scss'],
})
export class MobilePostJobModalComponent implements OnInit {
  @Input()
  public existJob: IJob;

  @Input()
  public talent: IUser;

  @Input()
  public isDirect: boolean;

  @Input()
  public categorySelected?: ICategory;

  @Select(LanguagesState.languages)
  public languages$: Observable<PaginatedResults<ILanguage>>;

  @Select(GeocoderState.geolocation)
  public geolocation$: Observable<GeoLocation>;

  @Select(CategoriesState.categories)
  public categories$: Observable<PaginatedResults<ICategory>>;

  @Select(CountriesState.countries)
  public countries$: Observable<PaginatedResults<ICountry>>;

  @Select(UserState.user)
  public currentUser$: Observable<IUser>;

  @Select(FeeTypesState.feeTypes)
  public feeTypes$: Observable<IFeeType[]>;

  @ViewChild('formPostJob') private modalComponent: MobileModalDialogComponent;

  public filterCategories: Array<Filter> = [];
  public categories: Array<ICategory> = [];
  public feeTypes: Array<IFeeType> = [];
  public dataCountries: Array<ICountry> = [];
  public formBuilder: FormGroup;
  public activeUser: User;
  public needsPayment = false;
  public totalCosts = 0;
  public directPaymentCosts = 0;
  public talentPaymentVAT = 0;
  public talentPaymentCosts = 0;
  public showAdditionalOptions = false;
  public isBusy = false;
  public message: any;
  public submitSuccess = false;
  public isLoading = true;
  public workFromHomeText = 'Online (work from home)';
  public jobInfo = true;
  public patternCategory: string[] = ['actors', 'models', 'extras', 'make-up-artists', 'host-and-promotors'];

  constructor(
    private fb: FormBuilder,
    private store: Store,
    protected categoryService: CategoriesService,
    private fileService: FileService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  infoJob() {
    this.jobInfo = false;
  }

  async openDialog() {
    this.fetchCategory();
    if (this.existJob !== null && this.existJob !== undefined) {
      this.categoryIsChecked(this.existJob.category);
    }
    return await this.modalComponent.open().then(() => {
      this.jobInfo = true;
      this.formBuilder = null;
      this.feeTypes = [];
      this.dataCountries = [];
      this.categories = [];
      this.filterCategories = [];
      this.categorySelected = null;
      this.isLoading = false;
      this.submitSuccess = false;
    });
  }

  async close() {
    return await this.modalComponent.close();
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.isBusy) {
        return;
      }
      this.isBusy = true;

      const data = { ...this.formBuilder.value, isDirectOffer: this.isDirect };

      const categoryFilters: IFilter[] = this.filterCategories;

      let filters: any[] = [];
      for (const slug of Object.keys(this.filters.value)) {
        const formValue = this.filters.value[slug];
        const categoryFilter: IFilter = categoryFilters.find((f: IFilter): boolean => f.slug === slug);
        if (categoryFilter?.type === 'checkbox') {
          const jobFilters = formValue
            .filter((c: any) => c.checked)
            .map((c: any) => ({
              id: v4(),
              filterId: categoryFilter.id,
              filterOptionId: c.value,
              jobId: data.id,
              lowValue: null,
              highValue: null,
            }));

          filters = filters.concat(jobFilters);
          delete data[slug];
          continue;
        }
        if (['number'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: parseInt(formValue, 10),
            highValue: null,
          });
          delete data[slug];
          continue;
        }
        if (['text'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: null,
            highValue: null,
            value: formValue,
          });
          delete data[slug];
          continue;
        }
        if (['slider', 'color'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: formValue[0],
            highValue: formValue[1],
          });
          delete data[slug];
          continue;
        }
        if (['text'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: null,
            highValue: null,
            value: formValue,
          });
          delete data[slug];
          continue;
        }
        data[slug] = formValue;
      }
      data.filters = filters;

      if (data.age.length === 2) {
        data.startAge = data.age[0];
        data.endAge = data.age[1];
      }
      delete data.age;
      delete data.workFromHomeText;
      delete data.haveTravelingCost;

      data.dates = data.dates.map((d: any) => {
        const dateObj = {
          id: d.id,
          start: this.getDateTimeAsMoment({ date: d.startDate, time: d.startTime }).utc().format('YYYY-MM-DD HH:mm:ss'),
          end: this.getDateTimeAsMoment({ date: d.endDate, time: d.endTime }).utc().format('YYYY-MM-DD HH:mm:ss'),
          days: d.days,
          hours_per_day: d.hours_per_day,
        };
        return dateObj;
      });

      data.publishingChannels = data.publishingChannels
        .filter((c: any) => c.checked)
        .map((c: any) => c.value)
        .join(',');

      data.countries = data.countries
        .filter((c: any) => c.checked === true)
        .map((c: any) => {
          delete c.checked;
          c.jobId = data.id;
          return c;
        });
      data.talentCountries = data.talentCountries
        .filter((c: any) => c.checked === true)
        .map((c: any) => {
          delete c.checked;
          c.jobId = data.id;
          return c;
        });

      data.keywords = data.keywords
        .filter((k: any) => k.keyword != null && k.keyword !== '')
        .map((k: any) => k.keyword)
        .join(';');

      data.gender =
        data.gender
          .filter((c: any) => c.checked)
          .map((c: any) => c.value)
          .join(';') + ';';

      data.countries = data.countries.filter(
        (test, index, array) =>
          index === array.findIndex((findTest) => findTest.countryId === test.countryId && findTest.city === test.city),
      );
      data.talentCountries = data.talentCountries.filter(
        (test, index, array) =>
          index === array.findIndex((findTest) => findTest.countryId === test.countryId && findTest.city === test.city),
      );

      //Remove talent countries only for actor jobs.
      if (this.categorySelected.slug === 'actors') {
        data.talentCountries = null;
      }

      data.media = data.media?.map((m: any) => {
        if (m.file !== null) {
          m.data = m.url;
        }
        delete m.file;
        delete m.url;
        return m;
      });

      data.licenceDuration = data.licenceDuration === 'other' ? data.licenceDurationCustom : data.licenceDuration;
      delete data.licenceDurationCustom;

      if (data.extraFees === '') {
        delete data.extraFees;
      }

      const jobNeedsPayment = !this.existJob && this.needsPayment;
      const cleanPath = window.location.pathname.replace('/job-thank-you', '');
      const returnUrl =
        this.isDirect && !cleanPath.startsWith('/account/')
          ? `${environment.baseUrl}${cleanPath}/job-process-payment/[PAYMENT_ID]${window.location.search}`
          : `${environment.baseUrl}/account/jobs/status/open`;

      await this.store.dispatch(new SaveJob(data, this.talent?.id, returnUrl, jobNeedsPayment)).toPromise();
      if (this.isDirect) {
        this.message = {
          message: await this.translate
            .get('Your offer needs payment, you are being redirected to our payment provider.')
            .toPromise(),
          type: 'success',
          returnUrl: '/account/jobs/status/ongoing',
        };
      } else {
        this.store.dispatch(new FetchEditJob(data.id));
        this.submitSuccess = true;
        this.message = {
          message:
            this.existJob !== undefined && this.existJob !== null
              ? 'Your job has been successfully edited'
              : 'Your job has been successfully posted',
          type: 'success',
          returnUrl: '/account/jobs/status/ongoing',
        };
      }
    } catch (e) {
      this.message = {
        message: await this.translate.get('Er is een onbekende fout opgetreden').toPromise(),
        type: 'error',
      };

      throw e;
    } finally {
      this.isBusy = false;
      this.submitSuccess = true;
    }
  }

  public exactDayIsChecked() {
    if (!this.formBuilder.get('exactDate')?.value) {
      this.formBuilder.patchValue({
        exactDate: true,
      });
    } else {
      this.formBuilder.patchValue({
        exactDate: false,
      });
    }
  }

  public categoryIsChecked(value: ICategory) {
    this.filterCategories = [];
    if (
      this.categorySelected !== undefined &&
      this.categorySelected !== null &&
      value.slug === this.categorySelected.slug
    ) {
      this.categorySelected = null;
      this.formBuilder.patchValue({
        categoryId: null,
      });
    } else {
      this.categorySelected = value;
      this.formBuilder.patchValue({
        categoryId: value.id,
      });
      this.fetchTalentCategory();
    }
  }

  public viewMore(e: any) {
    const selectorID = e.target.getAttribute('data-id');
    const body = e.target.parentElement.querySelector(selectorID);

    if (e.target.getAttribute('aria-expanded') === 'false') {
      e.target.innerHTML = e.target.getAttribute('data-close');
      e.target.setAttribute('aria-expanded', true);
      body.classList.add('show');
    } else {
      e.target.setAttribute('aria-expanded', false);
      e.target.innerHTML = e.target.getAttribute('data-open');
      body.classList.remove('show');
    }
  }

  public getCountryCode(control: FormGroup): string {
    return this.dataCountries.find((c) => c.id === control.get('countryId').value)?.isoCode;
  }

  public removeCountry(i: number): void {
    this.countries.removeAt(i);
  }

  public removeDate(i: number): void {
    this.dates.removeAt(i);
  }

  public async removeMedia(i: number): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to delete this image?').toPromise();
    if (confirm(translation)) {
      this.media.removeAt(i);
    }
  }

  public toggleAdditionalOptions(): void {
    this.showAdditionalOptions = !this.showAdditionalOptions;
  }

  public removeLanguage(i: number): void {
    this.languages.removeAt(i);
  }

  public setWorkFromHome(): void {
    this.formBuilder.get('workFromHome').setValue(!this.formBuilder.get('workFromHome').value);
  }

  public removeLicenceCountry(i: number): void {
    this.licenceCountries.removeAt(i);
  }

  public matchById(val1: any, val2: any): boolean {
    return val1?.id === val2?.id;
  }

  public otherBroadcastCountriesSelected(): boolean {
    if (this.formBuilder?.get('broadcastCountries').value === 'other' && this.licenceCountries.length === 0) {
      this.addLicenceCountry();
    } else if (this.formBuilder?.get('broadcastCountries').value === 'worldwide' && this.licenceCountries.length > 0) {
      this.licenceCountries.clear();
    }

    return this.formBuilder?.get('broadcastCountries').value === 'other';
  }

  public otherLicenceDurationSelected(): boolean {
    this.formBuilder.get('licenceDurationCustom').clearValidators();
    this.formBuilder.get('licenceDurationCustom').clearAsyncValidators();
    if (this.formBuilder?.get('licenceDuration').value === 'other') {
      this.formBuilder.get('licenceDurationCustom').setValidators([Validators.required]);
    } else if (this.formBuilder?.get('licenceDuration').value === 'forever') {
      this.formBuilder.get('licenceDurationCustom').markAsPristine();
      this.formBuilder.get('licenceDurationCustom').markAllAsTouched();
    }
    this.formBuilder.get('licenceDurationCustom').updateValueAndValidity();
    return this.formBuilder?.get('licenceDuration').value === 'other';
  }

  public addMedia(): void {
    this.media.push(this.getMediaFormGroup());
  }

  public addLanguage(): void {
    this.languages.push(this.getLanguageFormGroup());
  }

  public addLicenceCountry(): void {
    this.licenceCountries.push(this.getCountryFormGroup());
  }

  public addDate(): void {
    this.dates.push(this.getDateFormGroup(null, this.formBuilder.get('exactDate').value));
  }

  public addCountry(): void {
    this.countries.push(this.getCountryWithCityFormGroup());
  }

  public getTotalHours(): any {
    return (o: any) =>
      o.pipe(
        switchMap((dates: any) =>
          of(dates).pipe(
            map((date) => {
              const start = this.getDateTimeAsMoment(date.startDate);
              const end = this.getDateTimeAsMoment(date.endDate);
              return moment.duration(end.diff(start)).asHours();
            }),
          ),
        ),
      );
  }

  public get countries(): FormArray {
    return this.formBuilder.get('countries') as FormArray;
  }

  public get dates(): FormArray {
    return this.formBuilder.get('dates') as FormArray;
  }

  public get licenceCountries(): FormArray {
    return this.formBuilder.get('licenceCountries') as FormArray;
  }

  public get filters(): FormGroup {
    return this.formBuilder.get('filters') as FormGroup;
  }

  public get exactDate(): FormControl {
    return this.formBuilder.get('exactDate') as FormControl;
  }

  public get languages(): FormArray {
    return this.formBuilder.get('languages') as FormArray;
  }

  public get keywords(): FormArray {
    return this.formBuilder.get('keywords') as FormArray;
  }

  public get media(): FormArray {
    return this.formBuilder.get('media') as FormArray;
  }

  public get requestTalentVideo(): FormControl {
    return this.formBuilder.get('requestTalentVideo') as FormControl;
  }

  private fetchCategory() {
    this.categories$.subscribe((category) => {
      if (category !== null) {
        const priorityCategory = category.results
          .filter((res) => {
            if (this.patternCategory.indexOf(res.slug) >= 0) {
              return res;
            }
          })
          .sort((a, b) => {
            return this.patternCategory.indexOf(a.slug) - this.patternCategory.indexOf(b.slug);
          });

        const notPriority = category.results.filter((res) => {
          if (this.patternCategory.indexOf(res.slug) < 0) {
            return res;
          }
        });

        this.categories.push(...priorityCategory, ...notPriority);
        this.fetchFeeTypes();
      }
    });
  }

  private fetchFeeTypes() {
    this.feeTypes$.subscribe((feeType) => {
      if (feeType !== null) {
        this.feeTypes.push(...feeType);
        this.fetchCountries();
      }
    });
  }

  private fetchCountries() {
    this.countries$.subscribe((country) => {
      if (country !== null) {
        this.dataCountries.push(...country.results);
        this.fetchUser();
      }
    });
  }

  private fetchUser() {
    this.currentUser$.subscribe((user: User) => {
      if (user != null) {
        this.activeUser = user;

        if (user.isAdmin) {
          this.store.dispatch(new FetchAllCasters());
        }

        this.initializeForm();
        this.setupFormField();

        const selectedCountry: Country = user?.country;
        if (selectedCountry != null && this.existJob == null) {
          this.patchCountryFields('talentCountries', user.country);
          this.patchCountryFields('countries', user.country);
        }

        this.isLoading = false;
      }
    });
  }

  private fetchTalentCategory() {
    if (this.categorySelected !== null) {
      this.categoryService
        .getBySlug(this.categorySelected.slug)
        .toPromise()
        .then((res) => {
          this.filterCategories.push(...res.filters);
          this.patchFilters(res.filters, this.existJob);
        });
    }
  }

  private calculateTotalCosts(data: any): void {
    const isFeeJob = data?.feeType?.slug === 'fee';
    const isPromoted = data?.promoted === true;

    let totalCosts = 0;
    this.directPaymentCosts = 0;
    this.talentPaymentCosts = 0;

    if (!isFeeJob) {
      this.directPaymentCosts += 2.5;
      totalCosts += 2.5;
    }

    if (isPromoted) {
      this.directPaymentCosts += 9;
      totalCosts += this.directPaymentCosts;
    }

    if (isFeeJob) {
      this.talentPaymentCosts = parseFloat(data?.rate || '0');
      this.talentPaymentCosts += parseFloat(data?.extraFees || '0');
      this.talentPaymentVAT = this.talentPaymentCosts * environment.vat;
      this.talentPaymentCosts += this.talentPaymentVAT;
      totalCosts += this.talentPaymentCosts;
    }

    this.totalCosts = totalCosts;
  }

  private setNeedsPayment(data: any): void {
    if (this.existJob?.payment !== null && this.existJob?.payment !== undefined) {
      this.needsPayment = false;
      return;
    }
    if (this.totalCosts <= 0) {
      this.needsPayment = false;
      return;
    }

    if (this.isDirect && !this.activeUser.bypassPayment) {
      this.needsPayment = true;
      return;
    }
    this.needsPayment = this.directPaymentCosts > 0 || data.feeType?.slug !== 'fee';
  }

  private patchCountryFields(field: string, selectedCountry: ICountry): void {
    for (let i = 0; i < (this.formBuilder.get(field) as FormArray).length; i++) {
      (this.formBuilder.get(field) as FormArray).at(i).patchValue({
        countryId: selectedCountry.id,
        coordinates: {
          x: selectedCountry.coordinates?.x,
          y: selectedCountry.coordinates?.y,
        },
      });
    }
  }

  private getMediaFormGroup(media?: IMedia): FormGroup {
    const mediaFileForm = this.fb.group({
      id: [media?.id || v4(), []],
      mediaType: [media?.mediaType || MediaType.photo, []],
      path: [media?.path, []],
      url: [media?.url, []],
      file: [null, media == null ? [Validators.required] : []],
    });

    mediaFileForm.get('file').valueChanges.subscribe(async (data) => {
      mediaFileForm.get('url').setValue(await this.fileService.readFile(data));
    });

    return mediaFileForm;
  }

  private getDateFormGroup(d?: JobDate, exactDate: boolean = true): FormGroup {
    let id = v4();
    let startDate = moment().local(true).set('hour', 9).set('minute', 0);
    let endDate = moment().local(true).set('hour', 17).set('minute', 0);

    if (!!d) {
      id = d.id;

      startDate = moment(d.start).local();
      endDate = moment(d.end).local();
    }

    const dateForm = this.fb.group({
      id: new FormControl(id, [Validators.required]),
      startDate: new FormControl(startDate, [Validators.required]),
      startTime: new FormControl(startDate, [Validators.required]),
      endDate: new FormControl(endDate, [Validators.required]),
      endTime: new FormControl(endDate, [Validators.required]),
      days: new FormControl(d?.days, exactDate ? [] : [Validators.required, Validators.min(1), Validators.max(100)]),
      hours_per_day: new FormControl(
        d?.hours_per_day,
        exactDate ? [] : [Validators.required, Validators.min(1), Validators.max(24)],
      ),
    });
    dateForm.get('startDate').valueChanges.subscribe((_) => {
      if (this.formBuilder.get('exactDate').value) {
        dateForm.get('endDate').setValue(dateForm.get('startDate').value);
      }
    });

    dateForm.valueChanges.subscribe((_) => this.validateStartAndEnd(dateForm, this.formBuilder.get('exactDate').value));
    return dateForm;
  }

  private getLanguageFormGroup(l?: ILanguage): FormGroup {
    const languages = this.store.selectSnapshot(LanguagesState.languages)?.results;
    return this.fb.group({
      id: [l?.id || languages[0]?.id, Validators.required],
    });
  }

  private getCountryFormGroup(c?: ICountry): FormGroup {
    const countries = this.store.selectSnapshot(CountriesState.countries)?.results;
    return this.fb.group({
      id: [c?.id || this.activeUser?.country.id || countries[0]?.id, Validators.required],
    });
  }

  private getDateTimeAsMoment(value: { date: Moment; time: Moment }): Moment {
    return moment(value.date.local()).set({
      hour: value.time.local().get('hour'),
      minute: value.time.local().get('minute'),
      second: 0,
    });
  }

  private validateStartAndEnd(date: AbstractControl, exactDate: boolean): void {
    if (!exactDate) {
      return;
    }
    const start = this.getDateTimeAsMoment({ date: date.get('startDate').value, time: date.get('startTime').value });
    const end = this.getDateTimeAsMoment({ date: date.get('endDate').value, time: date.get('endTime').value });

    if (!start.isSameOrBefore(end, 'second')) {
      date.get('startDate').setErrors({ invalid: true });
      date.get('startTime').setErrors({ invalid: true });
      date.get('endDate').setErrors({ invalid: true });
      date.get('endTime').setErrors({ invalid: true });
      return;
    }

    date.get('startDate').setErrors(null);
    date.get('startTime').setErrors(null);
    date.get('endDate').setErrors(null);
    date.get('endTime').setErrors(null);
  }

  private getKeywordsFormGroup(k?: string): FormGroup {
    return this.fb.group({
      keyword: [k || '', []],
    });
  }

  private getCountryWithCityFormGroup(c?: IJobCountry | IJobTalentCountry, withDistance: boolean = false): FormGroup {
    const countries = this.store.selectSnapshot(CountriesState.countries)?.results;
    const selectedCountry = c?.country || this.activeUser?.country || countries[0];
    const countryForm = this.fb.group({
      checked: [true],
      countryId: [selectedCountry?.id, Validators.required],
      city: [c?.city ?? ''],
      coordinates: this.fb.group({
        x: [selectedCountry.coordinates?.x, Validators.required],
        y: [selectedCountry.coordinates?.y],
      }),
    });
    if (withDistance) {
      let distance = 0;
      if (c != null) {
        distance = (c as JobTalentCountry).distance ?? 0;
      }
      countryForm.addControl('distance', new FormControl(`${distance}`, Validators.required));
    }

    countryForm.get('countryId').setValidators([Validators.required]);
    countryForm.get('countryId').updateValueAndValidity();
    if (withDistance) {
      countryForm.get('distance').setValidators([Validators.required]);
      countryForm.get('distance').updateValueAndValidity();
    }
    countryForm.get('coordinates.x').setValidators([Validators.required]);
    countryForm.get('coordinates.x').updateValueAndValidity();

    return countryForm;
  }

  private initializeForm() {
    const publishingChannels = this.existJob?.publishingChannels?.split(',') ?? [];
    const gender = this.existJob?.gender?.split(';') ?? ['male', 'female', 'transgender'];
    const keywords = this.existJob?.keywords.split(';') ?? [];
    for (let i = keywords.length; i < 3; i++) {
      keywords.push('');
    }
    const licenceDurationCustom = this.existJob == null ? '1 jaar' : this.existJob?.licenceDuration;

    this.formBuilder = new FormGroup({
      id: new FormControl(this.existJob?.id ?? v4(), [Validators.required]),
      title: new FormControl(this.existJob?.title, [Validators.required]),
      paymentTypeId: new FormControl('15a42529-95ef-4ca5-b923-97b70b456016', [Validators.required]),
      packageId: new FormControl(this.existJob?.packageId),
      categoryId: new FormControl(this.existJob?.category.id, [Validators.required]),
      userId: new FormControl(
        this.activeUser.isAdmin ? this.existJob?.user.id : this.activeUser.id,
        this.activeUser.isAdmin ? [Validators.required] : [],
      ),
      talentCountries: new FormArray(
        this.existJob?.talentCountries.map((c) => this.getCountryWithCityFormGroup(c, true)) ?? [
          this.getCountryWithCityFormGroup(null, true),
        ],
      ),
      countries: new FormArray(
        this.existJob?.countries.map((j) => this.getCountryWithCityFormGroup(j)) ?? [
          this.getCountryWithCityFormGroup(),
        ],
      ),
      workFromHome: new FormControl(this.existJob?.workFromHome),
      workFromHomeText: new FormControl('Online (work from home)'),
      exactDate: new FormControl(this.existJob?.exactDate ?? true, [Validators.required]),
      dates: new FormArray(
        this.existJob?.dates.map((d) => this.getDateFormGroup(d, true)) ?? [this.getDateFormGroup(null, true)],
      ),
      languages: new FormArray(this.existJob?.languages.map((l) => this.getLanguageFormGroup(l)) ?? []),
      media: new FormArray(this.existJob?.media.map((m) => this.getMediaFormGroup(m)) ?? []),
      feeType: new FormControl(this.existJob?.feeType ?? this.feeTypes[0], [Validators.required]),
      rate: new FormControl(this.existJob?.rate, []),
      extraFees: new FormControl(this.existJob?.extraFees, []),
      hideRate: new FormControl(this.existJob?.hideRate, []),
      commercialUse: new FormControl(this.existJob?.commercialUse ?? true, []),
      foodIncluded: new FormControl(this.existJob?.foodIncluded ?? false),
      shortDescription: new FormControl(this.existJob?.shortDescription, [Validators.required]),
      gender: new FormControl([
        { value: 'male', checked: gender.includes('male') },
        { value: 'female', checked: gender?.includes('female') },
        { value: 'transgender', checked: gender?.includes('transgender') },
      ]),
      age: new FormControl([this.existJob?.startAge ?? 18, this.existJob?.endAge ?? 100]),
      keywords: new FormArray(
        keywords.map((k) => this.getKeywordsFormGroup(k)) ?? [
          this.getKeywordsFormGroup(),
          this.getKeywordsFormGroup(),
          this.getKeywordsFormGroup(),
        ],
      ),
      publishingChannels: new FormControl(
        [
          { value: 'online', checked: publishingChannels?.includes('online') },
          { value: 'print', checked: publishingChannels?.includes('print') },
          { value: 'tv-film', checked: publishingChannels?.includes('tv-film') },
        ],
        [
          (control: FormControl) => {
            let hasOneChecked = false;

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (const value of control.value) {
              hasOneChecked = hasOneChecked || value.checked;
            }
            if (!hasOneChecked) {
              return { required: true };
            }

            return null;
          },
        ],
      ),
      broadcastCountries: new FormControl(
        this.existJob == null ? 'worldwide' : this.existJob?.broadcastCountries === 'other' ? 'other' : 'worldwide',
        [Validators.required],
      ),
      licenceCountries: new FormArray(this.existJob?.licenceCountries.map((l) => this.getCountryFormGroup(l)) ?? []),
      licenceDuration: new FormControl(
        this.existJob == null ? null : this.existJob?.licenceDuration === 'forever' ? 'forever' : 'other',
        [Validators.required],
      ),
      licenceDurationCustom: new FormControl(licenceDurationCustom !== 'forever' ? licenceDurationCustom : null),
      onlyFiltered: new FormControl(this.existJob?.onlyFiltered),
      promoted: new FormControl(this.existJob?.promoted),
      filters: new FormGroup({}),
      budgetCamOrderNumber: new FormControl(this.existJob?.budgetCamOrderNumber),
      requestTalentVideo: new FormControl(this.existJob?.requestTalentVideo ?? false),
      requestTalentVideoDescription: new FormControl(this.existJob?.requestTalentVideoDescription),
      travelingCost: new FormControl(this.existJob?.travelingCost ? this.existJob?.travelingCost : 0.23),
      haveTravelingCost: new FormControl(!this.existJob?.title ? true : this.existJob?.travelingCost ? true : false, [
        Validators.required,
      ]),
      maxTravelingCost: new FormControl(this.existJob?.maxTravelingCost),
      parkingCost: new FormControl(this.existJob?.parkingCost ?? null),
      maxParkingCost: new FormControl(this.existJob?.maxParkingCost),
    });
  }

  private setupFormField(): void {
    this.translate
      .get('Online (work from home)')
      .toPromise()
      .then((value): void => {
        this.workFromHomeText = value;
        if (this.formBuilder != null) {
          this.formBuilder.patchValue({
            workFromHomeText: this.workFromHomeText,
          });
        }
      });

    this.geolocation$.subscribe((geolocation: GeoLocation): void => {
      if (geolocation != null) {
        let subForm: AbstractControl = (this.formBuilder.get('countries') as FormArray).controls.find(
          (c: AbstractControl): boolean => c.get('city').value === geolocation.description,
        );
        if (subForm == null) {
          subForm = (this.formBuilder.get('talentCountries') as FormArray).controls.find(
            (c: AbstractControl): boolean => c.get('city').value === geolocation.description,
          );
        }
        if (subForm != null) {
          subForm.patchValue({
            city: geolocation.city,
            coordinates: geolocation.coordinates,
          });
        }
      }
    });

    this.feeTypes = this.feeTypes.filter(
      (feeTypes: IFeeType): boolean => feeTypes.slug === 'fee' || feeTypes.slug === 'freebie',
    );

    this.formBuilder.get('haveTravelingCost').valueChanges.subscribe((isHaveTravelingCost) => {
      if (isHaveTravelingCost) {
        this.formBuilder.get('travelingCost').setValue(0.23);
      } else {
        this.formBuilder.get('travelingCost').setValue(null);
      }
    });

    if (this.formBuilder.get('feeType').value.slug === 'fee') {
      this.formBuilder.get('rate').setValidators([Validators.required, Validators.min(15)]);
    }

    this.formBuilder.get('licenceDuration').valueChanges.subscribe((value) => {
      if (this.formBuilder?.get('licenceDuration').value !== 'other') {
        this.formBuilder?.patchValue({
          licenceDurationCustom: null,
        });
      }
      this.formBuilder.get('licenceDurationCustom').updateValueAndValidity();
    });

    this.formBuilder.get('broadcastCountries').valueChanges.subscribe((value) => {
      this.formBuilder.get('broadcastCountries').updateValueAndValidity({ emitEvent: false });
    });

    this.formBuilder.get('feeType').valueChanges.subscribe((f: IFeeType) => {
      const priceControl = this.formBuilder.get('rate');
      if (f.slug === 'fee') {
        priceControl.setValidators([Validators.required, Validators.min(15)]);
        priceControl.updateValueAndValidity();
        return;
      }
      this.formBuilder.get('extraFees').setValue(null);
      priceControl.setValue(null);
      priceControl.clearValidators();
      priceControl.updateValueAndValidity();
    });

    this.formBuilder.get('exactDate').valueChanges.subscribe(() => {
      (this.formBuilder.get('dates') as FormArray).controls = [];
      this.addDate();
    });

    this.formBuilder.valueChanges
      .pipe(
        tap((data: any) => this.calculateTotalCosts(data)),
        tap((data: any) => this.setNeedsPayment(data)),
      )
      .subscribe();

    if (this.existJob != null) {
      this.store.dispatch(new LoadCategory(this.existJob.category.id));
    }
  }

  private patchFilters(filters: IFilter[], job?: IJob): void {
    this.formBuilder.setControl('filters', new FormGroup({}));
    filters.map((value) => {
      this.addCategoryFilterFormGroup(value, job);
    });
  }

  private addCategoryFilterFormGroup(filter: IFilter, job?: IJob): void {
    const jobFilters = job?.filters.filter((f: any) => f.filterId === filter.id) ?? [];
    const formControl = new FormControl();

    if (filter.type === 'checkbox') {
      const value = [];
      for (const talentProfileFilter of jobFilters) {
        value.push({
          value: talentProfileFilter.filterOption?.id,
          checked: true,
        });
      }
      formControl.setValue(value);
    }

    if (filter.type === 'slider' || filter.type === 'color') {
      const value = [jobFilters[0]?.lowValue, jobFilters[0]?.highValue];
      formControl.setValue(value);
    }
    if (filter.type === 'number') {
      const value = [jobFilters[0]?.lowValue];
      formControl.setValue(value);
    }
    if (filter.type === 'text') {
      const value = [jobFilters[0]?.value];
      formControl.setValue(value);
    }

    this.filters.addControl(filter.slug, formControl);
  }
}
