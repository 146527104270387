import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeRemaining',
})
export class TimeRemainingPipe implements PipeTransform {
  transform(dateStart: any, args?: any): any {
    if (dateStart) {
      const start: moment.Moment = moment(dateStart).local();
      const end: moment.Moment = moment().local();
      const locale: string = localStorage.getItem('language');

      // set locale based on active language
      moment.locale(locale);

      const timeRemaining: string = end.to(start);

      return timeRemaining.charAt(0).toUpperCase() + timeRemaining.slice(1);
    } else {
      return null;
    }
  }
}
