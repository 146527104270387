import { classToClass, classToPlain, ClassTransformOptions, plainToClass } from 'class-transformer';

export class BaseModel {
  public static classToPlain<T>(obj: T, options?: ClassTransformOptions): Record<string, unknown>;
  public static classToPlain<T>(obj: T[], options?: ClassTransformOptions): Record<string, unknown>[];
  public static classToPlain<T>(
    obj: T | T[],
    options?: ClassTransformOptions,
  ): Record<string, unknown> | Record<string, unknown>[] {
    const defaultOptions = { groups: ['related'] };
    return classToPlain(obj, { ...defaultOptions, ...options });
  }

  public static plainToClass<T, V>(cls: any, plain: V[], options?: ClassTransformOptions): T[];
  public static plainToClass<T, V>(cls: any, plain: V, options?: ClassTransformOptions): T;
  public static plainToClass<T, V>(cls: any, plain: V | V[], options?: ClassTransformOptions): T | T[] {
    const defaultOptions = { excludeExtraneousValues: true, groups: ['related'] };
    return plainToClass(cls, plain as any, { ...defaultOptions, ...options });
  }

  public static classToClass<T>(obj: T, options?: ClassTransformOptions): T;
  public static classToClass<T>(obj: T[], options?: ClassTransformOptions): T[];
  public static classToClass<T>(obj: T | T[], options?: ClassTransformOptions): T | T[] {
    const defaultOptions = { excludeExtraneousValues: true, groups: ['related'] };
    return classToClass(obj, { ...defaultOptions, ...options });
  }
}
