import { Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentsService } from '@core/services';
import { delay, map, retryWhen, take, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { FetchJobs, GetJobQuota, UserState } from '@core/states';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-payment-process-modal',
  templateUrl: './payment-process-modal.component.html',
  styleUrls: ['./payment-process-modal.component.scss'],
})
export class PaymentProcessModalComponent {
  @Input()
  public payment_uid: string;

  @Input()
  public isDirectOffer = false;

  @Input()
  public reloadJobs = false;

  public status$: Subject<string> = new Subject<string>();

  public current = 0;
  public tries = 5;
  public interval = 6000;

  public failed = false;
  public success = false;

  public status: string;

  public errorStatus = ['cancelled', 'expired', 'refunded', 'failed', 'chargeback'];
  public successStatus = ['completed', 'reserved'];
  public pendingStatus = ['pending', 'planned'];

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PaymentsService) private paymentsService: PaymentsService,
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    private data: { payment_uid?: string; isDirectOffer?: boolean; reloadJobs?: boolean },
    @Optional() private dialogRef: MatDialogRef<PaymentProcessModalComponent>,
    private store: Store,
  ) {
    this.payment_uid = data?.payment_uid;
    this.isDirectOffer = data?.isDirectOffer || false;
    this.reloadJobs = data?.reloadJobs || false;

    this.checkStatus();

    setTimeout(() => this.close(), this.tries * this.interval);
  }

  public checkStatus(): void {
    if (!this.payment_uid) {
      return;
    }

    this.paymentsService
      .getPaymentStatus(this.payment_uid)
      .pipe(
        map(({ status }: { status: string }) => {
          this.status = status;
          this.status$.next(status);
          if (this.pendingStatus.includes(status)) {
            throw status;
          }
          this.success = this.successStatus.includes(status);
          this.close();
          return status;
        }),
        retryWhen((errors: Observable<Error>) =>
          errors.pipe(
            take(this.tries),
            delay(this.interval),
            tap(() => {
              this.current++;
              if (this.current === this.tries) {
                this.failed = true;
              }
            }),
          ),
        ),
      )
      .subscribe();
  }

  public close(): void {
    this.status$.complete();
    // this.status$.unsubscribe();
    this.store.dispatch(new GetJobQuota());
    if (this.reloadJobs) {
      const userId = this.store.selectSnapshot(UserState.user)?.id;
      this.store.dispatch(new FetchJobs(userId, 'open'));
    }
    if (this.success && this.isDirectOffer) {
      setTimeout(() => {
        this.dialogRef.close({
          success: this.success,
          status: this.status,
        });
      }, 3000);
    } else {
      // show result for 3 seconds before closing
      setTimeout(() => this.dialogRef.close(true), 3000);
    }
  }
}
