import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core';
import * as moment from 'moment';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import html2pdf from 'html2pdf.js';
import { UserProfile } from '@core/models';

@Component({
  selector: 'app-talent-profile-pdf-modal',
  templateUrl: './talent-profile-pdf-modal.component.html',
  styleUrls: ['./talent-profile-pdf-modal.component.scss'],
})
export class TalentProfilePDFModalComponent implements OnInit {
  public isProcess = false;

  public progressStatus = '';

  public progressPercentage = 0;

  public profile: UserProfile;

  public math = Math;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<TalentProfilePDFModalComponent>,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.profile = this.data.profile;
  }

  public closeModal(): void {
    return this.dialogRef.close();
  }

  public async printPage(): Promise<void> {
    if (this.isProcess) {
      return;
    }

    this.isProcess = true;
    this.progressStatus = 'Downloading Image...';
    this.progressPercentage = 1;

    const portfolioElement: HTMLElement = document.getElementById('portfolio').cloneNode(true) as HTMLElement;

    const portfolioImgs: NodeListOf<HTMLImageElement> = portfolioElement.querySelectorAll('.photoImg');
    const profilePicture: NodeListOf<HTMLImageElement> = portfolioElement.querySelectorAll('.profilePictureImg');

    const totalImageToConvert =
      (portfolioImgs ? portfolioImgs.length : 0) + (profilePicture.length ? profilePicture.length : 0);
    const percentageIncreaseUnit = totalImageToConvert ? 90 / totalImageToConvert : 0;
    let percentageInterval: ReturnType<typeof setInterval>;

    if (percentageIncreaseUnit) {
      percentageInterval = setInterval(() => {
        this.progressPercentage = this.progressPercentage + 1;

        if (this.progressPercentage >= percentageIncreaseUnit) {
          // stop interval whein it's reach increaseUnit
          clearInterval(percentageInterval);
        }
      }, 1000);
    }

    if (portfolioImgs) {
      let iPortfolioImg = 0;
      for (const portfolioImg of Array.from(portfolioImgs)) {
        portfolioImgs[iPortfolioImg].src = await this.cropImageAndCovertToBase64(portfolioImg.src, 478 / 595);
        // kill progress interval
        if (iPortfolioImg === 0) {
          if (percentageInterval) {
            clearInterval(percentageInterval);
          }
          this.progressPercentage = percentageIncreaseUnit;
        } else {
          this.progressPercentage = this.progressPercentage + percentageIncreaseUnit;
        }

        iPortfolioImg++;
      }
    }

    if (profilePicture) {
      let iProfilePicture = 0;
      for (const profilePictureElm of Array.from(profilePicture)) {
        profilePicture[iProfilePicture].src = await this.cropImageAndCovertToBase64(profilePictureElm.src, 478 / 595);
        this.progressPercentage = this.progressPercentage + percentageIncreaseUnit;

        iProfilePicture++;
      }
    }

    const datetime = moment(new Date()).format('YYYYMMDDHHmmss');
    this.progressPercentage = 90;
    this.cd.detectChanges();

    const opt = {
      margin: 0,
      filename: `${this.profile.user.profileName}_${datetime}.pdf`,
      image: { type: 'jpeg', quality: 0.5 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'px', format: [842, 595], orientation: 'landscape' },
      pagebreak: { mode: 'css' },
    };

    this.progressStatus = 'Generate Pdf file...';

    if (percentageIncreaseUnit) {
      percentageInterval = setInterval((): void => {
        this.progressPercentage = this.progressPercentage + 1;
        this.cd.detectChanges();

        if (this.progressPercentage >= 99) {
          // stop interval when it's reach 99%
          clearInterval(percentageInterval);
        }
      }, 2000);
    }

    setTimeout(() => {
      html2pdf()
        .set(opt)
        .from(portfolioElement)
        .save()
        .then(() => {
          if (percentageInterval) {
            clearInterval(percentageInterval);
          }
          this.isProcess = false;
          this.progressStatus = '';
          this.progressPercentage = 100;
          this.cd.detectChanges();
        });
    }, 1000);
  }

  private cropImageAndCovertToBase64(url, aspectRatio): Promise<string> {
    return new Promise((resolve): void => {
      const inputImage: HTMLImageElement = new Image();
      inputImage.crossOrigin = 'anonymous';

      inputImage.onload = (): void => {
        // let's store the width and height of our image
        const inputWidth: number = inputImage.naturalWidth;
        const inputHeight: number = inputImage.naturalHeight;

        // get the aspect ratio of the input image
        const inputImageAspectRatio: number = inputWidth / inputHeight;

        // if it's bigger than our target aspect ratio
        let outputWidth: number = inputWidth;
        let outputHeight: number = inputHeight;
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio;
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputWidth / aspectRatio;
        }

        // calculate the position to draw the image at
        const outputX: number = (outputWidth - inputWidth) * 0.5;
        const outputY: number = (outputHeight - inputHeight) * 0.5;

        // create a canvas that will present the output image
        const outputImage: HTMLCanvasElement = document.createElement('canvas');

        // set it to the same size as the image
        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        // draw our image at position 0, 0 on the canvas
        const ctx: CanvasRenderingContext2D = outputImage.getContext('2d');
        ctx.drawImage(inputImage, outputX, outputY);

        const dataURL: string = outputImage.toDataURL('image/jpeg');

        // Convert to Base64 string
        const base64: string = dataURL;

        resolve(base64);
      };

      // start loading our image
      inputImage.src = url;
    });
  }
}
