import { Component, OnInit, ViewChild } from '@angular/core';
import { JobApplication } from '@core/models';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { IJob } from '@core/interfaces';
import { Store } from '@ngxs/store';
import {
  AcceptJobApplication,
  CancelBookingJobApplication,
  CancelJobApplication,
  ConfirmJobApplication,
  RejectBookingJobApplication,
  RejectJobApplication,
} from '@core/states';
import { FormControl, FormGroup, Validators } from '@angular/forms';

interface ConfirmBookingApplicant {
  actionType: 'cancelBooking' | 'rejectBooking' | 'rejectJob' | 'confirmJob' | 'cancelJobApplication' | 'acceptJob';
  jobApplication: JobApplication;
  job: IJob;
}

@Component({
  selector: 'app-mobile-confirm-booking-modal',
  templateUrl: './mobile-confirm-booking-modal.component.html',
  styleUrls: ['./mobile-confirm-booking-modal.component.scss'],
})
export class MobileConfirmBookingModalComponent implements OnInit {
  @ViewChild('appConfirmBookingModal') private modalComponent: MobileModalDialogComponent;

  public isSuccess: boolean;
  public isBusy: boolean;
  public data?: ConfirmBookingApplicant;
  public form = new FormGroup({
    reason: new FormControl(null, [Validators.required]),
  });

  constructor(private store: Store) {}

  ngOnInit(): void {}

  async openDialog(data: ConfirmBookingApplicant): Promise<void> {
    this.data = data;
    return await this.modalComponent.open().then(() => {
      this.isBusy = false;
      this.isSuccess = false;
      this.data = null;
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public async rejectBooking(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new RejectBookingJobApplication(
          this.data.jobApplication.user.id,
          this.data.job.id,
          this.data.jobApplication.id,
          this.form.value.reason,
        ),
      )
      .toPromise();

    this.isSuccess = true;
    this.isBusy = false;
  }

  public async cancelBooking(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new CancelBookingJobApplication(
          this.data.jobApplication.user.id,
          this.data.job.id,
          this.data.jobApplication.id,
          this.form.value.reason,
        ),
      )
      .toPromise();

    this.isSuccess = true;
    this.isBusy = false;
  }

  public async rejectJob(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new RejectJobApplication(
          this.data.jobApplication.user.id,
          this.data.jobApplication.job.id,
          this.data.jobApplication.id,
        ),
      )
      .toPromise();

    this.isSuccess = true;
    this.isBusy = false;
  }

  public async confirmJob(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new ConfirmJobApplication(
          this.data.jobApplication.user.id,
          this.data.jobApplication.job.id,
          this.data.jobApplication.id,
        ),
      )
      .toPromise();

    this.isSuccess = true;
    this.isBusy = false;
  }

  public async cancelJobApplication(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new CancelJobApplication(
          this.data.jobApplication.user.id,
          this.data.jobApplication.job.id,
          this.data.jobApplication.id,
        ),
      )
      .toPromise();

    this.isSuccess = true;
    this.isBusy = false;
  }

  public async acceptJob(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new AcceptJobApplication(
          this.data.jobApplication.user.id,
          this.data.jobApplication.job.id,
          this.data.jobApplication.id,
        ),
      )
      .toPromise();

    this.isSuccess = true;
    this.isBusy = false;
  }
}
