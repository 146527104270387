<app-mobile-modal-dialog #appMembershipModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="membership-modal-container" *ngIf="user | async as user">
    <ng-container *ngIf="memberships">
      <div *ngFor="let membership of sortedMembership; let i = index">
        <div
          [class.paid-membership]="membership.id == user.membership?.id"
          class="membership-item"
          [ngClass]="{ current: membership.id === user.membership?.id }">
          <p class="mb-3" *ngIf="membership.id == user.membership?.id">
            <b translate>Current.</b>
            <span translate *ngIf="user.membership?.interval === 'year'">Yearly membership</span>
            <span translate *ngIf="user.membership?.interval === 'month'">Monthly membership</span>
          </p>
          <span
            class="mb-3 d-block"
            *ngIf="membership.id == user.membership?.id && !!user.upcomingMembership && !user.membership?.title?.includes('Free')">
            {{ 'Active until' | translate: { date: user.membership.next_bill_date } }}
          </span>
          <span
            class="mb-3"
            *ngIf="membership.id == user.membership?.id && !user.upcomingMembership && !user.membership?.title?.includes('Free')">
            {{ 'Next bill' | translate: { date: user.membership.next_bill_date } }}
          </span>
          <span
            class="mb-3 d-block"
            *ngIf="membership.id == user.upcomingMembership?.id && !user.membership?.title?.includes('Free')">
            {{ 'Will start at' | translate: { date: user.membership.next_bill_date } }}
          </span>

          <div class="content rounded-sm panel d-flex flex-column">
            <h2 class="membership-title">
              {{ membership?.title | translate }}
              <img *ngIf="membership?.title !== 'Free'" src="/assets/img/mobile/badge.svg" alt="" />
            </h2>
            <!-- <div 
                            class="mb-3 bold price"
                            *ngIf="membership.price_month > 0" 
                            [innerHTML]="
                                'membershipPrice' | 
                                translate: { price_month: membership.price_month, price_year: membership.price_year }">
                        </div> -->

            <p
              [innerHTML]=" 
                                'membershipProfiles' |
                                translate: { profiles: membership.talent_profiles }"></p>
            <p
              [innerHTML]="
                                'membershipCommission' | 
                                translate: { commission: membership.commission }"></p>
            <p
              *ngIf="membership.job_reactions && membership.price_month > 0"
              [innerHTML]="
                                'membershipJobReactions' |
                                translate: { job_reactions: membership.job_reactions }"></p>
            <p
              *ngIf="membership.job_reactions && membership.price_month == 0"
              [innerHTML]="
                                'membershipJobReactions' |
                                translate: { 
                                    job_reactions: membership.job_reactions > 5 ? 5 : membership.job_reactions 
                                }"></p>
            <p *ngIf="!membership.job_reactions" [innerHTML]="'membershipUnlimitedJobReactions' | translate"></p>
            <p
              *ngIf="membership.direct_messages"
              [innerHTML]="
                                'membershipDM' |
                                translate: { direct_messages: membership.direct_messages }"></p>
            <p
              *ngIf="!membership.direct_messages && membership.price_month > 0"
              [innerHTML]="'membershipUnlimitedDM' | translate"></p>
            <p
              *ngIf="membership.jobs_limit"
              [innerHTML]="
                                'membershipJobOffers' | 
                                translate: { direct_messages: membership.jobs_limit }"></p>
            <p
              *ngIf="!membership.jobs_limit && membership.price_month > 0"
              [innerHTML]="'membershipUnlimitedJobOffers' | translate"></p>
            <p
              [innerHTML]="'membershipPictures' | 
                            translate: { portfolio_pictures: membership.portfolio_pictures }"></p>
            <p [innerHTML]="'membershipVideos' | translate: { videos: membership.videos }"></p>
            <p *ngIf="membership.price_month == 0">-</p>

            <!-- action button -->
            <ng-container *ngIf="!user?.upcomingMembership">
              <button
                *ngIf="membership.id !== user.membership?.id"
                [class]="
                                    getButtonPlanLabel(membership, user.upcomingMembership) === 'Upgrade plan' ? 
                                    'purple-button' : 'outline-button'"
                (click)="openModal(membership, user.membership)"
                [disabled]="membership.id == user.membership?.id && membership.price_month == 0">
                <span>{{ getButtonPlanLabel(membership, user.membership) }}</span>
              </button>

              <button
                *ngIf="membership.id === user.membership?.id && membership.price_month > 0"
                class="white-button"
                (click)="openModal(membership, user.membership)"
                [disabled]="membership.id == user.membership?.id && membership.price_month == 0">
                <span translate>View details</span>
              </button>
            </ng-container>

            <ng-container *ngIf="!!user?.upcomingMembership">
              <button
                *ngIf="membership.id !== user.upcomingMembership.id && membership.id !== user.membership.id"
                [class]="
                    getButtonPlanLabel(membership, user.upcomingMembership) === 'Upgrade plan' ? 
                    'purple-button' : 'outline-button'
                "
                (click)="openModal(membership, user.upcomingMembership)"
                [disabled]="membership.id == user.upcomingMembership?.id && membership.price_month == 0">
                <span translate>{{ getButtonPlanLabel(membership, user.upcomingMembership) }}</span>
              </button>

              <button
                *ngIf="(membership.id === user.membership.id || membership.id === user.upcomingMembership.id) && membership.price_month > 0"
                class="white-button"
                (click)="openModal(membership, user.upcomingMembership)"
                [disabled]="membership.id == user.upcomingMembership?.id && membership.price_month == 0">
                <span translate>View details</span>
              </button>
            </ng-container>
          </div>
        </div>
        <div [class.no-line]="i > 0" class="divider-line"></div>
      </div>
    </ng-container>
  </div>
  <app-mobile-account-membership-confirmation-modal
    #membershipConfirmationModal></app-mobile-account-membership-confirmation-modal>
</app-mobile-modal-dialog>
