<section class="signup">
  <div class="row">
    <div class="col-6">
      <div
        class="custom-card"
        style="background-image: url(/assets/img/mobile/signup/singup-caster.png); height: 258px; width: 100%">
        <h5 translate>Find Talents</h5>
        <p translate>for your projects</p>
        <button class="btn" (click)="signUp('caster')">{{'Signup as Caster' | translate}}</button>
      </div>
    </div>
    <div class="col-6">
      <div
        class="custom-card"
        style="background-image: url(/assets/img/mobile/signup/signup-talent.png); height: 258px; width: 100%">
        <h5 translate>Find Jobs</h5>
        <p translate>as Actor or Model</p>
        <button class="btn" (click)="signUp('talent')">{{'Signup as Talent' | translate}}</button>
      </div>
    </div>
  </div>
</section>
<app-mobile-auth-modal #loginModal></app-mobile-auth-modal>
