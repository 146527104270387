<app-mobile-modal-dialog #appDetailTravelCostModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="isSuccessType !== undefined && !confirmApprove; else showDetail">
    <div class="modal-body">
      <app-mobile-success *ngIf="isSuccessType === 'approve'" [message]="'Travel cost approved'"></app-mobile-success>
      <app-mobile-success *ngIf="isSuccessType === 'reject'" [message]="'Travel cost rejected'">
        <p class="text-center" translate>
          Travel cost successfully rejected. However, the talent can still submit their traveling expenses
        </p>
      </app-mobile-success>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="confirmApprove;">
    <div class="modal-body">
      <h6 style="font-size: 26px" class="text-center text-bold mt-3" translate>
        Are you sure you want to approve the travel expenses?
      </h6>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button
          type="button"
          class="btn btn-bg-purple"
          style="border-radius: 100px; margin: auto"
          (click)="approveTravelCosts()">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ 'Yes' | translate }}</span>
        </button>
      </div>
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" style="border-radius: 100px; margin: auto" class="btn btn-bg-gray">
          {{'Cancel' | translate}}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #showDetail>
    <ng-container *ngIf="!confirmApprove && jobApplication !==undefined && jobApplication$ | async">
      <div class="modal-body">
        <div class="travel-cost-info">
          <h6>Talent has filled in traveling cost, approve or reject now</h6>
          <p>Please click within 11 days on approve or reject to prevent automatic approval</p>
          <div *ngFor="let expense of jobApplication.travelExpenses">
            <div class="item" *ngIf="expense.type === TravelExpenseType.kilometers">
              <p class="title">{{ expense.description }}</p>
              <ol>
                <li>{{ expense.distance }} KM</li>
                <li>-</li>
                <li>
                  <span class="currency">€</span>
                  {{ expense.price }}
                </li>
              </ol>
            </div>
            <div class="item" *ngIf="expense.type === TravelExpenseType.parking">
              <p class="title">{{ expense.description }}</p>
              <ol>
                <li>{{ expense.time }} {{hours | translate}}</li>
                <li>-</li>
                <li>
                  <span class="currency">€</span>
                  {{ expense.price }}
                </li>
              </ol>
            </div>
            <div class="item" *ngIf="expense.type === TravelExpenseType.other">
              <p class="title">{{ expense.description }}</p>
              <ol>
                <li>
                  <span class="currency">€</span>
                  {{ expense.price }}
                </li>
              </ol>
            </div>
          </div>
          <h6>
            {{'Total' | translate}}:
            <span class="currency">€</span>
            {{totalPrice}}
          </h6>
        </div>
      </div>
      <div class="modal-footer">
        <div class="wrap-btn mb-2" *ngFor="let receipt of jobApplication.travelReceipts">
          <button
            type="button"
            class="btn btn-bg-white"
            style="border-radius: 100px; margin: auto"
            (click)="download(receipt)">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
            <span *ngIf="!isBusy">{{ 'Download receipt' | translate }}</span>
          </button>
        </div>
        <div class="wrap-btn mb-2">
          <button
            type="button"
            class="btn btn-bg-purple"
            style="border-radius: 100px; margin: auto"
            (click)="confirmApproveTravelCost()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
            <span *ngIf="!isBusy">{{ 'Approve' | translate }}</span>
          </button>
        </div>
        <div class="wrap-btn mb-2">
          <button
            type="button"
            class="btn btn-bg-gray"
            style="border-radius: 100px; margin: auto"
            (click)="rejectTravelCosts()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
            <span *ngIf="!isBusy">{{ 'Reject' | translate }}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>
</app-mobile-modal-dialog>
