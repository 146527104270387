import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Credit, CreditPackage } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  public constructor(private http: HttpClient) {}

  public get(): Observable<Credit> {
    return this.http.get(`${environment.apiBaseUrl}/credits`).pipe(map((data: Credit) => plainToClass(Credit, data)));
  }

  public getPackages(): Observable<CreditPackage[]> {
    return this.http.get(`${environment.apiBaseUrl}/credits/packages`).pipe(
      map((data: CreditPackage[]) => {
        return data.map((creditPackage) => plainToClass(CreditPackage, creditPackage));
      }),
    );
  }
}
