import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersService } from '@core/services';

import { SearchStateModel } from './search.state-model';

import { SearchByName } from './search.actions';
import { IUser } from '@core/interfaces';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@State<SearchStateModel>({
  name: 'userSearch',
  defaults: {
    users: null,
    isValid: false,
    inProgress: false,
  },
})
@Injectable()
export class SearchState {
  public constructor(private usersService: UsersService) {}

  @Selector()
  static inProgress(state: SearchStateModel): boolean {
    return state.inProgress;
  }

  @Selector()
  static isValid(state: SearchStateModel): boolean {
    return state.isValid;
  }

  @Selector()
  static users(state: SearchStateModel): IUser[] {
    return state.users;
  }

  @Action(SearchByName)
  public searchByName(ctx: StateContext<SearchStateModel>, { name }: SearchByName): Observable<IUser[]> {
    ctx.patchState({ isValid: false, inProgress: true });
    return this.usersService
      .searchByName(name)
      .pipe(tap((users: IUser[]) => ctx.patchState({ isValid: false, inProgress: false, users })));
  }
}
