import { ActivatedRoute } from '@angular/router';

export class SetFilters {
  public static readonly type = '[Search] Set filters';

  public constructor(public filters: any, public page: string, public route: ActivatedRoute) {}
}

export class LoadResults {
  public static readonly type = '[Search] Load results';

  public constructor(public filters: any, public page: string) {}
}

export class CreateChips {
  public static readonly type = '[Search] Create chips';

  public constructor(public filters: any) {}
}

export class SetCategory {
  public static readonly type = '[Search] Set category';

  public constructor(public categorySlug: string) {}
}

export class UpdateRecentlyViewed {
  public static readonly type = '[Search] Recently view';

  public constructor(public job: any) {}
}

export class UpdatePageNumber {
  public static readonly type = '[Search] Update pagenumber';

  public constructor(public page: string, public pageNumber: number) {}
}
