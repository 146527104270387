import { ICredit, ICreditPackage, ICreditTransaction, IJobApplication, IPayment, IUser } from '@core/interfaces';
import { Type } from 'class-transformer';
import { UserProfile } from './user-profile.model';
import { Credit } from './credit.model';
import { Payment } from './payment.model';
import { JobApplication } from './job-application.model';
import { CreditPackage } from './credit-package';

export class CreditTransaction implements ICreditTransaction {
  @Type(() => UserProfile)
  public user?: IUser;

  @Type(() => Credit)
  public credit?: ICredit;

  @Type(() => Payment)
  payment?: IPayment;

  @Type(() => JobApplication)
  jobApplication?: IJobApplication;

  @Type(() => CreditPackage)
  package?: ICreditPackage;

  public id: string;
  public userId: string;
  public creditId: string;
  public amount: number;
  public paymentId?: string;
  public transactionType: 'topup' | 'spend' | 'withdraw';
  public packageId?: string;
  public created: Date;
}
