import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import Splide, { SplideOptions } from '@splidejs/splide';

@Component({
  selector: 'app-mobile-carousel',
  templateUrl: './mobile-carousel.component.html',
  styleUrls: ['./mobile-carousel.component.scss'],
})
export class MobileCarouselComponent implements OnChanges, AfterViewInit {
  @ContentChild('carouselSlideTpl')
  public slideTemplate: TemplateRef<ElementRef>;

  @ContentChild('carouselFirstSlideTpl')
  public firstSlideTemplate: TemplateRef<ElementRef>;

  @Input()
  public items: Array<any>;

  @Input()
  public transition: any;

  @Input()
  public options: SplideOptions;

  @ViewChild('splideEl')
  private splideEl: ElementRef;

  public visible = [];

  private splide: Splide;

  public constructor(private cd: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    if (!this.splideEl) {
      return;
    }
    this.splide = new Splide(this.splideEl.nativeElement, this.options);

    this.splide.on('mounted resized', () => {
      const slides = this.splide.Components.Elements.getSlides(true).filter((s) => s.isVisible());
      for (const slide of slides) {
        this.visible[slide.index] = true;
        this.visible[slide.index + 1] = true;
        this.visible[slide.index + 2] = true;
      }

      this.cd.detectChanges();
    });

    this.splide.on('move', (newIndex: number) => {
      const options = this.splide.options;
      const ahead = options.perMove * options.perPage;
      for (let i = newIndex; i < newIndex + ahead; i++) {
        this.visible[i] = true;
      }
      this.cd.detectChanges();
    });

    this.splide.mount({}, this.transition);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes && this.splide) {
      this.splide.options = changes.options.currentValue;
    }
  }
}
