import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Review, User } from '@core/models';
import { Store } from '@ngxs/store';
import { DeleteReview, SaveReview } from '@core/states/reviews';
import { v4 as uuid } from 'uuid';
import { FetchJobs, UserState } from '@core/states';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent {
  @Output()
  public cancelClicked = new EventEmitter();

  @Input()
  public jobId: string;

  @Input()
  public user: User;

  @Input()
  public isTalent: boolean;

  public form: FormGroup = new FormGroup({
    id: new FormControl(uuid(), [Validators.required]),
    overallRating: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(5)]),
    communication: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(5)]),
    attitude: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(5)]),
    comment: new FormControl(null, [Validators.required]),
  });

  public review: Review;
  private reviewer: User;

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    private data: { jobId: string; user: User; reviews?: Review[]; isTalent: boolean },
    @Optional() private dialogRef: MatDialogRef<ReviewComponent>,
    private store: Store,
    private translateService: TranslateService,
  ) {
    this.jobId = data?.jobId;
    this.user = data?.user;
    this.isTalent = data?.isTalent;
    this.reviewer = this.store.selectSnapshot(UserState.user);

    if (data.reviews) {
      this.review = data.reviews.find(
        (rev: Review) => rev.reviewerId === this.reviewer?.id && rev.talentId === this.user?.id,
      );

      this.form.patchValue(this.review);
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public async saveReview(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    const review = {
      ...this.form.value,
      talentId: this.user?.id,
      jobId: this.jobId,
      reviewerId: this.reviewer.id,
    } as Review;
    await this.store.dispatch(new SaveReview(review)).toPromise();
    if (this.isTalent) {
      await this.store.dispatch(new FetchJobs(this.reviewer?.id, 'completed')).toPromise();
    }
    this.closeModal();
  }

  public async deleteReview(): Promise<void> {
    const translation = await this.translateService.get('Are you sure you want to delete your review?').toPromise();
    if (confirm(translation)) {
      await this.store.dispatch(new DeleteReview(this.form.value?.id)).toPromise();
      if (this.isTalent) {
        await this.store.dispatch(new FetchJobs(this.reviewer?.id, 'completed')).toPromise();
      }
    }
    this.closeModal();
  }
}
