import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateAndSort',
})
export class TranslateSortPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  async transform(value: any, propertyName: any, valueName: any, ...args: any[]): Promise<unknown> {
    if (!value || !propertyName || !valueName) {
      return value;
    }
    const result = [];

    for (const item of value.results) {
      const translated = await this.translate.get(item[propertyName]).toPromise();

      const existing = result.find((i: any) => i['translated'] === translated);

      if (existing) {
        continue;
      }

      result.push({
        value: item[valueName],
        translated,
        translateKey: item.translateKey,
      });
    }

    result.sort((i1: any, i2: any) => i1['translated'].localeCompare(i2['translated']));

    return result;
  }
}
