import { IJob, IPayment, JobApplicationStatus, PaymentStatus } from '@core/interfaces';

import { Category } from './category.model';
import { User } from './user.model';
import { PaymentType } from './payment-type.model';
import { Country } from './country.model';
import { DiscountCode } from './discount-code.model';
import { Media } from './media.model';
import { FeeType } from './fee-type.model';
import { JobFilter } from './job-filter.model';
import { Language } from './language.model';
import { JobDate } from './job-date.model';
import { JobCountry } from './job-country.model';
import { JobTalentCountry } from './job-talent-country.model';
import { Type } from 'class-transformer';
import { JobApplication } from '@core/models/job-application.model';
import { Review } from '@core/models/review.model';
import { environment } from '@environments/environment';

export class Job implements IJob {
  @Type(() => Category)
  public category?: Category;
  @Type(() => User)
  public user?: User;
  @Type(() => PaymentType)
  public paymentType?: PaymentType;
  @Type(() => JobCountry)
  public countries?: JobCountry[];
  @Type(() => JobTalentCountry)
  public talentCountries?: JobTalentCountry[];
  @Type(() => Media)
  public media?: Media[];
  @Type(() => FeeType)
  public feeType: FeeType;
  @Type(() => JobFilter)
  public filters?: JobFilter[];
  @Type(() => Language)
  public languages?: Language[];
  @Type(() => Country)
  public licenceCountries?: Country[];
  @Type(() => Review)
  public reviews?: Review[];
  @Type(() => JobDate)
  public dates?: JobDate[];
  @Type(() => DiscountCode)
  public discountCode?: DiscountCode;
  @Type(() => JobApplication)
  public jobApplications: JobApplication[];

  public payment?: IPayment;

  public id: string;

  public rate?: number;
  public extraFees?: number;
  public foodIncluded?: boolean;
  public bypassPayment: boolean;
  public title: string;
  public shortDescription: string;
  public additionalInfo?: string;
  public publishingChannels?: string;
  public broadcastCountries?: string;
  public licenceDuration?: string;
  public currency: string;
  public completed: boolean;
  public cancelled: boolean;
  public isDirectOffer: boolean;
  public enableNotification: boolean;
  public gender?: string;
  public startAge?: number;
  public endAge?: number;
  public keywords?: string;
  public onlyFiltered?: boolean;
  public promoted?: boolean;
  public customDesign?: boolean;
  public projectNumber?: string;
  public invoiceEmail?: string;
  public workFromHome?: boolean;
  public exactDate?: boolean;
  public hideRate?: boolean;
  public commercialUse?: boolean;
  public budgetCamOrderNumber?: string;
  public requestTalentVideo?: boolean;
  public requestTalentVideoDescription?: string;
  public travelingCost?: number;
  public maxTravelingCost?: number;
  public parkingCost: boolean;
  public maxParkingCost?: number;
  public hide?: boolean;
  public negotiable?: boolean;

  get firstDate(): JobDate {
    return this.getSortedDates[0];
  }

  get lastDate(): JobDate {
    return this.getSortedDates[this.dates.length - 1];
  }

  get getSortedDates(): JobDate[] {
    const byDate = this.dates.slice(0);
    byDate.sort((a, b) => {
      return a.start > b.start ? 1 : a.start < b.start ? -1 : 0;
    });
    return byDate;
  }

  get formattedPublishingChannels(): string[] {
    return this.publishingChannels?.split(',');
  }

  get formattedGenders(): string[] {
    const splittedGenders = this.gender?.split(';').filter((v) => v);
    if (splittedGenders?.length === 0) {
      return ['No preference'];
    }
    return splittedGenders;
  }

  get totalRate(): number {
    return (this.rate ?? 0) + (this.extraFees ?? 0);
  }

  get totalRateInclVat(): number {
    return this.totalRate * (1 + environment.vat);
  }

  get hideRateFee(): string {
    if (this.totalRate < 200) {
      return '€';
    } else if (this.totalRate < 600) {
      return '€€';
    } else {
      return '€€€';
    }
  }

  get hasTravelCosts(): boolean {
    return this.travelingCost !== undefined || this.parkingCost;
  }

  get hasJobApplicationPayments(): boolean {
    return (
      this.totalRate === 0 ||
      this.jobApplications.some(
        (j: JobApplication) => j.payment !== null && j.payment?.paymentStatus === PaymentStatus.completed,
      )
    );
  }

  get totalBookedTalent(): number {
    return this.jobApplications.filter((jobApplication) =>
      [
        JobApplicationStatus.accepted,
        JobApplicationStatus.open,
        JobApplicationStatus.confirmed,
        JobApplicationStatus.travelCostPending,
        JobApplicationStatus.travelCostRejected,
        JobApplicationStatus.travelCostApproved,
        JobApplicationStatus.completed,
      ].includes(jobApplication.status),
    ).length;
  }

  get totalRejectedTalent(): number {
    return this.jobApplications.filter((jobApplication) =>
      [JobApplicationStatus.rejected].includes(jobApplication.status),
    ).length;
  }

  get totalConfirmedTalent(): number {
    return this.jobApplications.filter((jobApplication) =>
      [JobApplicationStatus.confirmed].includes(jobApplication.status),
    ).length;
  }
}
