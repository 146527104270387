import { IFilterOption } from '@core/interfaces';

export class FilterOption implements IFilterOption {
  public id: string;
  public order: number;
  public slug: string;
  public label: string;

  get translateKey(): string {
    return 'FILTER_OPTION.' + this.slug;
  }
}
