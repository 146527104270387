import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { EmailsService } from '@core/services';

import { SendEmail } from './emails.actions';
import { Observable } from 'rxjs';

@State<any>({
  name: 'emails',
})
@Injectable()
export class EmailsState {
  public constructor(private emailsService: EmailsService) {}

  @Action(SendEmail)
  public sendEmail(_: StateContext<any>, { email, name, message, isoCode }: SendEmail): Observable<any> {
    return this.emailsService.send(name, email, message, isoCode);
  }
}
