import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@core/guards/admin-guard';
import { AccountGuard } from '@core/guards/account-guard';
import { DisabledGuard } from '@auth/guards';

const routes: Routes = [
  {
    path: 'account',
    canActivate: [AccountGuard, DisabledGuard],
    canActivateChild: [DisabledGuard],
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    loadChildren: () => import('./visitor/visitor.module').then((m) => m.VisitorModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0],
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
