<section class="list-nav">
  <ol>
    <li *ngFor="let item of menus">
      <div class="notif-badge notif-badge-danger" *ngIf="item.notif"></div>
      <button *ngIf="item.type !== 'url'" (click)="actionIdentifier(item)">
        <p translate>{{item.name}} {{item.totalData !== null && item.totalData > 0 ? '('+item.totalData+')': ''}}</p>
      </button>
      <div *ngIf="item.type === 'url'" (click)="openUrl(item.value)">
        <p translate>{{item.name}} {{item.totalData !== null && item.totalData > 0 ? '('+item.totalData+')': ''}}</p>
      </div>
    </li>
  </ol>
</section>
<app-mobile-credit-modal *ngIf="isTalent" #creditModal></app-mobile-credit-modal>
<app-mobile-languange-modal #langModal></app-mobile-languange-modal>
