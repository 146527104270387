import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-note-input',
  templateUrl: './note-input.component.html',
  styleUrls: ['./note-input.component.scss'],
})
export class NoteInputComponent implements OnInit {
  @Input() public negotiable = false;

  @Output()
  public applyClicked = new EventEmitter<any>();

  public form = new FormGroup({
    note: new FormControl(),
  });
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  public clickApply(skipNote): void {
    this.applyClicked.emit({
      skipNote,
      note: this.form.get('note').value,
    });
  }

  public get noteValue(): string {
    return this.form.get('note').value;
  }

  public getPlaceholder(): string {
    return this.negotiable
      ? this.translateService.instant('Ex. I would like to ask XX euro for this assignment with a usage of XX.')
      : this.translateService.instant('Write a note to the Caster');
  }
}
