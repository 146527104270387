import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PaymentsService } from '@core/services';

import {
  ApproveRefundRequest,
  CreateBankAccount,
  CreateMerchant,
  CreatePayment,
  CreateTravelExpensePayment,
  DenyRefundRequest,
  FetchAllPayments,
  FetchBankAccount,
  FetchMerchant,
  FetchPayment,
  FetchPayments,
  FetchRefunds,
  FetchSettlements,
  FetchSettlementSpecifications,
  FetchTransactionsForUser,
  RequestRefundJobApplication,
  UpdateEscrow,
} from './payments.actions';
import { PaymentsStateModel } from './payments.state-model';
import { PaginatedResults, Payment } from '@core/models';
import { BankAccountDTO, ListResults, MerchantDTO, SettlementDTO, SettlementRowDTO, TransactionDTO } from '@core/dto';

@State<PaymentsStateModel>({
  name: 'payment',
  defaults: {
    payment: null,
    payments: null,
    merchant: null,
    bankAccount: null,
    settlements: null,
    settlementRows: null,
    transactions: null,
    refunds: null,
    allPayments: null,
    paymentErrorId: null,
    paymentSuccessId: null,
  },
})
@Injectable()
export class PaymentsState {
  public constructor(private paymentsService: PaymentsService) {}

  @Selector()
  static merchant(state: PaymentsStateModel): MerchantDTO {
    return state.merchant;
  }

  @Selector()
  static payment(state: PaymentsStateModel): Payment {
    return state.payment;
  }

  @Selector()
  static payments(state: PaymentsStateModel): PaginatedResults<Payment> {
    return state.payments;
  }

  @Selector()
  static settlements(state: PaymentsStateModel): ListResults<SettlementDTO> {
    return state.settlements;
  }

  @Selector()
  static settlementRows(state: PaymentsStateModel): ListResults<SettlementRowDTO> {
    return state.settlementRows;
  }

  @Selector()
  static transactions(state: PaymentsStateModel): ListResults<TransactionDTO> {
    return state.transactions;
  }

  @Selector()
  static bankAccount(state: PaymentsStateModel): BankAccountDTO {
    return state.bankAccount;
  }

  @Selector()
  static refunds(state: PaymentsStateModel): PaginatedResults<Payment> {
    return state.refunds;
  }

  @Selector()
  static allPayments(state: PaymentsStateModel): PaginatedResults<Payment> {
    return state.allPayments;
  }

  @Selector()
  static paymentErrorId(state: PaymentsStateModel): string {
    return state.paymentErrorId;
  }

  @Selector()
  static paymentSuccessId(state: PaymentsStateModel): string {
    return state.paymentSuccessId;
  }

  @Action(FetchPayment)
  public async fetchPayment(ctx: StateContext<PaymentsStateModel>, { paymentId }: FetchPayment): Promise<void> {
    const payment = await this.paymentsService.getPaymentById(paymentId).toPromise();
    ctx.patchState({ payment });
  }

  @Action(FetchPayments)
  public async fetchPayments(ctx: StateContext<PaymentsStateModel>, { userId }: FetchPayments): Promise<void> {
    const payments = await this.paymentsService.getPaymentsForUser(userId).toPromise();
    ctx.patchState({ payments });
  }

  @Action(FetchSettlements)
  public async fetchBalance(ctx: StateContext<PaymentsStateModel>, { userId }: FetchSettlements): Promise<void> {
    const settlements = await this.paymentsService.getSettlementsForUser(userId).toPromise();
    ctx.patchState({ settlements });
  }

  @Action(FetchTransactionsForUser)
  public async fetchTransactionsForUser(
    ctx: StateContext<PaymentsStateModel>,
    { userId }: FetchTransactionsForUser,
  ): Promise<void> {
    const transactions = await this.paymentsService.getTransactionsForUser(userId).toPromise();
    ctx.patchState({ transactions });
  }

  @Action(FetchMerchant)
  public async fetchMerchant(ctx: StateContext<PaymentsStateModel>, { userId }: FetchMerchant): Promise<void> {
    const merchant = await this.paymentsService.getMerchantForUser(userId).toPromise();
    ctx.patchState({ merchant });
  }

  @Action(FetchBankAccount)
  public async fetchBankAccount(ctx: StateContext<PaymentsStateModel>, { userId }: FetchBankAccount): Promise<void> {
    const bankAccount = await this.paymentsService.getBankAccountForUser(userId).toPromise();
    ctx.patchState({ bankAccount });
  }

  @Action(CreateMerchant)
  public async createMerchant(ctx: StateContext<PaymentsStateModel>, { userId }: CreateMerchant): Promise<void> {
    const merchant = await this.paymentsService.createMerchant(userId).toPromise();
    ctx.patchState({ merchant });
  }

  @Action(CreateBankAccount)
  public async createBankAccount(ctx: StateContext<PaymentsStateModel>, { userId }: CreateBankAccount): Promise<void> {
    const bankAccount = await this.paymentsService.createBankAccountForUser(userId).toPromise();
    window.location.href = bankAccount.verification_url;
  }

  @Action(UpdateEscrow)
  public async updateEscrow(
    ctx: StateContext<PaymentsStateModel>,
    { userId, paymentId, days }: UpdateEscrow,
  ): Promise<void> {
    await this.paymentsService.updateEscrow(paymentId, days).toPromise();
    window.location.reload();
  }

  @Action(CreatePayment)
  public async createPayment(
    ctx: StateContext<PaymentsStateModel>,
    { userId, jobApplicationId, returnUrl }: CreatePayment,
  ): Promise<void> {
    ctx.patchState({ paymentErrorId: null });
    try {
      const result = await this.paymentsService.createPayment(userId, jobApplicationId, returnUrl).toPromise();
      if (result.oppTransaction?.redirect_url) {
        window.location.href = result.oppTransaction.redirect_url;
      } else if (result.payment.amount === 0) {
        ctx.patchState({ paymentSuccessId: jobApplicationId });
      } else {
        ctx.patchState({ paymentErrorId: jobApplicationId });
      }
    } catch {
      ctx.patchState({ paymentErrorId: jobApplicationId });
    }
  }

  @Action(CreateTravelExpensePayment)
  public async createTravelExpensePayment(
    ctx: StateContext<PaymentsStateModel>,
    { jobApplicationId, returnUrl }: CreateTravelExpensePayment,
  ): Promise<void> {
    ctx.patchState({ paymentErrorId: null });
    try {
      const result: { payment: Payment; oppTransaction: TransactionDTO } = await this.paymentsService
        .createTravelExpensePayment(jobApplicationId, returnUrl)
        .toPromise();

      if (result.oppTransaction?.redirect_url) {
        window.location.href = result.oppTransaction.redirect_url;
      } else if (result.payment.amount === 0) {
        ctx.patchState({ paymentSuccessId: jobApplicationId });
      } else {
        ctx.patchState({ paymentErrorId: jobApplicationId });
      }
    } catch {
      ctx.patchState({ paymentErrorId: jobApplicationId });
    }
  }

  @Action(FetchSettlementSpecifications)
  public async fetchSettlementSpecification(
    ctx: StateContext<PaymentsStateModel>,
    { userId, settlementUid }: FetchSettlementSpecifications,
  ): Promise<void> {
    ctx.patchState({ settlementRows: null });
    const settlementRows = await this.paymentsService.getSettlementSpecifications(userId, settlementUid).toPromise();
    ctx.patchState({ settlementRows });
  }

  @Action(FetchRefunds)
  public async fetchRefunds(
    ctx: StateContext<PaymentsStateModel>,
    { page, pageSize, sortColumn, sortDirection }: FetchRefunds,
  ): Promise<void> {
    const refunds = await this.paymentsService.getRefunds(page, pageSize, sortColumn, sortDirection).toPromise();
    ctx.patchState({ refunds });
  }

  @Action(RequestRefundJobApplication)
  public async requestRefund(
    ctx: StateContext<PaymentsStateModel>,
    { jobApplicationId, paymentId, reason }: RequestRefundJobApplication,
  ): Promise<void> {
    await this.paymentsService.requestRefund(jobApplicationId, paymentId, reason).toPromise();
  }

  @Action(ApproveRefundRequest)
  public async approveRefundRequest(
    ctx: StateContext<PaymentsStateModel>,
    { paymentId }: ApproveRefundRequest,
  ): Promise<void> {
    const payment = await this.paymentsService.updateRefunds(paymentId, 'approved').toPromise();
    await ctx.dispatch(new FetchRefunds()).toPromise();
  }

  @Action(DenyRefundRequest)
  public async denyRefundRequest(
    ctx: StateContext<PaymentsStateModel>,
    { paymentId }: DenyRefundRequest,
  ): Promise<void> {
    const payment = await this.paymentsService.updateRefunds(paymentId, 'denied').toPromise();
    await ctx.dispatch(new FetchRefunds()).toPromise();
  }

  @Action(FetchAllPayments)
  public async fetchAllPayments(
    ctx: StateContext<PaymentsStateModel>,
    { page, pageSize, sortColumn, sortDirection }: FetchAllPayments,
  ): Promise<void> {
    const allPayments = await this.paymentsService
      .getAllPayments(page, pageSize, sortColumn, sortDirection)
      .toPromise();
    ctx.patchState({ allPayments });
  }
}
