import { BaseOppObjectDTO, ObjectType } from './base-opp-object.dto';
import { AddressDTO } from './address.dto';
import { MetaDataDTO } from './metadata.dto';
import { EscrowDTO } from './escrow.dto';
import { FeesDTO } from './fees.dto';
import { RefundDTO } from './refund.dto';
import { PaymentStatus } from '@core/interfaces/payment';
import { ProductDTO } from './product.dto';

export enum PaymentMethodType {
  ideal = 'ideal',
  bcmc = 'bcmc',
  sepa = 'sepa',
  paypalpc = 'paypay-pc',
  creditcard = 'creditcard',
}

export enum PaymentFlow {
  direct = 'direct',
  email = 'email',
}

export enum TransactionLocale {
  nl = 'nl',
  en = 'en',
  de = 'de',
  fr = 'fr',
}

export class TransactionDTO extends BaseOppObjectDTO {
  public merchant_uid: string;
  public has_checkout: boolean;
  public payment_method: PaymentMethodType;
  public payment_flow: PaymentFlow;
  public payment_details: any[];
  public amount: number;
  public return_url: string;
  public redirect_url: string;
  public notify_url: string;
  public status: PaymentStatus;
  public metadata: MetaDataDTO[];
  public statuses: TransactionStatus[];
  public order: any[];
  public escrow: EscrowDTO;
  public fees: FeesDTO;
  public refunds: RefundDTO;
}

export class MandateTransactionDTO {
  public merchant_uid: string;
  public reference?: string;
  public token?: string;
  public notify_url: string;
  public total_price: number;
  public payout_description: string;
  public products: {
    name: string;
    price: number;
    quantity: number;
  }[];
  public metadata: { [key: string]: unknown };
}

export class TransactionStatus {
  public uid: string;
  public object: ObjectType;
  public created: number;
  public updated: number;
  public status: PaymentStatus;
}

export class CreateTransactionDTO {
  public merchant_uid: string;
  public profile_uid?: string;
  public locale?: TransactionLocale;
  public checkout?: boolean;
  public payment_method?: PaymentMethodType;
  public payment_flow?: PaymentFlow;
  public issuer?: any;
  public escrow?: boolean;
  public escrow_period?: number;
  public escrow_date?: string;
  public skip_confirmation_page?: boolean;
  public buyer_name_first?: string;
  public buyer_name_last?: string;
  public buyer_emailaddress?: string;
  public date_expired?: string;
  public total_price: number;
  public shipping_costs?: number;
  public discount?: number;
  public partner_fee?: number;
  public payout_description?: string;
  public address?: AddressDTO;
  public products: ProductDTO[];
  public return_url: string;
  public notify_url: string;
  public metadata: any[];
}
