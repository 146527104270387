<app-mobile-modal-dialog #appCancelBookingModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!isSuccess; else success">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <div class="modal-body">
        <div class="cancel-booking-info">
          <h6 [ngStyle]="{'font-size': '16px' }" class="mb-2" translate>Bulk Message</h6>
        </div>
        <p translate>
          Talents cannot reply to this message, 1-way communication. For 2-way communication, please send a direct
          message via Talent profile. (This feature will be expanded soon)
        </p>
        <div class="mt-3">
          <textarea
            formControlName="message"
            class="textarea-control"
            style="height: 156px !important"
            placeholder="{{'write a message' | translate}}"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-bg-purple"
          [disabled]="!form.valid"
          (click)="submit()"
          style="border-radius: 100px; width: 182px; margin: auto">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ 'Send' | translate }}</span>
        </button>
        <button type="button" class="btn btn-block" (click)="close()">{{'Cancel' | translate}}</button>
      </div>
    </form>
  </ng-container>
  <ng-template #success>
    <div class="modal-body">
      <app-mobile-success [message]="'Bulk message successfully sent'"></app-mobile-success>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
