import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Job, JobApplication } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import {
  AcceptJobApplicationAndCreatePayment,
  RequestToBookJobApplication,
} from '@core/states/users/jobs/jobs.actions';
import { PaymentsState } from '@core/states';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JobInvoice } from '@core/models/job-invoice';

@Component({
  selector: 'app-mobile-request-book-modal',
  templateUrl: './mobile-request-book-modal.component.html',
  styleUrls: ['./mobile-request-book-modal.component.scss'],
})
export class MobileRequestBookModalComponent implements OnInit {
  @Select(PaymentsState.paymentSuccessId)
  public paymentSuccessId: Observable<string>;

  @ViewChild('appRequestToBookModal') private modalComponent: MobileModalDialogComponent;

  public isSuccess: boolean;
  public isBusy: boolean;
  public submitConfirmation = false;
  public jobApplication: JobApplication;
  public job: Job;
  public isEditFee = false;
  public formBuilder: FormGroup;

  constructor(private store: Store, public translate: TranslateService) {
    this.formBuilder = new FormGroup({
      totalFee: new FormControl(0, [Validators.required]),
      totalBuyout: new FormControl(0),
      travelCost: new FormControl(false),
      travelCostAmount: new FormControl(0),
      travelCostMax: new FormControl(false),
      travelCostMaxAmount: new FormControl(0),
      parkingCost: new FormControl(false),
      parkingCostMax: new FormControl(false),
      parkingCostMaxAmount: new FormControl(0),
    });
  }

  ngOnInit(): void {}

  get formValue() {
    return this.formBuilder.value;
  }

  async openDialog(applicant: JobApplication, job: Job): Promise<void> {
    this.jobApplication = applicant;
    this.job = job;
    this.isEditFee = false;
    this.submitConfirmation = false;

    this.formBuilder.patchValue({
      totalFee: job.rate,
      totalBuyout: job.extraFees ?? 0,
      travelCost: this.job.hasTravelCosts,
      travelCostAmount: this.job.travelingCost,
      travelCostMax: this.job.maxTravelingCost > 0 ? true : false,
      travelCostMaxAmount: this.job.maxTravelingCost,
      parkingCost: this.job.parkingCost,
      parkingCostMax: this.job.parkingCost,
      parkingCostMaxAmount: this.job.maxParkingCost,
    });

    this.changeControlValue('travelCost', this.job.maxTravelingCost > 0 ? true : false);
    this.changeControlValue('parkingCost', this.job.parkingCost);

    if (this.job.bypassPayment === false) {
      this.paymentSuccessId.subscribe((value) => {
        if (this.jobApplication.id === value) {
          this.close();
        }
      });
    }

    return await this.modalComponent.open().then(() => {
      this.isBusy = false;
      this.isSuccess = false;
      this.jobApplication = null;
      this.job = null;
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public async acceptJobApplication(): Promise<void> {
    this.isBusy = true;
    try {
      if (this.job.bypassPayment) {
        await this.store.dispatch(
          new RequestToBookJobApplication(
            this.jobApplication.user.id,
            this.jobApplication.jobId,
            this.jobApplication.id,
          ),
        );
        this.isSuccess = true;
        this.isBusy = false;
      } else {
        await this.store.dispatch(
          new AcceptJobApplicationAndCreatePayment(
            this.jobApplication.user.id,
            this.jobApplication.user.id,
            this.jobApplication.jobId,
            this.jobApplication.id,
            null,
            !this.job.bypassPayment,
          ),
        );
        this.isSuccess = true;
        this.isBusy = false;
      }
    } catch (error) {
      alert('Failed to accept applicant');
    }
  }

  public async submitEditFee(): Promise<void> {
    if (!this.formBuilder.valid) {
      return;
    }
    this.isBusy = true;

    try {
      const jobInvoiceItem: JobInvoice = {
        fee: this.formValue.totalFee,
        buyOut: this.formValue.totalBuyout,
        tc: this.formValue.travelCost,
        pc: this.formValue.parkingCost,
        tcAmount: this.formValue.travelCost ? this.formValue.travelCostAmount : null,
        tcMaxAmount:
          this.formValue.travelCost && this.formValue.travelCostMax ? this.formValue.travelCostMaxAmount : null,
        pcMaxAmount:
          this.formValue.parkingCost && this.formValue.parkingCostMax ? this.formValue.parkingCostMaxAmount : null,
      };

      await this.store.dispatch(
        new RequestToBookJobApplication(
          this.jobApplication.user.id,
          this.jobApplication.jobId,
          this.jobApplication.id,
          jobInvoiceItem,
        ),
      );
    } catch (error) {
      console.error('Failed to accept applicant', error);
    }

    this.isBusy = false;
    this.isSuccess = true;
  }

  public showEditFee(value: boolean): void {
    this.isEditFee = value;
    if (value) {
      this.submitConfirmation = false;
    }
  }

  public setSubmitConfirmation(value: boolean): void {
    this.submitConfirmation = value;
  }

  public onChangeTravelCost(value: boolean): void {
    const travelCostAmmount = this.formBuilder.get('travelCostAmount');
    this.formBuilder.get('travelCost').setValue(value);
    if (value && travelCostAmmount.value <= 0) {
      travelCostAmmount.setValue(0.23);
    }
    if (!value) {
      travelCostAmmount.setValue(0);
    }
  }

  public changeControlValue(controlName: string, value?: boolean): void {
    const control = this.formBuilder.get(`${controlName}Max`);
    const maxValue = this.formBuilder.get(`${controlName}MaxAmount`);
    control.setValue(value ?? !control.value);
    maxValue.setValue(0);
    if (control.value === true) {
      maxValue.enable();
    } else {
      maxValue.disable();
    }
  }
}
