import { Type } from 'class-transformer';
import { IUserProfile, MediaType } from '@core/interfaces';

import { Category } from './category.model';
import { UserProfileFilter } from './user-profile-filter.model';
import { UserAlbum } from './user-album.model';
import { TalentCategory } from './talent-category.model';
import { User } from './user.model';
import { Package } from './package.model';

export class UserProfile implements IUserProfile {
  @Type(() => Category)
  public category: Category;
  @Type(() => UserProfileFilter)
  public filters?: UserProfileFilter[];
  @Type(() => UserAlbum)
  public albums?: UserAlbum[];
  @Type(() => TalentCategory)
  public talentCategories?: TalentCategory[];
  @Type(() => User)
  public user?: User;
  @Type(() => Package)
  public packages?: Package[];
  public id: string;
  public userId?: string;
  public bio?: string;
  public rate?: number;
  public yearsExperience?: number;
  public averageReview?: number;
  public totalReviews?: number;
  public totalJobs?: number;
  public completed?: boolean;

  public get firstPhotoAlbum(): UserAlbum {
    return this.albums?.find((a) => a.albumType === MediaType.photo);
  }
}
