import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthState, SignIn, StartSignUp } from '@core/states';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { v4 } from 'uuid';
import { MobileForgotPasswordModalComponent } from '../mobile-forgot-password-modal/mobile-forgot-password-modal.component';
import { ActivatedRoute } from '@angular/router';

interface IAuthModalConfig {
  showFormSignUp: boolean;
  role?: 'talent' | 'caster';
}

@Component({
  selector: 'app-mobile-auth-modal',
  templateUrl: './mobile-auth-modal.component.html',
  styleUrls: ['./mobile-auth-modal.component.scss'],
})
export class MobileAuthModalComponent implements OnInit {
  @ViewChild('appLoginModal') private modalComponent: MobileModalDialogComponent;
  @ViewChild('forgotPasswordModal') private forgotPasswordModal: MobileForgotPasswordModalComponent;

  public message: any;
  public redirectUrl: string;
  public isBusy = false;
  public showSignUpForm = false;
  public showSignUpSuccess: boolean;
  public modalConfig: IAuthModalConfig;
  public signInForm: FormGroup;
  public signUpForm: FormGroup;

  constructor(private translateService: TranslateService, private store: Store, protected route: ActivatedRoute) {}

  ngOnInit(): void {
    this.redirectUrl = this.route.snapshot.queryParams['route'] as string;
  }

  switchFormSignUp() {
    this.message = null;
    this.isBusy = false;
    this.showSignUpForm = true;
    this.setupFormSignUp();
  }

  async openDialog(signUp?: IAuthModalConfig) {
    if (signUp) {
      this.modalConfig = signUp;
      this.showSignUpForm = signUp.showFormSignUp;
      this.setupFormSignUp();
    } else {
      this.setupFormSignIn();
    }

    return await this.modalComponent.open().then(() => {
      this.showSignUpForm = false;
      this.isBusy = false;
      this.message = null;
      this.showSignUpSuccess = false;
      if (this.signInForm !== undefined) {
        this.signInForm.reset();
      }
      if (this.signUpForm !== undefined) {
        this.signUpForm.reset();
      }
    });
  }

  forgotPassword() {
    this.forgotPasswordModal.openDialog();
    this.close();
  }

  setupFormSignIn() {
    this.signInForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  setupFormSignUp() {
    this.signUpForm = new FormGroup({
      id: new FormControl(v4(), [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      agree: new FormControl(null, [Validators.requiredTrue]),
      role: new FormControl(
        [],
        [
          (c: FormControl) =>
            c !== undefined || c.value.length > 0
              ? c.value?.filter((r) => r.checked === true).length === 0
                ? { invalid: true }
                : null
              : null,
        ],
      ),
    });

    if (this.modalConfig !== undefined) {
      this.signUpForm.patchValue({
        role: [{ value: this.modalConfig.role, checked: true }],
      });
    } else {
      this.signUpForm.patchValue({
        role: [{ value: 'talent', checked: true }],
        agree: false,
      });
    }
  }

  public close() {
    this.modalComponent.close();
  }

  public async onSubmitSignUp(): Promise<any> {
    try {
      if (this.isBusy) {
        return;
      }

      this.message = null;
      this.isBusy = true;

      const user = {
        ...this.signUpForm.value,
        agree: undefined,
      };
      user.role = user?.role?.filter((r) => r.checked === true).map((r) => r.value);

      await this.store.dispatch(new StartSignUp(user)).toPromise();
      this.showSignUpSuccess = true;
    } catch (e) {
      this.message = {
        message: await this.translateService
          .get(
            'An error occured when siging up. The emailaddress might already be registered, try forgot password to retrieve your password.',
          )
          .toPromise(),
        type: 'alert alert-danger',
      };
    } finally {
      this.isBusy = false;
    }
  }

  public async onSubmitSignIn(): Promise<any> {
    if (this.signInForm.invalid || this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.message = null;

    const data = { ...this.signInForm.value };

    try {
      await this.store
        .dispatch(
          new SignIn(
            data.username,
            data.password,
            this.redirectUrl !== undefined ? this.redirectUrl : '/account/details/dashboard',
          ),
        )
        .pipe(switchMap(() => this.store.selectOnce(AuthState.jwtToken)))
        .toPromise();

      this.modalComponent.close();
    } catch (e) {
      let message = 'Error';
      const lang = this.translateService.currentLang.toUpperCase();
      switch (e.status) {
        case 401:
        case 403:
          message =
            lang === 'NL'
              ? 'Uw inloggegevens zijn onjuist, of uw email is nog niet geverifiëerd'
              : 'Your credentials are incorrect, or email needs to be verified';
          break;
        case 400:
        case 500:
        case 501:
        case 502:
          message =
            lang === 'NL'
              ? 'Er is iets misgegaan, probeer het later nog een keer'
              : 'Something went wrong, please try again later';
          break;
      }
      this.message = { message, type: 'alert alert-danger' };
    } finally {
      this.isBusy = false;
    }
  }
}
