import { Component, Inject, Input, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CancelBookingJobApplication } from '@core/states';

import { Store } from '@ngxs/store';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss'],
})
export class CancelBookingComponent {
  @Input()
  public jobId: string;

  @Input()
  public jobApplicationId: string;

  @Input()
  public userId: string;

  public form = new FormGroup({
    reason: new FormControl(null, [Validators.required]),
  });

  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { jobApplicationId: string; jobId: string; userId: string },
    @Optional() private dialogRef: MatDialogRef<CancelBookingComponent>,
    private store: Store,
  ) {
    this.jobApplicationId = this.data.jobApplicationId;
    this.jobId = this.data.jobId;
    this.userId = this.data.userId;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public async cancelBooking(): Promise<void> {
    await this.store
      .dispatch(new CancelBookingJobApplication(this.userId, this.jobId, this.jobApplicationId, this.form.value.reason))
      .toPromise();

    this.closeModal();
  }
}
