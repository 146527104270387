import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { UserReportsStateModel } from './reports.state-model';
import { LoadUserReports, CloseReport, DeleteTalentByReport, DeleteJobByReport } from './reports.actions';
import { UserReportsService } from '@core/services';
import { PaginatedResults, UserReport } from '@core/models';

@State<UserReportsStateModel>({
  name: 'userReports',
  defaults: {
    reports: null,
  },
})
@Injectable()
export class UserReportsState {
  public constructor(private userReportsService: UserReportsService) {}

  @Selector()
  static reports(state: UserReportsStateModel): PaginatedResults<UserReport> {
    return state.reports;
  }

  @Action(LoadUserReports)
  public async loadPendingVerifications(
    ctx: StateContext<UserReportsStateModel>,
    { page, pageSize, sortColumn, sortDirection }: LoadUserReports,
  ): Promise<void> {
    const reports = await this.userReportsService.findAll(page, pageSize, sortColumn, sortDirection).toPromise();
    ctx.patchState({ reports });
  }

  @Action(CloseReport)
  public async closeReport(ctx: StateContext<UserReportsStateModel>, { userReportId }: CloseReport): Promise<void> {
    await this.userReportsService.closeReport(userReportId);
    this.removeFromReports(ctx, userReportId);
  }

  @Action(DeleteTalentByReport)
  public async deleteTalentByReport(
    ctx: StateContext<UserReportsStateModel>,
    { userReportId, talentId }: DeleteTalentByReport,
  ): Promise<void> {
    await this.userReportsService.deleteTalentByReport(userReportId, talentId);
    this.removeFromReports(ctx, userReportId);
  }

  @Action(DeleteJobByReport)
  public async deleteJobByReport(
    ctx: StateContext<UserReportsStateModel>,
    { userReportId, jobId }: DeleteJobByReport,
  ): Promise<void> {
    await this.userReportsService.deleteJobByReport(userReportId, jobId);
    this.removeFromReports(ctx, userReportId);
  }

  private removeFromReports(ctx: StateContext<UserReportsStateModel>, userReportId: string): void {
    const currentReports = ctx.getState().reports;
    const newResults = currentReports.results.filter((obj) => obj.id !== userReportId);
    const newReports = { results: newResults, total: newResults.length } as PaginatedResults<UserReport>;
    ctx.patchState({ reports: newReports });
  }
}
