<section class="helpdesk">
  <div class="card-helpdesk">
    <div class="info">
      <p>Helpdesk</p>
      <div class="addtional">
        <a href="https://helpdesk.casterbee.nl" target="_blank" class="btn">{{'Read more' | translate}}</a>
      </div>
    </div>
    <img class="photo" src="/assets/img/mobile/helpdesk/helpdesk.png" alt="" />
  </div>
</section>
