import { IFilter } from '@core/interfaces';
import { FilterOption } from './filter-option.model';
import { Type } from 'class-transformer';

export class Filter implements IFilter {
  @Type(() => FilterOption)
  public filterOptions?: FilterOption[];
  public id: string;
  public type: string;
  public slug: string;
  public order: number;
  public label: string;
  public min?: number;
  public max?: number;
  public unit?: string;
  public required: boolean;
  public placeholder?: string;

  get translateKey(): string {
    return 'FILTER.' + this.slug;
  }

  get placeholderTranslateKey(): string {
    return 'FILTER_PLACEHOLDER.' + this.slug;
  }
}
