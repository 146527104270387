import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CategoriesService } from '@core/services';

import { CategoriesStateModel } from '@core/states';

import { LoadCategories, LoadCategory } from './categories.actions';
import { Category, PaginatedResults } from '@core/models';

@State<CategoriesStateModel>({
  name: 'categories',
  defaults: {
    categories: null,
    category: null,
  },
})
@Injectable()
export class CategoriesState implements NgxsOnInit {
  public constructor(private categoriesService: CategoriesService) {}

  @Selector()
  static categories(state: CategoriesStateModel): PaginatedResults<Category> {
    return state.categories;
  }

  @Selector()
  static category(state: CategoriesStateModel): Category {
    return state.category;
  }

  @Action(LoadCategories)
  public async loadCategories(ctx: StateContext<CategoriesStateModel>): Promise<void> {
    const categories = await this.categoriesService.findAll().toPromise();
    ctx.patchState({ categories });
  }

  @Action(LoadCategory)
  public async loadCategory(ctx: StateContext<CategoriesStateModel>, { categoryId }: LoadCategory): Promise<void> {
    const category = await this.categoriesService.getById(categoryId).toPromise();
    ctx.patchState({ category });
  }

  public ngxsOnInit(ctx: StateContext<CategoriesStateModel>): void {
    ctx.dispatch(new LoadCategories());
  }
}
