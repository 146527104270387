import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  public isError: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject<HttpErrorResponse>(null);
  constructor() {}
}
