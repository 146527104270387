import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUserProfile } from '@core/interfaces';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { UserProfile } from '@core/models';
import { UserProfilesService } from '@core/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-mobile-talent-bulk-message',
  templateUrl: './mobile-talent-bulk-message.component.html',
  styleUrls: ['./mobile-talent-bulk-message.component.scss'],
})
export class MobileTalentBulkMessageComponent implements OnInit {
  @ViewChild('appMobileTalentBulkMessage') public modalComponent: MobileModalDialogComponent;

  @Output()
  public resetSelection = new EventEmitter<any>();

  public seletedTalents: UserProfile[] = [];

  public storageName = 'bulkMessage';

  public isSuccess = false;

  public isBusy = false;

  public form = new FormGroup({
    message: new FormControl(null, [Validators.required]),
  });

  constructor(private profileService: UserProfilesService) {}

  ngOnInit(): void {}

  async openDialog(seletedTalents: UserProfile[]) {
    this.seletedTalents = seletedTalents;
    return await this.modalComponent.open().then(() => {
      this.isSuccess = false;
      this.isBusy = false;
    });
  }

  async close() {
    return await this.modalComponent.close();
  }

  public updateTalentToBulkMessage(talent: UserProfile): void {
    const isTalentExist = this.seletedTalents.find((value) => value.id === talent.id);
    if (isTalentExist) {
      this.seletedTalents = this.seletedTalents.filter((value) => value.id !== talent.id);
    } else {
      this.seletedTalents.push(talent);
    }

    localStorage.setItem(this.storageName, JSON.stringify(this.seletedTalents));
  }

  public submitBulkMessage(): void {
    this.isBusy = true;
    let message = '';
    const profileIds: string[] = [];

    if (this.form.get('message')?.value) {
      message = this.form.get('message')?.value.replace(/\n/g, '<br>');
    }

    // Get data from local storage
    const storedData = localStorage.getItem('bulkMessage');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (Array.isArray(parsedData)) {
        parsedData.map((value) => {
          profileIds.push(value.id);
        });
      }
    }

    try {
      this.profileService
        .sendBulkMessage({
          message,
          profileIds,
        })
        .subscribe(() => {
          this.isSuccess = true;
          localStorage.removeItem('bulkMessage');
          this.isBusy = false;
          this.resetSelection.emit('');
        });
    } catch (error) {
      console.log(error);
    }
  }

  public getThumbUrl(user: UserProfile): string {
    return `${environment.apiBaseUrl}/media/thumb/${user.user.photo.id}`;
  }
}
