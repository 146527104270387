import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@auth/services';

@Injectable({
  providedIn: 'root',
})
export class DisabledGuard implements CanActivate, CanActivateChild {
  private readonly whiteList: RegExp[] = [/\/account\/disabled/, /\/account\/disabled\/remove-items\/.*/];

  public constructor(private authService: AuthService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isEnabled(state.url);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isEnabled(state.url);
  }

  private isEnabled(url: string): boolean {
    const disabled = this.authService.getJwtToken()?.getBool('disabled');

    if (disabled && !this.whiteList.some((exp: RegExp) => exp.test(url))) {
      this.router.navigateByUrl('/account/disabled').then();
      return false;
    }
    return true;
  }
}
