import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IJob } from '@core/interfaces';

@Component({
  selector: 'app-mobile-job-rows-list',
  templateUrl: './mobile-job-rows-list.component.html',
  styleUrls: ['./mobile-job-rows-list.component.scss'],
})
export class MobileJobRowsListComponent implements OnInit {
  @Input()
  public jobs: IJob[];
  @Input()
  public promoted = false;
  @Output()
  public jobDetail: EventEmitter<IJob> = new EventEmitter<IJob>();

  public selectedJobDetail: IJob;
  constructor() {}

  ngOnInit(): void {}

  public openModal(selectedJob: IJob) {
    this.jobDetail.emit(selectedJob);
  }

  public getGradientBg(price: number): string {
    if (price >= 0 && price <= 99) {
      return 'job-0-99';
    }
    if (price >= 100 && price <= 275) {
      return 'job-100-275';
    }
    if (price >= 276 && price <= 650) {
      return 'job-276-650';
    }
    if (price >= 651) {
      return 'job-651';
    }
  }
}
