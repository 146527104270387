import { Component, Inject, Input, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobApplicationStatus } from '@core/interfaces';
import { JobApplication } from '@core/models';
import { CasterCancelBookingJobApplication } from '@core/states';

import { Store } from '@ngxs/store';

@Component({
  selector: 'app-caster-cancel-booking',
  templateUrl: './caster-cancel-booking.component.html',
  styleUrls: ['./caster-cancel-booking.component.scss'],
})
export class CasterCancelBookingComponent {
  public jobApplication: JobApplication;
  public title: string;

  public form = new FormGroup({
    reason: new FormControl(null, []),
  });

  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { jobApplication: JobApplication },
    @Optional() private dialogRef: MatDialogRef<CasterCancelBookingComponent>,
    private store: Store,
  ) {
    this.jobApplication = this.data.jobApplication;
    this.title = [JobApplicationStatus.accepted, JobApplicationStatus.requesttobook].includes(
      this.jobApplication.status,
    )
      ? 'Cancel booking request'
      : 'Cancel booking';
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public async cancelBooking(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new CasterCancelBookingJobApplication(
          this.jobApplication.jobId,
          this.jobApplication.id,
          this.form.value.reason,
        ),
      )
      .toPromise();

    this.closeModal();
  }
}
