<div class="d-flex flex-column w-100 text-white">
  <app-gradient
    class="d-flex justify-content-center flex-column p-5"
    gradientDirection="horizontal"
    gradientName="purple-bright">
    <ng-template #title>
      <h4 translate>Your payment is being processed, this might take a few seconds...</h4>
    </ng-template>

    <div class="result" *ngIf="status$ | async as status; else title">
      <div class="success d-flex flex-column align-items-center" *ngIf="successStatus.includes(status)">
        <h3 class="d-inline-block" translate>The payment is successful!</h3>
        <div class="success-img mt-4">
          <img src="assets/img/checkmark-green.svg" alt="Success" />
        </div>
      </div>

      <div class="error" *ngIf="errorStatus.includes(status)" translate>
        <h3 class="text-center p-0 m-0">Something went wrong with your transaction, please try again.</h3>
      </div>

      <div class="unknown" *ngIf="pendingStatus.includes(status)">
        <ng-container *ngIf="!failed">
          <app-spinner class="mx-auto"></app-spinner>
          <p class="p-0 m-0 mt-4 text-center" translate>Checking the status of the transaction.</p>
        </ng-container>
        <h3 class="p-0 m-0 text-center" *ngIf="failed" translate>
          The status of the transaction is not known yet, please check again later.
        </h3>
      </div>
    </div>
  </app-gradient>
</div>
