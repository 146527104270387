/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import gettext from 'gettext-parser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TranslatePoHttpLoader implements TranslateLoader {
  /**
   * Translation domain
   */
  public domain = '';

  constructor(protected http: HttpClient, protected prefix: string = 'i18n', protected suffix: string = '.po') {}

  public getTranslation(lang: string): Observable<any> {
    return this.http
      .get(`${this.prefix}/${lang}${this.suffix}`, { responseType: 'text' })
      .pipe(map((contents: string) => this.parse(contents)));
  }

  public parse(contents: string): any {
    const translations: { [key: string]: string } = {};

    const po = gettext.po.parse(contents, 'utf-8');
    if (!po.translations.hasOwnProperty(this.domain)) {
      return translations;
    }

    Object.keys(po.translations[this.domain]).forEach((key) => {
      const translation: string = po.translations[this.domain][key].msgstr.pop();
      if (key.length > 0 && translation.length > 0) {
        translations[key] = translation;
      }
    });

    return translations;
  }
}
