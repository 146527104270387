import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDeck, IUserProfile } from '@core/interfaces';
import { AddUserProfileToDeck, DecksState, GetDecks } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Deck } from '@core/models';

@Component({
  selector: 'app-deck-selection-modal',
  templateUrl: './deck-selection-modal.component.html',
  styleUrls: ['./deck-selection-modal.component.scss'],
})
export class DeckSelectionModalComponent {
  @Select(DecksState.decks)
  public decks$: Observable<Deck[]>;

  public userProfile: IUserProfile;

  public isBusy = false;

  public constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<DeckSelectionModalComponent>,
  ) {
    this.userProfile = this.data.userProfile;
    this.store.dispatch(new GetDecks());
  }

  public async onDeckSelected(deck: IDeck): Promise<void> {
    try {
      this.isBusy = true;
      await this.store.dispatch(new AddUserProfileToDeck(deck, this.userProfile)).toPromise();
      this.dialogRef.close();
    } finally {
      this.isBusy = false;
    }
  }
}
