import { ILanguage } from '@core/interfaces/language';

export class Language implements ILanguage {
  public id: string;
  public isoCode: string;
  public name: string;
  public native?: boolean;

  get translateKey(): string {
    return `LANGUAGE.${this.isoCode}`;
  }
}
