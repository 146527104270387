import { IMembership } from '@core/interfaces';
import { BaseModel } from '@core/models/base-model.model';
import * as moment from 'moment';

export class Membership extends BaseModel implements IMembership {
  public id: string;
  public price_month: number;
  public price_year: number;
  public title: string;

  public talent_profiles: number;
  public commission: number;
  public job_reactions: number;
  public direct_messages: number;
  public jobs_limit: number;
  public portfolio_pictures: number;
  public videos: number;
  public packages: number;
  public decks: number;
  public website: boolean;
  public ads: boolean;
  public hide: boolean;
  public startDate: Date;
  public endDate: Date;

  public interval?: 'month' | 'year';

  public get price_year_save(): number {
    return parseFloat((this.price_month * 12 - this.price_year).toFixed(2));
  }

  public get next_bill_date(): string {
    const endDate = moment(this.endDate).local();

    return endDate.format('D MMM YYYY');
  }
}
