import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  public activeRoute$: Observable<any>;
  private history = [];
  private parentHistory = [];
  private activeRouteSubject$ = new BehaviorSubject<ActivatedRoute>(null);

  public constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.hookupRouteChange();
    this.hookupScroll();

    this.activeRoute$ = this.activeRouteSubject$.asObservable();
    this.activeRouteSubject$.next(this.getActiveRoute());
  }

  private getActiveRoute(): ActivatedRoute {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  private hookupRouteChange(): void {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e) => {
      const activeRoute = this.getActiveRoute();
      this.activeRouteSubject$.next(activeRoute);
    });
  }

  private hookupScroll(): void {
    this.router.events.pipe(filter((e: any): e is Scroll => e instanceof Scroll)).subscribe((e) => {
      const route = this.getActiveRoute();
      const prevParentRoute = this.parentHistory[0] || null;
      this.history.unshift(route);
      this.parentHistory.unshift(route.parent);

      // Only keep last 5 items
      this.history.length = 5;
      this.parentHistory.length = 5;

      if (e.position) {
        this.viewportScroller.scrollToPosition(e.position);
        return;
      }

      if (e.anchor) {
        this.viewportScroller.scrollToAnchor(e.anchor);
        return;
      }

      const preventResetScroll = route.snapshot.params['userId'] || route?.url === prevParentRoute?.url;

      if (preventResetScroll) {
        return;
      }

      this.viewportScroller.scrollToPosition([0, 0]);
    });
  }
}
