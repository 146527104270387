import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { SearchStateModel } from './search.state-model';
import {
  CreateChips,
  LoadResults,
  SetCategory,
  SetFilters,
  UpdatePageNumber,
  UpdateRecentlyViewed,
} from './search.actions';
import { SearchService } from '@src/visitor/services/search.service';
import { FiltersService, JobsService, UserProfilesService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { CategoriesState } from '@core/states';
import { ICategory } from '@core/interfaces/category';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Job, PaginatedResults } from '@core/models';
import { v4 } from 'uuid';

@State<SearchStateModel>({
  name: 'search',
  defaults: {
    category: null,
    categoryFilters: null,
    filters: null,
    chips: null,
    latestTalents: null,
    talents: null,
    jobs: null,
    promotedJobs: null,
    recentlyViewedJobs: [],
    pageNumber: 0,
    perPage: 25,
    shuffle: null,
  },
})
@Injectable()
export class SearchState {
  public constructor(
    private store: Store,
    private searchService: SearchService,
    private filtersService: FiltersService,
    private userProfilesService: UserProfilesService,
    private jobsService: JobsService,
    protected translate: TranslateService,
  ) {}

  @Selector()
  static talents(state: SearchStateModel): any {
    return state.talents;
  }

  @Selector()
  static latestTalents(state: SearchStateModel): any {
    return state.latestTalents;
  }

  @Selector()
  static jobs(state: SearchStateModel): Observable<PaginatedResults<Job>> {
    return state.jobs;
  }

  @Selector()
  static promotedJobs(state: SearchStateModel): Observable<PaginatedResults<Job>> {
    return state.promotedJobs;
  }

  @Selector()
  static chips(state: SearchStateModel): any {
    return state.chips;
  }

  @Selector()
  static filters(state: SearchStateModel): any {
    return state.filters;
  }

  @Selector()
  static category(state: SearchStateModel): any {
    return state.category;
  }

  @Selector()
  static categoryFilters(state: SearchStateModel): any {
    return state.categoryFilters;
  }

  @Selector()
  static recentlyViewedJobs(state: SearchStateModel): Job[] {
    return state.recentlyViewedJobs;
  }

  @Action(UpdateRecentlyViewed)
  public async updateRecentlyViewed(ctx: StateContext<SearchStateModel>, { job }: UpdateRecentlyViewed): Promise<void> {
    const state = ctx.getState();
    if (state.recentlyViewedJobs.includes(job)) {
      return;
    }
    const recentlyViewedJobs = [...state.recentlyViewedJobs];
    const length = recentlyViewedJobs.unshift(job);
    ctx.patchState({ recentlyViewedJobs: recentlyViewedJobs.slice(0, 3) });
  }

  @Action(CreateChips)
  public async createChips(ctx: StateContext<SearchStateModel>, { filters }: SetFilters): Promise<void> {
    const categoryFilters = this.store.selectSnapshot(SearchState.categoryFilters);
    const filterMapping = await this.searchService.getFilterMapping(filters, categoryFilters);
    const reducedFilters = await filterMapping.reduce(async (acc: any, cur: any) => {
      if (cur.skipChip === true) {
        return acc;
      }

      const accum = await acc;
      const rawValue = await cur.rawValue();
      accum.push({
        label: cur.label,
        type: cur.type,
        fieldName: cur.fieldName,
        parent: cur.parent,
        value: await cur.value(rawValue),
      });

      return accum;
    }, Promise.resolve([]));

    const chips = reducedFilters.filter((c: any) => c.value !== undefined && c.value !== null);
    ctx.patchState({ chips });
  }

  @Action(UpdatePageNumber)
  public async updatePageNumber(
    ctx: StateContext<SearchStateModel>,
    { pageNumber, page }: UpdatePageNumber,
  ): Promise<void> {
    ctx.patchState({ pageNumber });
    ctx.dispatch([new LoadResults(ctx.getState().filters, page)]);
  }

  @Action(LoadResults)
  public async loadResults(ctx: StateContext<SearchStateModel>, { filters, page }: LoadResults): Promise<void> {
    const categoryFilters = this.store.selectSnapshot(SearchState.categoryFilters);
    const urlParams = await this.searchService.getUrlParams(filters, categoryFilters);
    const pageNumber = ctx.getState().pageNumber;

    let orderBy = 'created';
    let orderByDirection = 'desc';

    if (urlParams['orderBy'] !== undefined) {
      const orderByValue = urlParams['orderBy'].split(';');
      delete urlParams['orderBy'];
      orderBy = orderByValue[0];
      orderByDirection = orderByValue[1];
      if (orderBy === 'shuffle') {
        let shuffle = ctx.getState().shuffle;
        if (pageNumber === 0) {
          shuffle = v4();
          ctx.patchState({ shuffle });
        }
        orderBy = `shuffle(${shuffle})`;
      }
    }

    if (page === 'talents') {
      if (pageNumber === 0) {
        ctx.patchState({ latestTalents: null, talents: null });
        const latestTalents = await this.userProfilesService.findAll(0, 25, 'created', 'desc', urlParams).toPromise();
        ctx.patchState({ latestTalents });
      }

      const talents = await this.userProfilesService
        .findAll(pageNumber, ctx.getState().perPage, orderBy, orderByDirection, urlParams)
        .toPromise();

      const currentTalents = ctx.getState().talents;
      if (pageNumber > 0 && currentTalents != null) {
        const updatedTalents = new PaginatedResults<Job>();
        updatedTalents.results = [...currentTalents.results, ...talents.results];
        updatedTalents.total = talents.total;
        ctx.patchState({
          talents: updatedTalents,
        });
      } else {
        ctx.patchState({
          talents,
        });
      }
    } else {
      if (pageNumber === 0) {
        const promotedJobs = await this.jobsService
          .findAll(0, 5, orderBy, orderByDirection, true, urlParams)
          .toPromise();

        ctx.patchState({
          promotedJobs,
        });
      }

      const jobs = await this.jobsService
        .findAll(pageNumber, ctx.getState().perPage, orderBy, orderByDirection, false, urlParams)
        .toPromise();

      const currentJobs = ctx.getState().jobs;
      if (pageNumber !== 0 && currentJobs != null) {
        const updatedJobs = new PaginatedResults<Job>();
        updatedJobs.results = [...currentJobs.results, ...jobs.results];
        updatedJobs.total = jobs.total;
        ctx.patchState({
          jobs: updatedJobs,
        });
      } else {
        ctx.patchState({
          jobs,
        });
      }
    }
  }

  @Action(SetCategory)
  public async selectCategory(ctx: StateContext<SearchStateModel>, { categorySlug }: SetCategory): Promise<void> {
    const categories = this.store.selectSnapshot(CategoriesState.categories);
    const category = categories?.results?.find((c: ICategory) => c.slug === categorySlug);

    const categoryFilters = !categorySlug ? [] : await this.filtersService.findByCategorySlug(categorySlug).toPromise();

    ctx.patchState({
      category,
      categoryFilters,
    });
  }

  @Action(SetFilters)
  public async setFilters(ctx: StateContext<SearchStateModel>, { filters, page, route }: SetFilters): Promise<void> {
    const state = ctx.getState();
    if (state.filters?.categorySlug !== filters?.categorySlug) {
      ctx.dispatch(new SetCategory(filters.categorySlug));
      if (state.filters?.categorySlug) {
        filters.talentCategorySlug = null;
      }
    }

    const categoryFilters = this.store.selectSnapshot(SearchState.categoryFilters);
    const urlParams = await this.searchService.getUrlParams(filters, categoryFilters);

    const relativeTo = route.firstChild ?? route;

    ctx.patchState({ filters, pageNumber: 0 });
    ctx.dispatch([
      new CreateChips(filters),
      new LoadResults(filters, page),
      new Navigate(['.'], urlParams, { relativeTo }),
    ]);
  }
}
