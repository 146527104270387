<section class="talent">
  <div *ngIf="!isProcess" class="talent-horizontal swiper swiper-talent">
    <div class="swiper-wrapper">
      <ng-container *ngFor="let talent of talents">
        <div class="card-talent swiper-slide">
          <div class="info">
            <p class="price">
              <span translate>from</span>
              <span class="text-bold">€ {{talent.rate}}</span>
              p/d
            </p>
            <div class="name">
              <p class="name">
                {{talent.user.profileName}}
                <img src="/assets/img/mobile/badge.svg" alt="badge" *ngIf="talent.user?.hasBadge" />
              </p>
            </div>
            <div class="ratting">
              <p *ngIf="talent?.averageReview">
                <img src="/assets/img/mobile/ratting.svg" alt="ratting" />
                <span class="rate-number">{{talent?.averageReview | number: '1.1-1'}}</span>
                <span>({{talent?.totalReviews}})</span>
              </p>
            </div>
            <div class="addtional">
              <p>{{'Height' | translate}}: {{tallBody(talent)?.value}}</p>
              <p *ngIf="talent.user.languages">Native language: {{ nativeLanguage(talent)?.name | translate }}</p>
              <p>{{talent.user.city}},{{talent.user.country.isoCode}}</p>
              <button class="btn btn btn-profile" (click)="onClick(talent)">View Profile</button>
            </div>
          </div>
          <div class="photo" [style.background-image]="'url(/assets/img/mobile/talent/'+talent.id+'.png)'"></div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isProcess" class="loading-screen talent-horizontal">
    <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
    <p class="text-center mt-3">Loading...</p>
  </div>
</section>
