<div #splideEl *ngIf="slideTemplate" class="splide">
  <div class="splide__arrows">
    <button class="splide__arrow splide__arrow--prev">
      <img class="d-block" data-splide-arrow="prev" fill="white" src="/assets/img/arrow-left.svg" />
    </button>
    <button class="splide__arrow splide__arrow--next">
      <div>
        <img class="d-block" data-splide-arrow="next" fill="white" src="/assets/img/arrow-right.svg" />
      </div>
    </button>
  </div>

  <div class="splide__track">
    <div class="splide__list">
      <ng-container *ngIf="firstSlideTemplate">
        <div class="splide__slide">
          <ng-container *ngTemplateOutlet="firstSlideTemplate"></ng-container>
        </div>
      </ng-container>

      <div *ngFor="let item of items; let i = index" class="splide__slide">
        <ng-container
          *ngTemplateOutlet="slideTemplate; context: {item: item, visible: visible[i] || false}"></ng-container>
      </div>
    </div>
  </div>
</div>
