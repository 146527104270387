import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IUserProfile } from '@core/interfaces/user-profile';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';

@Component({
  selector: 'app-talent-cards-list',
  templateUrl: './talent-cards-list.component.html',
  styleUrls: ['./talent-cards-list.component.scss'],
})
export class TalentCardsListComponent implements OnChanges {
  @Input()
  public talents: IUserProfile[];

  public tileSize: any;
  public gap: number;
  public perRow: number;

  public constructor(private layoutCalculator: LayoutCalculatorService) {
    this.layoutCalculator.layoutChanged$.subscribe((data) => this.updateParams(data));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('talents' in changes) {
      this.updateParams(this.layoutCalculator.data);
    }
  }

  private updateParams(data: any): void {
    this.perRow = this.getPerRow(data.breakpointName);
    this.tileSize = this.layoutCalculator.getTileSize(this.perRow, 9 / 16);
    this.gap = data.gap;
  }

  private getPerRow(breakpointName: string): number {
    switch (breakpointName) {
      case 'sm':
        return 3;
      case 'md':
        return 4;
      case 'lg':
        return 5;
      case 'xl':
        return 6;
      default:
        return 2;
    }
  }
}
