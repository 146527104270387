export class LoadTalents {
  public static readonly type = '[Talents] Load talents';

  public constructor(
    public page: number,
    public pageSize: number = 21,
    public sortColumn: string = '',
    public sortDirection: string = '',
    public filterFormData: any,
  ) {}
}

export class LoadLatestTalent {
  public static readonly type = '[Talents] Load latest talents';

  public constructor(
    public page: number,
    public pageSize: number = 21,
    public sortColumn: string = 'created',
    public sortDirection: string = 'desc',
    public filterFormData: any,
  ) {}
}

export class LoadPromoted {
  public static readonly type = '[Talents] Load promoted';

  public constructor(
    public page: number,
    public pageSize: number = 7,
    public sortColumn: string = '',
    public sortDirection: string = '',
    public filterFormData: any,
  ) {}
}

export class LoadTalent {
  public static readonly type = '[Talent] Load talent';

  public constructor(public id: string) {}
}

export class LoadTalentWithProfile {
  public static readonly type = '[Talent] Load talent with profiles';

  public constructor(public userId: string, public profileId: string) {}
}

export class LoadTalentProfileByProfileName {
  public static readonly type = '[Talent] load talent profile by profile name';

  public constructor(public profileName: string) {}
}
