import { IJobCountry } from '@core/interfaces/job-country';
import { Country } from '@core/models/country.model';
import { IPoint } from '@core/interfaces/point';
import { Type } from 'class-transformer';

export class JobCountry implements IJobCountry {
  @Type(() => Country)
  public country?: Country;

  public jobId: string;
  public countryId: string;
  public city?: string;
  public coordinates?: IPoint;
}
