import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AddUserProfile } from '@core/states';
import { IUserProfile } from '@core/interfaces';
import { v4 } from 'uuid';

@Component({
  selector: 'app-mobile-membership-modal',
  templateUrl: './mobile-membership-modal.component.html',
  styleUrls: ['./mobile-membership-modal.component.scss'],
})
export class MobileMembershipModalComponent implements OnInit {
  @ViewChild('mobileMembershipModal') public modalComponent: MobileModalDialogComponent;

  public showConfirmation = false;
  public isFamilyExist = false;
  public isBusy = false;
  public profileList: IUserProfile[] = [];
  private familyCategoryId = '0f9689c9-7444-47e1-bb03-c7c03b5c99d7';

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {}

  public async openDialog(profileList: IUserProfile[]) {
    this.profileList = profileList;
    this.showConfirmation = false;
    this.isFamilyExist = false;
    return await this.modalComponent.open();
  }

  public closeDialog() {
    this.modalComponent.close();
  }

  public async createFamilyRole() {
    this.isBusy = true;
    const userId = this.profileList[0].userId;
    const formValue = {
      id: v4(),
      categoryId: this.familyCategoryId,
      userId,
      completed: true,
    };
    const resp = await this.store.dispatch(new AddUserProfile(userId, formValue, false)).toPromise();

    this.isBusy = false;
    this.closeDialog();
  }

  public isFamilyRoleExist() {
    const isFamilyExist = this.profileList.find((value) => value.category.id === this.familyCategoryId);
    this.showConfirmation = true;
    if (!isFamilyExist) {
      console.log(this.profileList);
    } else {
      this.isFamilyExist = true;
    }
  }

  public goToMembershipPage(): void {
    this.modalComponent.close();
    this.router.navigateByUrl('/account/details/dashboard?openModal=membership');
  }
}
