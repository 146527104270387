<app-text-field [placeholder]="'Search' | translate" [formControl]="query" (blurred)="onBlur()" (focussed)="onFocus()">
  <span class="input-group-text bg-transparent border-0" prepend>
    <app-icon fill="white" icon="/assets/img/search.svg"></app-icon>
  </span>
</app-text-field>

<ng-container *ngIf="results$ | async as results">
  <ng-container *ngIf="!!results && results.length; else noResults">
    <div class="results">
      <ng-container *ngFor="let user of results">
        <div (click)="openUser(user)" class="result" *ngIf="user.profiles.length">
          <img *ngIf="user.photo; else noPhoto" class="profile-image rounded-circle mr-3" [src]="user.photo.url" />
          <ng-template #noPhoto>
            <div class="profile-image bg-gray rounded-circle mr-3"></div>
          </ng-template>
          <div>
            <div>{{ user.profileName }}</div>
            <div class="text-gray">{{ user.city }}, {{ user.country.isoCode }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noResults>
  <div class="result" translate>No results found.</div>
</ng-template>
