import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Review, PaginatedResults } from '@core/models';
import { IReview } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  public constructor(private http: HttpClient) {}

  public findAll(
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<Review>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/reviews`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Review>) => {
          data.results = data.results.map((item: Review) => plainToClass(Review, item));
          return data;
        }),
      );
  }

  public getReviewByTalent(talentId: string): Observable<Review> {
    return this.http
      .get(`${environment.apiBaseUrl}/reviews/${talentId}`)
      .pipe(map((data: Review) => plainToClass(Review, data)));
  }

  public getReviewByJob(talentId: string, reviewerId: string, jobId: string): Observable<Review> {
    return this.http
      .get(`${environment.apiBaseUrl}/reviews/${talentId}/${reviewerId}/${jobId}`)
      .pipe(map((data: Review) => plainToClass(Review, data)));
  }

  public upsert(review: IReview): Observable<Review> {
    return this.http
      .post(`${environment.apiBaseUrl}/reviews/${review.id}`, review)
      .pipe(map((data: IReview) => plainToClass(Review, data)));
  }

  public delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/reviews/${id}`);
  }
}
