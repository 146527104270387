<ng-container *ngIf="autoHide">
  <div class="floating-footer" appNavbarScroll>
    <div class="wrap">
      <button *ngIf="!usePreviousPath" (click)="goBackUrl()" class="btn-back btn">
        <img src="/assets/img/mobile/arrow-back.svg" alt="" />
      </button>
      <button *ngIf="usePreviousPath" (click)="goToPreviousPath()" class="btn-back btn">
        <img src="/assets/img/mobile/arrow-back.svg" alt="" />
      </button>
      <div class="action" *ngIf="showExtraButton">
        <button [class.no-padding]="customActionImage" class="btn" (click)="openDialog()">
          <img [src]="customActionImage || '/assets/img/mobile/add.svg'" alt="" />
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!autoHide">
  <div class="floating-footer">
    <div class="wrap">
      <button *ngIf="!usePreviousPath" (click)="goBackUrl()" class="btn-back btn">
        <img src="/assets/img/mobile/arrow-back.svg" alt="" />
      </button>
      <button *ngIf="usePreviousPath" (click)="goToPreviousPath()" class="btn-back btn">
        <img src="/assets/img/mobile/arrow-back.svg" alt="" />
      </button>
      <div class="action" *ngIf="showExtraButton">
        <button [class.no-padding]="customActionImage" class="btn" (click)="openDialog()">
          <img [src]="customActionImage || '/assets/img/mobile/add.svg'" alt="" />
        </button>
      </div>
    </div>
  </div>
</ng-container>
<app-mobile-modal-dialog #extraButton *ngIf="showExtraButton" [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-content></ng-content>
</app-mobile-modal-dialog>
