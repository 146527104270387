import { State, Action, StateContext, Selector, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { AuthStateModel } from './auth.state-model';

import { InitializeAuth, RefreshToken, SignIn, SignOut, VerifyAndSignIn, SetRedirectUrl } from './auth.actions';

import { AuthService } from '@auth/services';
import { JwtToken } from '@auth/models';
import { Navigate, RouterNavigation } from '@ngxs/router-plugin';
import { FetchUserDetails, ResetCurrentUser } from '@core/states/users/user';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { query } from '@angular/animations';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    jwtToken: null,
    authenticated: false,
    verifyError: false,
    verifyInProgress: false,
    disabled: false,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  public constructor(private authService: AuthService) {}

  @Selector()
  static jwtToken(state: AuthStateModel): JwtToken {
    return state.jwtToken;
  }

  @Selector()
  static authenticated(state: AuthStateModel): boolean {
    return state.jwtToken !== null;
  }

  @Selector()
  static disabled(state: AuthStateModel): boolean {
    return state.jwtToken?.getBool('disabled');
  }

  @Selector()
  static verifyInProgress(state: AuthStateModel): boolean {
    return state.verifyInProgress;
  }

  @Selector()
  static verifyError(state: AuthStateModel): boolean {
    return state.verifyError;
  }

  @Selector()
  static redirectUrl(state: AuthStateModel): string {
    return state.redirectUrl;
  }

  @Action(InitializeAuth)
  public async init(ctx: StateContext<AuthStateModel>): Promise<void> {
    const jwtToken = this.authService.getJwtToken();

    if (!jwtToken) {
      return;
    }

    ctx.patchState({
      jwtToken,
      authenticated: true,
      disabled: jwtToken.getBool('disabled'),
    });

    ctx.dispatch(new FetchUserDetails(jwtToken.payload.sub));
  }

  @Action(SignIn)
  public async signIn(ctx: StateContext<AuthStateModel>, { username, password, redirectUrl }: SignIn): Promise<void> {
    const jwtToken = await this.authService.login(username, password).toPromise();

    if (!jwtToken) {
      return;
    }

    ctx.patchState({
      jwtToken,
      authenticated: true,
      redirectUrl: null,
    });
    ctx.dispatch(new FetchUserDetails(jwtToken.payload.sub));
    if (redirectUrl != null) {
      let dataParams = {};
      if (redirectUrl?.includes('?action=')) {
        const parser = (url) =>
          url
            .slice(url.indexOf('?') + 1)
            .split('&')
            .reduce((a, c) => {
              const [key, value] = c.split('=');
              a[key] = value;
              return a;
            }, {});
        dataParams = parser(redirectUrl);
        redirectUrl = redirectUrl?.split('?')[0];
      }
      ctx.dispatch(new Navigate([`${redirectUrl}`], dataParams));
    } else if (jwtToken.payload.roles.includes('admin')) {
      ctx.dispatch(new Navigate([`/admin`]));
    } else {
      ctx.dispatch(new Navigate([`/account/details`]));
    }
  }

  @Action(VerifyAndSignIn)
  public async verifyAndsignIn(
    ctx: StateContext<AuthStateModel>,
    { verificationCode }: VerifyAndSignIn,
  ): Promise<void> {
    ctx.patchState({
      verifyInProgress: true,
      verifyError: false,
    });

    try {
      const jwtToken = await this.authService.verify(verificationCode).toPromise();

      if (!jwtToken) {
        ctx.patchState({
          verifyError: true,
          verifyInProgress: false,
        });
        return;
      }

      ctx.patchState({
        jwtToken,
        authenticated: true,
        verifyInProgress: false,
      });

      ctx.dispatch(new FetchUserDetails(jwtToken.payload.sub));
    } catch (error) {
      console.log('Failed to verify email', error);
      ctx.patchState({
        verifyError: true,
        verifyInProgress: false,
      });
    }
  }

  @Action(SignOut)
  public async signOut(ctx: StateContext<AuthStateModel>): Promise<void> {
    ctx.patchState({
      jwtToken: null,
      authenticated: false,
    });

    this.authService.logout();
    ctx.dispatch(new ResetCurrentUser());
    ctx.dispatch(new Navigate(['/']));
  }

  @Action(RefreshToken)
  public refreshToken(ctx: StateContext<AuthStateModel>): Observable<JwtToken> {
    const state = ctx.getState();
    if (state.jwtToken !== null) {
      return this.authService.refreshToken(state.jwtToken.access_token).pipe(
        tap((newJwtToken: JwtToken) => {
          ctx.patchState({
            jwtToken: newJwtToken,
          });
          ctx.dispatch(new FetchUserDetails(newJwtToken.payload.sub));
        }),
      );
    }
  }

  @Action(SetRedirectUrl)
  public async redirectUrl(ctx: StateContext<AuthStateModel>, { redirectUrl }: SetRedirectUrl): Promise<void> {
    ctx.patchState({
      redirectUrl,
    });
  }

  public ngxsOnInit(ctx: StateContext<AuthStateModel>): void {
    ctx.dispatch(new InitializeAuth());
  }
}
