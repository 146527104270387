import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { IPackage } from '@core/interfaces/package';
import { Package } from '@core/models/package.model';

@Injectable({
  providedIn: 'root',
})
export class PackagesService {
  public constructor(private http: HttpClient) {}

  public delete(packageId: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/packages/${packageId}`);
  }

  public save(p: IPackage): Observable<IPackage> {
    return this.http
      .put(`${environment.apiBaseUrl}/packages/${p.userProfileId}`, p)
      .pipe(map((data: IPackage) => plainToClass(Package, data)));
  }
}
