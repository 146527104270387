import { Note } from '@core/models';

export class FindTalentsNote {
  public static readonly type = '[Notes] Find note by talent id';

  public constructor(public talentId: string) {}
}

export class SaveNote {
  public static readonly type = '[Notes] Save note';

  public constructor(public note: Note) {}
}

export class DeleteNote {
  public static readonly type = '[Notes] Delete note';

  public constructor(public id: string) {}
}
