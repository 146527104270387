import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { RoleGuard } from './role-guard';
import { Router } from '@angular/router';
@Injectable()
export class AccountGuard extends RoleGuard {
  constructor(store: Store, router: Router) {
    super(['talent', 'caster'], store, router);
  }
}
