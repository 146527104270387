import { Component, Input } from '@angular/core';
import { IMessage } from '@core/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-message-time',
  templateUrl: './message-time.component.html',
  styleUrls: ['./message-time.component.scss'],
})
export class MessageTimeComponent {
  @Input()
  public message: IMessage;

  @Input()
  public showReadStatus?: boolean;

  public transformTime(datetime: Date): string {
    const currentDate = new Date();
    const differenceInDays = moment(currentDate).diff(datetime, 'days');
    const differenceInMinutes = moment(currentDate).diff(datetime, 'minutes');

    if (differenceInMinutes < 1) {
      return moment(datetime).local().fromNow();
    } else if (differenceInDays < 1) {
      return moment(datetime).local().format('H:mm');
    } else if (differenceInDays === 1) {
      return moment(datetime).local().calendar(currentDate);
    } else {
      return moment(datetime).local().format('DD-MM-YYYY, H:mm');
    }
  }
}
