<div class="d-flex flex-column w-100 text-white popup">
  <div class="noise">
    <div class="wrapper" *ngIf="jobApplication">
      <div class="checklist-image">
        <img src="/assets/img/purple-checklist.png" />
      </div>
      <div>
        <h2 translate>Awesome!</h2>
      </div>
      <div>
        <h4 translate>Thanks for the confirmation.</h4>
        <h4 translate>
          <!-- '{{jobApplication.job.title}}' -->
          You are now booked
        </h4>
      </div>
      <div class="button-container">
        <button
          class="w-100 h-100 rounded-sm border-0 text-white px-2 find-more-jobs-button"
          translate
          type="button"
          (click)="navigateToJobs()">
          Find more jobs!
        </button>
      </div>
    </div>

    <div
      *ngIf="isProcess"
      class="
        spinner-overlay
        position-absolute
        w-100
        h-100
        d-flex
        align-items-center
        justify-content-center
        flex-column
      ">
      <app-spinner></app-spinner>
      <div translate class="please-wait">Please wait</div>
    </div>

    <div
      *ngIf="isError || (!jobApplication && !isProcess)"
      class="
        spinner-overlay
        position-absolute
        w-100
        h-100
        d-flex
        align-items-center
        justify-content-center
        flex-column
      ">
      <h5 translate>Something went wrong!</h5>
    </div>
  </div>
</div>
<div class="close-button align-items-center justify-content-center cursor-pointer d-flex" (click)="close()">x</div>
