import { ICountry } from '@core/interfaces/country';
import { IPoint } from '@core/interfaces/point';

export class Country implements ICountry {
  public id: string;
  public isoCode: string;
  public name: string;
  public callingCode: number;
  public coordinates?: IPoint;
  public alpha2Code?: string;
  get translateKey(): string {
    return `COUNTRY.${this.isoCode}`;
  }
}
