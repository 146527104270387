<app-button gradientName="purple-bright" *ngIf="styleUI === 'dekstop'">
  <label class="px-2">
    <input (input)="onValueChange($event.target.files)" [accept]="accept" class="d-none" type="file" />
    <span translate>Upload</span>
  </label>
</app-button>
<div *ngIf="styleUI === 'mobile'" class="btn-upload">
  <label class="px-2">
    <input (input)="onValueChange($event.target.files)" [accept]="accept" class="d-none" type="file" />
    <span translate>Upload ID</span>
  </label>
</div>
