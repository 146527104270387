import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersService } from '@core/services';

import { VerifyEmailStateModel } from './verify-email.state-model';

import { ResendEmailVerification, StartVerifyEmail } from './verify-email.actions';
import { IUser } from '@core/interfaces/user';

@State<VerifyEmailStateModel>({
  name: 'verifyEmail',
  defaults: {
    user: null,
    inProgress: false,
    error: null,
  },
})
@Injectable()
export class VerifyEmailState {
  public constructor(private usersService: UsersService) {}

  @Selector()
  static error(state: VerifyEmailStateModel): string {
    return state.error;
  }

  @Selector()
  static inProgress(state: VerifyEmailStateModel): boolean {
    return state.inProgress;
  }

  @Selector()
  static user(state: VerifyEmailStateModel): IUser {
    return state.user;
  }

  @Action(StartVerifyEmail)
  public async startVerifyEmail(
    ctx: StateContext<VerifyEmailStateModel>,
    { verificationCode }: StartVerifyEmail,
  ): Promise<void> {
    try {
      ctx.patchState({ user: null, inProgress: true, error: null });
      const result = await this.usersService.verifyEmail(verificationCode).toPromise();
      ctx.patchState({ user: result, inProgress: false, error: null });
    } catch (e) {
      ctx.patchState({ user: null, inProgress: false, error: e.message });
    }
  }

  @Action(ResendEmailVerification)
  public async resendEmailVerification(
    ctx: StateContext<VerifyEmailStateModel>,
    { user }: ResendEmailVerification,
  ): Promise<void> {
    await this.usersService.resendEmailVerification(user).toPromise();
  }
}
