import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPackage } from '@core/interfaces/package';
import { AuthState } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { JwtToken } from '@auth/models';
import { AuthService } from '@auth/services';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
})
export class PackageComponent {
  @Select(AuthState.jwtToken)
  public token$: Observable<JwtToken>;

  @Input()
  public package: IPackage;

  @Output()
  public packageSelected = new EventEmitter();

  constructor(private store: Store, private authService: AuthService) {}

  public async onPackageSelected(p: IPackage): Promise<void> {
    if (this.authService.isDisabled()) {
      await this.store.dispatch(new Navigate(['/account/disabled'])).toPromise();
      return;
    }

    this.packageSelected.emit(p);
  }

  public isCaster(jwtToken: JwtToken): boolean {
    return !jwtToken || jwtToken.payload.roles.includes('caster');
  }
}
