import { IUser } from '@core/interfaces';
import { Type } from 'class-transformer';
import { UserProfile } from './user-profile.model';
import { ICredit } from '@core/interfaces/credit';

export class Credit implements ICredit {
  @Type(() => UserProfile)
  public user?: IUser;

  public id: string;
  public userId: string;
  public balance: number;
}
