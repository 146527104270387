import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NotesService } from '@core/services';

import { NotesStateModel } from '@core/states';
import { DeleteNote, FindTalentsNote, SaveNote } from '@core/states/notes/notes.actions';
import { Note } from '@core/models';

@State<NotesStateModel>({
  name: 'notes',
  defaults: {
    note: null,
  },
})
@Injectable()
export class NotesState {
  public constructor(private notesService: NotesService) {}

  @Selector()
  static note(state: NotesStateModel): Note {
    return state.note;
  }

  @Action(FindTalentsNote)
  public async findTalentsNote(state: StateContext<NotesStateModel>, { talentId }: FindTalentsNote): Promise<void> {
    const note = await this.notesService.getNoteByTalent(talentId).toPromise();
    state.patchState({ note });
  }

  @Action(SaveNote)
  public async saveNote(state: StateContext<NotesStateModel>, { note }: SaveNote): Promise<void> {
    note = await this.notesService.upsert(note).toPromise();
    state.patchState({ note });
  }

  @Action(DeleteNote)
  public async deleteNote(state: StateContext<NotesStateModel>, { id }: DeleteNote): Promise<void> {
    await this.notesService.delete(id).toPromise();
    state.patchState({ note: null });
  }
}
