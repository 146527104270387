<app-mobile-modal-dialog [disableCloseButton]="true" #appCompleteJobModal [backgroundColor]="'#121114'">
  <ng-container *ngIf="!isBusy; else loaderTemplate">
    <div class="invoice-container" *ngIf="!showSuccessMessage; else successMessage">
      <ng-container *ngIf="!loader; else spinnerTemplate">
        <div class="invoice-desc">
          <h4>Invoice</h4>
          <h4>
            <span translate>Invoice for job:</span>
            {{jobData.title}}
          </h4>
          <p>Total fee + buy-out: ${{getTotalFee}}</p>
          <p>Travel cost: {{getTotalTravelCost}}</p>
        </div>
        <div class="invoice-list-container">
          <div style="display: contents" *ngFor="let invoiceData of userInvoiceList; let i = index">
            <div class="user-invoice-card" *ngIf="!invoiceData?.deleted">
              <div class="user-name-information">
                <div class="profile-image-container">
                  <img class="profile-image rounded-circle" [src]="invoiceData.talentSelected.photo.thumbUrl" />
                </div>
                {{invoiceData.talentSelected.profileName}}
              </div>
              <div class="invoice-content">
                <p>Fee : {{'€'+invoiceData.totalFee}}</p>
                <p>Buy-out : {{'€'+invoiceData.totalBuyout}}</p>
                <p>
                  Travel cost :
                  <span translate>
                    {{ invoiceData.travelCost ? invoiceData.isPending ? ('Yes Pending' | translate) :
                    '€'+invoiceData.travelCostAmount : 'Disabled' }}
                  </span>
                </p>
                <p>Parking cost : {{invoiceData.parkingCost ? ('Yes Pending' | translate): 'Disabled'}}</p>
              </div>
              <div class="button-group reversed">
                <button (click)="openInvoiceForm(invoiceData, i)">Edit</button>
                <button (click)="deleteUserConfirmation(invoiceData)">Delete</button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style="display: flex; margin: 20px; gap: 10px">
            <button (click)="openInvoiceForm()" class="add-talent-button">Add talent</button>
            <button (click)="openNoteForm()" class="add-talent-button">Add note</button>
          </div>
          <div class="invoice-action button-group">
            <button (click)="close()">Cancel</button>
            <button [disabled]="isSubmitDisabled" (click)="submitConfirmation()">Proceed</button>
          </div>
        </div>
      </ng-container>
      <ng-template #spinnerTemplate>
        <div class="loader-container">
          <span class="spinner-border spinner-border-lg" aria-hidden="true"></span>
        </div>
      </ng-template>
      <app-mobile-complete-job-edit-modal
        (saveData)="insertInvoice($event)"
        #editInvoiceModal></app-mobile-complete-job-edit-modal>
      <app-mobile-complete-job-note-modal
        (saveNote)="saveNote($event)"
        #editNoteModal></app-mobile-complete-job-note-modal>
      <app-mobile-complete-job-confirmation-modal
        (confirm)="deleteUserInvoice()"
        #confirmationDeleteInvoiceModal></app-mobile-complete-job-confirmation-modal>
      <app-mobile-complete-job-confirmation-modal
        (confirm)="onSubmit()"
        #confirmationSubmitInvoiceModal></app-mobile-complete-job-confirmation-modal>
    </div>
    <ng-template #successMessage>
      <div class="success-message-container">
        <h3 translate>Job is completed is now showing at Completed jobs</h3>
        <p translate>
          When travel expenses is enabled, you will first receive a message to approve/reject this before the invoice
          will be sent.
        </p>
        <button (click)="close(true)">Close</button>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loaderTemplate>
    <div class="loader-container">
      <span class="spinner-border spinner-border-lg" aria-hidden="true"></span>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
