import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockedIndicatorComponent } from './components/locked-indicator/locked-indicator.component';
import { ValidationIndicatorComponent } from './components/validation-indicator/validation-indicator.component';
import { VisibilityIndicatorComponent } from './components/visibility-indicator/visibility-indicator.component';
import { ColorIndicatorComponent } from './components/color-indicator/color-indicator.component';
import { InfoIndicatorComponent } from './components/info-indicator/info-indicator.component';
import { GenericModule } from '../generic/generic.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    LockedIndicatorComponent,
    ValidationIndicatorComponent,
    VisibilityIndicatorComponent,
    ColorIndicatorComponent,
    InfoIndicatorComponent,
  ],
  imports: [CommonModule, GenericModule, NgbModule],
  exports: [
    LockedIndicatorComponent,
    ValidationIndicatorComponent,
    VisibilityIndicatorComponent,
    ColorIndicatorComponent,
    InfoIndicatorComponent,
  ],
})
export class IndicatorsModule {}
