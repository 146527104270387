import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges, OnInit {
  @Input()
  @HostBinding('class.is-busy')
  public isBusy = false;

  @Input()
  @HostBinding('class.dimmed')
  public isDimmed = false;

  @Input()
  @HostBinding('class.disabled')
  public isDisabled = false;

  @Input()
  @HostBinding('attr.button-style')
  public buttonStyle = 'purple';

  @Input()
  @HostBinding('attr.button-size')
  public buttonSize = 'md';

  @Input()
  public roundedSize = 'sm';

  public gradientName: string;
  public gradientDirection: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if ('buttonStyle' in changes) {
      this.updateGradientParameters(changes.buttonStyle.currentValue);
    }

    if ('roundedSize' in changes) {
      this.roundedSize = changes.roundedSize.currentValue;
    }
  }

  public ngOnInit(): void {
    this.updateGradientParameters(this.buttonStyle);
  }

  public updateGradientParameters(buttonStyle: string): void {
    switch (buttonStyle) {
      case 'orange-solid':
        this.gradientName = 'orange-solid';
        this.gradientDirection = 'horizontal';
        break;
      case 'orange':
        this.gradientName = 'orange-bright';
        this.gradientDirection = 'horizontal';
        break;
      case 'purple':
        this.gradientName = 'purple-bright';
        this.gradientDirection = 'horizontal';
        break;
      case 'violent-blue':
        this.gradientName = 'violent-violet-overdue-blue';
        this.gradientDirection = 'horizontal';
        break;
      case 'artyclick-purple':
        this.gradientName = 'artyclick-purple';
        this.gradientDirection = 'horizontal';
        break;
      default:
        this.gradientName = null;
        this.gradientDirection = null;
    }
  }
}
