import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RegionService } from '@core/services';

import { LoadRegion } from './region.actions';
import { IRegion } from '@core/interfaces/region';

@State<IRegion>({
  name: 'region',
})
@Injectable()
export class RegionState implements NgxsOnInit {
  public constructor(private regionService: RegionService) {}

  @Selector()
  static isoCode(state: IRegion): string {
    return state.isoCode;
  }

  @Action(LoadRegion)
  public async loadRegion(ctx: StateContext<IRegion>): Promise<void> {
    const region = await this.regionService.fetch().toPromise();
    ctx.patchState(region);
  }

  public ngxsOnInit(ctx: StateContext<RegionState>): void {
    ctx.dispatch(new LoadRegion());
  }
}
