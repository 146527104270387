import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-color-indicator',
  templateUrl: './color-indicator.component.html',
  styleUrls: ['./color-indicator.component.scss'],
})
export class ColorIndicatorComponent implements AfterViewInit {
  @Input()
  public color: number;

  @ViewChild('colorIndicator')
  public colorIndicator: ElementRef;

  public startColor = '#facc9d';
  public endColor = '#713606';

  public ngAfterViewInit(): void {
    this.pickHex(this.color);
  }

  public pickHex(percentage: number): void {
    const ratio = percentage / 100;
    const color1 = this.endColor;
    const color2 = this.startColor;

    const r = this.shiftColor(color1, color2, 1, 3, ratio);
    const g = this.shiftColor(color1, color2, 3, 5, ratio);
    const b = this.shiftColor(color1, color2, 5, 7, ratio);

    this.colorIndicator.nativeElement.style.backgroundColor = `#${this.hex(r)}${this.hex(g)}${this.hex(b)}`;
  }

  private shiftColor(color1: string, color2: string, start: number, end: number, ratio: number): number {
    return Math.ceil(
      parseInt(color1.substring(start, end), 16) * ratio + parseInt(color2.substring(start, end), 16) * (1 - ratio),
    );
  }

  private hex(x: number): string {
    const value = x.toString(16);
    return value.length === 1 ? `0${value}` : value;
  }
}
