import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { PaginatedResults, JobRequest } from '@core/models';
import { IJobRequest } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class JobRequestsService {
  public constructor(private http: HttpClient) {}

  public findAll(
    query: string,
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<JobRequest>> {
    return this.http
      .get<PaginatedResults<JobRequest>>(`${environment.apiBaseUrl}/job-requests`, {
        params: {
          q: `${query ?? ''}`,
          page: `${page}`,
          limit: `${limit}`,
          orderBy: `${orderBy}`,
          orderByDirection: `${orderByDirection}`,
        },
      })

      .pipe(
        map((data: PaginatedResults<JobRequest>) => {
          data.results = data.results.map((item: JobRequest) => plainToClass(JobRequest, item));
          return data;
        }),
      );
  }

  public getById(id: string): Observable<JobRequest> {
    return this.http
      .get(`${environment.apiBaseUrl}/job-requests/${id}`)
      .pipe(map((data: JobRequest) => plainToClass(JobRequest, data)));
  }

  public saveJobRequest(jobRequestData: IJobRequest): Observable<JobRequest> {
    return this.http
      .post(`${environment.apiBaseUrl}/job-requests`, jobRequestData)
      .pipe(map((data: JobRequest) => plainToClass(JobRequest, data)));
  }

  public promotedJob(id: string): Observable<JobRequest> {
    return this.http
      .put(`${environment.apiBaseUrl}/jobs/${id}/promote`, null)
      .pipe(map((data: JobRequest) => plainToClass(JobRequest, data)));
  }
}
