import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselsModule } from '../generic/carousels/carousels.module';
import { GradientsModule } from '../generic/gradients/gradients.module';
import { IconsModule } from '../generic/icons/icons.module';
import { MoneyModule } from '../money/money.module';
import { RatingModule } from '../rating/rating.module';

import { JobRowComponent } from './components/job-row/job-row.component';
import { ImagesModule } from '../generic/images/images.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@shared/pipes/pipes.module';

const components = [JobRowComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    CarouselsModule,
    IconsModule,
    GradientsModule,
    ImagesModule,
    RatingModule,
    MoneyModule,
    TranslateModule,
    PipesModule,
  ],
  exports: components,
})
export class RowsModule {}
