import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SliderComponent } from '../slider/slider.component';

@Component({
  selector: 'app-metric-slider',
  templateUrl: '../slider/slider.component.html',
  styleUrls: ['../slider/slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetricSliderComponent),
      multi: true,
    },
  ],
})
export class MetricSliderComponent extends SliderComponent {
  protected defaultOptions = {
    floor: 0,
    ceil: 500,
    showSelectionBar: true,
    translate: (value: number) => {
      if (isNaN(value)) {
        return '';
      }
      const valueInInches = Math.round(value * 0.393700787 * 100) / 100;
      return `${value}cm/${valueInInches}"`;
    },
  };
}
