import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TalentCardsListComponent } from './components/talent-cards-list/talent-cards-list.component';
import { UserCardsListComponent } from './components/job-application-user-cards-list/job-application-user-cards-list.component';
import { JobRowsListComponent } from './components/job-rows-list/job-rows-list.component';
import { CardsModule } from '../cards/cards.module';
import { RowsModule } from '../rows/rows.module';
import { GenericModule } from '../generic/generic.module';
import { TranslateModule } from '@ngx-translate/core';

const components = [TalentCardsListComponent, UserCardsListComponent, JobRowsListComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, CardsModule, RowsModule, GenericModule, TranslateModule],
  exports: components,
})
export class ListsModule {}
