<ng-container *ngIf="transactions?.data?.length">
  <h2 class="h5 pb-4" translate>Reserved payments</h2>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th translate>Date</th>
          <th translate>Description</th>
          <th translate>Amount</th>
          <th translate>Fees</th>
          <th translate>Total</th>
          <th translate>Status</th>
          <th translate>Reserved until</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of transactions.data">
          <td>{{ transaction.created * 1000 | date }}</td>
          <td>{{ transaction.order.products[0].name }}</td>
          <td>{{ transaction.amount / 100 | currency }}</td>
          <td>{{ transaction.fees.merchant_order_fee / 100 | currency }}</td>
          <td>{{ transaction.fees.merchant_payable_amount / 100 | currency }}</td>
          <td translate>{{ 'PAYMENT_STATUS.' + transaction.status }}</td>
          <td>
            <ng-container *ngIf="transaction.status == 'reserved'">
              {{ transaction.escrow.end * 1000 | date }}
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
