<div class="d-flex flex-column w-100 text-white">
  <div class="wrapper">
    <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>
    <div class="success-create-job">
      <div class="img-wrap">
        <img src="assets/img/checkmark-white.svg" alt="Success" />
      </div>
      <h1 class="h3 text-center" translate>Job Successfully posted</h1>
      <div class="btn-wrap">
        <button (click)="viewJob()" translate>View jobs</button>
        <button (click)="findTalent()" translate>Find Talent</button>
      </div>
    </div>
  </div>
</div>
