import { IJobInvoice } from '@core/interfaces/job-invoice';

export class JobInvoice implements IJobInvoice {
  id?: string;
  userId?: string;
  jobId?: string;
  jobInvoiceId?: string;
  applicantId?: string;
  talentName?: string;
  fee: number;
  buyOut: number;
  tc: boolean;
  tcAmount: number;
  tcMaxAmount: number;
  pc: boolean;
  pcMaxAmount: number;
  reason?: string;
  reason_type?: string;
  buyoutProperty?: any;
}
