import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlbumsModule } from './albums/albums.module';
import { CardsModule } from './cards/cards.module';
import { CarouselsModule } from './carousels/carousels.module';
import { GenericModule } from './generic/generic.module';
import { IndicatorsModule } from './indicators/indicators.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ListsModule } from './lists/lists.module';
import { MessagingModule } from './messaging/messaging.module';
import { MoneyModule } from './money/money.module';
import { NavigationModule } from './navigation/navigation.module';
import { NotificationsModule } from './notifications/notifications.module';
import { RatingModule } from './rating/rating.module';
import { DecksModule } from './decks/decks.module';
import { RowsModule } from './rows/rows.module';
import { SearchModule } from './search/search.module';
import { TranslateModule } from '@ngx-translate/core';
import { ScreenSizeModule } from './screen-size/screen-size.module';
import { RolesModule } from '@src/ui/roles/roles.module';
import { MasonryPhotoModule } from './masonry-photo/masonry-photo.module';

const submodules = [
  AlbumsModule,
  CardsModule,
  CarouselsModule,
  DecksModule,
  GenericModule,
  IndicatorsModule,
  LayoutsModule,
  ListsModule,
  MessagingModule,
  MoneyModule,
  NavigationModule,
  NotificationsModule,
  RatingModule,
  RowsModule,
  SearchModule,
  ScreenSizeModule,
  RolesModule,
  MasonryPhotoModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, TranslateModule, ...submodules],
  exports: submodules,
})
export class UiModule {}
