import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrectLangService {
  public isError: BehaviorSubject<HttpErrorResponse> = new BehaviorSubject<HttpErrorResponse>(null);
  constructor() {}

  public getLanguage(): string {
    if (localStorage.getItem('language') === 'nl') {
      return 'nl-NL';
    } else {
      return 'en-EN';
    }
  }
}
