import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-base-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class BaseTableComponent {
  public currentPage = 1;
  public itemsPerPage = 25;
  public sortColumn = 'id';
  public sortDirection = 'desc';

  public constructor(protected route: ActivatedRoute) {
    if (this.route.snapshot.queryParamMap.get('currentPage')) {
      this.currentPage = parseInt(this.route.snapshot.queryParamMap.get('currentPage'), 10);
    }
    if (this.route.snapshot.queryParamMap.get('perPage')) {
      this.itemsPerPage = parseInt(this.route.snapshot.queryParamMap.get('perPage'), 10);
    }
    if (this.route.snapshot.queryParamMap.get('sortColumn')) {
      this.sortColumn = this.route.snapshot.queryParamMap.get('sortColumn');
    }
    if (this.route.snapshot.queryParamMap.get('sortDirection')) {
      this.sortDirection = this.route.snapshot.queryParamMap.get('sortDirection');
    }
  }

  public pageChanged(newPage: number): void {
    this.currentPage = newPage;
    this.scrollToTop();
    this.updateItems();
  }

  public sort(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.currentPage = 1;
    this.scrollToTop();
    this.updateItems();
  }

  public getIcon(type: string): string[] {
    if (this.sortColumn === type) {
      if (this.sortDirection === 'asc') {
        return ['fas', 'arrow-down'];
      }
      return ['fas', 'arrow-up'];
    }
    return ['fas', 'arrows-alt-v'];
  }

  // smooth scroll to top of page
  public scrollToTop(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }

  public updateItems(): void {}
}
