import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileFieldComponent),
      multi: true,
    },
  ],
})
export class FileFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public accept: string;

  @Input()
  public styleUI = 'dekstop';

  private value: string;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public onValueChange(value: FileList): void {
    this.onChange(value[0]);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private updateField(value: string): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.value = value;
  }
}
