import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertsModule } from './alerts/alerts.module';
import { ButtonsModule } from './buttons/buttons.module';
import { CarouselsModule } from './carousels/carousels.module';
import { FormsModule } from './forms/forms.module';
import { GradientsModule } from './gradients/gradients.module';
import { IconsModule } from './icons/icons.module';
import { ImagesModule } from './images/images.module';
import { SpinnersModule } from './spinners/spinners.module';
import { VideosModule } from './videos/videos.module';
import { ViewSwitcherModule } from './view-switcher/view-switcher.module';

const submodules = [
  AlertsModule,
  ButtonsModule,
  CarouselsModule,
  FormsModule,
  GradientsModule,
  IconsModule,
  ImagesModule,
  SpinnersModule,
  VideosModule,
  ViewSwitcherModule,
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...submodules],
  exports: submodules,
})
export class GenericModule {}
