<div [class.fullscreen-dialog]="isDialog" class="mobile-account-messaging-list-container">
  <div id="mobile-app" *ngIf="!showDetailChat">
    <app-mobile-header *ngIf="!isDialog" [showFilter]="false"></app-mobile-header>
    <div class="message-warning">
      <img src="/assets/img/mobile/lamp.svg" alt="" />
      <p>
        {{warningText}}
        <a [href]="warningHref" translate>view the rules</a>
      </p>
    </div>
    <div class="chat-list-container" infiniteScroll [scrollWindow]="true" (scrolled)="onScrollDown()">
      <ng-container *ngFor="let chat of chats$ | async">
        <div (click)="onChatClicked(chat)" class="chat-item">
          <div class="chat-profile-pic">
            <app-mobile-image-preview
              [image]="chat.sender.id == userId ? 
              chat.recipient?.photo?.thumbUrl : 
              chat.sender?.photo?.thumbUrl"></app-mobile-image-preview>
          </div>
          <div class="chat-overview">
            <div class="chat-overview-data">
              <p class="chat-user-name">
                {{ chat.sender.id == userId ? chat.recipient?.profileName : chat.sender?.profileName }}
              </p>
              <p>{{chat.lastMessageReceived?.created | date:"dd/MM/YY"}}</p>
            </div>
            <div class="chat-overview-detail">
              <p *ngIf="chat.lastMessageReceived">{{ chat.lastMessageReceived.message }}</p>
              <div class="badge-container">
                <p *ngIf="chat.totalUnread" class="badge">
                  {{ chat.totalUnread }}{{ chat.totalUnread > 99 ? "+" : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-divider"></div>
      </ng-container>
    </div>
    <app-mobile-navbar *ngIf="!isDialog" [conditionalNavbar]="true"></app-mobile-navbar>
  </div>
  <ng-container *ngIf="showDetailChat">
    <div id="mobile-app" class="chat-detail" *ngIf="activeChat$ | async as activeChat">
      <div class="chat-detail-header">
        <span (click)="onChatClicked(activeChat)">
          <img src="/assets/img/mobile/arrow-back.svg" alt="" />
          Back
        </span>
        <span>{{activeChat?.recipient?.profileName}}</span>
        <div class="chat-profile-pic">
          <app-mobile-image-preview
            [image]="activeChat.sender.id == userId ? 
                            activeChat.recipient?.photo?.thumbUrl : 
                            activeChat.sender?.photo?.thumbUrl"></app-mobile-image-preview>
        </div>
      </div>
      <div class="message-warning mt-3">
        <img src="/assets/img/mobile/lamp.svg" alt="" />
        <p>
          {{warningText}}
          <a (click)="goToLink(warningHref)" translate>view the rules</a>
        </p>
      </div>

      <div
        #messagesContainer
        class="message-list-container"
        infiniteScroll
        [scrollWindow]="false"
        (scrolledUp)="loadMoreMessage()"
        (scroll)="onScroll($event)">
        <div
          class="message-container"
          [ngClass]="{ own: message.senderId === userId }"
          *ngFor="let message of activeChat?.messages">
          <p [innerHTML]="convertTextToLink(message.message)"></p>
          <span [class.read-mark-active]="message.read">
            {{ transformTime(message.created) }}
            <img *ngIf="message.senderId === userId" src="/assets/img/read-mark.svg" alt="" />
          </span>
        </div>
      </div>

      <form [formGroup]="form" (ngSubmit)="sendMessage()">
        <span
          #messageField
          class="message-field flex-grow-1"
          role="textbox"
          [textContent]="message"
          (input)="onChangeMessage($event.target.textContent)"
          (keypress)="onMessageFieldKeyPress($event)"
          (paste)="onMessageFieldPaste($event)"
          contenteditable></span>
        <button [disabled]="form.invalid" translate type="submit">
          <img src="/assets/img/mobile/arrow-up.svg" alt="" />
        </button>
      </form>
    </div>
  </ng-container>
</div>
