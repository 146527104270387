<div class="d-flex flex-column w-100 text-white p-5">
  <h1 class="h3 text-center mb-3" translate>Choose language</h1>
  <div class="row">
    <div class="col">
      <div (click)="changeLanguage(language)" *ngFor="let language of languages" class="cursor-pointer language mb-2">
        {{ language.label }}
      </div>
    </div>
  </div>
</div>
