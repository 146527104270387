import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextFieldComponent } from '../text-field/text-field.component';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFieldComponent),
      multi: true,
    },
  ],
})
export class PasswordFieldComponent extends TextFieldComponent implements OnInit {
  public ngOnInit(): void {
    this.type = 'password';
  }

  public switchViewMode(): void {
    this.type = this.type === 'password' ? 'text' : 'password';
  }
}
