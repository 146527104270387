export class SendMobileVerificationCode {
  public static readonly type = '[Verify mobile] Send mobile verification code';

  public constructor(public phonenumber: string) {}
}

export class CheckMobileVerificationCode {
  public static readonly type = '[Verify mobile] Check mobile verification code';

  public constructor(public phonenumber: string, public verificationCode: string) {}
}
