import { Invoice } from '@core/models';

export class FetchInvoices {
  public static readonly type = '[Invoices] Fetch invoices';

  public constructor(
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'desc',
    public infintyScrollMode: boolean = false,
  ) {}
}

export class FetchInvoice {
  public static readonly type = '[Invoices] Fetch invoice by id';

  public constructor(public id: string) {}
}

export class SaveInvoice {
  public static readonly type = '[Invoices] Save invoice';

  public constructor(public invoice: Invoice) {}
}
