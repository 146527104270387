<app-mobile-modal-dialog
  [disableCloseButton]="true"
  #claimTravelExpensesIncorrectFee
  [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!showSuccess">
    <div class="d-flex flex-column w-100 text-white">
      <div class="wrapper">
        <div class="success-create-job">
          <h4 class="text-center">{{"Is this info not correct?" | translate}}</h4>
          <p class="text-center mt-3">
            ONLY fill this in when the info is not correct. Your message will be sent directly to the Caster &
            Casterbee.
          </p>
          <p class="text-center">
            Is it correct? Then close this popup, scroll
            <br />
            down and click on Continue
          </p>
          <form [formGroup]="form" (submit)="submit()" class="mb-3">
            <textarea formControlName="reason" class="form-control"></textarea>
          </form>
        </div>
      </div>
      <div class="btn-group">
        <button class="purple" (click)="submit()" translate>Submit</button>
        <button class="black" (click)="close(false)" translate>Cancel</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showSuccess">
    <div class="wrapper full-height-version">
      <div style="flex: auto">
        <app-mobile-success [height]="'unset'" [message]="'Thank you'"></app-mobile-success>
        <p style="font-size: 18px; text-align: center; padding: 20px">
          Your note that you disagree with the info has been sent to the Caster and to Casterbee.
          <br />
          <br />
          The Caster or Casterbee will respond shortly
        </p>
      </div>
      <div class="btn-group" style="width: 100%">
        <button class="black" (click)="close(true)" translate>Close</button>
      </div>
    </div>
  </ng-container>
</app-mobile-modal-dialog>
