export class DeleteMedia {
  public static readonly type = '[Media] Delete media';

  public constructor(public id: string) {}
}

export class GetMediaInfo {
  public static readonly type = '[Media] Get media info';

  public constructor(public id: string) {}
}
