import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileAuthModalComponent } from '../mobile-auth-modal/mobile-auth-modal.component';

@Component({
  selector: 'app-mobile-sign-up',
  templateUrl: './mobile-sign-up.component.html',
  styleUrls: ['./mobile-sign-up.component.scss'],
})
export class MobileSignUpComponent implements OnInit {
  @ViewChild('loginModal') private loginModal: MobileAuthModalComponent;

  constructor() {}

  ngOnInit(): void {}

  public signUp(roleValue: 'talent' | 'caster') {
    this.loginModal.openDialog({
      showFormSignUp: true,
      role: roleValue,
    });
  }
}
