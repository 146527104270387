import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { IUserAlbum, IUser, MediaType } from '@core/interfaces';
import { Media } from '@core/models';
import { plainToClass } from 'class-transformer';
import { v4 } from 'uuid';
import AutoAnimate from '@formkit/auto-animate';

@Component({
  selector: 'app-mobile-account-profile-album-media',
  templateUrl: './mobile-account-profile-album-media.component.html',
  styleUrls: ['./mobile-account-profile-album-media.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobileAccountProfileAlbumMediaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MobileAccountProfileAlbumMediaComponent),
      multi: true,
    },
  ],
})
export class MobileAccountProfileAlbumMediaComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @ViewChild('mediaList') mediaListRef: ElementRef;

  @Output()
  public delete = new EventEmitter<IUserAlbum>();

  @Input()
  public user: IUser;

  @Input()
  public albumType: MediaType;

  public maxItems: number;

  public isUrlValid = true;

  public form = new FormGroup({
    media: new FormArray([]),
  });

  public constructor() {}

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    AutoAnimate(this.mediaListRef.nativeElement);
  }

  public writeValue(value: IUserAlbum): void {
    this.albumType = value.albumType;
    if (this.albumType === MediaType.photo) {
      this.maxItems = this.user.membership.portfolio_pictures;
    } else if (this.albumType === MediaType.video) {
      this.maxItems = this.user.membership.videos;
    }

    const orderedMedia = value.media
      .sort((a, b) => a.order - b.order)
      .map((item: Media, index: number) => {
        if (item.order === null) item.order = index + 1;

        const newMedia = new FormControl(item);

        return newMedia;
      });

    this.form = new FormGroup({
      id: new FormControl(value.id, [Validators.required]),
      albumType: new FormControl(this.albumType, [Validators.required]),
      media: new FormArray(
        orderedMedia,
        this.albumType === MediaType.photo ? [Validators.required, Validators.min(4)] : [],
      ),
    });

    this.form.valueChanges.subscribe((data) => this.onChange(data));
    this.form.statusChanges.subscribe(() => this.onChange(this.form.value));
  }

  public get media(): FormArray {
    return this.form.get('media') as FormArray;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(_: AbstractControl): ValidationErrors | null {
    for (const media of this.form.value.media) {
      if (media.link === '' && media.uploaded !== true) {
        return { uploaded: false };
      }
    }
    if (this.form.invalid) {
      return { invalid: true };
    }
  }

  public deleteMedia(index: number): void {
    this.media.removeAt(index);
    this.reorderMedia();
  }

  public onPasteYoutubeLink(value: any): void {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i; // regex to detect url is valid or not
    if (urlPattern.test(value)) {
      this.isUrlValid = true;
      this.addMedia(value);
    } else {
      this.isUrlValid = false;
    }
  }

  public async addMedia(link?: string): Promise<void> {
    for (let i = 0; i < this.media.controls.length; i++) {
      const mediaFormStatus = this.media.controls[i]['status'];
      if (mediaFormStatus === 'INVALID') {
        this.media.removeAt(i);
      }
    }

    const media = plainToClass(Media, {
      id: v4(),
      mediaType: this.form.value.albumType,
      order: this.media.controls.length + 1,
      processed: false,
      uploaded: false,
      link: link || '',
    });

    this.media.push(
      new FormControl(media, [
        (control: AbstractControl) => {
          if (!control.value.link && !control.value.uploaded) {
            return { uploaded: false };
          }
          return null;
        },
      ]),
    );
  }

  public moveUp(index: number): void {
    if (index <= 0) return;

    const prevMediaOrder = this.media.at(index - 1).value.order;
    const currentMediaOrder = this.media.at(index).value.order;

    this.media.at(index).setValue(
      plainToClass(Media, {
        ...this.media.at(index).value,
        order: prevMediaOrder,
      }),
    );

    this.media.at(index - 1).setValue(
      plainToClass(Media, {
        ...this.media.at(index - 1).value,
        order: currentMediaOrder,
      }),
    );

    this.moveItemInFormArray(this.media, index, index - 1);
  }

  public moveDown(index: number): void {
    if (index >= this.media.length - 1) return;

    const nextMediaOrder = this.media.at(index + 1).value.order;
    const currentMediaOrder = this.media.at(index).value.order;

    this.media.at(index).setValue(
      plainToClass(Media, {
        ...this.media.at(index).value,
        order: nextMediaOrder,
      }),
    );

    this.media.at(index + 1).setValue(
      plainToClass(Media, {
        ...this.media.at(index + 1).value,
        order: currentMediaOrder,
      }),
    );

    this.moveItemInFormArray(this.media, index, index + 1);
  }

  public reorderMedia(): void {
    this.media.controls.forEach((mediaControl, index) => {
      mediaControl.setValue(
        plainToClass(Media, {
          ...this.media.at(index).value,
          order: index + 1,
        }),
      );
    });
  }

  private moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
  }
}
