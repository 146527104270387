import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { JobApplicationsService } from '@core/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IJobApplication } from '@core/interfaces';
import { Navigate } from '@ngxs/router-plugin';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm-job-applications-modal',
  templateUrl: './confirm-job-applications-modal.component.html',
  styleUrls: ['./confirm-job-applications-modal.component.scss'],
})
export class ConfirmJobApplicationModalComponent implements OnInit {
  @Input()
  public hash: string;

  public jobApplication: IJobApplication;
  public isProcess = true;
  public isError = false;

  public constructor(
    private jobApplicationsService: JobApplicationsService,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<ConfirmJobApplicationModalComponent>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.confirmJob();
  }

  public async confirmJob(): Promise<void> {
    try {
      this.jobApplication = await this.jobApplicationsService.confirmJobApplication(this.data.hash).toPromise();
    } catch (error) {
      console.error('Failed to confirm job application', error);
      this.isError = true;
    }

    this.isProcess = false;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async navigateToJobs(): Promise<void> {
    this.close();
    await this.router.navigate(['/find-jobs'], {
      relativeTo: this.getActiveRoute(),
    });
  }

  private getActiveRoute(): ActivatedRoute {
    let route = this.route;

    while (route.children?.length > 0) {
      route = route.firstChild;
    }

    return route;
  }
}
