import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-floating-footer',
  templateUrl: './mobile-floating-footer.component.html',
  styleUrls: ['./mobile-floating-footer.component.scss'],
})
export class MobileFloatingFooterComponent implements OnInit {
  @Input()
  public customActionImage = '';

  @Input()
  public usePreviousPath = false;

  @Input()
  public backUrl: string;

  @Input()
  public directButtonAction = false;

  @Input()
  public customBackButtonAction = false;

  @Input()
  public autoHide = true;

  @Input()
  public showExtraButton = false;

  @Output()
  public actionKeyPress = new EventEmitter();

  @Output()
  public backButtonKeyPress = new EventEmitter();

  @ViewChild('extraButton') private modalComponent: MobileModalDialogComponent;

  private hasPreviousUrl = false;

  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hasPreviousUrl = this.router.url !== '/';
    });
  }

  async openDialog() {
    if (!this.directButtonAction) {
      return await this.modalComponent.open();
    } else {
      this.actionKeyPress.emit('');
    }
  }

  public closeDialog() {
    this.modalComponent.close();
  }

  public goToPreviousPath() {
    if (this.customBackButtonAction) {
      this.backButtonKeyPress.emit('');
    } else {
      this.location.back();
    }
  }

  public goBackUrl() {
    this.router.navigateByUrl(this.backUrl);
  }
}
