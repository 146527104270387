<app-mobile-modal-dialog #claimTravelExpensesAccountNumber [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="modal-body-custom">
    <form [formGroup]="form" (ngSubmit)="submit()" class="success-create-job mobile-version">
      <div style="max-height: 80%; overflow: auto">
        <ol>
          <li translate>Bank Number</li>
          <li>
            <input type="text" class="form-control w-100" formControlName="bankNumber" />
          </li>
        </ol>
        <ol>
          <li translate>Name registered with bank</li>
          <li>
            <input type="text" class="form-control w-100" formControlName="accountName" />
          </li>
        </ol>
        <ol>
          <li translate>COC-number</li>
          <li>
            <input type="text" class="form-control w-100" formControlName="coc" />
          </li>
        </ol>
        <ol>
          <li translate>VAT-Number</li>
          <li>
            <input type="text" class="form-control w-100" formControlName="vat" />
          </li>
        </ol>
        <ol>
          <li translate>Are you KOR</li>
          <li>
            <div class="w-100 d-flex">
              <label (click)="changeKor(false)" class="mr-4 d-flex align-items-center">
                <app-radio [isReadOnly]="true" [value]="false" class="mr-2" formControlName="kor"></app-radio>
                <span translate>No</span>
              </label>
              <label (click)="changeKor(true)" class="d-flex align-items-center">
                <app-radio [isReadOnly]="true" [value]="true" class="mr-2" formControlName="kor"></app-radio>
                <span translate>Yes</span>
              </label>
            </div>
          </li>
        </ol>
        <div class="d-flex mt-3">
          <div class="mr-2">
            <img src="/assets/img/light.svg" style="width: 18px; height: 18px; position: relative; top: 3px" />
          </div>
          <p translate>If you do not have a Chamber of Commerce/VAT number, leave the fields empty</p>
        </div>
      </div>
      <div class="btn-wrap">
        <button type="submit" [disabled]="form.invalid">{{'Save Changes' | translate}}</button>
        <button type="button" (click)="close()">{{'Cancel' | translate}}</button>
      </div>
    </form>
  </div>
</app-mobile-modal-dialog>
