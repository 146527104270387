import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-language-modal',
  templateUrl: './change-language-modal.component.html',
  styleUrls: ['./change-language-modal.component.scss'],
})
export class ChangeLanguageModalComponent {
  public languages = [
    {
      code: 'nl',
      label: 'Nederlands',
    },
    {
      code: 'en',
      label: 'English',
    },
  ];

  constructor(
    private translateService: TranslateService,
    @Optional() private dialogRef: MatDialogRef<ChangeLanguageModalComponent>,
  ) {}

  public changeLanguage(language: any): void {
    this.translateService.use(language.code);
    localStorage.setItem('language', language.code.toLowerCase());
    this.dialogRef?.close();
  }
}
