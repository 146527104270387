import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { IFeeType } from '@core/interfaces/fee-type';
import { FeeType } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class FeeTypesService {
  public constructor(private http: HttpClient) {}

  public findAll(): Observable<IFeeType[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/fee-types`)
      .pipe(map((data: IFeeType[]) => data.map((item: FeeType) => plainToClass(FeeType, item))));
  }
}
