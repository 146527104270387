import { Component, OnInit } from '@angular/core';
import SwiperCore, { Pagination } from 'swiper';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-mobile-banner',
  templateUrl: './mobile-banner.component.html',
  styleUrls: ['./mobile-banner.component.scss'],
})
export class MobileBannerComponent implements OnInit {
  public swiper: SwiperCore;

  constructor() {}

  ngOnInit(): void {
    this.swiper = new SwiperCore('.swiper', {
      slidesPerView: 'auto',
      centeredSlides: true,
      // pagination: {
      //   clickable: true,
      //   el: ".swiper-paginate"
      // },
    });
  }

  public openExternalUrl(url: string): void {
    window.open(url, '_blank').focus();
  }
}
