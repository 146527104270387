import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Job, JobApplication } from '@core/models';

@Component({
  selector: 'app-small-job-card',
  templateUrl: './small-job-card.component.html',
  styleUrls: ['./small-job-card.component.scss'],
})
export class SmallJobCardComponent implements OnInit {
  @Input()
  public jobApplication: JobApplication;

  @Input()
  public job: Job;

  @Input()
  public themeIndex = 0;

  @Output()
  public jobApplicationEvent: EventEmitter<JobApplication> = new EventEmitter<JobApplication>();

  @Output()
  public jobEvent: EventEmitter<Job> = new EventEmitter<Job>();

  public themes = [
    'green-blue-bright',
    'purple-bright',
    'orange-bright',
    'orange-bright',
    'green-blue-bright',
    'purple-bright',
    'purple-bright',
    'orange-bright',
    'green-blue-bright',
  ];

  public ngOnInit(): void {
    if (this.jobApplication) {
      this.job = this.jobApplication?.job;
    }
  }

  public open(): void {
    this.jobApplicationEvent.emit(this.jobApplication);
    this.jobEvent.emit(this.job);
  }
}
