import { ICreditTransaction, IInvoice } from '@core/interfaces';
import { Payment } from './payment.model';
import { Transaction } from './transaction.model';
import { BaseModel } from './base-model.model';

import { Transform, Type } from 'class-transformer';
import * as moment from 'moment';
import { InvoiceText } from '@core/models/invoice-text.model';
import { CreditTransaction } from './credit-transaction.model';

export class Invoice extends BaseModel implements IInvoice {
  @Type(() => Payment)
  public payment?: Payment;

  @Type(() => Transaction)
  public transaction?: Transaction;

  @Type(() => InvoiceText)
  public invoiceText?: InvoiceText;

  @Type(() => CreditTransaction)
  public creditTransaction?: ICreditTransaction;

  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;

  public id: string;
  public bookingsType: string;
  public bookingsNumber: number;
  public invoiceNumber?: string;

  get invoiceEndpointType(): string {
    if (this.bookingsType === 'CB' || this.bookingsType === 'TC') {
      return 'booking-invoice';
    }
    if (this.bookingsType === 'CS' || this.bookingsType === 'CR') {
      return 'service-invoice';
    }
    return '';
  }

  get title(): string {
    if (this.payment != null) {
      if (this.payment.jobApplication != null) {
        return this.payment.jobApplication.job.title;
      }
      if (this.payment.job != null) {
        return this.payment.job.title;
      }
      if (this.payment.creditTransaction?.package != null) {
        return `Buy ${this.payment.creditTransaction.package.name}`;
      }
    }
    if (this.transaction != null) {
      return this.transaction.transactionDetails.products[0].name;
    }
    return 'unknown';
  }

  get service(): string {
    if (this.payment != null) {
      if (this.payment.paymentType?.name === 'TravelCosts') {
        return 'Travel costs';
      }
      if (this.payment.jobApplication != null) {
        return 'Paid job';
      }
      return 'Casterbee service';
    }
    if (this.transaction != null) {
      return 'Casterbee service';
    }
  }

  get paymentStatus(): string {
    if (this.payment != null) {
      if (['chargeback', 'refunded'].includes(this.payment.paymentStatus)) {
        return 'PAYMENT_STATUS.refunded';
      }
      if (['pending', 'reserved'].includes(this.payment.paymentStatus)) {
        return 'PAYMENT_STATUS.reserved';
      }
      if (['released', 'paid', 'completed'].includes(this.payment.paymentStatus)) {
        return 'PAYMENT_STATUS.paid';
      }
      return 'PAYMENT_STATUS.error';
    }
    return 'PAYMENT_STATUS.paid';
  }

  get paymentColor(): string {
    if (this.paymentStatus === 'PAYMENT_STATUS.refunded') {
      return 'blue';
    }
    if (this.paymentStatus === 'PAYMENT_STATUS.reserved') {
      return 'gray';
    }
    if (this.paymentStatus === 'PAYMENT_STATUS.paid') {
      return 'green';
    }
    return 'red';
  }

  get formattedBookingsNumber(): string {
    return `${this.bookingsType}${`${this.bookingsNumber}`.padStart(4, '0')}`;
  }

  get invoiceDate(): Date {
    if (this.transaction != null) {
      return this.transaction.date;
    }
    return this.created;
  }
}
