import { ITravelExpense, TravelExpenseType } from '@core/interfaces';
import { Transform, TransformFnParams, Type } from 'class-transformer';
import { JobApplication } from '@core/models/job-application.model';

export class TravelExpense implements ITravelExpense {
  @Type(() => JobApplication)
  public jobApplication: JobApplication;
  @Transform((jobStatus: TransformFnParams) => TravelExpenseType[jobStatus.value])
  public type: TravelExpenseType;

  public id: string;
  public jobApplicationId: string;
  public description?: string;
  public distance?: number;
  public time?: number;
  public price: number;
  public currency: string;
  public paid: boolean;

  get summary(): string {
    if (this.type === TravelExpenseType.kilometers) {
      return `${this.description}, ${this.distance} km`;
    }
    if (this.type === TravelExpenseType.parking) {
      return `${this.description}, ${this.time} hours`;
    }
    return this.description;
  }
}
