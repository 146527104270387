import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Job, JobApplication } from '@core/models';
import { Select, Store } from '@ngxs/store';
import {
  CancelJobApplication,
  ConfirmJobApplication,
  FetchJobApplication,
  InitiateChat,
  JobsState,
  RejectJobApplication,
} from '@core/states';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MessagingModalComponent } from '@src/ui/messaging/components/messaging-modal/messaging-modal.component';
import { ReportUserModalComponent } from '@src/visitor/modals/components/report-user-modal/report-user-modal.component';
import { ReviewComponent } from '@shared/components';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UploadAuditionVideoComponent } from '@src/shared/components/job-applications/upload-audition-video/upload-audition-video.component';
import { RejectBookingComponent } from '@src/account/job-applications/modals/reject-booking/reject-booking.component';
import { CancelBookingComponent } from '@src/account/job-applications/modals/cancel-booking/cancel-booking.component';
import { Router } from '@angular/router';
import { JobApplicationStatus } from '@core/interfaces';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-job-application-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class JobApplicationDetailsComponent implements OnInit {
  @Output()
  public cancelClicked: EventEmitter<any> = new EventEmitter();

  @Input()
  public jobApplicationId?: string;

  @Select(JobsState.jobApplication)
  public jobApplication$: Observable<JobApplication>;

  public jobFilters: any[] = [];
  public mobileVersion: boolean;

  public constructor(
    @Optional() private dialogRef: MatDialogRef<JobApplicationDetailsComponent>,
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) @Optional() private data?: any,
  ) {
    if (window.innerWidth <= 500) {
      this.mobileVersion = true;
    } else {
      this.mobileVersion = false;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.jobApplicationId ??= this.data?.jobApplicationId;
    this.store.dispatch(new FetchJobApplication(this.jobApplicationId));

    if (this.mobileVersion) {
      const jobApplication = await this.jobApplication$
        .pipe(
          filter((j: JobApplication) => !!j),
          take(1),
        )
        .toPromise();

      this.router
        .navigate([`/account/job-applications/${jobApplication.status}`], {
          queryParams: {
            action: 'openDetailApplicant',
            applicantID: jobApplication.id,
            jobID: jobApplication.jobId,
          },
        })
        .then(() => {
          this.closeModal();
          window.location.reload();
        });
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public isVideoRequested(jobApplication: JobApplication): boolean {
    return jobApplication.job.requestTalentVideo;
  }

  public async uploadJobApplicationVideo(jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(UploadAuditionVideoComponent, {
      data: {
        job: jobApplication.job,
        jobApplication,
      },
    });
    await dialogRef.afterClosed().toPromise();
  }

  public async acceptJob(jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        jobApplication,
      },
    });
    await dialogRef.afterClosed().toPromise();
    this.closeModal();
  }

  public async confirmJob(jobApplication: JobApplication): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to confirm your application?').toPromise();
    if (confirm(translation)) {
      await this.store.dispatch(
        new ConfirmJobApplication(jobApplication.user.id, jobApplication.job.id, jobApplication.id),
      );
      this.closeModal();
    }
  }

  public async cancelJobApplication(jobApplication: JobApplication): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to cancel your application?').toPromise();
    if (confirm(translation)) {
      await this.store.dispatch(
        new CancelJobApplication(jobApplication.user.id, jobApplication.job.id, jobApplication.id),
      );
      this.closeModal();
    }
  }

  public async rejectJob(jobApplication: JobApplication): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to reject this job?').toPromise();
    if (confirm(translation)) {
      await this.store.dispatch(
        new RejectJobApplication(jobApplication.user.id, jobApplication.job.id, jobApplication.id),
      );
      this.closeModal();
    }
  }

  public openChatModal(jobApplication: JobApplication): boolean {
    this.store.dispatch(new InitiateChat(jobApplication.user.id, jobApplication.job.user.id));
    this.dialog.open(MessagingModalComponent, {
      data: {
        userId: jobApplication.user.id,
        recipientId: jobApplication.job.user.id,
      },
    });
    return false;
  }

  public async openReportJob(jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(ReportUserModalComponent, {
      data: {
        talentId: jobApplication.user.id,
        jobId: jobApplication.jobId,
      },
    });
    await dialogRef.afterClosed().toPromise();
  }

  public async review(job: Job, jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(ReviewComponent, {
      data: {
        reviews: job.reviews,
        jobId: job.id,
        user: job.user,
        isTalent: false,
      },
    });
    await dialogRef.afterClosed().toPromise();

    await this.store.dispatch(new FetchJobApplication(jobApplication.id)).toPromise();
  }

  public async claimTravelCosts(jobApplication: JobApplication): Promise<void> {
    this.closeModal();
    await this.dialogRef.afterClosed().toPromise();
    await this.router.navigate(['/account/job-applications/details', jobApplication.id, 'travel-expenses']);
  }

  public async rejectBooking(jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(RejectBookingComponent, {
      data: {
        jobId: jobApplication.jobId,
        jobApplicationId: jobApplication.id,
        userId: jobApplication.user.id,
      },
    });

    await dialogRef.afterClosed().toPromise();
    this.closeModal();
  }

  public async cancelBooking(jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(CancelBookingComponent, {
      data: {
        jobId: jobApplication.jobId,
        jobApplicationId: jobApplication.id,
        userId: jobApplication.user.id,
      },
    });

    await dialogRef.afterClosed().toPromise();
    this.closeModal();
  }

  public showTravelCosts(jobApplication: JobApplication): boolean {
    return (
      [JobApplicationStatus.travelCostRejected, JobApplicationStatus.completed].includes(jobApplication.status) ||
      (jobApplication.status === JobApplicationStatus.confirmed && jobApplication.job.completed) ||
      jobApplication.tcEmailTalentSent
    );
  }
}
