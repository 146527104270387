import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-trashbin',
  templateUrl: './trashbin.component.html',
  styleUrls: ['./trashbin.component.scss'],
})
export class TrashbinComponent {
  public hover: boolean;

  public constructor() {
    this.hover = false;
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.hover = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.hover = false;
  }
}
