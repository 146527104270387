import { Transform, Type } from 'class-transformer';
import { IUserReport } from '@core/interfaces';
import { BaseModel } from './base-model.model';
import { User } from './user.model';
import { Job } from './job.model';

import * as moment from 'moment';

export class UserReport extends BaseModel implements IUserReport {
  @Type(() => User)
  public user: User;
  @Type(() => User)
  public talent: User;
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;
  @Type(() => Job)
  public job: Job;
  public id: string;
  public comment: string;
  public status: string;
}
