import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-unsubscribe-page-component',
  templateUrl: './unsubscribe.component.html',
})
export class UnsubscribeComponent implements OnDestroy {
  public ngDestroyed$ = new Subject();

  public ngOnDestroy(): void {
    this.ngDestroyed$.next();
  }
}
