import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import { FormsModule } from '../generic/forms/forms.module';
import { IconsModule } from '../generic/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';

const components = [SearchComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, FormsModule, IconsModule, TranslateModule],
  exports: components,
})
export class SearchModule {}
