<ng-container *ngIf="settlements?.data?.length">
  <h2 class="h5 pb-4" translate>Payments</h2>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th style="min-width: 120px" translate>Period</th>
          <th translate>Total amount</th>
          <th translate>Total fees</th>
          <th translate>Total paid</th>
          <th translate>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let settlement of settlements.data">
          <tr>
            <td>{{ { start: settlement.period_start * 1000, end: settlement.period_end * 1000 } | dateRange }}</td>
            <td>{{ settlement.total_volume / 100 | currency }}</td>
            <td>
              {{ (settlement.total_transaction_costs + settlement.total_order_fees + settlement.total_refund_fees) / 100
              | currency }}
            </td>
            <td>{{ settlement.amount_payable / 100 | currency }}</td>
            <td translate>{{ 'SETTLEMENT_STATUS.' + settlement.status }}</td>
            <td translate>
              <a (click)="getSettlementSpecifications(settlement.uid)">
                <ng-container *ngIf="settlement.uid != settlementUid" translate>Show transactions</ng-container>
                <ng-container *ngIf="settlement.uid == settlementUid" translate>Hide transactions</ng-container>
              </a>
            </td>
          </tr>
          <tr *ngIf="settlementUid == settlement.uid">
            <td colspan="6" class="px-0">
              <table class="table bg-gray rounded" *ngIf="settlementRows$ | async as settlementRows; else loading">
                <thead>
                  <tr>
                    <th style="min-width: 120px" class="border-top-0" translate>Date</th>
                    <th class="border-top-0" translate>Description</th>
                    <th class="border-top-0" translate>Amount</th>
                    <th class="border-top-0" translate>Fee</th>
                    <th class="border-top-0" translate>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let settlementRow of settlementRows.data">
                    <td>{{ settlementRow.date * 1000 | date: 'dd-MM-YYYY HH:mm' }}</td>
                    <td *ngIf="settlementRow.type == 'refund'">Refunded</td>
                    <td *ngIf="settlementRow.type != 'refund'">{{ settlementRow.description }}</td>
                    <td>{{ settlementRow.volume / 100 | currency }}</td>
                    <td>{{ (settlementRow.volume - settlementRow.amount_payable) / 100 | currency }}</td>
                    <td>{{ settlementRow.amount_payable / 100 | currency }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <ng-template #loading>
    <app-spinner style="max-width: 50px; margin: 0 auto"></app-spinner>
  </ng-template>
</ng-container>
