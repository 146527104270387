import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IJob } from '@core/interfaces/job';
import { JobApplication, PaginatedResults } from '@core/models';
import { JobsState } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import SwiperCore from 'swiper';
import { MobileJobDetailComponent } from '../mobile-job-detail/mobile-job-detail.component';

@Component({
  selector: 'app-mobile-job-slide',
  templateUrl: './mobile-job-slide.component.html',
  styleUrls: ['./mobile-job-slide.component.scss'],
})
export class MobileJobSlideComponent implements OnInit {
  @ViewChild('detailJobModal') detailJobModal: MobileJobDetailComponent;

  @Input()
  jobs: IJob[];

  @Select(JobsState.jobApplications)
  public userJobs$: Observable<PaginatedResults<JobApplication>>;

  public swiper: SwiperCore;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.swiper = new SwiperCore('.swiper', {
      slidesPerView: 'auto',
      observer: true,
    });
  }

  public async jobDetail(selectedJob: IJob): Promise<void> {
    this.detailJobModal.openDialog(selectedJob);
  }

  /**
   * Check if the logged in user has already applied to the job
   */
  public get userAlreadyApplied$(): Observable<boolean> {
    const job = this.store.selectSnapshot(JobsState.job);
    return this.userJobs$.pipe(
      filter<PaginatedResults<JobApplication>>((jobs: PaginatedResults<JobApplication>) => jobs !== null),
      map<PaginatedResults<JobApplication>, JobApplication[]>((jobs: PaginatedResults<JobApplication>) => jobs.results),
      map<JobApplication[], JobApplication[]>((jobs: JobApplication[]) =>
        jobs.filter((jobApplication: JobApplication) => jobApplication.jobId === job.id),
      ),
      map<JobApplication[], boolean>((jobs: JobApplication[]) => jobs.length > 0),
    );
  }
}
