<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #appMobileTalentContactModal>
  <div class="modal-body text-center mobile-talent-contact-modal-container">
    <ng-container *ngIf="contactModal === contactModalType.CONFIRMATION">
      <h4>Are you sure want to see contact details</h4>
      <div class="group-action-button">
        <button>Cancel</button>
        <button (click)="confirmContactDetails()">Yes</button>
      </div>
    </ng-container>
    <ng-container *ngIf="contactModal === contactModalType.REQUEST_DOCUMENTED">
      <div class="request-documented-container">
        <img class="request-documented-image" src="/assets/img/mobile/documented.svg" alt="" />
        <p class="request-documented">Request documented</p>
      </div>
    </ng-container>
    <ng-container *ngIf="contactModal === contactModalType.CONTACT_DETAILS">
      <div class="contact-details-container">
        <p>{{talent.profileName}}</p>
        <p *ngIf="talent?.phonenumberWithCallingCode">
          <img src="/assets/img/mobile/talent-contact-phone.svg" alt="" />
          {{talent?.phonenumberWithCallingCode}}
        </p>
        <p>
          <img src="/assets/img/mobile/talent-contact-email.svg" alt="" />
          -
          <!-- blabalbla@gmail.com -->
        </p>
        <!-- <p>
          <img src="/assets/img/mobile/talent-contact-insta.svg" alt="">
          Insagram link
        </p> -->
      </div>
    </ng-container>
  </div>
  <!-- <div class="modal-body text-center mobile-talent-contact-modal-container">
    <h4>
      100% Free
      <br />
      offer assignment
    </h4>
    <p>
      Only the fee excluding VAT will be invoiced
      <br />
      <br />
      It is not allowed outside Casterbee to book
      <br />
      <br />
      Stay safe, keep us informed
    </p>
    <div class="user-frame">
      <p>Can we help?</p>
      <div class="talent-bio">
        <p>
          {{talent.fullName}}
          <br />
          <br />
          {{talent.phonenumber}}
          <br />
          <br />
          {{talent.email}}
        </p>
      </div>
    </div>
    <div class="text-center mb-3">
      <button class="btn-extra btn-bg-purple">Understood</button>
    </div>
  </div> -->
</app-mobile-modal-dialog>
