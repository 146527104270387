import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { SpinnersModule } from '../spinners/spinners.module';
import { GradientsModule } from '../gradients/gradients.module';
import { BookmarkComponent } from '@src/ui/generic/buttons/components/bookmark/bookmark.component';
import { ToggleButtonComponent } from '@src/ui/generic/buttons/components/toggle-button/toggle-button.component';
import { TrashbinComponent } from './components/trashbin/trashbin.component';
import { IconsModule } from '@src/ui/generic/icons/icons.module';
import { SolidButtonComponent } from './components/solid-button/solid-button.component';

@NgModule({
  declarations: [ButtonComponent, BookmarkComponent, ToggleButtonComponent, TrashbinComponent, SolidButtonComponent],
  imports: [CommonModule, SpinnersModule, GradientsModule, IconsModule],
  exports: [ButtonComponent, BookmarkComponent, ToggleButtonComponent, TrashbinComponent, SolidButtonComponent],
})
export class ButtonsModule {}
