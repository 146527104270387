import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-similar-talents-overview',
  templateUrl: './similar-talents-overview.component.html',
  styleUrls: ['./similar-talents-overview.component.scss'],
})
export class SimilarTalentsOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
