import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-languange-modal',
  templateUrl: './mobile-languange-modal.component.html',
  styleUrls: ['./mobile-languange-modal.component.scss'],
})
export class MobileLanguangeModalComponent implements OnInit {
  @ViewChild('appLanguangeModal') private modalComponent: MobileModalDialogComponent;

  public languages = [
    {
      code: 'nl',
      label: 'Nederlands',
    },
    {
      code: 'en',
      label: 'English',
    },
  ];
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  async openDialog() {
    return await this.modalComponent.open();
  }

  async close() {
    return await this.modalComponent.close();
  }

  public currentLanguage(): string {
    return this.translateService.currentLang.toUpperCase();
  }

  public changeLanguage(language: any): void {
    this.translateService.use(language.code);
    localStorage.setItem('language', language.code.toLowerCase());
    this.modalComponent.close();
  }
}
