import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Job, JobApplication } from '@core/models';
import { Store } from '@ngxs/store';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { CasterCancelBookingJobApplication, RejectJobApplications, SendBulkMessage } from '@core/states';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-send-bulk-message-modal',
  templateUrl: './mobile-send-bulk-message-modal.component.html',
  styleUrls: ['./mobile-send-bulk-message-modal.component.scss'],
})
export class MobileSendBulkMessageModalComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public jobDetail?: Job;

  @ViewChild('appCancelBookingModal') private modalComponent: MobileModalDialogComponent;

  public applicants: Array<JobApplication> = [];
  public isBusy = false;
  public isSuccess = false;
  public form = new FormGroup({
    message: new FormControl(null, [Validators.required]),
  });

  constructor(private store: Store, private translate: TranslateService) {}

  ngOnInit(): void {}

  async openDialog(applicants: Array<JobApplication>) {
    if (!applicants.length) {
      const failTranslation = await this.translate.get('Select at least 1 talent').toPromise();
      alert(failTranslation);
      return;
    }

    this.applicants.push(...applicants);
    return await this.modalComponent.open().then(() => {
      this.applicants = [];
      this.isSuccess = false;
    });
  }

  async close() {
    return await this.modalComponent.close();
  }

  public async submit(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(
        new SendBulkMessage(
          this.jobDetail.id,
          this.applicants.map((a: JobApplication) => a.id),
          this.form.value.message,
        ),
      )
      .toPromise();
    this.isBusy = false;
    this.isSuccess = true;
  }
}
