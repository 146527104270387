import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  DeleteReview,
  FindReview,
  FindReviewByJob,
  SaveReview,
  LoadReviews,
  DeleteAdminReview,
} from '@core/states/reviews/reviews.actions';
import { ReviewsStateModel } from '@core/states/reviews/reviews.state-model';
import { ReviewsService } from '@core/services/reviews.service';
import { Review, PaginatedResults } from '@core/models';
import { removeItem } from '@ngxs/store/operators';

@State<ReviewsStateModel>({
  name: 'reviews',
  defaults: {
    reviews: null,
    review: null,
  },
})
@Injectable()
export class ReviewsState {
  public constructor(private reviewsService: ReviewsService) {}

  @Selector()
  static review(state: ReviewsStateModel): Review {
    return state.review;
  }

  @Selector()
  static reviews(state: ReviewsStateModel): PaginatedResults<Review> {
    return state.reviews;
  }

  @Action(LoadReviews)
  public async loadPendingVerifications(
    ctx: StateContext<ReviewsStateModel>,
    { page, pageSize, sortColumn, sortDirection }: LoadReviews,
  ): Promise<void> {
    const reviews = await this.reviewsService.findAll(page, pageSize, sortColumn, sortDirection).toPromise();
    ctx.patchState({ reviews });
  }

  @Action(FindReview)
  public async findReview(state: StateContext<ReviewsStateModel>, { talentId }: FindReview): Promise<void> {
    const review = await this.reviewsService.getReviewByTalent(talentId).toPromise();
    state.patchState({ review });
  }

  @Action(FindReviewByJob)
  public async findReviewByJob(
    state: StateContext<ReviewsStateModel>,
    { talentId, reviewerId, jobId }: FindReviewByJob,
  ): Promise<void> {
    const review = await this.reviewsService.getReviewByJob(talentId, reviewerId, jobId).toPromise();
    state.patchState({ review });
  }

  @Action(SaveReview)
  public async saveReview(state: StateContext<ReviewsStateModel>, { review }: SaveReview): Promise<void> {
    review = await this.reviewsService.upsert(review).toPromise();
    state.patchState({ review });
  }

  @Action(DeleteReview)
  public async deleteReview(state: StateContext<ReviewsStateModel>, { id }: DeleteReview): Promise<void> {
    await this.reviewsService.delete(id).toPromise();
    state.patchState({ review: null });
  }

  @Action(DeleteAdminReview)
  public async deleteAdminReview(
    state: StateContext<ReviewsStateModel>,
    { id, page, pageSize, sortColumn, sortDirection }: DeleteAdminReview,
  ): Promise<void> {
    await this.reviewsService.delete(id).toPromise();
    state.dispatch(new LoadReviews(page, pageSize, sortColumn, sortDirection));
  }
}
