<div class="w-100" [ngClass]="{ invalid: albums.controls.length == 0, 'd-flex': albums.controls.length == 0 }">
  <ng-container *ngFor="let album of albums.controls; let i = index">
    <ng-container *ngIf="modalType === 'photo'">
      <app-mobile-account-profile-album-media
        *ngIf="album.value.albumType === 'photo'"
        class="d-flex w-100"
        (delete)="deleteAlbum(i)"
        [formControl]="album"
        [albumType]="modalType"
        [user]="user"></app-mobile-account-profile-album-media>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="modalType === 'video'">
    <ng-container *ngFor="let album of albums.controls; let i = index">
      <app-mobile-account-profile-album-media
        *ngIf="album.value.albumType === 'video'"
        class="d-flex w-100"
        (delete)="deleteAlbum(i)"
        [formControl]="album"
        [albumType]="modalType"
        [user]="user"></app-mobile-account-profile-album-media>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="modalType === 'video'">
    <ng-container *ngFor="let album of albums.controls; let i = index">
      <app-mobile-account-profile-album-media
        *ngIf="album.value.albumType === 'video'"
        class="d-flex w-100"
        (delete)="deleteAlbum(i)"
        [formControl]="album"
        [albumType]="modalType"
        [user]="user"></app-mobile-account-profile-album-media>
    </ng-container>
  </ng-container>
</div>
