<app-mobile-modal-dialog #appWithoutTravelCostModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <ng-container *ngIf="!isSuccess; else success">
    <div class="modal-body">
      <div style="margin-top: 20px">
        <h4 class="text-center">{{"Are you sure there were no travel costs?" | translate}}</h4>
      </div>
    </div>
    <div class="modal-footer">
      <div class="wrap-btn mb-2">
        <button
          type="button"
          class="btn-submit"
          style="border-radius: 100px; margin: auto auto 2px auto; width: 182px"
          (click)="agree()">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ 'Yes' | translate }}</span>
        </button>
      </div>
      <button type="button" (click)="close()" class="btn-submit btn-bg-gray">{{'Cancel' | translate}}</button>
    </div>
  </ng-container>
  <ng-template #success>
    <div class="modal-body">
      <h4 class="text-center">{{"Your travel costs have been recorded as zero" | translate}}</h4>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
    </div>
  </ng-template>
</app-mobile-modal-dialog>
