import { AfterViewChecked, Component, ContentChildren, forwardRef, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxGroupComponent implements ControlValueAccessor, AfterViewChecked {
  @ContentChildren(CheckboxComponent, { descendants: true })
  public checkboxes: QueryList<CheckboxComponent>;

  public value: any[];

  private isInitialized = false;

  @Input()
  public matcher = (checkbox: CheckboxComponent) => {
    if (!this.value) {
      return;
    }

    return this.value.find((c: CheckboxComponent) => c.value === checkbox.value);
  };

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewChecked(): void {
    this.initialize(this.checkboxes);
    this.checkboxes.changes.subscribe((data) => this.initialize(data));
  }

  public initialize(data: QueryList<CheckboxComponent>): void {
    if (this.isInitialized || !data || data.length === 0) {
      return;
    }

    this.isInitialized = true;
    setTimeout(() => {
      const newValue = [];
      data.forEach((checkbox: CheckboxComponent, _: number) => {
        checkbox.registerOnChange(() => this.onCheckboxChanged(checkbox));

        const existingValue = this.matcher(checkbox);
        newValue.push({
          value: checkbox.value,
          checked: existingValue?.checked || checkbox.checked,
        });

        checkbox.writeValue(existingValue?.checked || false);
      });

      this.value = newValue;
    });
  }

  public writeValue(value: any): void {
    this.value = value;
    this.isInitialized = false;
    this.initialize(this.checkboxes);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onCheckboxChanged(checkbox: CheckboxComponent): void {
    const existingValue = this.matcher(checkbox);
    existingValue.checked = checkbox.checked;
    this.onChange(this.value);
  }
}
