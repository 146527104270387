import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './components/video/video.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { IconsModule } from '../icons/icons.module';

const modules = [VgCoreModule, VgControlsModule, VgOverlayPlayModule, VgBufferingModule];

@NgModule({
  declarations: [VideoComponent],
  imports: [CommonModule, IconsModule, ...modules],
  exports: [VideoComponent, ...modules],
})
export class VideosModule {}
