import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input()
  public options: Options;

  @ViewChild('slider')
  public slider: any;

  @Input()
  public slidersRefresh: EventEmitter<void> = new EventEmitter<void>();

  public effectiveOptions: Options;

  public value = 0;
  public highValue: number;

  protected defaultOptions: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
  };

  public ngOnInit(): void {
    this.effectiveOptions = { ...this.defaultOptions, ...this.options };
  }

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngOnChanges(changes: SimpleChanges): void {
    if ('options' in changes) {
      this.effectiveOptions = { ...this.defaultOptions, ...changes.options.currentValue };
    }
  }

  public onUserChangeEnd(changeContext: ChangeContext): void {
    if (this.highValue != null) {
      if (changeContext.value !== this.value || changeContext.highValue !== this.highValue) {
        this.onChange([changeContext.value, changeContext.highValue]);
      }
    } else {
      this.onChange(changeContext.value);
    }
  }

  public onUserChange(changeContext: ChangeContext): void {}

  public writeValue(value: any): void {
    if (Array.isArray(value)) {
      if (value[0] == null || value[1] == null) {
        value = [this.effectiveOptions.floor, this.effectiveOptions.ceil];
      }
      const finalValue = [...value].sort((a, b) => a - b);
      this.value = Number.parseInt(finalValue[0], 10);
      this.highValue = Number.parseInt(finalValue[1], 10);
    } else {
      this.value = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
