import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';

export class SubformsHelper {
  private subforms = {
    gender: new FormGroup({
      male: new FormControl(null, []),
      female: new FormControl(null, []),
      transgender: new FormControl(null, []),
    }),
    age: new FormGroup({
      age: new FormControl([null, null], []),
    }),
    rate: new FormGroup({
      rate: new FormControl([null, null], []),
      tfp: new FormControl(null, []),
      xchange: new FormControl(null, []),
    }),
    location: new FormGroup({
      description: new FormControl(null, [Validators.required]),
      x: new FormControl(null, [Validators.required]),
      y: new FormControl(null, [Validators.required]),
      distance: new FormControl('0', [Validators.required]),
    }),
    language: new FormGroup({
      isoCode: new FormControl(null),
    }),
  };

  public getSubforms(): any {
    return this.subforms;
  }

  public getSubform(name: string): any {
    return this.subforms[name];
  }

  public patchSubform(name: string, data: any, fieldName?: string): any {
    fieldName = fieldName || name;
    const form = this.getSubform(name);
    if (name === 'gender' && typeof data === 'object') {
      form.patchValue(
        {
          male: data?.male === 'true',
          female: data?.female === 'true',
        },
        { emitEvent: false },
      );
      return;
    }

    if (name === 'location' && typeof data === 'object') {
      form.patchValue(
        {
          description: data?.description,
          x: data?.x,
          y: data?.y,
          distance: data?.distance || '0',
        },
        { emitEvent: false },
      );
      return;
    }
    if (name === 'language' && typeof data === 'object') {
      form.patchValue(
        {
          id: data?.id,
          translateKey: data?.translateKey,
        },
        { emitEvent: false },
      );
      return;
    }

    form.patchValue(
      {
        [fieldName]: data,
      },
      { emitEvent: false },
    );

    form.updateValueAndValidity();
  }
}
