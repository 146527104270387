import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-daterange-field',
  templateUrl: './daterange-field.component.html',
  styleUrls: [
    '../text-field/text-field.component.scss',
    './daterange-field.component.scss',
    '../time-field/time-field.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeFieldComponent),
      multi: true,
    },
  ],
})
export class DateRangeFieldComponent implements ControlValueAccessor {
  @Input()
  public isReadOnly = false;

  @Input()
  public iconColor = 'white';

  @Input()
  public range: FormGroup = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public writeValue(value: any): void {
    if (value) {
      this.range.setValue(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.range.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
