import { Component, Input } from '@angular/core';
import { ListResults, TransactionDTO } from '@core/dto';
import { IUser } from '@core/interfaces';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
})
export class TransactionsListComponent {
  @Input()
  public transactions: ListResults<TransactionDTO>;

  @Input()
  public user: IUser;

  public constructor(private store: Store) {}
}
