<div class="mobile-talent-photoalbum-container">
  <div class="mobile-talent-detail-bio">
    <div class="img-profile">
      <ng-container *ngIf="!profilePic; else showProfilePic">
        <img src="/assets/img/mobile/default-profile-picture.png" alt="" />
      </ng-container>
      <ng-template #showProfilePic>
        <app-mobile-image-preview [image]="profilePic"></app-mobile-image-preview>
      </ng-template>
    </div>
    <div class="bio-desc">
      <p>
        {{profile.user.profileName}}
        <img *ngIf="profile.user.hasBadge" src="/assets/img/mobile/badge.svg" alt="" />
      </p>
      <p *ngIf="profile.rate">
        <span translate>from</span>
        <span class="text-bold">&nbsp;€ {{profile.rate}}</span>
        p/d
      </p>
      <p>
        <ng-container *ngFor="let role of profiles; let i = index">
          <ng-container *ngIf="i >= 1">,</ng-container>
          <span translate>{{role.category?.translateKeySingular}}</span>
        </ng-container>
      </p>
      <p>
        <span translate>
          <ng-container *ngIf="profile.user?.city">{{profile.user?.city}},</ng-container>
          {{profile.user.country.isoCode}}
        </span>
      </p>
      <p>
        <ng-container *ngFor="let language of profile.user.languages; let i = index">
          <span *ngIf="i >= 1">, &nbsp;</span>
          <span translate>{{language.translateKey}}</span>
          <span *ngIf="i === 0">&nbsp;(Native)</span>
        </ng-container>
      </p>
      <p *ngIf="profile.user.averageReview">
        <img src="/assets/img/mobile/ratting.svg" alt="" />
        <small class="average mx-1 my-auto">{{ profile.user.averageReview | number: '1.1-1' }}</small>
        <small *ngIf="profile.user.totalReviews" class="count my-auto">({{ profile.user.totalReviews }})</small>
      </p>
    </div>
  </div>

  <ng-content select="[profiles]"></ng-content>

  <div class="mobile-talent-detail-breadcrumb">
    <span
      *ngFor="let breadrumb of breadcrumbTabList"
      [class.active]="breadrumb === activeBreadcrumbTab"
      (click)="onSelectBreadcrumb(breadrumb)">
      {{breadrumb.label}}
    </span>
  </div>

  <div
    *ngIf="activeBreadcrumbTab.label === 'Photos' || activeBreadcrumbTab.label === 'Videos'"
    [@inOutAnimation]
    class="photoalbum-container"
    [style.height.px]="photoalbumContainerHeight"
    appAutoAnimate>
    <div class="photoalbum-item" #photoContainer *ngFor="let media of items; let i = index">
      <ng-container *ngIf="media.mediaType == activeBreadcrumbTab.key">
        <div class="photoalbum-round-container">
          <app-mobile-image-preview (click)="openMedia(media, i)" [image]="media.thumbUrl"></app-mobile-image-preview>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="activeBreadcrumbTab.label === 'About'" [@inOutAnimation]>
    <app-mobile-talent-about [profile]="profile"></app-mobile-talent-about>
  </div>
  <div *ngIf="activeBreadcrumbTab.label === 'Voice'">
    <app-mobile-account-profile-voice
      [profile]="profile"
      (updateProfile)="updateProfileOnVoiceChange($event)"></app-mobile-account-profile-voice>
  </div>
</div>
