<div [formGroup]="form" *ngIf="form" class="w-100 rounded-sm mt-2 px-3 error-border" [ngClass]="albumType">
  <div class="row">
    <div class="col-12">
      <div>{{ 'maxAlbumItems' | translate: { max: maxItems } }}</div>
      <div class="d-flex d-sm-flex">
        <app-solid-button
          *ngIf="media.controls.length < maxItems"
          (btnClick)="addMedia()"
          type="button"
          buttonStyle="purple">
          {{ "Add " + albumType | translate }}
        </app-solid-button>
        <app-solid-button class="ml-2" (btnClick)="delete.emit(null)" type="button" buttonStyle="dark-gunmetal">
          {{ "Delete album" | translate }}
        </app-solid-button>
      </div>
    </div>

    <div #mediaList class="col-12 d-flex flex-wrap mt-4" formArrayName="media">
      <app-media-item-field
        *ngFor="let media of media.controls; let i = index"
        (delete)="deleteMedia(i)"
        (moveUp)="moveUp(i)"
        (moveDown)="moveDown(i)"
        [albumId]="form.value.id"
        [formControl]="media"
        [isProfilePhoto]="i === 0 && albumType === 'photo'"
        [allowSorting]="true"
        class="d-flex"></app-media-item-field>
    </div>
  </div>
</div>
