import { Transform, Type } from 'class-transformer';
import { IJob, IReview, IUser } from '@core/interfaces';
import { Job, User } from '@core/models';
import * as moment from 'moment';

export class Review implements IReview {
  @Type(() => User)
  public talent: IUser;

  @Type(() => User)
  public reviewer: IUser;

  @Type(() => Job)
  public job: IJob;

  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;

  public id: string;
  public overallRating: number;
  public communication: number;
  public attitude: number;
  public comment: string;

  public reviewerId: string;
  public talentId: string;
  public jobId: string;
}
