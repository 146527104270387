import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { FetchCredit, FetchCreditPackages } from './credit.actions';
import { CreditStateModel } from './credit.state-model';
import { CreditService } from '@core/services/credit.service';
import { Credit, CreditPackage } from '@core/models';

@State<CreditStateModel>({
  name: 'credit',
  defaults: {
    credit: null,
    packages: [],
    isCreditLoaded: false,
  },
})
@Injectable()
export class CreditState {
  public constructor(private creditService: CreditService) {}

  @Selector()
  static credit(state: CreditStateModel): Credit {
    return state.credit;
  }

  @Selector()
  static packages(state: CreditStateModel): CreditPackage[] {
    return state.packages;
  }

  @Action(FetchCredit)
  public async fetchCurrentUser(state: StateContext<CreditStateModel>): Promise<void> {
    const credit = await this.creditService.get().toPromise();
    state.patchState({ credit, isCreditLoaded: true });
  }

  @Action(FetchCreditPackages)
  public async fetchCreditPackages(state: StateContext<CreditStateModel>): Promise<void> {
    const creditPackages = await this.creditService.getPackages().toPromise();
    state.patchState({ packages: creditPackages });
  }
}
