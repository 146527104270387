import { IUser, UserRole } from '@core/interfaces';

export class FetchAll {
  public static readonly type = '[Users] Fetch all';
  public constructor(
    public query: string,
    public userRole: UserRole = UserRole.talent,
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'asc',
  ) {}
}

export class SetUserVisibility {
  public static readonly type = '[Users] Set visibility';
  public constructor(public user: IUser, public hidden: boolean) {}
}

export class SetUserBypassPayment {
  public static readonly type = '[Users] Set bypasspayment';
  public constructor(public user: IUser, public bypassPayment: boolean) {}
}
