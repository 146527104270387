import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendEmail } from '@core/states';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss'],
})
export class NotAvailableComponent implements OnChanges {
  @Input()
  public isoCode: string;

  public isSent = false;

  public form = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    isoCode: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    message: new FormControl(null, [Validators.required]),
  });

  public constructor(private store: Store) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('isoCode' in changes) {
      this.form.patchValue({
        isoCode: changes.isoCode.currentValue,
      });
    }
  }

  public send(): void {
    const data = this.form.value;
    this.store
      .dispatch(new SendEmail(data.name, data.email, data.message, data.isoCode))
      .pipe(tap(() => (this.isSent = true)))
      .subscribe();
  }
}
