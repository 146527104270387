import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMedia } from '@core/interfaces';
import { EmbedVideoService } from '@core/services';
import { Select, Store } from '@ngxs/store';
import { Media } from '@core/models';
import { Observable } from 'rxjs';
import { GetMediaInfo, MediaState } from '@core/states';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-media-item-modal',
  templateUrl: './media-item-modal.component.html',
  styleUrls: ['./media-item-modal.component.scss'],
})
export class MediaItemModalComponent {
  @Select(MediaState.media)
  public media$: Observable<Media>;

  public media: IMedia;
  public embedHTML: string;

  constructor(
    @Optional() private dialogRef: MatDialogRef<MediaItemModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { media: Media; mediaId: string; width: number; height: number },
    private embedVideoService: EmbedVideoService,
    private store: Store,
  ) {
    const md: Media = plainToClass(Media, this.data.media);
    if (this.data.mediaId !== undefined) {
      this.store.dispatch(new GetMediaInfo(this.data.mediaId));
      this.media$.subscribe((media) => {
        if (media != null) {
          this.media = media;
          if (this.media.link) {
            this.embedHTML = this.embedVideoService.embed(this.media.link, {
              attr: { width: data.width != null ? data.width : 640, height: data.height != null ? data.height : 480 },
            });
          }
        }
      });
    } else {
      this.media = md;
      if (this.media.link) {
        this.embedHTML = this.embedVideoService.embed(this.media.link, {
          attr: { width: data.width != null ? data.width : 640, height: data.height != null ? data.height : 480 },
        });
      }
    }
  }
}
