import { BaseModel } from './base-model.model';
import { Type } from 'class-transformer';
import { IGeoLocation } from '@core/interfaces';
import { IPoint } from '../interfaces/point';
import { Country } from './country.model';

export class GeoLocation extends BaseModel implements IGeoLocation {
  @Type(() => Country)
  public country?: Country;
  public id: string;
  public city?: string;
  public description?: string;
  public coordinates: IPoint;
}

export class GeocodingResultDTO {
  public description: string;
  public matched: GeocodingMatchedDTO;
}

export class GeocodingMatchedDTO {
  public main_text: string;
  public main_text_matched_substrings: GeocodingMatchedSubstring[];
  public secondary_text: string;
}

export class GeocodingMatchedSubstring {
  public length: number;
  public offset: number;
}
