import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IJob } from '@core/interfaces/job';
import { SplideOptions } from '@splidejs/splide';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';
import { UpdateRecentlyViewed } from '@src/visitor/states/search';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-job-row',
  templateUrl: './job-row.component.html',
  styleUrls: ['./job-row.component.scss'],
})
export class JobRowComponent implements OnChanges {
  @Input()
  public job: IJob;

  @HostBinding('class')
  public hover = '';

  @Input()
  public theme = 0;

  public themes = ['purple-bright', 'orange-bright', 'green-blue-bright'];

  public imageSize: any;
  public tileSize: any;

  public options: SplideOptions = {
    keyboard: false,
    perPage: 1,
    perMove: 1,
    pagination: true,
    waitForTransition: false,
    type: 'loop',
    lazyLoad: 'nearby',
    preloadPages: 1,
  };

  public constructor(
    private cd: ChangeDetectorRef,
    private layoutCalculator: LayoutCalculatorService,
    private store: Store,
    private route: ActivatedRoute,
  ) {
    this.layoutCalculator.layoutChanged$.subscribe(this.updateParams);
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.updateParams(this.layoutCalculator.data);
    this.hover = 'hover';
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.hover = '';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('job' in changes) {
      this.updateParams(this.layoutCalculator.data);
    }
  }

  public async openModal(): Promise<void> {
    this.store.dispatch(
      new Navigate(['job', this.job.id], this.route.snapshot.queryParams, {
        relativeTo: this.route,
      }),
    );
    this.store.dispatch(new UpdateRecentlyViewed(this.job));
  }

  public getGradientBg(): string {
    const price = this.job.totalRate;
    if (price >= 0 && price <= 99) {
      return 'job-0-99';
    }
    if (price >= 100 && price <= 275) {
      return 'job-100-275';
    }
    if (price >= 276 && price <= 650) {
      return 'job-276-650';
    }
    if (price >= 651) {
      return 'job-651';
    }
  }

  private updateParams(data: any): void {
    const perRow = this.layoutCalculator?.getTalentsPerRow(data.breakpointName);
    this.tileSize = this.layoutCalculator?.getTileSize(perRow, 9 / 12);
    this.imageSize = this.layoutCalculator?.getImageSize(this.tileSize);

    this.options = {
      ...this.options,
      width: this.imageSize?.width,
      height: this.imageSize?.height,
    };

    this.cd?.detectChanges();
  }
}
