import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IUser } from '@core/interfaces';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SearchByName, SearchState } from '@core/states';
import { Store } from '@ngxs/store';
import { Job } from '@core/models';

@Component({
  selector: 'app-mobile-complete-job-edit-modal',
  templateUrl: './mobile-complete-job-edit-modal.component.html',
  styleUrls: ['./mobile-complete-job-edit-modal.component.scss'],
})
export class MobileCompleteJobEditModalComponent implements OnInit {
  @Output()
  public saveData = new EventEmitter<any>();

  @ViewChild('appMobileCompleteJobEdit') private modalComponent: MobileModalDialogComponent;

  public isEdit = false;
  public formBuilder: FormGroup;
  public performSearch$ = new Subject<string>();
  public results$: Subject<IUser[]> = new Subject<IUser[]>();
  private destroyed$ = new Subject();
  private index = null;
  private firstInitialize = false;

  constructor(private store: Store) {
    this.formBuilder = new FormGroup({
      talentSearch: new FormControl(''),
      talentSelected: new FormControl(false, [Validators.required]),
      totalFee: new FormControl(0, [Validators.required]),
      totalBuyout: new FormControl(0, [Validators.required]),
      travelCost: new FormControl(false),
      travelCostAmount: new FormControl(0),
      travelCostMax: new FormControl(false),
      travelCostMaxAmount: new FormControl(0),
      parkingCost: new FormControl(false),
      parkingCostAmount: new FormControl(0),
      parkingCostMax: new FormControl(false),
      parkingCostMaxAmount: new FormControl(0),
      isPending: new FormControl(true),
      deleted: new FormControl(false),
      applicantDataTemp: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  get formValue() {
    return this.formBuilder.value;
  }

  public onChangeTravelCost(value: boolean) {
    const travelCostAmmount = this.formBuilder.get('travelCostAmount');
    this.formBuilder.get('travelCost').setValue(value);
    if (value && travelCostAmmount.value <= 0) {
      travelCostAmmount.setValue(0.23);
    }
    if (!value) {
      travelCostAmmount.setValue(0);
    }
  }

  public initializeForm() {
    this.isEdit = false;
    this.formBuilder.patchValue({
      talentSearch: '',
      talentSelected: false,
      totalFee: 0,
      totalBuyout: 0,
      travelCost: false,
      travelCostAmount: 0,
      travelCostMax: false,
      travelCostMaxAmount: 0,
      parkingCost: false,
      parkingCostAmount: 0,
      parkingCostMax: false,
      parkingCostMaxAmount: 0,
      isPending: true,
      deleted: false,
      applicantDataTemp: null,
    });
    this.formBuilder.get('travelCostMaxAmount').disable();
    this.formBuilder.get('parkingCostMaxAmount').disable();

    if (!this.firstInitialize) {
      const talentSearchControl = this.formBuilder.get('talentSearch');
      talentSearchControl.valueChanges.pipe(takeUntil(this.destroyed$), tap(this.performSearch$)).subscribe();

      this.performSearch$
        .pipe(
          tap(() => this.results$.next(null)),
          takeUntil(this.destroyed$),
          debounceTime(200),
          filter((query) => query?.length > 0),
          switchMap((query: string) => this.store.dispatch(new SearchByName(query))),
          switchMap(() => this.store.select(SearchState.users)),
          tap(this.results$),
        )
        .subscribe();

      this.firstInitialize = true;
    }
  }

  public onSave(isDelete: boolean) {
    if (this.formValue.talentSelected) {
      this.saveData.emit({
        data: {
          ...this.formBuilder.value,
          deleted: isDelete,
          totalFee: Number(this.formBuilder.value.totalFee),
          totalBuyout: Number(this.formBuilder.value.totalBuyout),
          travelCostAmount: Number(this.formBuilder.value.travelCostAmount),
          travelCostMaxAmount: Number(this.formBuilder.value.travelCostMaxAmount),
          parkingCostMaxAmount: Number(this.formBuilder.value.parkingCostMaxAmount),
        },
        index: this.index,
      });
      this.close();
    }
  }

  async openDialog(data: any): Promise<void> {
    this.initializeForm();
    if (data?.index >= 0) {
      this.isEdit = true;
      this.formBuilder.patchValue(data);
      this.index = data.index;
    } else {
      const jobData: Job = data.jobData;
      const newData = {
        travelCost: (jobData.hasTravelCosts && jobData.travelingCost > 0) || false,
        travelCostAmount: jobData?.travelingCost || 0.23,
        travelCostMax: jobData?.maxTravelingCost >= 1,
        travelCostMaxAmount: jobData?.maxTravelingCost || 0,
        parkingCost: jobData?.parkingCost || false,
        parkingCostAmount: 0,
        parkingCostMax: jobData?.maxParkingCost >= 1 || false,
        parkingCostMaxAmount: jobData?.maxParkingCost || 0,
      };
      this.formBuilder.patchValue(newData);
    }

    return await this.modalComponent.open().then(() => {
      this.index = null;
    });
  }

  public changeControlValue(controlName: string) {
    const control = this.formBuilder.get(`${controlName}Max`);
    const maxValue = this.formBuilder.get(`${controlName}MaxAmount`);
    control.setValue(!control.value);
    maxValue.setValue(0);
    if (control.value === true) {
      maxValue.enable();
    } else {
      maxValue.disable();
    }
  }

  public selectUser(userData?: IUser) {
    this.formBuilder.get('talentSearch').setValue('');
    this.formBuilder.get('talentSelected').setValue(userData || null);
    this.results$.next(null);
  }

  public close() {
    this.modalComponent.close();
    this.formBuilder.get('talentSearch').setValue('');
  }
}
