import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '../generic/buttons/buttons.module';
import { FormsModule } from '../generic/forms/forms.module';
import { IconsModule } from '../generic/icons/icons.module';
import { ImagesModule } from '../generic/images/images.module';
import { SpinnersModule } from '../generic/spinners/spinners.module';
import { AlbumsFieldComponent } from './components/albums-field/albums-field.component';
import { AlbumFieldComponent } from './components/album-field/album-field.component';
import { MediaItemFieldComponent } from './components/media-item-field/media-item-field.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const components = [AlbumsFieldComponent, AlbumFieldComponent, MediaItemFieldComponent];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    ButtonsModule,
    FormsModule,
    IconsModule,
    ImagesModule,
    SpinnersModule,
    TranslateModule,
    FontAwesomeModule,
  ],
  exports: components,
})
export class AlbumsModule {}
