import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-gradient',
  templateUrl: './gradient.component.html',
  styleUrls: ['./gradient.component.scss'],
})
export class GradientComponent implements OnInit, OnChanges {
  @Input()
  public gradientName = 'orange-bright';

  @Input()
  public gradientDirection = 'horizontal';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setClassName(this.gradientName);
    this.setClassName(this.gradientDirection);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('gradientName' in changes) {
      this.setClassName(changes.gradientName.currentValue, changes.gradientName.previousValue);
    }

    if ('gradientDirection' in changes) {
      this.setClassName(changes.gradientDirection.currentValue, changes.gradientDirection.previousValue);
    }
  }

  private setClassName(current: string, previous?: string): void {
    if (previous) {
      this.renderer.removeClass(this.el.nativeElement, previous);
    }

    this.renderer.addClass(this.el.nativeElement, current);
  }
}
