import { Component, OnInit, ViewChild } from '@angular/core';
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import { MobileAuthModalComponent } from '../mobile-auth-modal/mobile-auth-modal.component';

SwiperCore.use([Navigation, Scrollbar]);

@Component({
  selector: 'app-mobile-feature',
  templateUrl: './mobile-feature.component.html',
  styleUrls: ['./mobile-feature.component.scss'],
})
export class MobileFeatureComponent implements OnInit {
  @ViewChild('loginModal') private loginModal: MobileAuthModalComponent;

  public swiper: SwiperCore;

  constructor() {}

  ngOnInit(): void {
    this.swiper = new SwiperCore('.swiper-feature', {
      slidesPerView: 'auto',
      centeredSlides: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      navigation: {
        nextEl: '.feature-next',
        prevEl: '.feature-prev',
      },
    });
  }

  public signUp(roleValue: 'talent' | 'caster') {
    this.loginModal.openDialog({
      showFormSignUp: true,
      role: roleValue,
    });
  }
}
