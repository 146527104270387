<app-mobile-modal-dialog #appForgotPasswordModal>
  <ng-container *ngIf="isSuccess">
    <div class="modal-body" style="height: 400px">
      <div class="signup-success">
        <h6 translate>Succes!</h6>
        <p translate>Please check your email and click on the link to continue the password reset process.</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
    </div>
  </ng-container>
  <form style="text-align: center" *ngIf="!isSuccess" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="modal-body">
      <div style="height: 360px">
        <h5 class="title-sign-up"><span translate>Forgot Password?</span></h5>
        <p class="text-center" style="margin-bottom: 100px">
          We'll send you an email with further instructions on how to reset your password
        </p>
        <input
          class="form-control"
          style="height: 50px; margin-bottom: 42px"
          type="email"
          placeholder="{{ 'Email address' | translate }}"
          formControlName="email"
          autocomplete="username" />
        <div class="wrap-alert" *ngIf="message?.type">
          <app-alert [type]="message?.type">{{ message?.message }}</app-alert>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn-submit" [disabled]="!form.valid">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
        <span *ngIf="!isBusy">{{ "Send instructions" | translate }}</span>
      </button>
    </div>
  </form>
</app-mobile-modal-dialog>
