import { BaseOppObjectDTO } from './base-opp-object.dto';
import { FeesDTO } from './fees.dto';

export class SettlementRowDTO extends BaseOppObjectDTO {
  type: string;
  reference: string;
  volume: number;
  fees: FeesDTO;
  amount: number;
  amount_paid: number;
  amount_payable: number;
  description: string;
}
