export class LoadUserReports {
  public static readonly type = '[User Reports] Load reports';

  public constructor(
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'asc',
  ) {}
}

export class CloseReport {
  public static readonly type = '[User Reports] Close report';
  public constructor(public userReportId: string) {}
}

export class DeleteTalentByReport {
  public static readonly type = '[User Reports] Delete talent by report';
  public constructor(public userReportId: string, public talentId: string) {}
}

export class DeleteJobByReport {
  public static readonly type = '[User Reports] Delete job by report';
  public constructor(public userReportId: string, public jobId: string) {}
}
