<app-mobile-modal-dialog
  [disableCloseButton]="onProgress"
  [backgroundColor]="'rgb(123 123 123 / 10%)'"
  #mobileAccountProfileDeleteModal>
  <div class="mobile-account-profile-delete-modal">
    <div *ngIf="!onProgress" class="body-message">
      <ng-container *ngIf="profileCount >= 2 || showSuccessDialog">
        <p *ngIf="deleteStatus === 'need confirmation'">Are you sure want to delete this profile?</p>
        <p *ngIf="deleteStatus !== 'need confirmation'">
          <img src="/assets/img/mobile/check-white.svg" alt="close" />
          You have successfully delete your profile
        </p>
      </ng-container>
      <p *ngIf="profileCount <= 1 && !showSuccessDialog">You must have at least 1 profile</p>
    </div>
    <div *ngIf="onProgress" class="body-message">
      <div class="loading-screen">
        <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
        <p class="text-center mt-3">Deleting your profile, please wait...</p>
      </div>
    </div>
    <div *ngIf="!onProgress" class="modal-action">
      <button
        class="delete-button"
        (click)="onDeleteClick()"
        *ngIf="deleteStatus === 'need confirmation' && profileCount >= 2">
        Delete
      </button>
      <button class="close-button" (click)="modalComponent.close()">
        {{deleteStatus === 'need confirmation' && profileCount >= 2 ? 'Cancel' : 'Close'}}
      </button>
    </div>
  </div>
</app-mobile-modal-dialog>
