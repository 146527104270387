import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  @Input()
  public icon: string;

  @Input()
  public fill: string;

  @Input()
  public stroke: string;

  @Input()
  public strokeWidth: number;

  @Input()
  public useOriginal = false;

  public removeSvgAttributes = [];
  public setSvgAttributes = [];

  public ngOnChanges(): void {
    this.removeSvgAttributes = this.getRemoveSvgAttributes();
    this.setSvgAttributes = this.getSetSvgAttributes();
  }

  public getRemoveSvgAttributes(): string[] {
    if (this.useOriginal) {
      return;
    }

    const removeSvgAttributes = ['width', 'height'];

    if (this.fill) {
      removeSvgAttributes.push('fill');
    }

    if (this.stroke) {
      removeSvgAttributes.push('stroke');
    }

    if (this.strokeWidth) {
      removeSvgAttributes.push('stroke-width');
    }

    return removeSvgAttributes;
  }

  public getSetSvgAttributes(): any {
    if (this.useOriginal) {
      return;
    }

    const attributes = [
      { propertyName: 'fill', attributeName: 'fill', defaultValue: 'transparent' },
      { propertyName: 'stroke', attributeName: 'stroke', defaultValue: 'transparent' },
      { propertyName: 'strokeWidth', attributeName: 'stroke-width', defaultValue: 1 },
    ];

    const result = {};
    for (const attribute of attributes) {
      const value = this[attribute.propertyName];

      if (!value) {
        continue;
      }

      result[attribute.attributeName] = value;
    }

    return result;
  }

  public svgInserted(e: SVGElement): void {
    e.style.margin = '0 auto';
  }
}
