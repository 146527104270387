<ng-template #modal>
  <div class="modal-background" [ngStyle]="{'background': backgroundColor}"></div>
  <div class="modal-header">
    <h5 class="modal-title"></h5>
    <div class="modal-header">
      <button *ngIf="!disableCloseButton" type="button" class="close" (click)="dismiss()">
        <img src="/assets/img/mobile/close.svg" alt="close" />
      </button>
    </div>
  </div>
  <ng-content></ng-content>
</ng-template>
