import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IUserProfile } from '@core/interfaces';
import { ICategory } from '@core/interfaces/category';
import { Language, UserProfile, UserProfileFilter } from '@core/models';
import { UserProfilesService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import SwiperCore from 'swiper';
@Component({
  selector: 'app-mobile-talent-slide',
  templateUrl: './mobile-talent-slide.component.html',
  styleUrls: ['./mobile-talent-slide.component.scss'],
})
export class MobileTalentSlideComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('swiperRef', { static: false }) sliderRef?: MobileTalentSlideComponent;

  @Input()
  public category: ICategory;
  public swiper: SwiperCore;
  public talents: Array<UserProfile> = [];
  public isProcess = true;

  private talentModelIDs = [
    'c4499c76-13b7-4ce2-ab4d-7a46bb95610e',
    'cd186b65-34b3-4276-9fa5-8a154d92c33b',
    '19370a9c-1367-4f7a-83da-6f1635323093',
    '7b911f7f-89f4-4ce6-8cbd-34edb832dddb',
  ];
  private talentActorIDs = [
    '90cbd450-efe1-43cd-9237-186985f9a0a0',
    '904aae1e-b4fc-454c-b319-32aec8f6a9fc',
    '65cd6db4-89ed-4173-acd9-cadab16a030a',
    '0d4b1eff-becf-4d5e-a1b7-282af7556ba8',
    '0e079c04-0af6-4c49-bbb7-5522030b01b',
  ];
  private talentMUAIDs = [
    '41fd02d9-7bb1-4071-b8b5-ceb6169c26ba',
    '0b8fb0d4-a7bd-4e94-aff8-ead3ce181e3a',
    '091cca3b-ce87-46fb-bfa5-3ece21f44807',
    '77695f0b-6f83-47fa-9536-6d5b1ed4c10a',
    '6454e690-be2b-492c-9d13-15cc67a4d003',
  ];
  private talentExtraIDs = [
    '35ffa5bc-e366-4172-9cce-fe0a2addbebc',
    '54e44756-dda5-46a2-afee-2000a9bc3eb1',
    'ec97ff16-e2d3-41da-bab4-1e3d1ea18b21',
    'd961b43d-f176-41bf-8aca-8a446c6c3214',
    'c3b3ad5f-b8d0-4a39-95c8-cbb0e63df198',
  ];
  private talentPresenterIDs = [
    '1aee49d9-1e2f-4258-b248-58eee04d6d86',
    '204f0aaa-7aa8-4fe7-b334-1a0a84aae8e2',
    '4173d9bd-bc29-4e0f-b132-51ed5482a1fc',
    '02dfc9f9-989d-4abc-a0aa-88fe126404dd',
    '0ed358ca-9c35-4da9-9150-6063cefc5be4',
  ];
  constructor(private userProfilesService: UserProfilesService, private route: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {
    this.loadTalents();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('category' in changes) {
      this.loadTalents();
    }
  }

  public tallBody(talent: UserProfile): UserProfileFilter {
    return talent.filters.filter((res) => {
      if (res.filter.slug === 'height') {
        return res;
      }
    })?.[0];
  }

  public onClick(talent: UserProfile): void {
    this.store.dispatch(
      new Navigate(['profile', talent.user.id, talent.id], this.route.snapshot.queryParams, {
        relativeTo: this.route,
      }),
    );
  }

  public nativeLanguage(userProfile: IUserProfile): Language {
    return userProfile.user.languages.filter((userLanguage) => userLanguage.native)?.[0];
  }

  private async loadTalents(): Promise<void> {
    this.talents = [];
    let talentIDs = this.talentActorIDs;

    if (this.category != null) {
      switch (this.category.slug) {
        case 'models':
          talentIDs = this.talentModelIDs;
          break;
        case 'actors':
          talentIDs = this.talentActorIDs;
          break;
        case 'make-up-artists':
          talentIDs = this.talentMUAIDs;
          break;
        case 'extras':
          talentIDs = this.talentExtraIDs;
          break;
        case 'host-and-promotors':
          talentIDs = this.talentPresenterIDs;
          break;
      }
    }

    const profileDataPromises = [];
    this.isProcess = true;

    talentIDs.forEach((id: string) => {
      profileDataPromises.push(this.userProfilesService.getById(id).toPromise());
    });

    try {
      const result = await Promise.all(profileDataPromises);
      result.forEach((profileData) => {
        if (!!profileData && !this.talents.find((value) => value.id === profileData.id)) {
          this.talents.push(profileData);
        }
      });

      setTimeout(() => {
        this.swiper = new SwiperCore('.swiper-talent', {
          slidesPerView: 'auto',
          observer: true,
        });
      }, 500);

      this.isProcess = false;
    } catch (error) {
      // console.log("Failed to load profile data", error)
    }
  }
}
