import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICategory } from '@core/interfaces/category';
import { IUserProfile } from '@core/interfaces/user-profile';
import { UserProfilesService } from '@core/services';
import { SplideOptions } from '@splidejs/splide';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';

@Component({
  selector: 'app-category-talents-carousel',
  templateUrl: './category-talents-carousel.component.html',
  styleUrls: ['./category-talents-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTalentsCarouselComponent implements OnChanges {
  @Input()
  public category: ICategory;

  @Input()
  public talents$: Observable<IUserProfile[]>;

  public category$: Observable<ICategory>;

  public tileSize: any;
  public startTileSize: any;

  public options: SplideOptions = {
    autoHeight: true,
    autoWidth: true,
    keyboard: 'focused',
    pagination: false,
    padding: 0,
    perPage: 12,
    waitForTransition: false,
  };

  public constructor(
    private cd: ChangeDetectorRef,
    private layoutCalculator: LayoutCalculatorService,
    private userProfilesService: UserProfilesService,
  ) {
    this.layoutCalculator.layoutChanged$.subscribe((data) => this.updateParams(data));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('category' in changes) {
      this.loadTalents(this.category.slug);
    }
  }

  private loadTalents(categorySlug: string): void {
    this.talents$ = this.userProfilesService.findAll(0, 50, 'created', 'asc', { categorySlug: `${categorySlug}` }).pipe(
      filter((data) => data !== null),
      map((data) => data?.results),
      tap(() => this.updateParams(this.layoutCalculator.data)),
    );

    this.talents$.subscribe();
  }

  private updateParams(data: any): void {
    const perRow = this.layoutCalculator.getTalentsPerRow(data.breakpointName);
    this.tileSize = this.layoutCalculator.getTileSize(perRow, 9 / 16);
    const imageSize = this.layoutCalculator.getImageSize(this.tileSize);

    this.startTileSize = {
      width: this.tileSize.width * 2 + data.gap,
      height: imageSize.height,
    };

    this.options = {
      ...this.options,
      padding: data.padding,
      gap: data.gap,
      perPage: perRow,
      perMove: perRow - 1,
    };

    this.cd.detectChanges();
  }
}
