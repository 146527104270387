import { IMedia, MediaType } from '@core/interfaces/media';
import { Transform, TransformFnParams } from 'class-transformer';
import { environment } from '@environments/environment';

export class Media implements IMedia {
  @Transform((mediaType: TransformFnParams) => MediaType[mediaType.value])
  public mediaType: MediaType;
  public id: string;
  public path?: string;
  public userId?: string;
  public link?: string;
  public processed?: boolean;
  public uploaded = true;
  public externalThumbUrl?: string;
  public order?: number;

  public get url(): string {
    if (!!this.link) {
      return this.link;
    }
    if (this.mediaType === MediaType.idverification) {
      return `${environment.apiBaseUrl}/media/private/${this.id}`;
    }

    return `${environment.apiBaseUrl}/media/${this.id}`;
  }

  public get thumbUrl(): string {
    if (!!this.externalThumbUrl) {
      return this.externalThumbUrl;
    }
    return `${environment.apiBaseUrl}/media/thumb/${this.id}`;
  }
}
