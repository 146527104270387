import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IUser } from '@core/interfaces';
import { SearchByName } from '@core/states/users/search/search.actions';
import { SearchState } from '@core/states/users/search/search.state';
import { Store } from '@ngxs/store';
import { ModalService } from '@src/modal/services/modal.service';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnDestroy {
  public query = new FormControl(null);

  public performSearch$ = new Subject<string>();
  public results$: Subject<IUser[]> = new Subject<IUser[]>();
  private destroyed$ = new Subject();

  public constructor(private store: Store, private modalService: ModalService, private router: Router) {
    this.performSearch$
      .pipe(
        tap(() => this.results$.next(null)),
        takeUntil(this.destroyed$),
        debounceTime(200),
        filter((query) => query?.length > 0),
        switchMap((query: string) => this.store.dispatch(new SearchByName(query))),
        switchMap(() => this.store.select(SearchState.users)),
        tap(this.results$),
      )
      .subscribe();

    this.query.valueChanges.pipe(takeUntil(this.destroyed$), tap(this.performSearch$)).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(null);
  }

  public onFocus(): void {
    this.performSearch$.next(this.query.value);
  }

  public onBlur(): void {
    setTimeout(() => {
      this.results$.next(null);
    }, 250);
  }

  public openUser(user: IUser): void {
    this.modalService.routeTo(['profile', user.id, user.profiles[0].id], this.router.url.indexOf('admin') === -1);
  }
}
