import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-mobile-account-profile-media-form',
  templateUrl: './mobile-account-profile-media-form.component.html',
  styleUrls: ['./mobile-account-profile-media-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobileAccountProfileMediaFormComponent),
      multi: true,
    },
  ],
})
export class MobileAccountProfileMediaFormComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public accept: string;

  @Input()
  public maxSize = 300;

  public error: string;

  private value: string;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public onValueChange(value: File[]): void {
    if (value?.length === 0) {
      return;
    }

    const file = value[0];

    if (this.accept != null && this.accept !== '') {
      let fileTypes = [this.accept];
      if (this.accept.indexOf(',') > -1) {
        fileTypes = this.accept.split(',');
      }
      this.error = `Selected filetype is not allowed`;
      for (const fileType of fileTypes) {
        const regex = new RegExp(fileType.replace('/', '\\/'));
        if (file.type.match(regex)) {
          this.error = null;
        }
      }
    }

    const maxSizeInBytes = this.maxSize * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      this.error = `Max. ${this.maxSize}MB allowed`;
      return;
    }

    this.error = null;
    this.onChange(value[0]);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private updateField(value: string): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.value = value;
  }
}
