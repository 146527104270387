<div class="upload">
  <div class="photo" style="background-image: url({{fileURL}});"></div>
  <div class="btn-upload">
    <input
      class="inputFile"
      (input)="onValueChange($event.target.files)"
      [accept]="allowMimeType"
      type="file"
      id="file" />
    <label for="file">
      <img src="/assets/img/mobile/camera.svg" alt="" />
    </label>
  </div>
</div>
