<div class="mx-5 mt-5">
  <div class="d-flex flex-wrap w-100 text-white">
    <h1 class="h3 w-100 text-center mb-3" translate>Select a deck</h1>

    <app-deck
      class="col-12 col-sm-6 d-flex flex-column mb-5"
      *ngFor="let deck of (decks$ | async)"
      (click)="onDeckSelected(deck)"
      [deck]="deck"></app-deck>
  </div>
</div>

<div
  *ngIf="isBusy"
  class="spinner-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
  <app-spinner></app-spinner>
</div>
