<app-text-field (input)="onValueChange($event.target.value)" [type]="type" class="w-100">
  <span class="input-group-text bg-transparent border-0" prepend>
    <app-icon fill="white" icon="/assets/img/lock-with-keyhole.svg" stroke="#5f43f0" strokeWidth="3"></app-icon>
  </span>

  <ng-content append select="[append]"></ng-content>

  <span (click)="switchViewMode()" append class="input-group-text bg-transparent border-0 cursor-pointer">
    <app-icon *ngIf="type === 'password'" fill="white" fill="#5f43f0" icon="/assets/img/invisible.svg"></app-icon>
    <app-icon *ngIf="type === 'text'" fill="white" fill="#5f43f0" icon="/assets/img/visible.svg"></app-icon>
  </span>
</app-text-field>
