import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersService } from '@core/services';

import { VerifyMobileStateModel } from './verify-mobile.state-model';

import { CheckMobileVerificationCode, SendMobileVerificationCode } from './verify-mobile.actions';

@State<VerifyMobileStateModel>({
  name: 'verifyMobile',
  defaults: {
    isValid: false,
    inProgress: false,
  },
})
@Injectable()
export class VerifyMobileState {
  public constructor(private usersService: UsersService) {}

  @Selector()
  static inProgress(state: VerifyMobileStateModel): boolean {
    return state.inProgress;
  }

  @Selector()
  static isValid(state: VerifyMobileStateModel): boolean {
    return state.isValid;
  }

  @Action(SendMobileVerificationCode)
  public async sendMobileVerificationCode(
    ctx: StateContext<VerifyMobileStateModel>,
    { phonenumber }: SendMobileVerificationCode,
  ): Promise<void> {
    ctx.patchState({ isValid: false, inProgress: true });
    await this.usersService.sendMobileVerificationCode(phonenumber).toPromise();
    ctx.patchState({ isValid: false, inProgress: false });
  }

  @Action(CheckMobileVerificationCode)
  public async checkMobileVerificationCode(
    ctx: StateContext<VerifyMobileStateModel>,
    { phonenumber, verificationCode }: CheckMobileVerificationCode,
  ): Promise<any> {
    ctx.patchState({ isValid: false, inProgress: true });
    const result = await this.usersService.checkMobileVerificationCode(phonenumber, verificationCode).toPromise();
    ctx.patchState({ isValid: result, inProgress: false });
  }
}
