import { BaseModel } from './base-model.model';
import { Transform, Type } from 'class-transformer';
import { INotification } from '@core/interfaces';
import { User } from '@core/models/user.model';
import * as moment from 'moment';

export class Notification extends BaseModel implements INotification {
  @Type(() => User)
  public user: User;
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public received: Date;

  public id: string;
  public type: string;
  public message: string;
  public read: boolean;
  public payload: any;

  public get getTranslationKey(): string {
    switch (this.type) {
      case 'chat':
        return 'You have received a message from';
      case 'directOffer':
        return 'You have received a direct job offer from';
      case 'talentAcceptedDirectOffer':
        return 'Your direct offer has been accepted by';
      case 'talentRejectedDirectOffer':
        return 'Your direct offer has been rejected by';
      case 'talentAppliedToJob':
        return 'User has applied to your job';
      case 'casterAcceptedApplication':
        return 'Your application to job has been accepted';
      case 'casterRejectedApplication':
        return 'Your application to job has been rejected';
      case 'idVerified':
        return 'Your ID is verified by Casterbee';
      case 'idRejected':
        return 'Your ID is rejected by Casterbee';
      case 'idNotUploaded':
        return 'Upload your ID to have your profile visible';
      case 'jobCancelled':
        return 'The job you applied to has been cancelled';
      default:
        return this.message;
    }
  }

  public get getTranslationMap(): { profileName?: string; jobTitle?: string } {
    switch (this.type) {
      case 'chat':
        return { profileName: this.payload.message.sender.profileName };
      case 'directOffer':
        return { profileName: this.payload.job?.user?.profileName };
      case 'talentAcceptedDirectOffer':
        return { profileName: this.payload.jobApplication?.user.profileName };
      case 'talentRejectedDirectOffer':
        return { profileName: this.payload.jobApplication?.user.profileName };
      case 'talentAppliedToJob':
        return {
          profileName: this.payload.jobApplication?.user.profileName,
          jobTitle: this.payload.jobApplication.job?.title,
        };
      case 'casterAcceptedApplication':
        return {
          profileName: this.payload.jobApplication.job?.user.profileName,
          jobTitle: this.payload.jobApplication.job?.title,
        };
      case 'casterRejectedApplication':
        return {
          profileName: this.payload.jobApplication.job?.user.profileName,
          jobTitle: this.payload.jobApplication.job?.title,
        };
      case 'jobCancelled':
        return {
          profileName: this.payload.jobApplication.job?.user.profileName,
          jobTitle: this.payload.jobApplication.job?.title,
        };
      case 'idVerified':
      case 'idRejected':
      case 'idNotUploaded':
      default:
        return {};
    }
  }
}
