export class SearchCity {
  public static readonly type = '[Geocoder] Search city';

  public constructor(public search: string, public isoCode: string) {}
}

export class GetGeolocationByCity {
  public static readonly type = '[Geocoder] Get geolocation by city';

  public constructor(public city: string, public isoCode: string) {}
}

export class GetGeolocationByDescription {
  public static readonly type = '[Geocoder] Get geolocation by description';

  public constructor(public description: string) {}
}

export class SearchLocation {
  public static readonly type = '[Geocoder] Search location';

  public constructor(public search: string) {}
}
