import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-complete-job-note-modal',
  templateUrl: './mobile-complete-job-note-modal.component.html',
  styleUrls: ['./mobile-complete-job-note-modal.component.scss'],
})
export class MobileCompleteJobNoteModalComponent implements OnInit {
  @Output()
  public saveNote = new EventEmitter<any>();

  @ViewChild('appCompleteJobNoteModal') private modalComponent: MobileModalDialogComponent;

  public formBuilder: FormGroup;

  constructor() {
    this.formBuilder = new FormGroup({
      note: new FormControl(''),
    });
  }

  ngOnInit(): void {}

  public get noteValue() {
    return this.formBuilder.get('note')?.value;
  }

  async openDialog(noteValue: string): Promise<void> {
    if (noteValue) {
      this.formBuilder.patchValue({ note: noteValue });
    }

    return await this.modalComponent.open().then(() => {});
  }

  public save() {
    this.saveNote.emit(this.noteValue);
    this.close();
  }

  public close() {
    this.modalComponent.close();
  }
}
