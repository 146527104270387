import { ChangeContext } from '@angular-slider/ngx-slider';
import { AfterViewInit, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SliderComponent } from '../slider/slider.component';

@Component({
  selector: 'app-color-slider',
  templateUrl: '../slider/slider.component.html',
  styleUrls: ['../slider/slider.component.scss', './color-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorSliderComponent),
      multi: true,
    },
  ],
})
export class ColorSliderComponent extends SliderComponent implements AfterViewInit {
  public startColor = '#facc9d';
  public endColor = '#713606';
  protected defaultOptions = {
    floor: 0,
    ceil: 100,
    translate: (value: number) => '',
  };
  private pointer: any;
  private ceilPointer: any;

  public ngAfterViewInit(): void {
    const el = this.slider.elementRef.nativeElement;

    const bar = el.querySelector('.ngx-slider-full-bar .ngx-slider-bar');
    bar.style.backgroundImage = `linear-gradient(to right, ${this.startColor} 0%, ${this.endColor} 100%)`;

    this.pointer = el.querySelector('.ngx-slider-pointer-min');
    this.ceilPointer = el.querySelector('.ngx-slider-pointer-max');
    this.pickHex(this.value, this.pointer);
    this.pickHex(this.highValue, this.ceilPointer);
  }

  public onUserChange(changeContext: ChangeContext): void {
    this.pickHex(changeContext.value, this.pointer);
    if (changeContext.highValue !== undefined) {
      this.pickHex(changeContext.highValue, this.ceilPointer);
    }
    super.onUserChange(changeContext);
  }

  public pickHex(percentage: number, pointer: any): void {
    if (!pointer) {
      return;
    }

    const ratio = percentage / 100;
    const color1 = this.endColor;
    const color2 = this.startColor;

    const r = this.shiftColor(color1, color2, 1, 3, ratio);
    const g = this.shiftColor(color1, color2, 3, 5, ratio);
    const b = this.shiftColor(color1, color2, 5, 7, ratio);

    pointer.style.backgroundColor = `#${this.hex(r)}${this.hex(g)}${this.hex(b)}`;
  }

  private shiftColor(color1: string, color2: string, start: number, end: number, ratio: number): number {
    return Math.ceil(
      parseInt(color1.substring(start, end), 16) * ratio + parseInt(color2.substring(start, end), 16) * (1 - ratio),
    );
  }

  private hex(x: number): string {
    const value = x.toString(16);
    return value.length === 1 ? `0${value}` : value;
  }
}
