import { IUserAlbum } from '@core/interfaces';
import { MediaType } from '@core/interfaces';
import { Media } from './media.model';
import { Transform, TransformFnParams, Type } from 'class-transformer';

export class UserAlbum implements IUserAlbum {
  @Transform((mediaType: TransformFnParams) => MediaType[mediaType.value])
  public albumType: MediaType;
  @Type(() => Media)
  public media: Media[];
  @Type(() => Date)
  public updated: Date;

  public id: string;
  public title: string;
}
