<footer>
  <div class="row">
    <div class="nav-footer col">
      <p translate>About</p>
      <ol>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl">Casterbee</a></li>
        <li translate>
          <a target="_blank" href="https://helpdesk.casterbee.nl/terms-and-conditions/">Terms & conditions</a>
        </li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/contact/">Contact</a></li>
      </ol>
    </div>
    <div class="nav-footer col-5">
      <p translate>Special</p>
      <ol>
        <li translate><a target="_blank" href="https://acties.casterbee.nl">Campaigns</a></li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/blog-en-tips/">Blok & Tips</a></li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/onderzoek/">Research</a></li>
      </ol>
    </div>
  </div>
  <div class="separator"></div>
  <div class="row">
    <div class="nav-footer col">
      <p translate>Caster</p>
      <ol>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/voorwaarden-casters/">Rules</a></li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/werking-caster/">How it works</a></li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/facturatie/">Payment</a></li>
      </ol>
    </div>
    <div class="nav-footer col-5">
      <p translate>Talent</p>
      <ol>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/regels-talent/">Rules</a></li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/starten-met-profiel/">Start</a></li>
        <li translate><a target="_blank" href="https://helpdesk.casterbee.nl/betaling-talent/">Payment</a></li>
      </ol>
    </div>
  </div>
  <p class="text-footer" translate>Jouw foto gebruikt, maar geen toestemming. Meld het hier</p>
  <div class="separator"></div>
  <p>©2023 Casterbee, Inc, All rights reserved. Privacy, Terms, Sitemap</p>
  <ol class="social">
    <li>
      <a href="https://www.instagram.com/casterbee?igsh=MWc4bnc4cWR0a3hiMQ==" target="_blank">
        <img src="/assets/img/mobile/instagram.svg" alt="" />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/casterbeecom?mibextid=LQQJ4d" target="_blank">
        <img src="/assets/img/mobile/facebook.svg" alt="" />
      </a>
    </li>
    <li>
      <a href="https://www.linkedin.com/company/casterbee" target="_blank">
        <img src="/assets/img/mobile/linkedin.svg" alt="" />
      </a>
    </li>
  </ol>
</footer>
