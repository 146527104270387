import { IJobFilter } from '@core/interfaces';
import { Filter } from './filter.model';
import { FilterOption } from './filter-option.model';
import { Type } from 'class-transformer';

export class JobFilter implements IJobFilter {
  @Type(() => Type)
  public filter: Filter;
  @Type(() => FilterOption)
  public filterOption?: FilterOption;
  public id: string;
  public lowValue?: number;
  public highValue?: number;
  public value?: string;
}
