import { IJobApplication, IPayment, JobApplicationStatus } from '@core/interfaces';
import { Transform, TransformFnParams, Type } from 'class-transformer';
import * as moment from 'moment';

import { Job } from './job.model';
import { Media } from './media.model';
import { User } from './user.model';
import { TravelExpense } from '@core/models/travel-expense.model';

export class JobApplication implements IJobApplication {
  @Type(() => User)
  public user: User;
  @Transform((jobStatus: TransformFnParams) => JobApplicationStatus[jobStatus.value])
  public status: JobApplicationStatus;
  @Type(() => Job)
  public job: Job;
  @Type(() => Media)
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;
  @Type(() => TravelExpense)
  public travelExpenses?: TravelExpense[];
  @Type(() => Media)
  public travelReceipts?: Media[];
  public talentVideo?: Media;
  public talentVideoId?: string;
  public jobId: string;
  public id: string;
  public isDirectOffer: boolean;
  public casterRequestedVideo?: Date;
  public payment?: IPayment;
  public costRejectionReason?: string;
  public tcEmailTalentSent?: boolean;
  public tcEmailCasterSent?: boolean;
  public notes?: string;
  public withoutTc?: boolean;
  public jobInvoiceItem?: any;
  public profilePic?: string;
}
