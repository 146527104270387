import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JobRequestsService } from '@core/services';

@Component({
  selector: 'app-job-request-modal',
  templateUrl: './job-request-modal.component.html',
  styleUrls: ['./job-request-modal.component.scss'],
})
export class JobRequestModalComponent {
  public isSubmitted = false;
  public isBusy = false;

  public form: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phonenumber: new FormControl(null, [Validators.required]),
    message: new FormControl(null, [Validators.required]),
  });

  constructor(
    @Optional() private dialogRef: MatDialogRef<JobRequestModalComponent>,
    private jobRequestService: JobRequestsService,
  ) {}

  public async submitJob(): Promise<any> {
    if (this.form.invalid || this.isBusy) {
      return;
    }

    this.isBusy = true;

    try {
      await this.jobRequestService.saveJobRequest(this.form.value).toPromise();
      this.isSubmitted = true;
    } catch (error) {
      console.error('Failed to submit job request', error);
    }

    this.isBusy = false;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
