<h1 class="text-center" translate *ngIf="isDirect && !(isEdited || isExistingJob)">Offer Job</h1>
<h1 class="text-center" translate *ngIf="!isDirect && !(isEdited || isExistingJob)">Create Job</h1>
<h1 class="text-center" translate *ngIf="isDirect && (isEdited || isExistingJob)">Edit Offered Job</h1>
<h1 class="text-center" translate *ngIf="!isDirect && (isEdited || isExistingJob)">Edit Job</h1>

<div class="guideline" *ngIf="!isDirect && !(isEdited || isExistingJob)">
  <div class="floating-text-guideline">
    <img src="/assets/img/light-icon.svg" />
    <h3 translate>Tips & directions</h3>
    <p translate class="guideline-subtext">Improve the quality of the Talents by following these guidelines</p>
    <p class="row-list">
      <strong>€1.500 - €4.000</strong>
      <span translate>Pro Talents (o.a. remembering texts, acting emotions)</span>
    </p>
    <p class="row-list">
      <strong>€500 - €800</strong>
      <span translate>Actors/Models with camera- and text experience</span>
    </p>
    <p class="row-list">
      <strong>€350+</strong>
      <span translate>Experienced Talents</span>
    </p>
    <p class="row-list">
      <strong>€200</strong>
      <span translate>Beginners / (edel-) extras</span>
    </p>
    <p class="row-list">
      <strong>€25 - €99</strong>
      <span translate>Hobbyists / extras</span>
    </p>
  </div>
</div>

<form *ngIf="form" (ngSubmit)="onSubmit()" [formGroup]="form" class="mt-5 d-flex flex-column">
  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <app-label [isRequired]="true"><span translate>Title</span></app-label>
    </div>
    <div class="col-12 col-sm-9">
      <app-text-field class="d-flex rounded-sm w-100" formControlName="title"></app-text-field>
      <small class="text-white" *ngIf="!isDirect" translate>
        Give your job listing a title. A clear title describing your job listing.
      </small>
    </div>
  </div>

  <div *ngIf="categories?.length > 0" class="row mb-3">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <app-label [isRequired]="true"><span translate>Category</span></app-label>
    </div>
    <div class="col-7 col-sm-5 d-flex">
      <app-select class="w-100 rounded-sm" formControlName="categoryId" iconColor="white">
        <ng-container>
          <app-option
            *ngFor="let category of categories"
            [value]="category.id"
            class="d-flex w-100 text-white"
            translate>
            {{ category.translateKeySingular }}
          </app-option>
        </ng-container>
      </app-select>
      <app-locked-indicator *ngIf="!isDirect" [locked]="true" class="ml-2 mt-2"></app-locked-indicator>
    </div>
  </div>

  <div *ngIf="currentUser.isAdmin" class="row mb-3">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <app-label [isRequired]="true"><span translate>Caster</span></app-label>
    </div>
    <div class="col-12 col-sm-9 d-flex" *ngIf="casters$ | async as casters">
      <app-select class="bg-gray border-0 w-100 rounded-sm" formControlName="userId" iconColor="black">
        <ng-container>
          <app-option *ngFor="let caster of casters" [value]="caster.id" class="d-flex w-100" translate>
            {{ caster.fullName }}
          </app-option>
        </ng-container>
      </app-select>
      <app-locked-indicator *ngIf="!isDirect" [locked]="true" class="ml-2 mt-2"></app-locked-indicator>
    </div>
  </div>

  <hr />

  <div class="row mb-2 locations" formArrayName="countries">
    <div class="col-12 col-sm-3 d-flex pt-2">
      <app-label [isRequired]="true"><span translate>Location</span></app-label>
    </div>
    <div class="col-12 col-sm-9">
      <div class="container" *ngIf="availableCountries">
        <div
          *ngFor="let countryControl of countries.controls; let i = index"
          [formGroupName]="i"
          class="row mb-2"
          class="location-{{ i }}"
          [ngClass]="{ disabled: !countryControl.get('checked').value }">
          <div class="col-1 d-flex align-items-center">
            <label>
              <app-checkbox formControlName="checked"></app-checkbox>
            </label>
          </div>
          <div class="col-10 col-md-4 mb-2 mb-md-0 px-1">
            <app-select
              class="w-100 rounded-sm"
              formControlName="countryId"
              iconColor="white"
              [searchable]="true"
              [isLocked]="!countryControl.get('checked').value">
              <app-option
                *ngFor="let country of availableCountries"
                [value]="country.id"
                [searchValue]="country.translateKey | translate"
                class="d-flex w-100 py-1 align-items-center">
                <img
                  class="flag mr-2"
                  src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                    country.isoCode.toLowerCase()
                  }}.svg" />
                <span class="text-white" translate>{{ country.translateKey }}</span>
              </app-option>
            </app-select>
          </div>
          <div class="col-10 offset-1 col-md-4 offset-md-0 px-1">
            <app-geolocate-field
              class="d-flex rounded-sm w-100"
              formControlName="city"
              [countryCode]="getCountryCode(countryControl)"
              [placeholder]="'City' | translate"
              [isReadOnly]="!countryControl.get('checked').value"></app-geolocate-field>
          </div>
          <div class="col-1 col-md-3 d-flex align-items-center">
            <app-button
              (click)="removeCountry(i)"
              [ngClass]="{ invisible: i === 0 }"
              buttonStyle="white-outline"
              class="mr-2 align-self-center"
              id="removeCountry-{{ i }}">
              <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-10 offset-1 col-sm-3 offset-sm-4 px-0">
      <app-button (click)="addCountry()" buttonStyle="artyclick-purple" id="addCountry" class="w-100">
        <app-icon fill="white" icon="/assets/img/plus.svg"></app-icon>
        <span class="ml-1" translate>Add location</span>
      </app-button>
    </div>
  </div>
  <div class="row mb-3 locations">
    <div class="col-12 col-sm-3 d-flex pt-2"></div>
    <div class="col-12 col-sm-9 d-flex align-items-center" [ngClass]="{ disabled: !form.get('workFromHome').value }">
      <div class="container">
        <div class="row">
          <div class="col-1 d-flex align-items-center">
            <label class="mr-2">
              <app-checkbox formControlName="workFromHome"></app-checkbox>
            </label>
          </div>
          <div class="col-10 col-sm-6 px-1">
            <app-text-field
              class="w-50 rounded-sm cursor-pointer"
              iconColor="black"
              formControlName="workFromHomeText"
              [isReadOnly]="true"
              (click)="setWorkFromHome()"></app-text-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr class="my-3" />
  <div class="row mb-3 dates">
    <div class="col-3 d-flex pt-2">
      <label translate>Date(s)</label>
    </div>
    <div class="col-9 pt-2">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12 d-flex">
            <label class="align-items-center mr-3">
              <app-radio class="mr-2" formControlName="exactDate" [value]="true"></app-radio>
              <span translate>I have exact date(s)</span>
            </label>
          </div>
        </div>
        <ng-container formArrayName="dates" *ngIf="exactDate.value == true">
          <ng-container *ngFor="let date of dates.controls; let i = index" [formGroupName]="i" class="date-{{ i }}">
            <div class="row mb-2">
              <div class="col-12 col-md-8 offset-md-4 d-flex flex-column py-2" *ngIf="i > 0">
                <small class="text-white">{{ 'dayX' | translate: { day: i + 1 } }}</small>
              </div>
              <div class="col-12 col-md-2 d-flex flex-column pt-2">
                <app-label [isRequired]="true"><span translate>Date</span></app-label>
              </div>
              <div class="col-1 d-md-flex d-none pt-2">
                <app-icon fill="white" icon="/assets/img/calendar.svg"></app-icon>
              </div>
              <div class="col-12 col-md-5 d-flex mb-3">
                <app-date-field
                  class="d-flex w-100 rounded-sm"
                  [ngClass]="{ mustCheck: !isExistingJob }"
                  formControlName="startDate"
                  iconColor="black"></app-date-field>
              </div>
              <div class="col-12 col-md-3 mb-3 d-none d-md-block">
                <app-button (click)="addDate()" buttonStyle="artyclick-purple" class="h-100" *ngIf="i == 0">
                  <app-icon fill="white" icon="/assets/img/plus.svg"></app-icon>
                  <span class="ml-1" translate>Add date</span>
                </app-button>
                <app-button (click)="removeDate(i)" buttonStyle="artyclick-purple" class="h-100" *ngIf="i > 0">
                  <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
                  <span class="ml-1" translate>Delete</span>
                </app-button>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-2 d-flex flex-column pt-md-2 pt-0">
                <app-label [isRequired]="true"><span translate>Time</span></app-label>
              </div>
              <div class="col-1 d-md-flex d-none pt-2">
                <app-icon fill="white" icon="/assets/img/clock.svg"></app-icon>
              </div>
              <div class="col-12 col-md-5 d-flex">
                <div class="container">
                  <div class="row">
                    <div class="col-6 pl-0 pr-1">
                      <app-time-field class="d-flex w-100 rounded-sm" formControlName="startTime"></app-time-field>
                    </div>
                    <div class="col-6 pl-1 pr-0">
                      <app-time-field class="d-flex w-100 rounded-sm mr-2" formControlName="endTime"></app-time-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 mt-2 mb-3 d-block d-md-none">
                <app-button (click)="addDate()" buttonStyle="artyclick-purple" class="w-100" *ngIf="i == 0">
                  <app-icon fill="white" icon="/assets/img/plus.svg"></app-icon>
                  <span class="ml-1" translate>Add date</span>
                </app-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="row mb-3 pt-5">
          <div class="col-12 d-flex">
            <label class="align-items-center mr-3">
              <app-radio class="mr-2" formControlName="exactDate" [value]="false"></app-radio>
              <span translate>Pick a period</span>
            </label>
          </div>
        </div>
        <ng-container *ngIf="exactDate.value == false" formArrayName="dates">
          <ng-container *ngFor="let date of dates.controls; let i = index" [formArrayName]="i" class="date-{{ i }}">
            <div class="row mb-2 date">
              <div class="col-12 col-md-3 d-flex flex-column pt-md-3 pt-1">
                <app-label [isRequired]="true"><span translate>Date</span></app-label>
              </div>
              <div class="col-1 d-md-flex d-none pt-2">
                <app-icon fill="white" icon="/assets/img/calendar.svg"></app-icon>
              </div>
              <div class="col-12 col-md-6 d-flex mb-3">
                <div>
                  <app-daterange-field
                    class="d-flex w-100 rounded-sm"
                    iconColor="black"
                    [ngClass]="{ mustCheck: !isExistingJob, invalidDate: invalidDate }"
                    [range]="date"></app-daterange-field>
                </div>
              </div>
            </div>
            <div class="row mb-2 date">
              <div class="col-12 col-md-3 d-flex flex-column pt-md-2 pt-0">
                <app-label [isRequired]="true"><span translate>Total days</span></app-label>
              </div>
              <div class="col-1 d-md-flex d-none pt-2">
                <app-icon fill="white" icon="/assets/img/calendar.svg"></app-icon>
              </div>
              <div class="col-12 col-md-3 d-flex">
                <app-text-field
                  class="d-flex w-100 rounded-sm"
                  type="number"
                  formControlName="days"
                  min="0"
                  max="100"></app-text-field>
              </div>
            </div>
            <div class="row mb-2 date">
              <div class="col-12 col-md-3 d-flex flex-column pt-md-2 pt-0">
                <app-label [isRequired]="true"><span translate>Avg. hours per day</span></app-label>
              </div>
              <div class="col-1 d-md-flex d-none pt-2">
                <app-icon fill="white" icon="/assets/img/clock.svg"></app-icon>
              </div>
              <div class="col-12 col-md-3">
                <app-text-field
                  class="d-flex w-100 rounded-sm"
                  type="number"
                  formControlName="hours_per_day"
                  min="1"
                  max="24"></app-text-field>
              </div>
              <div class="col-12 col-md-5">
                <small class="text-white">This is an indicator of the average hours it takes per day</small>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <hr class="my-3" />

  <div class="row">
    <div class="col-12 col-sm-3 d-flex flex-column pt-3">
      <app-label [isRequired]="true"><span translate>Compensation</span></app-label>
      <small *ngIf="packageFee$ | async as packageFee" translate>
        Suggested fee based on selected package:
        <app-money [amount]="packageFee"></app-money>
      </small>
    </div>
    <div class="col-12 col-sm-9 feeTypes" *ngIf="feeTypes">
      <div *ngFor="let feeTypeEl of feeTypes" class="row">
        <div class="col-12">
          <div class="row mb-2">
            <div class="col-12 col-md-5 d-flex align-items-top pt-3">
              <ol style="list-style: none">
                <li>
                  <label translate>
                    <app-radio
                      [matcher]="matchById"
                      [value]="feeTypeEl"
                      class="mr-2"
                      formControlName="feeType"></app-radio>
                    {{ feeTypeEl.name }}
                  </label>
                </li>
                <li>
                  <small *ngIf="feeTypeEl.slug === 'freebie'" translate>Posting a freebie costs 5 euro.</small>
                </li>
              </ol>
            </div>
            <div class="col-12 col-md-7 pr-3 buyout">
              <div class="container ml-0 pl-0">
                <div class="row mb-3" *ngIf="feeTypeEl.slug === 'freebie'">
                  <div class="col-12 pl-0">&nbsp;</div>
                </div>
                <div class="row mb-3" *ngIf="feeTypeEl.slug === 'fee'">
                  <div class="col-12" *ngIf="form?.value?.feeType?.slug !== 'fee'">&nbsp;</div>
                  <ng-container *ngIf="form?.value?.feeType?.slug === 'fee'">
                    <app-label class="col-3 pt-2 px-0"><span translate>Fee</span></app-label>
                    <app-text-field
                      class="col-4 w-100 d-flex flex-fill rounded-sm px-0 mb-2"
                      formControlName="rate"
                      type="number"
                      min="0"
                      max="100000"></app-text-field>
                    <div class="col-5 d-flex">
                      <app-info-indicator class="ml-2 mt-2">
                        <small class="text-white">
                          <span translate>
                            You can hide your fee, the following text will be shown when the fee is hidden:
                          </span>
                          <br />
                          15 - 200 Euro: €
                          <br />
                          200 - 600 Euro: €€
                          <br />
                          600+ Euro: €€€
                          <br />
                          <span translate>When a talent applies, the fee will be immidiatly to that Talent</span>
                        </small>
                      </app-info-indicator>
                    </div>
                    <app-label class="col-3 pt-2 px-0"><span>Buyout</span></app-label>
                    <app-text-field
                      class="col-4 w-100 d-flex flex-fill rounded-sm px-0"
                      formControlName="extraFees"
                      [placeholder]="'included' | translate"
                      type="number"
                      min="0"
                      max="100000"></app-text-field>
                    <div class="col-12" style="height: max-content; padding: 0px; margin: 10px 0px">
                      <label class="d-flex align-items-center mb-1">
                        <app-checkbox class="mr-2" formControlName="negotiable"></app-checkbox>
                        <span translate>Negotiable</span>
                      </label>
                      <small translate>
                        When enabled 'Negotiable', the Talent and you will negotiate about the fees and details. After
                        negotiations you can send the booking request and in the popup change the details.
                      </small>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="feeTypeEl.slug === form?.value?.feeType?.slug">
                  <div class="row mb-3">
                    <div class="col-12 col-md-3 d-flex pl-0">
                      <label translate>Publishing channels</label>
                    </div>
                    <div class="col-12 col-md-9">
                      <div class="w-100 d-flex">
                        <app-checkbox-group
                          class="w-100"
                          formControlName="publishingChannels"
                          [ngClass]="{ mustCheck: !isExistingJob }">
                          <label class="d-flex align-items-center">
                            <app-checkbox class="mr-2" value="online"></app-checkbox>
                            Online
                          </label>

                          <label class="d-flex align-items-center">
                            <app-checkbox class="mr-2" value="print"></app-checkbox>
                            Print
                          </label>

                          <label class="d-flex align-items-center">
                            <app-checkbox class="mr-2" value="tv-film"></app-checkbox>
                            TV / Film
                          </label>
                        </app-checkbox-group>
                      </div>
                      <small class="text-white" translate>
                        Assignments booked via Casterbee always have the license for all mediums.
                      </small>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 d-flex pl-0">
                      <label translate>Broadcast countries</label>
                    </div>
                    <div class="col-12 col-md-9">
                      <div class="w-100 d-flex">
                        <app-select
                          class="w-100 rounded-sm"
                          [ngClass]="{ mustCheck: !isExistingJob }"
                          formControlName="broadcastCountries"
                          iconColor="white">
                          <app-option
                            class="d-flex w-100 py-1 align-items-center text-white"
                            translate
                            value="worldwide">
                            Worldwide
                          </app-option>
                          <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="other">
                            Defined countries
                          </app-option>
                        </app-select>
                      </div>
                      <div
                        class="w-100 mt-2 locations"
                        *ngIf="otherBroadcastCountriesSelected()"
                        formArrayName="licenceCountries">
                        <div
                          *ngFor="let licenceCountry of licenceCountries.controls; let i = index"
                          [formGroupName]="i"
                          class="row mb-2"
                          class="location-{{ i }}">
                          <div *ngIf="availableCountries" class="col-12 d-flex">
                            <app-select
                              [searchable]="true"
                              class="w-100 rounded-sm mr-2"
                              formControlName="id"
                              iconColor="white">
                              <app-option
                                *ngFor="let country of availableCountries"
                                [value]="country.id"
                                [searchValue]="country.translateKey | translate"
                                class="d-flex w-100 py-1 align-items-center">
                                <img
                                  class="flag mr-2"
                                  src="https://storage.googleapis.com/casterbee-public/img/flags/{{
                                    country.isoCode.toLowerCase()
                                  }}.svg" />
                                {{ country.translateKey | translate }}
                              </app-option>
                            </app-select>

                            <app-button
                              (click)="removeLicenceCountry(i)"
                              [ngClass]="{ invisible: i === 0 }"
                              buttonStyle="white-outline"
                              class="ml-2 align-self-center"
                              id="removeLocation-{{ i }}">
                              <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
                            </app-button>
                          </div>
                        </div>
                        <div class="col-12 col-md-9 offset-3 d-flex">
                          <app-button (click)="addLicenceCountry()" buttonStyle="orange" class="ml-auto">
                            <app-icon fill="white" icon="/assets/img/plus.svg"></app-icon>
                          </app-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 d-flex pl-0 align-items-top pt-2">
                      <label translate>License duration</label>
                    </div>
                    <div class="col-12 col-md-9">
                      <div class="w-100 d-flex">
                        <app-select
                          class="w-100 rounded-sm"
                          formControlName="licenceDuration"
                          [ngClass]="{ mustCheck: !isExistingJob }"
                          iconColor="white">
                          <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="forever">
                            Forever
                          </app-option>
                          <app-option class="d-flex w-100 py-1 align-items-center text-white" translate value="other">
                            Defined period
                          </app-option>
                        </app-select>
                      </div>
                      <div class="w-100 mt-2" *ngIf="otherLicenceDurationSelected()">
                        <app-text-field
                          class="d-flex rounded-sm w-100"
                          formControlName="licenceDurationCustom"></app-text-field>
                        <small class="text-white" translate>Define a custom period the licence is valid</small>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-12 col-md-3 d-flex pl-0 align-items-top pt-2">
                      <label translate>Commerial use</label>
                    </div>
                    <div class="col-12 col-md-9">
                      <div class="w-100 d-flex">
                        <app-select class="w-100 rounded-sm" formControlName="commercialUse" iconColor="white">
                          <app-option class="d-flex w-100 py-1 align-items-center text-white" translate [value]="true">
                            Yes
                          </app-option>
                          <app-option class="d-flex w-100 py-1 align-items-center text-white" translate [value]="false">
                            No
                          </app-option>
                        </app-select>
                      </div>
                      <small class="text-white" translate>
                        Commercial uses gives you the permission to use the footage for commercial purposes, for example
                        to promote your business or product.
                      </small>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <app-label><span translate>Lunch / dinner included?</span></app-label>
    </div>
    <div class="col-12 col-sm-9">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <label class="mr-4 d-flex align-items-center">
            <app-radio [value]="true" class="mr-2" formControlName="foodIncluded"></app-radio>
            <span translate>Yes</span>
          </label>
          <label class="d-flex align-items-center">
            <app-radio [value]="false" class="mr-2" formControlName="foodIncluded"></app-radio>
            <span translate>No</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <hr class="my-3" />

  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex">
      <app-label [isRequired]="false"><span translate>Traveling cost</span></app-label>
    </div>
    <div class="col-12 col-sm-9 d-flex">
      <label class="d-flex align-items-center mr-4">
        <app-radio
          class="haveTravelCost mr-2"
          [ngClass]="{ mustCheck: !isExistingJob }"
          [value]="false"
          formControlName="haveTravelingCost"></app-radio>
        <span translate>No</span>
      </label>
      <label class="d-flex align-items-center">
        <app-radio
          class="haveTravelCost mr-2"
          [ngClass]="{ mustCheck: !isExistingJob }"
          [value]="true"
          formControlName="haveTravelingCost"></app-radio>
        <span translate>Yes</span>
      </label>
    </div>
  </div>

  <div class="row mb-3" *ngIf="form.get('haveTravelingCost')?.value == true">
    <div class="col-12 col-sm-3 d-flex"></div>
    <div class="col-12 col-sm-9 d-flex">
      <label class="mr-4 d-flex align-items-center">
        <app-text-field class="mr-2" formControlName="travelingCost" type="number" step="0.01"></app-text-field>
        <span translate>per km</span>
      </label>
    </div>
  </div>
  <div class="row mb-3" *ngIf="form.get('travelingCost')?.value != null && form.get('travelingCost')?.value != false">
    <div class="col-12 col-sm-3 d-flex"></div>
    <div class="col-12 col-sm-9 d-flex">
      <label class="mr-4 d-flex align-items-center">
        <app-checkbox
          class="mr-2"
          formControlName="maxTravelingCost"
          [trueValue]="0"
          [falseValue]="null"
          [checked]="form.get('maxTravelingCost')?.value != null"></app-checkbox>
        <span translate>Max. amount</span>
      </label>
    </div>
  </div>

  <div class="row mb-3" *ngIf="form.get('maxTravelingCost')?.value != null">
    <div class="col-12 col-sm-3 d-flex"></div>
    <div class="col-12 col-sm-9 d-flex">
      <app-text-field class="mr-2" formControlName="maxTravelingCost" type="number"></app-text-field>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex">
      <app-label [isRequired]="false"><span translate>Parking cost</span></app-label>
    </div>
    <div class="col-12 col-sm-9 d-flex">
      <label class="mr-4 d-flex align-items-center">
        <app-radio class="mr-2" [value]="null" formControlName="parkingCost"></app-radio>
        <span translate>No</span>
      </label>
      <label class="d-flex align-items-center">
        <app-radio class="mr-2" [value]="true" formControlName="parkingCost"></app-radio>
        <span translate>Yes</span>
      </label>
    </div>
  </div>

  <div class="row mb-3" *ngIf="form.get('parkingCost')?.value === true">
    <div class="col-12 col-sm-3 d-flex"></div>
    <div class="col-12 col-sm-9 d-flex">
      <label class="mr-4 d-flex align-items-center">
        <app-checkbox
          class="mr-2"
          formControlName="maxParkingCost"
          [trueValue]="0"
          [falseValue]="null"
          [checked]="form.get('maxParkingCost')?.value != null"></app-checkbox>
        <span translate>Max. amount</span>
      </label>
    </div>
  </div>

  <div class="row mb-3" *ngIf="form.get('travelingCost')?.value != null && form.get('maxParkingCost')?.value != null">
    <div class="col-12 col-sm-3 d-flex"></div>
    <div class="col-12 col-sm-9 d-flex">
      <app-text-field class="mr-2" formControlName="maxParkingCost" type="number"></app-text-field>
    </div>
  </div>

  <hr class="my-3" />

  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex">
      <app-label [isRequired]="true"><span translate>Shoot description</span></app-label>
    </div>
    <div class="col-12 col-sm-9 d-flex">
      <app-textarea
        class="w-100 d-flex flex-fill rounded-sm mb-1"
        formControlName="shortDescription"
        minHeight="100"></app-textarea>
    </div>

    <div *ngIf="!isDirect" class="col-12 offset-0 col-sm-9 offset-sm-3 d-flex">
      <small class="text-white" translate>
        Tell the talents what the job is about, what they can expect, do they need to bring anything? The more details
        the better.
      </small>
    </div>
  </div>

  <ng-container *ngIf="!isDirect">
    <div class="row mb-3">
      <div class="col-12 col-sm-3 d-flex">
        <app-label><span translate>Gender</span></app-label>
      </div>
      <div class="col-12 col-sm-9 d-flex">
        <app-checkbox-group class="w-100 gender" [ngClass]="{ mustCheck: !isExistingJob }" formControlName="gender">
          <label class="d-flex align-items-center">
            <app-checkbox class="mr-2" value="male"></app-checkbox>
            <span translate>Male</span>
          </label>

          <label class="d-flex align-items-center">
            <app-checkbox class="mr-2" value="female"></app-checkbox>
            <span translate>Female</span>
          </label>

          <label class="d-flex align-items-center">
            <app-checkbox class="mr-2" value="transgender"></app-checkbox>
            <span translate>Transgender</span>
          </label>
        </app-checkbox-group>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-sm-3 d-flex">
        <app-label><span translate>Age</span></app-label>
      </div>
      <div class="col-12 col-sm-9 d-flex">
        <app-slider
          [options]="{ floor: 0, ceil: 100 }"
          class="w-100 flex-fill rounded-sm mb-1 p-2 age"
          [ngClass]="{ mustCheck: !isExistingJob }"
          formControlName="age"></app-slider>
      </div>
    </div>

    <hr class="my-3" />

    <div class="row mt-2">
      <div class="col-12 col-sm-3 d-flex align-items-center">
        <app-label><span translate>Request video</span></app-label>
      </div>
      <div class="col-12 col-sm-9">
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <label class="mr-4 d-flex align-items-center">
              <app-radio [value]="true" class="mr-2" formControlName="requestTalentVideo"></app-radio>
              <span translate>Yes</span>
            </label>
            <label class="d-flex align-items-center">
              <app-radio [value]="false" class="mr-2" formControlName="requestTalentVideo"></app-radio>
              <span translate>No</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-sm-3 d-flex align-items-center"></div>
      <div class="col-12 col-sm-9 d-flex">
        <app-textarea
          [isDisabled]="requestTalentVideo.value != true"
          [placeholder]="'Write a description about your video and perhaps with a script on it' | translate"
          class="w-100 d-flex flex-fill rounded-sm mb-1"
          formControlName="requestTalentVideoDescription"
          minHeight="100"></app-textarea>
      </div>
    </div>

    <hr class="my-3" />

    <div *ngIf="showAdditionalOptions" class="border rounded-sm py-2 px-2">
      <div class="row mb-3" formArrayName="languages">
        <div class="col-12 col-sm-3 d-flex pt-2">
          <app-label><span translate>Language</span></app-label>
        </div>
        <div class="col-12 col-sm-9">
          <div
            *ngFor="let language of languages.controls; let i = index"
            [formGroupName]="i"
            class="row mb-2"
            class="language-{{ i }}">
            <div *ngIf="languages$ | async as languageOptions" class="col-10 col-sm-6 d-flex">
              <app-select class="w-100 rounded-sm mr-2" formControlName="id" iconColor="black" [searchable]="true">
                <app-option
                  *ngFor="let item of languageOptions?.results"
                  [value]="item.id"
                  [searchValue]="item.translateKey | translate"
                  class="d-flex w-100 py-1 align-items-center">
                  {{ item.translateKey | translate }}
                </app-option>
              </app-select>
            </div>
            <div class="col-1 px-0">
              <app-button
                (click)="removeLanguage(i)"
                buttonStyle="white-outline"
                class="ml-2 align-self-center"
                id="removeLanguage-{{ i }}">
                <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
              </app-button>
            </div>
          </div>
          <div class="row">
            <div class="col-6 d-flex">
              <app-button (click)="addLanguage()" buttonStyle="white-outline" class="w-100 mr-2" id="addLanguage">
                <app-icon fill="white" icon="/assets/img/plus.svg"></app-icon>
              </app-button>
            </div>
          </div>
          <small class="text-white" translate>Which language(s) do you want your model to speak?</small>
        </div>
      </div>
      <div *ngIf="!!category" formGroupName="filters">
        <div *ngFor="let categoryFilter of category.filters" class="row mb-3">
          <app-label class="col-12 col-sm-3 align-self-start mt-2" translate>
            {{ 'FILTER.' + categoryFilter.slug | translate }}
          </app-label>

          <div class="col-12 col-sm-9">
            <div *ngIf="categoryFilter.type === 'checkbox'" class="rounded-sm py-2 px-3">
              <app-checkbox-group [formControlName]="categoryFilter.slug" class="row w-100">
                <label
                  *ngFor="let option of categoryFilter.filterOptions"
                  class="col-6 col-sm-4 my-1 d-flex align-items-center">
                  <app-checkbox [value]="option.id" class="mr-2" class="mr-2"></app-checkbox>
                  <app-icon
                    *ngIf="categoryFilter.hasIcon"
                    [icon]="'assets/img/filter-options/' + categoryFilter.slug + '/' + option.slug + '.svg'"
                    class="mr-2"
                    fill="white"></app-icon>
                  {{ option.translateKey | translate }}
                </label>
              </app-checkbox-group>
            </div>

            <div *ngIf="categoryFilter.type === 'slider'" class="rounded-sm px-3 py-2">
              <div class="row">
                <app-weight-slider
                  *ngIf="categoryFilter.unit === 'kg'"
                  [formControlName]="categoryFilter.slug"
                  [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"
                  class="col-12"></app-weight-slider>

                <app-metric-slider
                  *ngIf="categoryFilter.unit === 'cm'"
                  [formControlName]="categoryFilter.slug"
                  [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"
                  class="col-12"></app-metric-slider>
              </div>
            </div>

            <div *ngIf="categoryFilter.type === 'color'" class="rounded-sm px-3 pb-2">
              <div class="row">
                <app-color-slider [formControlName]="categoryFilter.slug" class="col-12"></app-color-slider>
              </div>
            </div>
            <div *ngIf="categoryFilter.type === 'number'" class="rounded-sm px-3">
              <div class="row">
                <app-text-field
                  class="col-12 flex-fill rounded-sm mb-1"
                  [formControlName]="categoryFilter.slug"></app-text-field>
              </div>
            </div>
            <div *ngIf="categoryFilter.type === 'text'" class="rounded-sm px-3">
              <div class="row">
                <app-text-field
                  class="col-12 flex-fill rounded-sm mb-1"
                  [formControlName]="categoryFilter.slug"></app-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <hr class="my-3" />

  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <label translate>Media</label>
    </div>
    <div class="col-12 col-sm-9 d-flex align-items-center mediaItems" formArrayName="media">
      <div class="mediaItems">
        <div
          *ngFor="let mediaItem of media.controls; let i = index"
          [formGroup]="mediaItem"
          [style.background-image]="mediaItem.get('url').value != null ? 'url(' + mediaItem.get('url').value + ')' : ''"
          class="media w-100">
          <div class="overlay">
            <app-media-field
              accept="image/png,image/x-png,image/gif,image/jpeg"
              class="mt-auto mr-auto"
              formControlName="file"></app-media-field>
            <div (click)="removeMedia(i)" class="delete ml-auto mt-auto mr-1">
              <app-icon fill="white" icon="/assets/img/trash.svg"></app-icon>
            </div>
          </div>
        </div>
      </div>
      <app-button
        (click)="addMedia()"
        *ngIf="media.controls.length < 9"
        class="mt-3 prefix-plus w-50"
        buttonStyle="artyclick-purple">
        <span translate>Add photo</span>
      </app-button>
    </div>
  </div>

  <hr class="my-3" />

  <div class="row mb-2">
    <div class="col-12 col-sm-3 d-flex align-items-start pt-2">
      <label>Custom design</label>
    </div>
    <div class="col-12 col-sm-9">
      <div class="w-100 d-flex">
        <label class="w-100 d-flex align-items-center">
          <app-checkbox
            class="mr-2"
            formControlName="customDesign"
            [isReadOnly]="job?.payment?.paymentStatus == 'completed'"
            [checked]="job?.customDesign"></app-checkbox>
          {{ "We create a custom design of your job post to attract the best candidates" | translate }}
        </label>
        <app-locked-indicator [locked]="job?.payment?.paymentStatus == 'completed'" class="m-2"></app-locked-indicator>
      </div>
      <small class="text-white" translate>Our design team will make a custom design</small>
      &nbsp;
      <a target="_blank" href="https://www.instagram.com/casterbee">
        <small class="text-example" translate>Examples</small>
      </a>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isDirect">
    <div class="col-12 col-sm-3 d-flex align-items-start pt-2">
      <label translate>Upgrade listing (optional)</label>
    </div>
    <div class="col-12 col-sm-9">
      <div class="w-100 d-flex">
        <label class="w-100 d-flex align-items-center" translate>
          <app-checkbox
            class="mr-2"
            formControlName="promoted"
            [isReadOnly]="job?.payment?.paymentStatus == 'completed'"
            [checked]="job?.promoted"></app-checkbox>
          Promoted
        </label>
        <app-locked-indicator [locked]="job?.payment?.paymentStatus == 'completed'" class="m-2"></app-locked-indicator>
      </div>
      <small class="text-white" translate>
        Posting a job is free of any additional charge. But if you want your job to really stand out from the rest, you
        can boost it for only € 9.
      </small>
    </div>
  </div>

  <hr class="my-3" />

  <div class="row">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <label translate>Total costs incl. VAT</label>
    </div>
    <div class="col-12 col-sm-9 d-flex flex-row mb-3 align-items-center">
      <div class="d-flex flex-row mr-3 rounded-sm w-25 p-2 currency">
        <span class="flex-grow-1">€</span>
        <span>{{ this.talentPaymentCosts | currency: 'EUR' : '' }}</span>
      </div>
      <small class="text-white" translate>The fee you have to pay when you select a Talent.</small>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <label translate>VAT</label>
    </div>
    <div class="col-12 col-sm-9 flex-column mb-3">
      <div class="d-flex mr-3 rounded-sm w-25 p-2 currency">
        <span class="flex-grow-1">€</span>
        <span>{{ this.talentPaymentVAT | currency: 'EUR' : '' }}</span>
      </div>
    </div>
  </div>

  <hr />

  <div class="row mb-3">
    <div class="col-12 col-sm-9 offset-sm-3 d-flex flex-row align-items-center">
      <div class="d-flex flex-row mr-3 rounded-sm w-25 p-2 currency">
        <span class="flex-grow-1">€</span>
        <span>{{ this.directPaymentCosts | currency: 'EUR' : '' }}</span>
      </div>
      <small class="text-white" translate>The fee you must pay directly when posting this job.</small>
    </div>
  </div>

  <hr />

  <div class="row mb-3">
    <div class="col-12 col-sm-3 d-flex align-items-center">
      <app-label><span translate>Discount code</span></app-label>
    </div>
    <div class="col-12 col-sm-4">
      <app-text-field class="d-inline-block rounded-sm" formControlName="budgetCamOrderNumber"></app-text-field>
    </div>
  </div>

  <hr />

  <div class="row mb-3" *ngIf="isDirect">
    <div class="col-12">
      <app-button
        *ngIf="needsPayment && job?.payment?.paymentStatus != 'completed'; else noPaymentDirect"
        [isBusy]="isBusy"
        [isDisabled]="form.invalid"
        buttonStyle="orange">
        <button [disabled]="form.invalid" class="w-100 h-100 bg-transparent text-white" translate type="submit">
          Offer job
          <ng-container translate>and create payment</ng-container>
        </button>
      </app-button>

      <ng-template #noPaymentDirect>
        <app-button [isBusy]="isBusy" [isDisabled]="form.invalid" buttonStyle="orange">
          <button [disabled]="form.invalid" class="w-100 h-100 bg-transparent text-white" translate type="submit">
            Offer job
          </button>
        </app-button>
      </ng-template>
    </div>
  </div>

  <div class="row mb-3" *ngIf="!isDirect">
    <div class="col-6 col-sm-3">
      <app-button [isBusy]="isBusy" [isDisabled]="form.invalid || isSubmitted" buttonStyle="artyclick-purple">
        <button
          [disabled]="form.invalid || isSubmitted"
          class="w-100 border-0 bg-transparent text-white m-2"
          type="submit">
          <span
            translate
            *ngIf="!isEdited && needsPayment && job?.payment?.paymentStatus != 'completed'; else noPaymentNonDirect">
            Save Job and create payment
          </span>
        </button>
      </app-button>

      <ng-template #noPaymentNonDirect>
        <span translate>Save Job</span>
      </ng-template>
    </div>
    <div class="col-6 col-sm-8 d-flex">
      <app-alert [type]="message?.type" class="rounded-sm align-items-center px-2 align-self-stretch flex-fill">
        {{ message?.message }}
      </app-alert>
    </div>
  </div>
</form>
