<ng-container *ngIf="styleUI === 'dekstop'">
  <div class="container">
    <div class="row mb-2">
      <div class="align-items-center">
        <app-button
          (click)="sendMobileVerification()"
          *ngIf="!valid"
          [isBusy]="isBusy"
          [isDisabled]="isBusy || timer$"
          gradientName="purple-bright">
          <span *ngIf="!timer$; else timer">Send verification code</span>
          <ng-template #timer>{{ timer$ | async }}</ng-template>
        </app-button>
      </div>
    </div>
    <div class="row">
      <div class="d-flex">
        <app-text-field #digit class="bg-gray rounded-sm border-0 mr-1" maxlength="1"></app-text-field>
        <app-text-field #digit class="bg-gray rounded-sm border-0 mr-1" maxlength="1"></app-text-field>
        <app-text-field #digit class="bg-gray rounded-sm border-0 mr-1" maxlength="1"></app-text-field>
        <app-text-field #digit class="bg-gray rounded-sm border-0 mr-1" maxlength="1"></app-text-field>
        <div class="ml-2 d-flex align-items-center">
          <app-validation-indicator [field]="this" class="icon"></app-validation-indicator>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="styleUI === 'mobile'">
  <div class="wrap-mobile-verfication">
    <div class="row">
      <div class="col-12">
        <button
          type="submit"
          class="btn-verify-mobile"
          (click)="sendMobileVerification()"
          *ngIf="!valid"
          [disabled]="isBusy || timer$">
          <span *ngIf="!timer$; else timer">{{ "Send verification code" | translate }}</span>
          <ng-template #timer>{{ timer$ | async }}</ng-template>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
        </button>
      </div>
      <div class="col-10">
        <input #inlineDigit type="number" class="form-control" value="" />
      </div>
      <div class="col-2">
        <app-validation-indicator [field]="this" class="icon"></app-validation-indicator>
      </div>
    </div>
  </div>
</ng-container>
