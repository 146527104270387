<section class="feature">
  <div class="swiper-feature">
    <div class="wrap-scroll">
      <ul>
        <li class="feature-prev" translate>For Casters</li>
        <li class="feature-next" translate>For Talents</li>
      </ul>
      <div class="swiper-scrollbar"></div>
    </div>
    <div class="swiper-wrapper">
      <div class="card-feature swiper-slide">
        <div class="header">
          <img src="/assets/img/mobile/feature/feature-2.png" alt="" />
          <div class="info">
            <h6 translate>How it works</h6>
            <p translate>When booking we only invoice the placed fee excl. VAT/travel expense</p>
          </div>
        </div>
        <ol>
          <li translate>You are in control. Casterbee offers you the free tools</li>
          <li translate>Streamline your work process and avoid email subfolders and time-consuming manual processes</li>
          <li translate>Never forget to download a WeTransfer again. View the videos from your dashboard</li>
          <li translate>Complete days of work in just one hour, from booking to rejection with 1 click</li>
        </ol>
        <button class="btn" (click)="signUp('caster')" type="button">{{'Place job' | translate}}</button>
      </div>
      <div class="card-feature swiper-slide">
        <div class="header">
          <img src="/assets/img/mobile/feature/feature-1.png" alt="" />
          <div class="info">
            <h6 translate>How it works</h6>
            <p translate>Get visible for Casters and get booked</p>
          </div>
        </div>
        <ol>
          <li translate>Increase your visibility for free with photos and videos</li>
          <li translate>Waiting 3 months for your payment is over, we aim for payment within 30 days</li>
          <li translate>Respond to jobs for free (limited per month with a free profile)</li>
          <li translate>
            Casterbee does not mark-up fees. You always know how much the client paid, full transparency
          </li>
        </ol>
        <button class="btn" (click)="signUp('talent')" type="button" translate>{{'Get booked' | translate}}</button>
      </div>
    </div>
  </div>
</section>
<app-mobile-auth-modal #loginModal></app-mobile-auth-modal>
