import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePictureComponent } from '@src/ui/profile-picture/components/profile-picture.component';
import { ImagesModule } from '@src/ui/generic/images/images.module';

const components = [ProfilePictureComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ImagesModule],
  exports: components,
})
export class ProfilePictureModule {}
