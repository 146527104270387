import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: [
    '../text-field/text-field.component.scss',
    './date-field.component.scss',
    '../time-field/time-field.component.scss',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFieldComponent),
      multi: true,
    },
  ],
})
export class DateFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public placeholder = '';

  @Input()
  public isReadOnly = false;

  @Input()
  public iconColor = 'white';

  public value: Moment;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public onBlur(): void {
    this.onChange(this.value);
  }

  public onValueChange(value: any): void {
    value = moment(value);
    this.value = this.value.local().set({ date: value.date(), month: value.month(), year: value.year() }).utc();
    this.onChange(this.value);
  }

  public writeValue(value: Moment): void {
    this.value = moment(value?.utc().format())?.utc();
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private updateField(value: Moment): void {
    if (!this.inputEl) {
      return;
    }

    this.inputEl.nativeElement.value = value.local().format('DD-MM-YYYY');
    // this.inputEl.nativeElement.value = formattedDate;
  }
}
