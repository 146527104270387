<div *ngIf="talent" class="d-flex flex-column w-100 h-100">
  <div (click)="onClick($event)" [ngStyle]="{ 'height.px': imageSize?.height }" class="position-relative">
    <app-image
      [image]="talent.photo.thumbUrl"
      [ngStyle]="{ 'width.px': imageSize?.width, 'height.px': imageSize?.height }"
      class="cover d-block rounded-sm initial"></app-image>

    <app-gradient class="top-bar rounded-sm" gradientDirection="vertical" gradientName="black-transparent">
      <app-icon *ngIf="talent.isPro" [useOriginal]="true" class="icon" icon="/assets/img/talent-pro.svg"></app-icon>
    </app-gradient>

    <app-gradient
      [gradientName]="hover ? 'purple-bright-transparent' : 'black-transparent'"
      class="bottom-bar rounded-sm d-flex flex-column justify-content-end"
      gradientDirection="vertical-reverse">
      <app-money [amount]="talent.rate" class="h5 mb-1"></app-money>
      <app-rating
        *ngIf="talent.totalReviews > 0"
        [average]="talent?.averageReview"
        [count]="talent.totalReviews"></app-rating>
    </app-gradient>
  </div>
  <div class="position-absolute user-checkbox">
    <label class="d-flex align-items-center" *ngIf="showCheckbox">
      <input (input)="select($event.target.checked)" type="checkbox" [checked]="checked" />
    </label>
  </div>
  <div (click)="onClick($event)" class="footer d-flex flex-fill align-items-center mt-auto">
    <div [ngStyle]="{ 'width.px': tileSize.width }" class="flex-column details">
      <div>{{ talent.profileName }}</div>
      <div class="text-gray">{{ talent.city }}, {{ talent.country.isoCode }}</div>
    </div>
  </div>
</div>
