import { IDeck, IDeckUser } from '@core/interfaces';
import { Type } from 'class-transformer';
import { DeckUser } from './deck-user.model';
import { Media } from './media.model';

export class Deck implements IDeck {
  @Type(() => DeckUser)
  public deckUsers: IDeckUser[];

  @Type(() => Media)
  public cover: Media;

  public id: string;
  public coverId: string;
  public title: string;
  public description: string;
}
