import { Component, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MobileModalDialogComponent } from '@shared/mobile-components/mobile-modal-dialog/mobile-modal-dialog.component';

const numberOnlyValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;

    if (value === null || value === '') {
      return null; // Don't validate empty values to allow optional controls
    }

    // Check if the string contains only digits
    const valid = /^\d+$/.test(value);

    return valid ? null : { numberOnly: true };
  };
};
@Component({
  selector: 'app-mobile-claim-travel-expenses-account-number-modal',
  templateUrl: './mobile-claim-travel-expenses-account-number-modal.component.html',
  styleUrls: ['./mobile-claim-travel-expenses-account-number-modal.component.scss'],
})
export class MobileClaimTravelExpensesAccountNumberModalComponent implements OnInit {
  @Output()
  public saveAccountNumber: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('claimTravelExpensesAccountNumber') private modalComponent: MobileModalDialogComponent;

  public isSuccess = false;
  public form: FormGroup = new FormGroup({
    bankNumber: new FormControl(null, [Validators.required]),
    accountName: new FormControl(null),
    coc: new FormControl(null, [numberOnlyValidator()]),
    vat: new FormControl(null),
    kor: new FormControl(false),
  });

  constructor(
    @Optional() private dialogRef: MatDialogRef<MobileClaimTravelExpensesAccountNumberModalComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
  ) {
    if (data?.form) {
      const dataForm = data.form as FormGroup;
      this.form = dataForm;
    }
  }

  ngOnInit(): void {}

  public changeKor(value) {
    this.form.get('kor').setValue(value);
  }

  public async openDialog(form) {
    const dataForm = form as FormGroup;
    this.form.patchValue(dataForm.value);
    // this.form = dataForm;
    return await this.modalComponent.open().then(() => {});
  }

  public async close() {
    return await this.modalComponent.close();
  }

  public submit() {
    this.saveAccountNumber.emit(this.form);
  }
}
