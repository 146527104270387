import { BaseOppObjectDTO } from './base-opp-object.dto';

export enum BankAccountStatus {
  new = 'new',
  pending = 'pending',
  approved = 'approved',
  disapproved = 'disapproved',
}

export class BankAccountDTO extends BaseOppObjectDTO {
  public verified: number;
  public verification_url: string;
  public status: BankAccountStatus;
  public account: AccountDTO;
  public bank: BankDTO;
  public reference: string;
  public return_url: string;
  public notify_url: string;
  public is_default: boolean;
}

export class AccountDTO {
  public account_iban: string;
  public account_name: string;
}

export class BankDTO {
  public bic: string;
}

export class CreateBankAccountDTO {
  public return_url: string;
  public notify_url: string;
  public is_default?: boolean;
  public reference?: string;
  public verification_method?: string;
}
