import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CountriesService } from '@core/services';
import { CountriesStateModel } from './countries.state-model';
import { LoadCountries } from './countries.actions';
import { Country, PaginatedResults } from '@core/models';

@State<CountriesStateModel>({
  name: 'countries',
  defaults: {
    countries: null,
  },
})
@Injectable()
export class CountriesState implements NgxsOnInit {
  public constructor(private countriesService: CountriesService) {}

  @Selector()
  static countries(state: CountriesStateModel): PaginatedResults<Country> {
    return state.countries;
  }

  @Action(LoadCountries)
  public async loadCountries(ctx: StateContext<CountriesStateModel>): Promise<void> {
    const countries = await this.countriesService.findAll().toPromise();
    ctx.patchState({ countries });
  }

  public ngxsOnInit(ctx: StateContext<CountriesStateModel>): void {
    ctx.dispatch(new LoadCountries());
  }
}
