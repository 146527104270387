import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(dateVal: any, args?: any): any {
    if (dateVal) {
      const currentDate: Date = new Date();
      const difference: number = moment(currentDate).diff(dateVal, 'days');
      const locale: string = localStorage.getItem('language');

      // set locale based on active language
      moment.locale(locale);

      if (difference < 1 || (difference > 1 && difference < 7)) {
        return moment(dateVal).local().fromNow();
      } else if (difference === 1) {
        return moment(dateVal).local().calendar(currentDate);
      } else {
        return moment(dateVal).local().format('LL');
      }
    }
    return dateVal;
  }
}
