<div class="input-group">
  <mat-date-range-input
    (click)="pickerRangeDate.open()"
    *ngIf="!isReadOnly"
    [rangePicker]="pickerRangeDate"
    class="form-control"
    [formGroup]="range"
    readonly>
    <input #startDate matStartDate placeholder="Start date" formControlName="startDate" />
    <input #endDate matEndDate placeholder="End date" formControlName="endDate" />
  </mat-date-range-input>

  <div *ngIf="isReadOnly" class="form-control">{{ value.toDate() | date: 'dd-MM-YYYY' }}</div>

  <div class="input-group-append">
    <mat-datepicker-toggle *ngIf="!isReadOnly" [for]="pickerRangeDate" class="d-flex align-items-center" matSuffix>
      <app-icon
        [fill]="iconColor"
        [stroke]="iconColor"
        icon="/assets/img/calendar.svg"
        matDatepickerToggleIcon></app-icon>
    </mat-datepicker-toggle>
    <div *ngIf="isReadOnly" class="d-flex align-items-center mr-3">
      <app-icon [fill]="iconColor" [stroke]="iconColor" icon="/assets/img/calendar.svg"></app-icon>
    </div>
  </div>

  <mat-date-range-picker #pickerRangeDate></mat-date-range-picker>
</div>
