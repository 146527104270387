import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TravelExpenseType } from '@core/interfaces';
import { Observable, Subscription } from 'rxjs';
import { JobApplication } from '@core/models';
import { JobsState } from '@core/states';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-mobile-claim-travel-expenses-row',
  templateUrl: './mobile-claim-travel-expenses-row.component.html',
  styleUrls: ['./mobile-claim-travel-expenses-row.component.scss'],
})
export class MobileClaimTravelExpensesRowComponent implements OnInit, OnDestroy {
  @Input()
  public jobApplication: JobApplication;

  @Input()
  public maxInvoiceTCAmount = 0;

  @Input()
  public maxInvoicePCAmount = 0;

  @Input()
  public travelingCost = 0;

  @Input()
  public removeBtn;

  @Input()
  public addBtn;

  @Input()
  public formGroup: FormGroup = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    type: new FormControl(TravelExpenseType.kilometers, [Validators.required]),
    description: new FormControl(null),
    distance: new FormControl(null),
    time: new FormControl(null),
    price: new FormControl(null),
  });

  @Input()
  public disableTravelCost = true;

  @Input()
  public disableParkingCost = true;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public copyForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @Select(JobsState.jobApplication)
  public jobApplication$: Observable<JobApplication>;

  public readonly types: string[] = Object.values(TravelExpenseType);

  public get type(): TravelExpenseType {
    return this.formGroup.get('type').value;
  }

  public disabled: boolean;

  private valueChangesSubscription: Subscription;

  public async ngOnInit(): Promise<void> {
    switch (this.type) {
      case TravelExpenseType.kilometers:
        if (!this.disableTravelCost) {
          this.formGroup.disable();
          this.disabled = true;
        }
        this.formGroup.get('price').setValidators([
          // Validators.required,
          Validators.min(0),
          Validators.max(this.maxInvoiceTCAmount || this.jobApplication.job.maxTravelingCost),
        ]);
        break;
      case TravelExpenseType.other:
        if (!this.disableTravelCost) {
          this.formGroup.disable();
          this.disabled = true;
        }
        this.formGroup
          .get('price')
          .setValidators([
            Validators.min(0),
            Validators.max(this.maxInvoiceTCAmount || this.jobApplication.job.maxTravelingCost),
          ]);
        break;
      case TravelExpenseType.parking:
        if (!this.disableParkingCost) {
          this.formGroup.disable();
          this.disabled = true;
        }
        this.formGroup.get('price').setValidators([
          // Validators.required,
          Validators.min(0),
          Validators.max(this.maxInvoicePCAmount || this.jobApplication.job.maxParkingCost),
        ]);
        break;
    }

    this.valueChangesSubscription = this.formGroup.get('distance').valueChanges.subscribe((distance: number): void => {
      const max: number =
        Math.round(distance * (this.travelingCost || this.jobApplication.job.travelingCost) * 100) / 100;
      this.formGroup.patchValue({
        price: Math.min(max, (this.maxInvoiceTCAmount || this.jobApplication.job.maxTravelingCost) ?? max),
      });
    });

    this.valueChangesSubscription = this.formGroup
      .get('type')
      .valueChanges.subscribe((type: TravelExpenseType): void => {
        switch (type) {
          case TravelExpenseType.kilometers:
            this.formGroup
              .get('price')
              .setValidators([
                Validators.required,
                Validators.min(0),
                Validators.max(this.maxInvoiceTCAmount || this.jobApplication.job.maxTravelingCost),
              ]);
            break;
          case TravelExpenseType.parking:
            this.formGroup
              .get('price')
              .setValidators([
                Validators.required,
                Validators.min(0),
                Validators.max(this.maxInvoicePCAmount || this.jobApplication.job.maxParkingCost),
              ]);
            break;
        }
        // this.formGroup.setControl('price', new FormControl(0, [Validators.required]));
      });
  }

  public ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();
  }
}
