import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent {
  @Input()
  public lazy = false;

  @Input()
  public src: string;

  @Input()
  public showOutline?: boolean;
}
