import { AfterViewInit, Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public checked = false;

  @Input()
  public value: string;

  @Input()
  public trueValue: any = true;

  @Input()
  public falseValue: any = false;

  @Input()
  public isReadOnly = false;

  public onChange = (_: any): void => {};
  public onTouched = (): void => {};

  public ngAfterViewInit(): void {
    this.updateCheckbox(this.checked);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('checked' in changes) {
      this.updateCheckbox(changes.checked.currentValue);
    }
  }

  public onValueChange(checked: boolean): void {
    if (this.isReadOnly) {
      return;
    }
    const value = checked ? this.trueValue : this.falseValue;
    this.checked = checked;
    this.onChange(value);
  }

  public writeValue(checked: any): void {
    this.checked = checked;
    this.updateCheckbox(this.checked);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private updateCheckbox(checked: boolean): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.checked = checked;
  }
}
