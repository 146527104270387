import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobApplicationsService } from '@core/services';
import { JobApplicationStatus } from '@core/interfaces';
@Component({
  selector: 'app-cancel-job-application-modal',
  templateUrl: './cancel-job-application-modal.component.html',
  styleUrls: ['./cancel-job-application-modal.component.scss'],
})
export class CancelJobApplicationModalComponent implements OnInit {
  public isSuccess = false;

  constructor(
    @Optional() private dialogRef: MatDialogRef<CancelJobApplicationModalComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
    private jobsApplicationsService: JobApplicationsService,
  ) {}

  ngOnInit(): void {}

  public closeModal(): void {
    this.dialogRef.close(this.isSuccess);
  }

  public agree() {
    try {
      this.jobsApplicationsService
        .updateJobApplicationState(
          this.data.jobApplication.job.id,
          this.data.jobApplication.id,
          JobApplicationStatus.cancelled,
        )
        .subscribe(() => {
          this.isSuccess = true;
        });
    } catch (error) {
      console.log(error);
    }
  }
}
