import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { GenericModule } from '../generic/generic.module';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '../notifications/notifications.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from '../search/search.module';
import { ScreenSizeModule } from '../screen-size/screen-size.module';

@NgModule({
  declarations: [NavbarComponent, SidebarComponent, FooterComponent],
  imports: [
    CommonModule,
    GenericModule,
    NotificationsModule,
    TranslateModule,
    RouterModule,
    SearchModule,
    NgbModule,
    ScreenSizeModule,
  ],
  exports: [NavbarComponent, SidebarComponent, FooterComponent],
})
export class NavigationModule {}
