import { Component, Inject, OnInit, Optional } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Job, JobApplication, User } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { AcceptJobApplicationAndCreatePayment, PaymentsState } from '@core/states';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-job-accept-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class AcceptJobTalentConfirmComponent implements OnInit {
  @Select(PaymentsState.paymentSuccessId)
  public paymentSuccessId: Observable<string>;

  public jobApplication: JobApplication;
  public job: Job;
  public user: User;

  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<AcceptJobTalentConfirmComponent>,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.jobApplication = this.data.jobApplication;
    this.job = this.data.job;
    this.user = this.data.user;
    if (this.job.bypassPayment === false) {
      this.paymentSuccessId.subscribe((value) => {
        if (this.jobApplication.id === value) {
          this.dialogRef.close();
        }
      });
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public acceptJobApplication(): void {
    this.isBusy = true;
    const returnUrl = `${environment.baseUrl}/account/jobs/status/open`;
    this.store.dispatch(
      new AcceptJobApplicationAndCreatePayment(
        this.user.id,
        this.jobApplication.user.id,
        this.jobApplication.jobId,
        this.jobApplication.id,
        returnUrl,
        !this.job.bypassPayment,
      ),
    );
    if (this.job.bypassPayment) {
      this.dialogRef.close();
    }
  }
}
