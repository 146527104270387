import { Component, Input, OnInit } from '@angular/core';
import { IInvoice } from '@core/interfaces';

@Component({
  selector: 'app-mobile-invoice-card',
  templateUrl: './mobile-invoice-card.component.html',
  styleUrls: ['./mobile-invoice-card.component.scss'],
})
export class MobileInvoiceCardComponent implements OnInit {
  @Input()
  invoices: IInvoice[];

  constructor() {}

  ngOnInit(): void {}

  public previewImage(invoice: IInvoice): string {
    switch (invoice.bookingsType) {
      case 'CR':
        return '/assets/img/mobile/default-invoice.png';
      case 'CB':
        return invoice.payment?.jobApplication?.job?.media?.length > 0
          ? invoice.payment?.jobApplication?.job?.media[0]?.url
          : '/assets/img/mobile/default-invoice.png';
      case 'TC':
        return invoice.payment?.jobApplication?.job?.media?.length > 0
          ? invoice.payment?.jobApplication?.job?.media[0]?.url
          : '/assets/img/mobile/default-invoice.png';
      case 'CS':
        return invoice.payment?.job?.media?.length > 0
          ? invoice.payment?.job?.media[0]?.url
          : '/assets/img/mobile/default-invoice.png';
      default:
        return '/assets/img/mobile/default-invoice.png';
    }
  }

  public previewJobCountry(invoice: IInvoice): string {
    switch (invoice.bookingsType) {
      case 'CB':
        return invoice.payment?.jobApplication?.job?.countries?.length > 0
          ? invoice.payment?.jobApplication?.job?.countries[0]?.country.name
          : null;
      case 'TC':
        return invoice.payment?.jobApplication?.job?.countries?.length > 0
          ? invoice.payment?.jobApplication?.job?.countries[0]?.country.name
          : null;
      case 'CS':
        return invoice.payment?.job?.countries?.length > 0 ? invoice.payment?.job?.countries[0]?.country.name : null;
      default:
        return null;
    }
  }
}
