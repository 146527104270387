<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #mobileAccountProfileEditModal>
  <div class="mobile-account-profile-edit-modal-container">
    <div *ngIf="isBusy" class="loading-screen">
      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
      <p class="text-center mt-3">Loading...</p>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" body class="form">
      <div class="mobile-account-profile-edit-form-container" *ngIf="modalType === 'about'">
        <ng-container *ngIf="!isEditProfileSuccess">
          <div class="form-overflow-container">
            <div class="form-container">
              <label translate class="form-label small-label">About your self</label>
              <textarea class="textarea-control" formControlName="bio" cols="30" rows="10"></textarea>
              <p class="form-note" translate>
                Write a 300 characters description about yourself to give applicants an idea who you are
              </p>
            </div>

            <div class="form-container">
              <label class="form-label" translate>What is your starting fee?</label>
              <input class="form-control input-euro input-half-width" type="number" min="0" formControlName="rate" />
            </div>

            <div class="form-container">
              <label class="form-label" translate>How many year of experience do you have?</label>
              <input class="form-control input-half-width" type="number" min="0" formControlName="yearsExperience" />
            </div>

            <div class="form-container" *ngIf="talentProfile?.category?.talentCategories.length > 0">
              <label class="form-label" translate>Select one or multiple categories</label>

              <app-checkbox-group class="w-100" formControlName="talentCategories">
                <label
                  *ngFor="let category of talentProfile?.category?.talentCategories"
                  class="d-flex align-items-center mb-3">
                  <app-checkbox [value]="category.id" class="mr-2"></app-checkbox>
                  {{ 'TALENT_CATEGORY.' + category.slug | translate }}
                </label>
              </app-checkbox-group>
            </div>

            <ng-container *ngIf="filterInitialize">
              <div *ngFor="let categoryFilter of requiredFilters" class="form-container">
                <label class="form-label">{{ categoryFilter.translateKey | translate }}</label>

                <ng-container *ngIf="categoryFilter.type === 'checkbox'">
                  <app-checkbox-group class="w-100" [formControlName]="categoryFilter.slug">
                    <label *ngFor="let option of categoryFilter.filterOptions" class="d-flex align-items-center mb-3">
                      <app-checkbox [value]="option.id" class="mr-2"></app-checkbox>
                      <app-icon
                        *ngIf="categoryFilter.hasIcon"
                        [icon]="'assets/img/filter-options/' + categoryFilter.slug + '/' + option.slug + '.svg'"
                        class="mr-2"
                        fill="white"></app-icon>
                      {{ option.translateKey | translate }}
                    </label>
                  </app-checkbox-group>
                </ng-container>

                <ng-container *ngIf="categoryFilter.type === 'number' || categoryFilter.type === 'text'">
                  <input
                    [ngClass]="
                      categoryFilter.type === 'number' ? 'form-control input-half-width' : 'form-control'"
                    [type]="categoryFilter.type"
                    [placeholder]="categoryFilter.placeholderTranslateKey | translate"
                    [formControlName]="categoryFilter.slug" />
                </ng-container>

                <div
                  *ngIf="categoryFilter.type === 'slider' || categoryFilter.type === 'color'"
                  class="col-12 col-sm-10">
                  <div *ngIf="categoryFilter.type === 'slider'" class="rounded-sm px-3 py-2">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-end">
                        <div (click)="clearValue(categoryFilter.slug)" class="cursor-pointer">
                          <small class="text-gray" translate>Reset value</small>
                        </div>
                      </div>
                      <app-weight-slider
                        *ngIf="categoryFilter.unit === 'kg'"
                        [formControlName]="categoryFilter.slug"
                        [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"
                        class="col-12"></app-weight-slider>

                      <app-metric-slider
                        *ngIf="categoryFilter.unit === 'cm'"
                        [formControlName]="categoryFilter.slug"
                        [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"
                        class="col-12"></app-metric-slider>
                    </div>
                  </div>
                  <div *ngIf="categoryFilter.type === 'color'" class="rounded-sm px-3 py-2">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-end">
                        <div (click)="clearValue(categoryFilter.slug)" class="cursor-pointer">
                          <small class="text-gray" translate>Reset value</small>
                        </div>
                      </div>
                      <app-color-slider [formControlName]="categoryFilter.slug" class="col-12"></app-color-slider>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <p class="divider message" translate>
              The fields below are NOT mandatory, you can always fill them in later
            </p>

            <ng-container *ngIf="filterInitialize">
              <div *ngFor="let categoryFilter of filters" class="form-container">
                <label class="form-label">{{ categoryFilter.translateKey | translate }}</label>

                <ng-container *ngIf="categoryFilter.type === 'checkbox'">
                  <app-checkbox-group class="w-100" [formControlName]="categoryFilter.slug">
                    <label *ngFor="let option of categoryFilter.filterOptions" class="d-flex align-items-center mb-3">
                      <app-checkbox [value]="option.id" class="mr-2"></app-checkbox>
                      <app-icon
                        *ngIf="categoryFilter.hasIcon"
                        [icon]="'assets/img/filter-options/' + categoryFilter.slug + '/' + option.slug + '.svg'"
                        class="mr-2"
                        fill="white"></app-icon>
                      {{ option.translateKey | translate }}
                    </label>
                  </app-checkbox-group>
                </ng-container>

                <ng-container *ngIf="categoryFilter.type === 'number' || categoryFilter.type === 'text'">
                  <input
                    [ngClass]="
                      categoryFilter.type === 'number' ? 'form-control input-half-width' : 'form-control'"
                    [type]="categoryFilter.type"
                    [placeholder]="categoryFilter.placeholderTranslateKey | translate"
                    [formControlName]="categoryFilter.slug" />
                </ng-container>

                <div
                  *ngIf="categoryFilter.type === 'slider' || categoryFilter.type === 'color'"
                  class="col-12 col-sm-10">
                  <div *ngIf="categoryFilter.type === 'slider'" class="rounded-sm px-3 py-2">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-end">
                        <div (click)="clearValue(categoryFilter.slug)" class="cursor-pointer">
                          <small class="text-gray" translate>Reset value</small>
                        </div>
                      </div>
                      <app-weight-slider
                        *ngIf="categoryFilter.unit === 'kg'"
                        [formControlName]="categoryFilter.slug"
                        [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"
                        class="col-12"></app-weight-slider>

                      <app-metric-slider
                        *ngIf="categoryFilter.unit === 'cm'"
                        [formControlName]="categoryFilter.slug"
                        [options]="{ floor: categoryFilter.min, ceil: categoryFilter.max }"
                        class="col-12"></app-metric-slider>
                    </div>
                  </div>

                  <div *ngIf="categoryFilter.type === 'color'" class="rounded-sm px-3 py-2">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-end">
                        <div (click)="clearValue(categoryFilter.slug)" class="cursor-pointer">
                          <small class="text-gray" translate>Reset value</small>
                        </div>
                      </div>
                      <app-color-slider [formControlName]="categoryFilter.slug" class="col-12"></app-color-slider>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="row mb-3" *ngIf="form.invalid">
            <div class="col-12">
              <span class="text-error" translate>Please fill in all fields marked in red.</span>
            </div>
          </div>
          <div class="action-button-container">
            <button (click)="editRole()">Edit role</button>
            <button (click)="onSubmit()">Save</button>
            <button (click)="modalComponent.close()">Cancel</button>
          </div>
        </ng-container>

        <p class="success-edit-profile" *ngIf="isEditProfileSuccess">
          <img src="/assets/img/mobile/check-white.svg" alt="close" />
          {{'You have successfully edit your profile' | translate}}
          <button (click)="modalComponent.close()">Close</button>
        </p>
      </div>

      <app-mobile-account-profile-albums
        *ngIf="modalType !== 'about'"
        class="d-flex"
        [talentProfile]="talentProfile"
        [modalType]="modalType"
        [user]="user"
        formControlName="albums"></app-mobile-account-profile-albums>
    </form>
  </div>
</app-mobile-modal-dialog>
