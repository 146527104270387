import { Component, Optional, Inject } from '@angular/core';
import { GTJoinTemplate } from '@src/core/interfaces/getting-started-join-template';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-getting-started-join-modal',
  templateUrl: './getting-started-join-modal.component.html',
  styleUrls: ['./getting-started-join-modal.component.scss'],
})
export class GettingStartedJoinModalComponent {
  public activeTemplate: GTJoinTemplate = GTJoinTemplate.JOIN;

  public email: string;

  public backIcon = faChevronLeft;

  public closeIcon = faTimes;

  public mobileVersion: boolean;

  public initalForm: GTJoinTemplate = GTJoinTemplate.JOIN;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<GettingStartedJoinModalComponent>,
    private routers: Router,
  ) {
    if (window.innerWidth <= 500) {
      this.closeModal();
      this.routers.navigate(['/'], {
        queryParams: {
          action: 'login',
        },
      });
    } else {
      this.activeTemplate = this.data?.form || GTJoinTemplate.JOIN;
      this.initalForm = this.data?.form || GTJoinTemplate.JOIN;
    }
  }

  public switchTemplate(template: GTJoinTemplate): void {
    this.activeTemplate = template;
  }

  public signupSuccess(email): void {
    this.email = email;
    this.activeTemplate = GTJoinTemplate.SIGNUP_CONFIRMATION_EMAIL;
  }

  public handleBack(): void {
    if (this.activeTemplate === GTJoinTemplate.FORGOT_PASSWORD || this.activeTemplate === GTJoinTemplate.SIGNUP) {
      this.activeTemplate = GTJoinTemplate.SIGNIN;
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
