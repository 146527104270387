import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsBadgeComponent } from './components/notifications-badge/notifications-badge.component';
import { IconsModule } from '../generic/icons/icons.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '../generic/buttons/buttons.module';
const components = [NotificationsBadgeComponent];

@NgModule({
  declarations: components,
  imports: [ButtonsModule, CommonModule, IconsModule, NgbPopoverModule, TranslateModule],
  exports: components,
})
export class NotificationsModule {}
