<ng-container *ngIf="notifications$ | async as notifications">
  <a [ngbPopover]="notificationsList" placement="bottom" class="ml-4 d-flex align-items-center position-relative">
    <app-icon class="mr-2" fill="white" icon="/assets/img/bell.svg"></app-icon>
    <div
      class="badge position-absolute d-flex justify-content-center align-items-center"
      *ngIf="notifications.length > 0">
      {{ notifications.length }}
    </div>
  </a>

  <ng-template #notificationsList>
    <app-button
      *ngIf="notifications.length > 0"
      (click)="markAllAsReadClicked()"
      buttonStyle="white-outline"
      class="mr-1 mb-1">
      <div class="px-2" translate>Mark all notifications as read</div>
    </app-button>
    <ul class="list-unstyled">
      <li
        class="notification p-2 cursor-pointer"
        (click)="notificationClicked(notification)"
        *ngFor="let notification of notifications">
        <b>{{ notification.received | date: 'dd-MM-yy H:mm':'nl' }}</b>

        <br />
        <span [innerHTML]="notification.getTranslationKey | translate: notification.getTranslationMap"></span>
      </li>
    </ul>
  </ng-template>
</ng-container>
