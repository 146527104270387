import { AfterViewInit, Component, HostListener, Input, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['../checkbox/checkbox.component.scss', './radio.component.scss'],
})
export class RadioComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public value: any;

  @Input()
  public isReadOnly = false;

  public checked = false;

  constructor(@Self() private ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  @Input()
  public matcher = (val1: any, val2: any): boolean => val1 === val2;

  @HostListener('click')
  public onClick(): void {
    if (this.isReadOnly) {
      return;
    }
    this.checked = true;
    this.updateField(this.checked);
    this.onChange(this.value);
  }

  public onChange = (_: any) => {};

  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.ngControl.control.valueChanges.subscribe((value) => {
      if (this.isReadOnly) {
        return;
      }
      this.writeValue(value);
    });
    this.updateField(this.checked);
  }

  public onValueChange(value: boolean): void {
    this.checked = value;
    this.onChange(this.value);
  }

  public writeValue(value: any): void {
    this.checked = this.matcher(value, this.value);
    this.updateField(this.checked);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private updateField(checked: boolean): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.checked = checked;
  }
}
