export class LoadCategories {
  public static readonly type = '[Category] Load categories';

  public constructor() {}
}

export class LoadCategory {
  public static readonly type = '[Category] Load category';

  public constructor(public categoryId: string) {}
}
