import { LOCALE_ID, NgModule } from '@angular/core';
import localeNl from '@angular/common/locales/nl';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CurrectLangService } from '@core/services/languange.service';

registerLocaleData(localeNl);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    CoreModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    InfiniteScrollModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: false,
      progressBar: true,
      maxOpened: 1,
      progressAnimation: 'increasing',
      iconClasses: {
        info: 'toast-info',
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [CurrectLangService],
      useFactory: (currectLangService) => currectLangService.getLanguage(),
    },
  ],
})
export class AppModule {}
