import { ViewportScroller } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment/moment';

import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { GeocoderState, SaveUser, UserState, VerifyVatNumber, VerifyVatState } from '@core/states';
import { ICountry, ILanguage, IUser, IUserProfile, UserRole } from '@core/interfaces';
import { GeoLocation, User } from '@core/models';
import { VatResultDTO } from '@core/dto';

import { FileService } from '@src/ui/generic/images/services/file.service';
import { Observable } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { AuthService } from '@auth/services';
import { GeoLocationsService } from '@core/services';
import { ToggleButtonComponent } from '@src/ui/generic/buttons/components/toggle-button/toggle-button.component';
import { MobileStorageKey, MobileStorageService } from '@shared/mobile-components/services/mobile-storage.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnChanges {
  @ViewChild(ToggleButtonComponent) public switch: ToggleButtonComponent<string>;

  @Select(UserState.profiles)
  public profiles$: Observable<IUserProfile[]>;

  @Select(GeocoderState.geolocation)
  public geolocation$: Observable<GeoLocation>;

  @Select(VerifyVatState.vatResult)
  public vatResult$: Observable<VatResultDTO>;

  @Input()
  public user: IUser;

  @Input()
  public countries: ICountry[];

  @Input()
  public languages: ILanguage[];

  public profileThumb: string;

  public idThumb: string;

  public showMobileVerification = false;

  public fullPhonenumber = null;

  public isBusy = false;

  public message: { message: string; type: string };

  public talentIsActiveRole = false;

  public phoneNumbersNotMatch = true;

  public form: FormGroup = new FormGroup(
    {
      id: new FormControl(null, []),
      profileName: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      transgender: new FormControl(false, []),
      birthday: new FormControl(null, [Validators.required]),
      phonenumberCountryId: new FormControl(null, [Validators.required]),
      phonenumber: new FormControl(null, [Validators.required]),
      phonenumberConfirmationCountryId: new FormControl(null, []),
      phonenumberConfirmation: new FormControl(null, []),
      mobileVerificationCode: new FormControl(null, []),
      website: new FormControl(null, []),
      idVerificationFile: new FormControl(null, [
        (control: FormControl) => {
          if (control.value != null) {
            if (
              !(control.value instanceof File) ||
              !['image/png', 'image/x-png', 'image/gif', 'image/jpeg'].includes((control.value as File).type)
            ) {
              return { invalid: true };
            }
          }

          return null;
        },
      ]),
      photoFile: new FormControl(null, []),
      street: new FormControl(null, [Validators.required]),
      houseNumber: new FormControl(null, [Validators.required]),
      postalCode: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required, this.validateCity()]),
      countryId: new FormControl(null, [Validators.required]),
      languages: new FormArray(
        [],
        [
          (control: FormArray) => {
            const counts = [];
            let hasDuplicates = false;

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (const languageFormControl of control.controls) {
              if (counts[languageFormControl.value.id] === undefined) {
                counts[languageFormControl.value.id] = 1;
              } else {
                languageFormControl.get('id').setErrors({ duplicate: true });
                hasDuplicates = true;
              }
            }
            if (hasDuplicates) {
              return { duplicate: true };
            }

            return null;
          },
        ],
      ),
      nativeLanguage: new FormGroup({
        id: new FormControl(null, [Validators.required]),
        isoCode: new FormControl(null),
        name: new FormControl(null),
        native: new FormControl(true),
      }),
      termsAndConditions: new FormControl(false, [
        (control: AbstractControl) => {
          if (control.value !== true) {
            return ['Please agree to the terms and conditions'];
          }
        },
      ]),
      activeJobAlert: new FormControl(null),
      isTalent: new FormControl(null, []),
      userType: new FormControl(null, [Validators.required]),
      vatNumberCountryId: new FormControl(null, [this.validateVatNumber()]),
      vatNumber: new FormControl(null, [this.validateVatNumber()]),
      vatCheckResult: new FormControl(null, []),
      coc_nr: new FormControl(null, []),
      companyName: new FormControl(null, []),
      coordinates: new FormGroup(
        {
          x: new FormControl(null, [Validators.required]),
          y: new FormControl(null, [Validators.required]),
        },
        [Validators.required],
      ),
    },
    { validators: this.companyFieldsValidator },
  );

  private hasProfiles = false;
  private validVatResult = false;
  private token: string;

  public constructor(
    private store: Store,
    private fileService: FileService,
    private viewportScroller: ViewportScroller,
    private translate: TranslateService,
    private authService: AuthService,
    private geoLocationsService: GeoLocationsService,
    private mobileStorageService: MobileStorageService,
  ) {
    this.profiles$.subscribe((p: IUserProfile[]): boolean => (this.hasProfiles = p.length > 0));
    this.geolocation$.subscribe((geolocation: GeoLocation): void => {
      if (geolocation != null) {
        this.form.patchValue({
          city: geolocation.city,
          countryId: geolocation.country.id,
          coordinates: geolocation.coordinates,
        });
      }
    });
    this.vatResult$.subscribe((vatResult: VatResultDTO): void => {
      if (vatResult != null) {
        this.validVatResult = vatResult.valid;
        this.form.patchValue({
          companyName: vatResult.name,
          vatCheckResult: vatResult,
        });
        this.form.get('vatNumber').updateValueAndValidity();
      }
    });
  }

  public get userLanguages(): FormArray {
    return this.form.get('languages') as FormArray;
  }

  public get userNativeLanguage(): FormGroup {
    return this.form.get('nativeLanguage') as FormGroup;
  }

  public get vatCheckResult(): VatResultDTO {
    return this.form.get('vatCheckResult').value as VatResultDTO;
  }

  public get vat(): string {
    return this.form.get('vatNumber').value;
  }

  public get countryCode(): string {
    return this.countries.find((c: ICountry): boolean => c.id === this.form.get('countryId').value)?.isoCode;
  }

  public userType(): string {
    return this.form.get('userType').value;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes) {
      this.setupForm(changes.user.currentValue);
      this.talentIsActiveRole = this.mobileStorageService.get(MobileStorageKey.activeRole) === UserRole.talent;
    }
  }

  public ngOnInit(): void {
    this.token = this.authService.getJwtToken().access_token;
    this.form.get('phonenumber').valueChanges.subscribe((value: string) => {
      this.phoneNumbersNotMatch =
        value !== this.form.get('phonenumberConfirmation').value ||
        this.form.get('phonenumberCountryId').value !== this.form.get('phonenumberConfirmationCountryId').value;
      return this.toggleMobileVerification();
    });
    this.form.get('phonenumberCountryId').valueChanges.subscribe((value: string) => {
      this.phoneNumbersNotMatch =
        value !== this.form.get('phonenumberConfirmationCountryId').value ||
        this.form.get('phonenumber').value !== this.form.get('phonenumberConfirmation').value;
      return this.toggleMobileVerification();
    });
    this.form.get('userType').valueChanges.subscribe((_: string) => this.toggleCompanyVerification());
    this.form
      .get('coc_nr')
      .valueChanges.pipe(startWith(null as string), pairwise())
      .subscribe(([prev, next]: [string, string]): void => {
        if (prev !== next) {
          this.onChangeCoc(next);
        }
      });
    this.form
      .get('vatNumber')
      .valueChanges.pipe(startWith(null as string), pairwise())
      .subscribe(([prev, next]: [string, string]): void => {
        if (prev !== next) {
          this.onChangeVatNumber(next);
        }
      });

    this.talentIsActiveRole = this.mobileStorageService.get(MobileStorageKey.activeRole) === UserRole.talent;
  }

  public toggleCompanyVerification(): void {
    const userType = this.form.get('userType').value;
    if (userType === 'company') {
      if (!this.form.get('coc_nr').value) {
        this.form.get('vatNumberCountryId').setValidators(Validators.required);
        this.form.get('vatNumber').setValidators([Validators.required, this.validateVatNumber()]);
      }

      if (!this.form.get('vatNumber').value) {
        this.form.get('coc_nr').setValidators(Validators.required);
      }
    } else {
      this.form.get('vatNumberCountryId').setValue(null);
      this.form.get('vatNumberCountryId').clearValidators();
      this.form.get('vatNumber').setValue(null);
      this.form.get('vatNumber').clearValidators();
      this.form.get('coc_nr').setValue(null);
      this.form.get('coc_nr').clearValidators();
      this.form.get('vatCheckResult').setValue(null);
    }
    this.form.updateValueAndValidity();
  }

  public async toggleMobileVerification(): Promise<void> {
    if (!this.countries) {
      return;
    }

    const talent: User = this.store.selectSnapshot(UserState.user);
    const phonenumber = this.form.get('phonenumber').value;
    const phonenumberCountryId = this.form.get('phonenumberCountryId').value;

    const phonenumberCountry: ICountry = this.countries.find((c: ICountry) => c.id === phonenumberCountryId);

    const phonenumberChanged: boolean = talent.phonenumber !== phonenumber;
    const phonenumberCountryChanged: boolean =
      talent.phonenumberCountry && talent.phonenumberCountry.id !== phonenumberCountryId;

    if (phonenumberCountry && phonenumber) {
      this.fullPhonenumber = `+${phonenumberCountry.callingCode}${phonenumber}`;
    }

    if (!phonenumberChanged && !phonenumberCountryChanged) {
      this.form.get('mobileVerificationCode').disable();
      this.showMobileVerification = false;
      this.form.updateValueAndValidity();
      return;
    }

    this.form.get('mobileVerificationCode').enable();
    this.showMobileVerification = true;
    this.form.updateValueAndValidity();
  }

  public addLanguageFormGroup(language: ILanguage): void {
    const languageFormGroup: FormGroup = new FormGroup({
      id: new FormControl(language?.id, [Validators.required]),
      isoCode: new FormControl(language?.isoCode),
      name: new FormControl(language?.name),
      native: new FormControl(language?.native),
    });
    this.userLanguages.push(languageFormGroup);
  }

  public deleteUserLanguage(index: number): void {
    this.userLanguages.removeAt(index);
  }

  public get vatCountry(): ICountry {
    const countryId = this.form.get('vatNumberCountryId').value;
    return this.countries.find((c: ICountry): boolean => c.id === countryId);
  }

  public async validateVat(): Promise<void> {
    const vatNumber = this.form.get('vatNumber').value;
    if (this.vatCountry != null) {
      await this.store.dispatch(new VerifyVatNumber(this.vatCountry.alpha2Code, vatNumber)).toPromise();
    }
  }

  public removeVerification() {
    this.form.patchValue({
      idVerificationFile: null,
    });
    this.idThumb = '';
  }

  get getTokenUser(): string {
    return this.authService.getJwtToken().access_token;
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.isBusy || this.form.invalid) {
        return;
      }

      this.isBusy = true;
      this.message = null;

      const formValue = {
        ...this.form.value,
        languages: [this.userNativeLanguage.value, ...this.form.value.languages],
        birthday: this.form.value.birthday.format('YYYY-MM-DD'),
        termsAndConditions: undefined,
        isTalent: undefined,
        country: undefined,
      };

      delete formValue.nativeLanguage;
      delete formValue.phonenumberConfirmation;
      delete formValue.phonenumberConfirmationCountryId;

      if (this.showMobileVerification) {
        formValue.mobileVerification = this.form.get('mobileVerificationCode').valid;
      }

      if (this.talentIsActiveRole) {
        formValue.mobileVerification = !this.phoneNumbersNotMatch;
      }

      if (this.user.profileName !== formValue.profileName) {
        formValue.times_profile_changed = this.user.times_profile_changed + 1;
      }

      if (formValue.languages.length === 0) {
        delete formValue.languages;
      }

      formValue.country = this.countries.find((c: ICountry): boolean => c.id === formValue.countryId);

      const formData = {
        ...formValue,
        photoFile: formValue.photoFile ? await this.fileService.readFile(formValue.photoFile) : null,
        idVerificationFile: formValue.idVerificationFile
          ? await this.fileService.readFile(formValue.idVerificationFile)
          : null,
      };

      await this.store.dispatch(new SaveUser(formData.id, formData)).toPromise();

      this.message = {
        message: await this.translate.get('Uw gegevens zijn opgeslagen').toPromise(),
        type: 'success',
      };
      if (this.user.isTalent && !this.hasProfiles) {
        this.viewportScroller.scrollToPosition([0, 0]);
        this.store.dispatch(new Navigate(['/account/roles/add']));
      }
    } catch (e) {
      this.message = {
        message: await this.translate.get('Er is een onbekende fout opgetreden').toPromise(),
        type: 'error',
      };
    } finally {
      this.isBusy = false;
    }
  }

  public isVatNumberCheckDisabled(): boolean {
    return !this.vatCountry || !this.form.get('vatNumber').value;
  }

  private onChangeVatNumber(value: string): void {
    const userType = this.form.get('userType').value;

    if (userType === 'company') {
      if (value && value !== '') {
        this.form.get('coc_nr').clearValidators();
      } else {
        this.form.get('coc_nr').setValidators(Validators.required);
      }

      this.form.get('coc_nr').updateValueAndValidity();
      this.form.updateValueAndValidity();
    }
  }

  private onChangeCoc(value: string): void {
    const userType = this.form.get('userType').value;

    if (userType === 'company') {
      if (value && value !== '') {
        this.form.get('vatNumberCountryId').clearValidators();
        this.form.get('vatNumber').clearValidators();
      } else {
        this.form.get('vatNumberCountryId').setValidators(Validators.required);
        this.form.get('vatNumber').setValidators([Validators.required, this.validateVatNumber()]);
      }
      this.form.get('vatNumber').updateValueAndValidity();
      this.form.get('vatNumberCountryId').updateValueAndValidity();
      this.form.updateValueAndValidity();
    }
  }

  private setupForm(user: IUser): void {
    if (!user) {
      return;
    }

    this.profileThumb = this.profileThumb || (!!user.photo ? `${user.photo?.url}` : null);
    this.userLanguages.clear();
    this.phoneNumbersNotMatch = user.phonenumber ? false : true;

    if (this.profileThumb === null) {
      this.form.get('photoFile').setValidators([
        Validators.required,
        (control: FormControl) => {
          if (control.value != null && control.value instanceof File) {
            if (!['image/png', 'image/x-png', 'image/gif', 'image/jpeg'].includes((control.value as File).type)) {
              return { invalid: true };
            }
          }

          return null;
        },
      ]);
    }

    this.form.patchValue({
      ...user,
      birthday: user.birthday ? moment(user.birthday) : null,
      phonenumberConfirmation: user.phonenumber ?? null,
      phonenumberConfirmationCountryId: user.phonenumberCountry ? user.phonenumberCountry.id : null,
    });

    if (user.languages !== undefined && user.languages.length > 0) {
      for (const language of user.languages.filter((userLang) => !userLang.native)) {
        this.addLanguageFormGroup(language);
      }

      const nativeLang = user.languages.filter((userLang) => userLang.native)?.[0];

      if (!!nativeLang) {
        this.userNativeLanguage.patchValue({
          id: nativeLang.id,
          name: nativeLang.name,
          isoCode: nativeLang.isoCode,
          native: true,
        });
      }
    } else {
      this.addLanguageFormGroup(null);
    }

    this.fixCoordinates(user);

    this.form.get('countryId').valueChanges.subscribe((value) => {
      const country = this.countries?.find((c: ICountry): boolean => c.id === value);
      if (this.form.get('city').value === null && country !== undefined) {
        this.form.patchValue({
          coordinates: country.coordinates,
        });
      }
    });

    if (user.idVerificationMedia) {
      this.idThumb = `${user.idVerificationMedia.url}?token=${this.getTokenUser}`;
    }
  }

  private validateCity(): any {
    return (control: FormControl) => {
      if (!this.form) {
        return null;
      }
      const coordinates = this.form.get('coordinates').value;
      if (coordinates.x == null || coordinates.y == null) {
        return { invalidCoordinates: true };
      }

      return null;
    };
  }

  private async fixCoordinates(user: IUser): Promise<void> {
    if (user.coordinates == null) {
      let geocodeResults = null;
      if (user.city != null) {
        geocodeResults = await this.geoLocationsService
          .searchCity(user.city, user.country.isoCode, 'nl', user.id)
          .toPromise();
      } else if (user.country != null) {
        this.form.patchValue({
          countryId: user.country.id,
          coordinates: user.country.coordinates,
        });
      }
      if (geocodeResults !== null && geocodeResults.length > 0) {
        const geolocation = await this.geoLocationsService
          .getGeolocationByDescription(geocodeResults[0].description, 'nl')
          .toPromise();
        if (geolocation != null) {
          this.form.patchValue({
            city: geolocation.city,
            countryId: geolocation.country.id,
            coordinates: geolocation.coordinates,
          });
        }
      }
    }
  }

  private companyFieldsValidator(control: AbstractControl): ValidationErrors | null {
    const userType: AbstractControl = control.get('userType');
    if (userType.value === 'private') {
      return null;
    }

    const vatNumber: AbstractControl = control.get('vatNumber');
    const companyName: AbstractControl = control.get('companyName');

    return vatNumber.value !== '' && vatNumber.value && (!companyName || !companyName.value)
      ? { isRequired: true }
      : null;
  }

  private validateVatNumber(): any {
    return (control: FormControl): { [s: string]: boolean } => {
      if (!this.form) {
        return null;
      }
      const userType = this.form.get('userType').value;
      if (userType === 'private') {
        return null;
      }

      const vatNumber = this.form.get('vatNumber').value;
      const cocNr = this.form.get('coc_nr').value;

      if (vatNumber === '' && (!cocNr || cocNr === '')) {
        return { isRequired: true };
      }
      return null;
    };
  }
}
