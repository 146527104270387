<div class="d-flex flex-column w-100 popup">
  <div
    class="close-btn d-flex position-absolute align-self-end justify-content-center cursor-pointer"
    (click)="closeModal()">
    <fa-icon class="close-icon" [icon]="closeIcon"></fa-icon>
  </div>

  <app-getting-started-profile
    (gotoPortfolio)="gotoPortfolio()"
    *ngIf="activeTemplate === 'profile'"></app-getting-started-profile>

  <app-getting-started-portfolio *ngIf="activeTemplate === 'portfolio'"></app-getting-started-portfolio>
</div>
