import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JobApplication, Payment } from '@core/models';
import { Store } from '@ngxs/store';
import { RequestRefundJobApplication } from '@core/states';

@Component({
  selector: 'app-job-application-request-refund',
  templateUrl: './request-refund.component.html',
  styleUrls: ['./request-refund.component.scss'],
})
export class RequestRefundJobApplicationComponent implements OnInit {
  @Output()
  public cancelClicked = new EventEmitter();

  @Input()
  public jobApplication: JobApplication;

  @Input()
  public payment: Payment;

  public form = new FormGroup({
    reason: new FormControl(null, [Validators.required]),
  });

  public formSend = false;

  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<RequestRefundJobApplicationComponent>,
    private store: Store,
  ) {}

  public ngOnInit(): void {
    this.jobApplication = this.data.jobApplication;
  }

  public closeModal(): void {
    this.dialogRef.close(this.formSend);
  }

  public requestRefund(): void {
    this.store.dispatch(
      new RequestRefundJobApplication(this.jobApplication.id, this.jobApplication.payment.id, this.form.value.reason),
    );
    this.formSend = true;
  }
}
