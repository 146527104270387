import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { LoadMemberships, LoadMembership, UpdateUserMembership } from './memberships.actions';
import { MembershipsStateModel } from './memberships.state-model';
import { Membership } from '@core/models';
import { MembershipsService } from '@core/services';

@State<MembershipsStateModel>({
  name: 'memberships',
  defaults: {
    memberships: null,
    membership: null,
  },
})
@Injectable()
export class MembershipsState {
  public constructor(private membershipService: MembershipsService) {}

  @Selector()
  static memberships(state: MembershipsStateModel): Membership[] {
    return state.memberships;
  }

  @Selector()
  static membership(state: MembershipsStateModel): Membership {
    return state.membership;
  }

  @Action(LoadMemberships)
  public async loadMemberships(state: StateContext<MembershipsStateModel>): Promise<void> {
    const memberships = await this.membershipService.findAll().toPromise();
    state.patchState({ memberships });
  }

  @Action(LoadMembership)
  public async loadMembership(
    state: StateContext<MembershipsStateModel>,
    { membershipId }: LoadMembership,
  ): Promise<void> {
    const membership = await this.membershipService.getById(membershipId).toPromise();
    state.patchState({ membership });
  }

  @Action(UpdateUserMembership)
  public async updateUserMembership(
    state: StateContext<MembershipsStateModel>,
    { userId, membership }: UpdateUserMembership,
  ): Promise<void> {
    const result = await this.membershipService.updateUserMembership(userId, membership).toPromise();
    if (result.redirect) {
      window.location.href = result.data;
    }
  }
}
