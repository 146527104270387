import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ICategory } from '@core/interfaces/category';
import { SplideOptions } from '@splidejs/splide';
import { LayoutCalculatorService } from '@src/ui/generic/carousels/services/layout-calculator.service';

@Component({
  selector: 'app-categories-carousel',
  templateUrl: './categories-carousel.component.html',
  styleUrls: ['./categories-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesCarouselComponent implements OnChanges, AfterViewInit {
  @Input()
  categories: ICategory[];

  public tileSize: any;

  public options: SplideOptions = {
    autoHeight: true,
    autoWidth: true,
    keyboard: 'focused',
    pagination: false,
    waitForTransition: false,
  };

  public constructor(private cd: ChangeDetectorRef, private layoutCalculator: LayoutCalculatorService) {
    this.layoutCalculator.layoutChanged$.subscribe((data) => this.updateParams(data));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('categories' in changes) {
      this.updateParams(this.layoutCalculator.data);
    }
  }

  public ngAfterViewInit(): void {
    this.updateParams(this.layoutCalculator.data);
  }

  private updateParams(data: any): void {
    const perView = this.getPerView(data.breakpointName);
    this.tileSize = this.layoutCalculator.getTileSize(perView);

    this.options = {
      ...this.options,
      padding: data.padding,
      gap: data.gap,
      perMove: perView,
    };

    this.cd.detectChanges();
  }

  private getPerView(breakpointName: string): number {
    switch (breakpointName) {
      case 'sm':
        return 3;
      case 'md':
        return 4;
      case 'lg':
        return 6;
      case 'xl':
        return 7;
      default:
        return 2;
    }
  }
}
