<div [formGroup]="form" *ngIf="form" class="mobile-account-profile-album-media-container" [ngClass]="albumType">
  <div #mediaList class="album-grid-container" formArrayName="media">
    <ng-container *ngIf="albumType === 'photo'">
      <app-mobile-account-profile-media-field
        *ngFor="let media of media.controls; let i = index"
        (delete)="deleteMedia(i)"
        (moveUp)="moveUp(i)"
        (moveDown)="moveDown(i)"
        [albumId]="form.value.id"
        [formControl]="media"
        [isProfilePhoto]="i === 0 && albumType === 'photo'"
        [allowSorting]="true"
        class="d-flex"></app-mobile-account-profile-media-field>
    </ng-container>
    <ng-container *ngIf="albumType === 'video'">
      <app-mobile-account-profile-media-field
        *ngFor="let media of media.controls; let i = index"
        (delete)="deleteMedia(i)"
        (moveUp)="moveUp(i)"
        (moveDown)="moveDown(i)"
        [albumId]="form.value.id"
        [formControl]="media"
        [isProfilePhoto]="i === 0 && albumType === 'video'"
        [allowSorting]="true"
        class="d-flex"></app-mobile-account-profile-media-field>
    </ng-container>
  </div>
  <div *ngIf="albumType === 'video'" class="form-container yt-link-input">
    <input
      (input)="onPasteYoutubeLink($event.target.value)"
      class="form-control"
      [class.input-invalid]="isUrlValid"
      type="text"
      placeholder="Youtube link" />
  </div>
  <div class="action-button-container" [class.w-full]="albumType === 'video'">
    <button *ngIf="albumType === 'video'" (click)="addMedia()">Upload Video From gallery</button>
    <button *ngIf="albumType === 'photo'" (click)="addMedia()">Upload Photo</button>
  </div>
</div>
