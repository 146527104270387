import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-helpdesk',
  templateUrl: './mobile-helpdesk.component.html',
  styleUrls: ['./mobile-helpdesk.component.scss'],
})
export class MobileHelpdeskComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
