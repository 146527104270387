import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { CheckMobileVerificationCode, SendMobileVerificationCode, VerifyMobileState } from '@core/states';
import { Store } from '@ngxs/store';
import { interval, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-verify-mobile',
  templateUrl: './verify-mobile.component.html',
  styleUrls: ['./verify-mobile.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VerifyMobileComponent,
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: VerifyMobileComponent,
      multi: true,
    },
  ],
})
export class VerifyMobileComponent implements ControlValueAccessor, AsyncValidator, OnDestroy {
  @Input()
  public phonenumber: string;

  @Input()
  public styleUI = 'dekstop';

  @ViewChildren('digit')
  public digits: any;

  @ViewChild('inlineDigit')
  public inlineDigits!: ElementRef;

  public isBusy = false;

  public value = '';

  public resetTime = 60;
  public timer$: Observable<string>;

  public valid = false;
  public onChange: (_: any) => Record<string, unknown>;
  public onTouch: (_: any) => Record<string, unknown>;
  public form = new FormGroup({
    code: new FormControl(null, [Validators.required]),
  });
  private destroyed = false;

  public constructor(private store: Store) {}

  @HostListener('input', ['$event'])
  private(e: InputEvent): void {
    let value = '';

    const digitFields = this.digits.toArray();
    for (const field of digitFields) {
      value += field.value;
    }

    this.onChange(this.value);
    this.onTouch(this.value);

    if (e.inputType === 'deleteContentBackward') {
      digitFields[this.value.length - 1]?.setFocus();
      return;
    }

    digitFields[this.value.length]?.setFocus();
  }

  public writeValue(_: null): void {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public async sendMobileVerification(): Promise<void> {
    try {
      if (this.isBusy || this.timer$) {
        return;
      }

      this.isBusy = true;
      this.timer$ = interval(1000).pipe(
        map((second: number) => {
          const displayValue = this.resetTime - second;
          if (displayValue <= 0) {
            this.timer$ = null;
          }

          return displayValue < 10 ? `0${displayValue}` : `${displayValue}`;
        }),
      );
      await this.store.dispatch(new SendMobileVerificationCode(this.phonenumber)).toPromise();
    } catch (e) {
      this.timer$ = null;
    } finally {
      this.isBusy = false;
    }
  }

  public async validate(_: AbstractControl): Promise<any> {
    if (this.destroyed) {
      return null;
    }

    if (this.value === '' && this.styleUI === 'mobile') {
      await this.store
        .dispatch(new CheckMobileVerificationCode(this.phonenumber, this.inlineDigits?.nativeElement.value))
        .toPromise();
      this.valid = await this.store.selectOnce(VerifyMobileState.isValid).toPromise();

      if (!this.valid) {
        return of({ invalid: true });
      }
    } else {
      await this.store.dispatch(new CheckMobileVerificationCode(this.phonenumber, this.value)).toPromise();
      this.valid = await this.store.selectOnce(VerifyMobileState.isValid).toPromise();

      if (!this.valid) {
        return of({ invalid: true });
      }
    }

    return null;
  }

  public ngOnDestroy(): void {
    this.destroyed = true;
    this.onChange(null);
  }
}
