import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Deck } from '@core/models';
import { IDeck, IUserProfile } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DecksService {
  public constructor(private http: HttpClient) {}

  public findAll(): Observable<IDeck[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/decks`)
      .pipe(map((data: IDeck[]) => data.map((item: IDeck) => plainToClass(Deck, item))));
  }

  public delete(deckId: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/decks/${deckId}`);
  }

  public save(deck: IDeck): Observable<IDeck> {
    return this.http
      .put(`${environment.apiBaseUrl}/decks/${deck.id}`, deck)
      .pipe(map((data: IDeck) => plainToClass(Deck, data)));
  }

  public addUserProfileToDeck(deck: IDeck, userProfile: IUserProfile): Observable<IDeck> {
    return this.http
      .put(`${environment.apiBaseUrl}/decks/${deck.id}/add/${userProfile.id}`, {})
      .pipe(map((data: IDeck) => plainToClass(Deck, data)));
  }

  public removeDeckUsers(deck: IDeck, deckUserIds: string[]): Observable<IDeck> {
    return this.http
      .put(`${environment.apiBaseUrl}/decks/${deck.id}/remove`, deckUserIds)
      .pipe(map((data: IDeck) => plainToClass(Deck, data)));
  }
}
