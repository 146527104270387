export enum ObjectType {
  address = 'address',
  bank_account = 'bank_account',
  contact = 'contact',
  customer = 'customer',
  escrow = 'escrow',
  merchant = 'merchant',
  payment_method = 'payment_method',
  product = 'product',
  profile = 'profile',
  order = 'order',
  refund = 'refund',
  settlement = 'settlement',
  status = 'status',
  transaction = 'transaction',
  ubo = 'ubo',
}

export class BaseOppObjectDTO {
  public uid: string;
  public object: ObjectType;
  public created: number;
  public updated: number;
}
