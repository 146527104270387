import { Component, Inject, Optional } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-getting-started-modal',
  templateUrl: './getting-started-modal.component.html',
  styleUrls: ['./getting-started-modal.component.scss'],
})
export class GettingStartedModalComponent {
  // profile or portfolio
  public activeTemplate = 'profile';

  public closeIcon = faTimes;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() private dialogRef: MatDialogRef<GettingStartedModalComponent>,
  ) {
    if (this.data?.hash && (this.data?.hash === 'profile' || this.data?.hash === 'portfolio')) {
      this.activeTemplate = this.data.hash;
    }
  }

  public gotoPortfolio(): void {
    this.activeTemplate = 'portfolio';
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
