import { INotification } from '@core/interfaces';

export class FetchAllNotifications {
  public static readonly type = '[Notifications] Fetch all';
  public constructor() {}
}

export class MarkNotificationAsRead {
  public static readonly type = '[Notifications] Mark notification as read';
  public constructor(public notification: INotification) {}
}

export class MarkAllNotificationAsRead {
  public static readonly type = '[Notifications] Mark all notifications as read';
  public constructor() {}
}

export class ClearNotifications {
  public static readonly type = '[Notifications] Clear notifications';
  public constructor() {}
}
