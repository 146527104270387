import { State, Action, StateContext, Selector, createSelector, Store, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DecksService } from '@core/services';

import { DecksStateModel } from './decks.state-model';
import { AddUserProfileToDeck, DeleteDeck, GetDecks, RemoveDeckUsers, SaveDeck } from './decks.actions';
import { IDeck } from '@core/interfaces';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { UserState } from '../users';
import { filter, switchMap } from 'rxjs/operators';

@State<DecksStateModel>({
  name: 'decks',
  defaults: {
    decks: [],
  },
})
@Injectable()
export class DecksState implements NgxsOnInit {
  public constructor(private decksService: DecksService, private store: Store) {}

  static deck(id: string): any {
    return createSelector([DecksState], (state: DecksStateModel) => state.decks.find((d: any) => d.id === id));
  }

  @Selector()
  static decks(state: DecksStateModel): IDeck[] {
    return state.decks;
  }

  @Action(GetDecks)
  public async getDecks(state: StateContext<DecksStateModel>): Promise<void> {
    const decks = await this.decksService.findAll().toPromise();
    state.patchState({ decks });
  }

  @Action(DeleteDeck)
  public async deleteDeck(ctx: StateContext<DecksStateModel>, { deck }: DeleteDeck): Promise<void> {
    await this.decksService.delete(deck.id).toPromise();
    ctx.setState(patch({ decks: removeItem<IDeck>((d) => d.id === deck.id) }));
  }

  @Action(SaveDeck)
  public async saveDecks(ctx: StateContext<DecksStateModel>, { deck, isNew }: SaveDeck): Promise<void> {
    const data = await this.decksService.save(deck).toPromise();

    if (isNew) {
      ctx.setState(patch({ decks: append<IDeck>([data]) }));
      return;
    }

    ctx.setState(patch({ decks: updateItem<IDeck>((d) => d.id === deck.id, data) }));
  }

  @Action(AddUserProfileToDeck)
  public async addUserProfileToDeck(
    ctx: StateContext<DecksStateModel>,
    { deck, userProfile }: AddUserProfileToDeck,
  ): Promise<void> {
    const data = await this.decksService.addUserProfileToDeck(deck, userProfile).toPromise();

    ctx.setState(patch({ decks: updateItem<IDeck>((d) => d.id === deck.id, data) }));
  }

  @Action(RemoveDeckUsers)
  public async removeDeckUsers(
    ctx: StateContext<DecksStateModel>,
    { deck, deckUserIds }: RemoveDeckUsers,
  ): Promise<void> {
    const data = await this.decksService.removeDeckUsers(deck, deckUserIds).toPromise();
    ctx.setState(patch({ decks: updateItem<IDeck>((d) => d.id === deck.id, data) }));
  }

  public ngxsOnInit(ctx: StateContext<DecksStateModel>): void {
    this.store
      .select(UserState.user)
      .pipe(
        filter((user) => !!user),
        switchMap(() => ctx.dispatch(new GetDecks())),
      )
      .subscribe();
  }
}
