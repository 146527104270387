import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobInvoiceService } from '@core/services/job-invoice';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-claim-travel-expenses-incorrect-fee',
  templateUrl: './mobile-claim-travel-expenses-incorrect-fee.component.html',
  styleUrls: ['./mobile-claim-travel-expenses-incorrect-fee.component.scss'],
})
export class MobileClaimTravelExpensesIncorrectFeeComponent implements OnInit {
  @ViewChild('claimTravelExpensesIncorrectFee') private modalComponent: MobileModalDialogComponent;
  public showSuccess = false;
  public jobApplicationId: any = null;

  public form: FormGroup = new FormGroup({
    reason: new FormControl(null),
  });

  constructor(private jobInvoiceService: JobInvoiceService, private router: Router) {}

  ngOnInit(): void {}

  public async openDialog(jobApplicationId: any) {
    this.jobApplicationId = jobApplicationId;
    return await this.modalComponent.open().then(() => {});
  }

  public close(reload: boolean): void {
    if (reload) {
      window.location.reload();
      return;
    }
    this.modalComponent.close();
  }

  public submit() {
    try {
      this.jobInvoiceService.decline(this.jobApplicationId, this.form.get('reason')?.value).subscribe(() => {
        this.showSuccess = true;
      });
    } catch (error) {
      console.log(error);
    }
  }
}
