import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validation-indicator',
  templateUrl: './validation-indicator.component.html',
  styleUrls: ['./validation-indicator.component.scss'],
})
export class ValidationIndicatorComponent {
  @Input()
  public field: FormControl;
}
