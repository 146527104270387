import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-locked-indicator',
  templateUrl: './locked-indicator.component.html',
  styleUrls: ['./locked-indicator.component.scss'],
})
export class LockedIndicatorComponent {
  @Input()
  @HostBinding('class.locked')
  public locked: boolean;
}
