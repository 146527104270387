import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MessagingStateModel } from './messaging.state-model';
import {
  ActivateChat,
  HideChat,
  InitiateChat,
  LoadChats,
  LoadMoreMessage,
  LoadNextPageChats,
  MarkMessagesAsRead,
  MessageReceived,
  SendMessage,
  UnsetActiveChat,
} from './messaging.actions';
import { MessagingService } from '@core/services';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SseState } from '../sse';
import { append, compose, iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Message } from '@core/models/message.model';
import { plainToClass } from 'class-transformer';
import { AuthState } from '../auth';
import { IChat, IMessage } from '@core/interfaces';
import { JwtToken } from '@auth/models';
import { Chat, PaginatedResults } from '@core/models';

@State<MessagingStateModel>({
  name: 'messaging',
  defaults: {
    chats: [],
    page: 0,
    total: 0,
    activeChat: null,
    isFetching: false, //indicate if form message need to scroll to bottom for appended new message
  },
})
@Injectable()
export class MessagingState implements NgxsOnInit {
  public constructor(private messagingService: MessagingService, private store: Store) {}

  @Selector()
  static chats(state: MessagingStateModel): Chat[] {
    return state.chats;
  }

  @Selector()
  static activeChat(state: MessagingStateModel): Chat {
    return state.activeChat;
  }

  @Action(MessageReceived)
  public async messageReceived(ctx: StateContext<MessagingStateModel>, { message }: MessageReceived): Promise<any> {
    let state = ctx.getState();

    if (!state.chats?.some((c) => c.id === message.chatId)) {
      const chat = await this.messagingService.loadChat(message.chatId).toPromise();
      ctx.setState(
        patch({
          chats: insertItem(chat, 0),
        }),
      );
    } else {
      ctx.setState(
        patch({
          chats: updateItem<IChat>(
            (chat) => chat.id === message.chatId,
            patch({
              lastMessageReceived: message,
              totalUnread: (totalUnread: number) =>
                !message.isSender && state.activeChat?.id !== message.chatId ? totalUnread + 1 : 0,
            }),
          ),
        }),
      );

      // Get the updated state
      const updatedState = ctx.getState();

      // Find the updated chat based on `message.chatId`
      const updatedChat = updatedState.chats.find((chat) => chat.id === message.chatId);

      // Remove the updated chat from its current position
      const updatedChats = updatedState.chats.filter((chat) => chat.id !== message.chatId);

      // Prepend the updated chat to the top of the array
      ctx.setState(
        patch({
          chats: [updatedChat, ...updatedChats], // Move the updated chat to the top
        }),
      );
    }

    if (state.activeChat?.id === message.chatId) {
      ctx.setState(
        patch({
          activeChat: iif<any>(
            (activeChat) => !!activeChat,
            patch({
              messages: append([message]),
            }),
          ),
        }),
      );

      if (!message.isSender && state.activeChat) {
        // update read mark in list of messages
        const updateItems = state.activeChat.messages
          .filter((msg: IMessage) => !msg.read && msg.recipientId === message.senderId)
          .map((msg: IMessage) =>
            updateItem<IMessage>((unreadMsg: IMessage): boolean => unreadMsg.id === msg.id, patch({ read: true })),
          );

        ctx.setState(
          patch({
            activeChat: iif<any>(
              (activeChat) => {
                return !!activeChat;
              },
              patch({
                messages: compose(...updateItems),
              }),
            ),
          }),
        );
      }
    }
    state = ctx.getState();
  }

  @Action(LoadChats)
  public loadChats(ctx: StateContext<MessagingStateModel>): Observable<any> {
    const isFetching = ctx.getState().isFetching;

    if (isFetching) return;

    ctx.patchState({
      isFetching: true,
    });

    return this.messagingService.loadChats().pipe(
      tap((response: PaginatedResults<Chat>): void => {
        ctx.patchState({
          chats: response.results,
          page: 0,
          total: response.total,
          isFetching: false,
        });
      }),
    );
  }

  @Action(LoadNextPageChats)
  public loadNextPageChats(ctx: StateContext<MessagingStateModel>): Observable<any> {
    const currentTotal = ctx.getState().total;
    const currentPage = ctx.getState().page;
    const currentChats = ctx.getState().chats;
    const isFetching = ctx.getState().isFetching;

    if (currentChats.length >= currentTotal || isFetching) return;

    ctx.patchState({
      isFetching: true,
    });

    return this.messagingService.loadChats(currentPage + 1).pipe(
      tap((response: PaginatedResults<Chat>): void => {
        const chatsIds = currentChats.map((chat) => chat.id);

        //make sure no duplicated chat
        const filteredResult = response.results.filter((chat) => !chatsIds.includes(chat.id));

        ctx.patchState({
          chats: [...currentChats, ...filteredResult],
          page: currentPage + 1,
          total: response.total,
          isFetching: false,
        });
      }),
    );
  }

  @Action(InitiateChat)
  public initiateChat(ctx: StateContext<MessagingStateModel>, { userId, recipientId }: InitiateChat): Observable<any> {
    return this.messagingService.initiateChat(userId, recipientId).pipe(
      tap((chat: Chat): void => {
        ctx.setState(
          patch({
            chats: iif<any[]>(
              (chats: any[]) => chats?.some((c): boolean => c.id === chat.id),
              updateItem<any>((c): boolean => c.id === chat.id, chat),
              insertItem<any>(chat),
            ),
            //     activeChat: chat,
          }),
        );
      }),
      switchMap((chat: Chat) => ctx.dispatch(new ActivateChat(chat.id))),
    );
  }

  @Action(SendMessage)
  public sendMessage(_: StateContext<MessagingStateModel>, { message }: SendMessage): Observable<any> {
    return this.messagingService.sendMessage(message);
  }

  @Action(UnsetActiveChat)
  public unsetActiveChat(ctx: StateContext<MessagingStateModel>): void {
    ctx.patchState({
      activeChat: null,
    });
  }

  @Action(ActivateChat)
  public activateChat(ctx: StateContext<MessagingStateModel>, { chatId }: ActivateChat): Observable<any> {
    return this.messagingService.loadChat(chatId, 20).pipe(
      tap((chat: Chat): void => {
        ctx.patchState({
          activeChat: chat,
        });
      }),
    );
  }

  @Action(LoadMoreMessage)
  public loadMoreMessage(ctx: StateContext<MessagingStateModel>, { chatId }: ActivateChat): Observable<any> {
    const chatInTheTop = ctx.getState().activeChat?.messages[0];

    if (!!chatInTheTop) {
      return this.messagingService.loadMoreMessages(chatId, 20, chatInTheTop.id).pipe(
        tap((messages: Message[]): void => {
          ctx.patchState({
            activeChat: {
              ...ctx.getState().activeChat,
              messages: [
                ...messages,
                ...(ctx.getState().activeChat?.messages || []), // Keep existing messages
              ],
            },
          });
        }),
      );
    } else {
      return;
    }
  }

  @Action(MarkMessagesAsRead)
  public markMessagesAsRead(ctx: StateContext<MessagingStateModel>, { chat }: MarkMessagesAsRead): Observable<any> {
    ctx.setState(
      patch({
        chats: updateItem<IChat>(
          (chatData: IChat): boolean => chatData.id === chat.id,
          patch({
            totalUnread: 0,
          }),
        ),
      }),
    );

    return this.messagingService.markMessagesAsRead(chat.id);
  }

  @Action(HideChat)
  public async hideChat(ctx: StateContext<MessagingStateModel>, { chat }: HideChat): Promise<any> {
    await this.messagingService.hideChat(chat.id).toPromise();
    ctx.setState(patch({ chats: removeItem<IChat>((c: IChat): boolean => c.id === chat.id) }));
    ctx.dispatch(new UnsetActiveChat());
  }

  public ngxsOnInit(ctx: StateContext<MessagingStateModel>): void {
    this.store
      .select(SseState.event())
      .pipe(
        filter(
          (event: any) => !!event && ['messaging.message.received', 'messaging.message.sent'].includes(event.type),
        ),
        map((event: any) =>
          plainToClass(Message, { ...event.payload, isSender: event.type === 'messaging.message.sent' }),
        ),
        switchMap((message: Message) => this.store.dispatch(new MessageReceived(message))),
      )
      .subscribe();

    this.store
      .select(AuthState.jwtToken)
      .pipe(
        filter((jwtToken: JwtToken) => !!jwtToken),
        switchMap(() => ctx.dispatch(new LoadChats())),
      )
      .subscribe();
  }
}
