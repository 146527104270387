import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-switcher-view',
  templateUrl: './view-switcher-view.component.html',
  styleUrls: ['./view-switcher-view.component.scss'],
})
export class ViewSwitcherViewComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  @HostBinding('hidden')
  public hidden: boolean;

  constructor() {}

  ngOnInit(): void {}
}
