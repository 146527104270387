import { IDeck, IUserProfile } from '@core/interfaces';

export class GetDecks {
  public static readonly type = '[Decks] Get decks';
  public constructor() {}
}

export class DeleteDeck {
  public static readonly type = '[Decks] Delete deck';
  public constructor(public deck: IDeck) {}
}

export class SaveDeck {
  public static readonly type = '[Decks] Save deck';
  public constructor(public deck: IDeck, public isNew?: boolean) {}
}

export class AddUserProfileToDeck {
  public static readonly type = '[Decks] Add user profile to deck';
  public constructor(public deck: IDeck, public userProfile: IUserProfile) {}
}

export class RemoveDeckUsers {
  public static readonly type = '[Decks] Remove deck users';
  public constructor(public deck: IDeck, public deckUserIds: string[]) {}
}
