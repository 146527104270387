import { Component, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IUserProfile } from '@core/interfaces';
import { User } from '@core/models';
import { MessagingModalComponent } from '../messaging-modal/messaging-modal.component';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AuthService } from '@auth/services';
import { InitiateChat } from '@core/states';

@Component({
  selector: 'app-send-message-button',
  templateUrl: './send-message-button.component.html',
  styleUrls: ['./send-message-button.component.scss'],
})
export class SendMessageButtonComponent {
  @Input()
  public user: User;

  @Input()
  public userProfile: IUserProfile;

  public isBusy = false;

  public constructor(private dialog: MatDialog, private store: Store, private authService: AuthService) {}

  @HostListener('click')
  public async openChatModal(): Promise<void> {
    if (this.user.disabled) {
      await this.store.dispatch(new Navigate(['/account/disabled'])).toPromise();
      return;
    }
    if (this.user.idVerification === false) {
      return;
    }
    if (this.user.id === this.userProfile.userId) {
      return;
    }

    const senderId = this.user.id;
    const recipientId = this.userProfile.userId;
    if (recipientId != null) {
      await this.store.dispatch(new InitiateChat(senderId, recipientId)).toPromise();
    }

    const dialogRef = this.dialog.open(MessagingModalComponent, {
      data: {
        userId: this.user.id,
        recipientId: this.userProfile.userId,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      return;
    }
  }
}
