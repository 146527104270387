import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { JobApplicationsService, JobsService, PaymentsService, UsersService } from '@core/services';
import { CreatePayment } from '@core/states';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  AcceptJobApplication,
  AcceptJobApplicationAndCreatePayment,
  ApplyToJob,
  ApproveTravelExpenses,
  CancelBookingJobApplication,
  CancelJob,
  CancelJobApplication,
  CancelRequestToBookJobApplication,
  CasterCancelBookingJobApplication,
  ClearDetailsJob,
  ClearJob,
  ConfirmJobApplication,
  DisableJobNotification,
  DuplicateJob,
  EnableJobNotification,
  FetchAllJobApplications,
  FetchEditJob,
  FetchJob,
  FetchJobApplication,
  FetchJobApplications,
  FetchJobDetails,
  FetchJobs,
  FetchJobsAdmin,
  FetchPotentialUsersForJob,
  MarkJobCompleted,
  RejectBookingJobApplication,
  RejectJobApplication,
  RejectJobApplications,
  RejectTravelExpenses,
  RequestTalentVideoForJobApplication,
  RequestToBookJobApplication,
  SaveJob,
  SaveJobApplicationTravelExpenses,
  UploadVideoForJobApplication,
  FetchJobsForHomePage,
  SendBulkMessage,
} from './jobs.actions';
import { JobsStateModel } from './jobs.state-model';
import { Job, JobApplication, PaginatedResults, User } from '@core/models';
import { JobApplicationStatus } from '@core/interfaces/job-application';

import { IJob } from '@core/interfaces/job';
import { Navigate } from '@ngxs/router-plugin';

@State<JobsStateModel>({
  name: 'jobs',
  defaults: {
    jobs: null,
    jobApplications: null,
    jobApplication: null,
    job: null,
    editJob: null,
    viewJobDetails: null,
    filteredJobApplications: null,
    potentialUsers: [],
    jobsForHomePage: null,
  },
})
@Injectable()
export class JobsState {
  public constructor(
    private jobsService: JobsService,
    private usersService: UsersService,
    private jobsApplicationsService: JobApplicationsService,
    private paymentsService: PaymentsService,
  ) {}

  @Selector()
  static jobs(state: JobsStateModel): PaginatedResults<Job> {
    return state.jobs;
  }

  @Selector()
  static job(state: JobsStateModel): Job {
    return state.job;
  }

  @Selector()
  static editJob(state: JobsStateModel): Job {
    return state.editJob;
  }

  @Selector()
  static viewJobDetails(state: JobsStateModel): Job {
    return state.viewJobDetails;
  }

  @Selector()
  static jobApplications(state: JobsStateModel): PaginatedResults<JobApplication> {
    return state.jobApplications;
  }

  @Selector()
  static filteredJobApplications(state: JobsStateModel): PaginatedResults<JobApplication> {
    return state.filteredJobApplications;
  }

  @Selector()
  static jobApplication(state: JobsStateModel): JobApplication {
    return state.jobApplication;
  }

  @Selector()
  static potentialUsers(state: JobsStateModel): User[] {
    return state.potentialUsers;
  }

  @Selector()
  static jobsForHomePage(state: JobsStateModel): PaginatedResults<Job> {
    return state.jobsForHomePage;
  }

  @Action(FetchPotentialUsersForJob)
  public async fetchPotentialUsers(
    ctx: StateContext<JobsStateModel>,
    { jobId }: FetchPotentialUsersForJob,
  ): Promise<void> {
    const potentialUsers = await this.usersService.fetchPotentialUsersForJob(jobId).toPromise();
    ctx.patchState({ potentialUsers });
  }

  @Action(FetchJobs)
  public async fetchJobs(
    ctx: StateContext<JobsStateModel>,
    { userId, status, page, pageSize, infintyScrollMode }: FetchJobs,
  ): Promise<void> {
    const jobs = await this.jobsService.getJobsForUser(userId, status, page, pageSize).toPromise();
    if (infintyScrollMode && jobs) {
      const currentJobs = ctx.getState().jobs;
      if (page > 0 && currentJobs != null) {
        const updatedTalents = new PaginatedResults<Job>();
        updatedTalents.results = [...currentJobs.results, ...jobs.results];
        updatedTalents.total = jobs.total;
        ctx.patchState({
          jobs: updatedTalents,
        });
      } else {
        ctx.patchState({ jobs });
      }
    } else {
      ctx.patchState({ jobs });
    }
  }

  @Action(FetchJobsAdmin)
  public async fetchJobsAdmin(
    ctx: StateContext<JobsStateModel>,
    { query, page, pageSize, sortColumn, sortDirection }: FetchJobsAdmin,
  ): Promise<void> {
    const jobs: PaginatedResults<Job> = await this.jobsService
      .getJobsForAdmin(query, page, pageSize, sortColumn, sortDirection)
      .toPromise();
    ctx.patchState({ jobs });
  }

  @Action(FetchJob)
  public async fetchJob(ctx: StateContext<JobsStateModel>, { jobId }: FetchJob): Promise<void> {
    const job: Job = await this.jobsService.getJob(jobId).toPromise();
    ctx.patchState({ job });
  }

  @Action(FetchEditJob)
  public async fetchEditJob(ctx: StateContext<JobsStateModel>, { jobId }: FetchJob): Promise<void> {
    const editJob: Job = await this.jobsService.getJobForEdit(jobId).toPromise();
    ctx.patchState({ editJob });
  }

  @Action(FetchJobDetails)
  public async fetchJobDetails(
    ctx: StateContext<JobsStateModel>,
    { jobId, updateInList }: FetchJobDetails,
  ): Promise<void> {
    const viewJobDetails = await this.jobsService.getJobDetailsForList(jobId).toPromise();

    if (updateInList) {
      ctx.setState(
        patch({
          jobs: patch({
            results: updateItem<Job>((job) => job.id === viewJobDetails.id, viewJobDetails),
          }),
        }),
      );
    } else {
      ctx.patchState({ viewJobDetails });
    }
  }

  @Action(ClearDetailsJob)
  public async ClearDetailsJob(ctx: StateContext<JobsStateModel>, { jobId }: ClearDetailsJob): Promise<void> {
    const clearJob: Job = ctx.getState().jobs.results.find((job) => job.id === jobId);
    const updatedJob = { ...clearJob };
    delete updatedJob.jobApplications;
    ctx.setState(
      patch({
        jobs: patch({
          results: updateItem<Job>((job) => job.id === jobId, updatedJob as Job),
        }),
      }),
    );
  }

  @Action(ClearJob)
  public async clearJob(ctx: StateContext<JobsStateModel>): Promise<void> {
    ctx.patchState({ job: null, editJob: null });
  }

  @Action(FetchJobApplications)
  public async fetchJobApplications(
    ctx: StateContext<JobsStateModel>,
    { userId, jobApplicationStatus }: FetchJobApplications,
  ): Promise<void> {
    const filteredJobApplications = await this.jobsApplicationsService
      .getJobApplicationsForUser(userId, jobApplicationStatus)
      .toPromise();
    ctx.patchState({ filteredJobApplications });
  }

  @Action(ApproveTravelExpenses)
  public async approveTravelExpenses(
    ctx: StateContext<JobsStateModel>,
    { jobId, jobApplicationId }: ApproveTravelExpenses,
  ): Promise<void> {
    const filteredJobApplications = await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.travelCostApproved)
      .toPromise();
    ctx.patchState({ filteredJobApplications });
  }

  @Action(RejectTravelExpenses)
  public async rejectTravelExpenses(
    ctx: StateContext<JobsStateModel>,
    { jobId, jobApplicationId, reason }: RejectTravelExpenses,
  ): Promise<void> {
    const filteredJobApplications = await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.travelCostRejected, reason)
      .toPromise();
    ctx.patchState({ filteredJobApplications });
  }

  @Action(FetchAllJobApplications)
  public async fetchAllJobApplications(
    ctx: StateContext<JobsStateModel>,
    { userId }: FetchAllJobApplications,
  ): Promise<void> {
    const jobApplications = await this.jobsApplicationsService.getAllJobApplicationsForUser(userId).toPromise();
    ctx.patchState({ jobApplications });
  }

  @Action(ApplyToJob)
  public async applyToJob(
    ctx: StateContext<JobsStateModel>,
    { userId, jobId, media, note }: ApplyToJob,
  ): Promise<void> {
    const jobApplication = await this.jobsApplicationsService.applyToJob(userId, jobId, media, note).toPromise();
    ctx.patchState({ jobApplication });
  }

  @Action(RequestTalentVideoForJobApplication)
  public async requestTalentVideoForJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobApplicationId }: RequestTalentVideoForJobApplication,
  ): Promise<void> {
    const jobApplication = await this.jobsApplicationsService
      .requestTalentVideoForJobApplication(jobApplicationId)
      .toPromise();
    await ctx.dispatch(new FetchJobDetails(jobApplication.jobId));
  }

  @Action(UploadVideoForJobApplication)
  public async uploadVideoForJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobApplicationId, media }: UploadVideoForJobApplication,
  ): Promise<void> {
    const jobApplication = await this.jobsApplicationsService
      .uploadJobApplicationVideo(jobApplicationId, media)
      .toPromise();
    ctx.patchState({ jobApplication });
  }

  @Action(AcceptJobApplication)
  public async acceptJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobId, jobApplicationId }: AcceptJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.accepted)
      .toPromise();
    await ctx.dispatch(new FetchJobDetails(jobId)).toPromise();
  }

  @Action(RequestToBookJobApplication)
  public async requestToBookJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobId, jobApplicationId, fees }: RequestToBookJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.requesttobook, null, fees)
      .toPromise();
    await ctx.dispatch(new FetchJobDetails(jobId)).toPromise();
  }

  @Action(CancelRequestToBookJobApplication)
  public async cancelRequestToBookJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobId, jobApplicationId }: RequestToBookJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.cancelled)
      .toPromise();
    await ctx.dispatch(new FetchJobDetails(jobId)).toPromise();
  }

  @Action(RejectJobApplication)
  public async rejectJobApplication(
    ctx: StateContext<JobsStateModel>,
    { userId, jobId, jobApplicationId }: RejectJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.rejected)
      .toPromise();
    await ctx.dispatch(new FetchJobs(userId, 'open')).toPromise();
  }

  @Action(RejectJobApplications)
  public async rejectJobApplications(
    ctx: StateContext<JobsStateModel>,
    { jobId, ids, reason }: RejectJobApplications,
  ): Promise<void> {
    await this.jobsService.rejectJobApplications(jobId, ids, reason).toPromise();
    ctx.dispatch(new FetchJobDetails(jobId));
  }

  @Action(SendBulkMessage)
  public async SendBulkMessage(
    ctx: StateContext<JobsStateModel>,
    { jobId, ids, message }: SendBulkMessage,
  ): Promise<void> {
    await this.jobsService.sendBulkMessage(jobId, ids, message).toPromise();
    ctx.dispatch(new FetchJobDetails(jobId));
  }

  @Action(ConfirmJobApplication)
  public async confirmJobApplication(
    ctx: StateContext<JobsStateModel>,
    { userId, jobId, jobApplicationId }: ConfirmJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.confirmed)
      .toPromise();
    await ctx.dispatch(new FetchJobApplications(userId, JobApplicationStatus.applied)).toPromise();
  }

  @Action(CancelJobApplication)
  public async cancelJobApplication(
    ctx: StateContext<JobsStateModel>,
    { userId, jobId, jobApplicationId }: CancelJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.cancelled)
      .toPromise();
    await ctx.dispatch(new FetchJobApplications(userId, JobApplicationStatus.applied)).toPromise();
  }

  @Action(AcceptJobApplicationAndCreatePayment)
  public async acceptJobApplicationAndCreatePayment(
    ctx: StateContext<JobsStateModel>,
    { jobUserId, jobId, jobApplicationId, returnUrl, directPayment }: AcceptJobApplicationAndCreatePayment,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.accepted)
      .toPromise();
    await ctx.dispatch(new FetchJobDetails(jobId));
    if (directPayment) {
      await ctx.dispatch(new CreatePayment(jobUserId, jobApplicationId, returnUrl)).toPromise();
    }
  }

  @Action(FetchJobApplication)
  public async fetchJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobApplicationId }: FetchJobApplication,
  ): Promise<void> {
    const jobApplication: JobApplication = await this.jobsApplicationsService
      .getJobApplication(jobApplicationId)
      .toPromise();
    ctx.patchState({ jobApplication });
  }

  @Action(SaveJobApplicationTravelExpenses)
  public async saveJobApplicationTravelExpenses(
    ctx: StateContext<JobsStateModel>,
    { id, travelExpenses, receipts, accountNumber, status, ownNumber, withoutTc }: SaveJobApplicationTravelExpenses,
  ): Promise<void> {
    const jobApplication: JobApplication = await this.jobsApplicationsService
      .saveJobApplicationTravelExpenses(id, travelExpenses, receipts, accountNumber, status, ownNumber, withoutTc)
      .toPromise();
    ctx.patchState({ jobApplication });
  }

  @Action(SaveJob)
  public async saveJob(
    ctx: StateContext<JobsStateModel>,
    { jobData, talentId, returnUrl, redirectToPayment }: SaveJob,
  ): Promise<Job> {
    const job: Job = await this.jobsService.saveJob({ job: jobData, talentId }).toPromise();
    ctx.patchState({ job });

    if (!redirectToPayment || job.payment?.paymentStatus === 'completed') {
      return job;
    }

    let transaction = null;
    if (!talentId) {
      transaction = await this.paymentsService.createJobPromotedPayment(job.id, returnUrl).toPromise();
      window.location.href = transaction.redirect_url;
    }
    if (talentId && job.jobApplications?.length === 1) {
      transaction = await this.paymentsService
        .createPayment(talentId, job.jobApplications[0].id, returnUrl)
        .toPromise();
      if (transaction.oppTransaction != null) {
        window.location.href = transaction.oppTransaction.redirect_url;
      }
    }
  }

  @Action(MarkJobCompleted)
  public async markJobCompleted(_: StateContext<JobsStateModel>, { id }: MarkJobCompleted): Promise<IJob> {
    return await this.jobsService.markJobCompleted(id).toPromise();
  }

  @Action(CancelJob)
  public async cancelJob(_: StateContext<JobsStateModel>, { id }: CancelJob): Promise<IJob> {
    return await this.jobsService.cancelJob(id).toPromise();
  }

  @Action(DuplicateJob)
  public async duplicateJob(ctx: StateContext<JobsStateModel>, { id }: DuplicateJob): Promise<void> {
    const result = await this.jobsService.duplicateJob(id).toPromise();
    ctx.dispatch(new Navigate([`/account/jobs/edit/${result.id}`]));
  }

  @Action(EnableJobNotification)
  public async enableJobNotification(_: StateContext<JobsStateModel>, { id }: EnableJobNotification): Promise<IJob> {
    return await this.jobsService.enableJobNotification(id).toPromise();
  }

  @Action(DisableJobNotification)
  public async disableJobNotification(_: StateContext<JobsStateModel>, { id }: DisableJobNotification): Promise<IJob> {
    return await this.jobsService.disableJobNotification(id).toPromise();
  }

  @Action(RejectBookingJobApplication)
  public async rejectBookingJobApplication(
    ctx: StateContext<JobsStateModel>,
    { userId, jobId, jobApplicationId, reason }: RejectBookingJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.rejectbooking, reason)
      .toPromise();
    await ctx.dispatch(new FetchJobApplications(userId, JobApplicationStatus.applied)).toPromise();
  }

  @Action(CancelBookingJobApplication)
  public async cancelBookingJobApplication(
    ctx: StateContext<JobsStateModel>,
    { userId, jobId, jobApplicationId, reason }: CancelBookingJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.cancelbooking, reason)
      .toPromise();
    await ctx.dispatch(new FetchJobApplications(userId, JobApplicationStatus.applied)).toPromise();
  }

  @Action(CasterCancelBookingJobApplication)
  public async casterCancelBookingJobApplication(
    ctx: StateContext<JobsStateModel>,
    { jobId, jobApplicationId, reason }: CasterCancelBookingJobApplication,
  ): Promise<void> {
    await this.jobsApplicationsService
      .updateJobApplicationState(jobId, jobApplicationId, JobApplicationStatus.castercancelbooking, reason)
      .toPromise();
    await ctx.dispatch(new FetchJobDetails(jobId));
  }

  @Action(FetchJobsForHomePage)
  public async fetchJobsForHomePage(ctx: StateContext<JobsStateModel>): Promise<void> {
    const jobs: PaginatedResults<Job> = await this.jobsService.findAll(0, 3, 'rate', 'desc', false, null).toPromise();
    ctx.patchState({ jobsForHomePage: jobs });
  }
}
