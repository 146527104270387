import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MasonryPhotoComponent } from './masonry-photo/masonry-photo.component';

@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [MasonryPhotoComponent],
  providers: [],
  exports: [MasonryPhotoComponent],
})
export class MasonryPhotoModule {}
