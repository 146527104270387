export class FetchTransactions {
  public static readonly type = '[Transactions] Fetch transactions';

  public constructor(
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'desc',
  ) {}
}
