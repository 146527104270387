import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MediaService } from '@core/services';
import { Media } from '@core/models';

import { MediaStateModel } from './media.state-model';
import { DeleteMedia, GetMediaInfo } from '@core/states/media/media.actions';

@State<MediaStateModel>({
  name: 'media',
  defaults: {
    media: null,
  },
})
@Injectable()
export class MediaState {
  public constructor(private mediaService: MediaService) {}

  @Selector()
  static media(state: MediaStateModel): Media {
    return state.media;
  }

  @Action(DeleteMedia)
  public async deleteMedia(_: StateContext<MediaStateModel>, { id }: DeleteMedia): Promise<void> {
    await this.mediaService.delete(id).toPromise();
  }

  @Action(GetMediaInfo)
  public async getMediaInfo(ctx: StateContext<MediaStateModel>, { id }: GetMediaInfo): Promise<void> {
    const media = await this.mediaService.info(id).toPromise();
    ctx.patchState({ media });
  }
}
