export class LoadJobRequests {
  public static readonly type = '[JobRequest] Load Job Requests';
  public constructor(
    public query: string,
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'desc',
  ) {}
}
