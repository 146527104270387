import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-account-profile-delete-modal',
  templateUrl: './mobile-account-profile-delete-modal.component.html',
  styleUrls: ['./mobile-account-profile-delete-modal.component.scss'],
})
export class MobileAccountProfileDeleteModalComponent implements OnChanges {
  @ViewChild('mobileAccountProfileDeleteModal') public modalComponent: MobileModalDialogComponent;
  @Output()
  public refreshProfile = new EventEmitter();
  @Output()
  public deleteTrigger = new EventEmitter();
  @Input() public deleteStatus = 'need confirmation';
  @Input() public profileCount = 1;
  @Input() public onProgress = false;

  public showSuccessDialog = false;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('onProgress' in changes) {
      if (this.deleteStatus !== 'need confirmation') {
        this.showSuccessDialog = true;
      }
    }
  }

  public async openDialog() {
    this.showSuccessDialog = false;
    return await this.modalComponent.open();
  }

  public onDeleteClick() {
    this.deleteTrigger.emit('');
  }
}
