import { Store } from '@ngxs/store';

import { CategoriesState, FiltersState } from '@core/states';
import { ICategory } from '@core/interfaces/category';
import { ITalentCategory } from '@core/interfaces/talent-category';
import { TranslateService } from '@ngx-translate/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { SubformsHelper } from './subforms.helper';

export class SearchPageHelper {
  private subformsHelper = new SubformsHelper();
  private popoverToggles = [];

  public constructor(private store: Store, private translate: TranslateService) {}

  public getSubforms(): any {
    return this.subformsHelper.getSubforms();
  }

  public getSubform(name: string): any {
    return this.subformsHelper.getSubform(name);
  }

  public patchSubform(name: string, data: any, fieldName?: string): any {
    return this.subformsHelper.patchSubform(name, data, fieldName);
  }

  public setPopoverToggles(toggles: Array<{ key: string; toggle: NgbPopover }>): void {
    this.popoverToggles = toggles;
  }

  public onOpenPopover(key: string): void {
    this.popoverToggles.filter((t) => t.key !== key).map((item) => item.toggle.close());
  }

  public findPopoverToggle(key: string): NgbPopover {
    return this.popoverToggles.find((t) => t.key === key).toggle;
  }

  public closePopover(key: string): void {
    this.findPopoverToggle(key)?.close();
  }

  public async getUrlParams(filterFormValue: any): Promise<any> {
    const filters = await this.getFilterMapping(filterFormValue);
    return await filters.reduce(async (acc: any, cur: any) => {
      const accum = await acc;
      const rawValue = await cur.rawValue();

      if (!rawValue || rawValue?.length === 0) {
        return accum;
      }

      if (!Array.isArray(rawValue) && typeof rawValue === 'object') {
        for (const key of Object.keys(rawValue)) {
          const v = rawValue[key];

          if (!v || v.length === 0) {
            continue;
          }

          accum[`${cur.fieldName}[${key}]`] = v;
        }

        return accum;
      }

      if (cur.parent) {
        accum[`${cur.parent}[${cur.fieldName}]`] = rawValue;
        return accum;
      }

      accum[cur.fieldName] = rawValue;
      return accum;
    }, Promise.resolve({}));
  }

  public async getFilterMapping(filterFormValue: any): Promise<any> {
    const mapping = [
      {
        label: await this.translate.get('Category').toPromise(),
        fieldName: 'categorySlug',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue.categorySlug || null,
        value: async (rawValue: string) => {
          const category = this.store
            .selectSnapshot(CategoriesState.categories)
            ?.results.find((c: ICategory) => c.slug === rawValue);

          return category?.label;
        },
      },
      {
        label: await this.translate.get('Subcategory').toPromise(),
        fieldName: 'talentCategorySlug',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue.talentCategorySlug || null,
        value: async (rawValue: string) => {
          const category = this.store
            .selectSnapshot(CategoriesState.categories)
            ?.results.find((c: ICategory) => c.slug === filterFormValue.categorySlug);

          const subcategory = category?.talentCategories.find((c: ITalentCategory) => c.slug === rawValue);
          return subcategory?.label;
        },
      },
      {
        label: await this.translate.get('Location').toPromise(),
        fieldName: 'location',
        type: 'text',
        parent: null,
        rawValue: async () => ({
          description: filterFormValue.location.description,
          x: filterFormValue.location.x,
          y: filterFormValue.location.y,
          distance: filterFormValue.location.distance || 0,
        }),
        value: async (rawValue: string) =>
          rawValue['description'] != null ? `${rawValue['description']} - ${rawValue['distance'] || 0} km` : null,
      },
      {
        label: await this.translate.get('Gender').toPromise(),
        fieldName: 'gender',
        type: 'text',
        parent: null,
        rawValue: async () => ({
          male: filterFormValue.gender.male || false,
          female: filterFormValue.gender.female || false,
        }),
        value: async (rawValue: any) => {
          const gender = [];

          if (rawValue['male'] !== false) {
            gender.push(await this.translate.get('Male').toPromise());
          }

          if (rawValue['female'] !== false) {
            gender.push(await this.translate.get('Female').toPromise());
          }

          if (gender.length === 0) {
            return;
          }

          return gender.join(', ');
        },
      },
      {
        label: await this.translate.get('Transgender').toPromise(),
        fieldName: 'transgender',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue.transgender,
        value: async (rawValue: any) => (rawValue ? 'Yes' : null),
      },
      {
        label: await this.translate.get('Age').toPromise(),
        fieldName: 'age',
        type: 'text',
        parent: null,
        rawValue: async () => {
          const nonNullValues = filterFormValue.age?.filter((v: any) => v !== null);
          if (!nonNullValues || nonNullValues.length === 0) {
            return;
          }

          return nonNullValues;
        },
        value: async (rawValue: any) => (rawValue ? rawValue.join(' - ') : null),
      },
      {
        label: await this.translate.get('Rate').toPromise(),
        fieldName: 'rate',
        type: 'text',
        parent: null,
        rawValue: async () => {
          const nonNullValues = filterFormValue.rate?.filter((v: any) => v !== null);
          if (!nonNullValues || nonNullValues.length === 0) {
            return;
          }

          return nonNullValues;
        },
        value: async (rawValue: any) => (rawValue ? rawValue.join(' - ') : null),
      },
      {
        label: await this.translate.get('Tfp').toPromise(),
        fieldName: 'tfp',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue.tfp,
        value: async (rawValue: any) => (rawValue ? 'Yes' : null),
      },
      {
        label: await this.translate.get('Xchange').toPromise(),
        fieldName: 'xchange',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue.xchange,
        value: async (rawValue: any) => (rawValue ? 'Yes' : null),
      },
      {
        label: await this.translate.get('OrderBy').toPromise(),
        fieldName: 'orderBy',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue.orderBy,
        value: async (rawValue: any) => rawValue,
        skipChip: true,
      },
      {
        label: await this.translate.get('Language').toPromise(),
        fieldName: 'language',
        type: 'text',
        parent: null,
        rawValue: async () => filterFormValue?.language,
        value: async (rawValue: any) =>
          rawValue ? await this.translate.get(`LANGUAGE.${rawValue}`).toPromise() : null,
      },
    ];

    const filters = this.store.selectSnapshot(FiltersState.filters);
    if (filters && filterFormValue?.filters) {
      for (const key of Object.keys(filterFormValue.filters)) {
        const filter = filters?.find((f) => f.slug === key);

        (mapping as any).push({
          label: await this.translate.get(filter.label).toPromise(),
          fieldName: key,
          type: filter.type,
          parent: 'filters',
          rawValue: async () => {
            const value = filterFormValue.filters[key];
            return value.filter((v: any) => v !== null);
          },
          value: async (rawValue: any) => {
            const options = filter.filterOptions.filter((o) => rawValue.includes(o.id));
            const labels = options.map((o) => o.label);
            return labels?.length > 0 ? labels.join(', ') : null;
          },
        });
      }
    }

    return mapping;
  }
}
