<ng-container *ngIf="talent?.reviews && initialized">
  <app-carousel [items]="talent.reviews" [options]="options" class="d-block">
    <ng-template #carouselSlideTpl let-item="item">
      <div class="container mh-3">
        <div class="row">
          <div class="col">
            <div class="pb-3 d-flex align-items-center">
              <app-image [image]="item.reviewer.photo.thumbUrl" class="profile-pic d-inline-block mr-2"></app-image>
              <h5 class="d-inline-block font-weight-light">{{ item.reviewer.profileName }}</h5>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <strong translate>Average rating</strong>
          </div>
          <div class="col-md-3">
            <app-rating [average]="(item.communication + item.overallRating + item.attitude) / 3"></app-rating>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md">
            <i>{{ item.comment }}</i>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8">
            <strong translate>Overall review score</strong>
          </div>
          <div class="col-md-3">
            <app-rating [average]="item.overallRating"></app-rating>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8">
            <strong translate>Communication</strong>
          </div>
          <div class="col-md-3">
            <app-rating [average]="item.communication"></app-rating>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8">
            <strong>Professional attitude</strong>
          </div>
          <div class="col-md-3">
            <app-rating [average]="item.attitude"></app-rating>
          </div>
        </div>
      </div>
    </ng-template>
  </app-carousel>
</ng-container>
