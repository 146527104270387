import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GeoLocationsService } from '@core/services';

import { GeocoderStateModel } from './geocoder.state-model';

import { SearchCity, SearchLocation, GetGeolocationByCity, GetGeolocationByDescription } from './geocoder.actions';
import { GeoLocation, GeocodingResultDTO } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { v4 } from 'uuid';

@State<GeocoderStateModel>({
  name: 'geolocation',
  defaults: {
    geolocation: null,
    geocodeResults: null,
    sessionKey: v4(),
  },
})
@Injectable()
export class GeocoderState {
  public constructor(private geolocationService: GeoLocationsService, private translateService: TranslateService) {}

  @Selector()
  static geolocation(state: GeocoderStateModel): GeoLocation {
    return state.geolocation;
  }

  @Selector()
  static geocodeResults(state: GeocoderStateModel): GeocodingResultDTO[] {
    return state.geocodeResults;
  }

  @Action(SearchCity)
  public async searchCity(ctx: StateContext<GeocoderStateModel>, { search, isoCode }: SearchCity): Promise<void> {
    const language = this.translateService.currentLang;
    const geocodeResults = await this.geolocationService
      .searchCity(search, isoCode, language, ctx.getState().sessionKey)
      .toPromise();
    ctx.patchState({ geocodeResults: geocodeResults.length ? geocodeResults : null });
  }

  @Action(SearchLocation)
  public async searchLocation(ctx: StateContext<GeocoderStateModel>, { search }: SearchLocation): Promise<void> {
    const language = this.translateService.currentLang;
    const geocodeResults = await this.geolocationService
      .searchLocation(search, language, ctx.getState().sessionKey)
      .toPromise();
    ctx.patchState({ geocodeResults: geocodeResults.length ? geocodeResults : null });
  }

  @Action(GetGeolocationByCity)
  public async getGeolocationByCity(
    ctx: StateContext<GeocoderStateModel>,
    { city, isoCode }: GetGeolocationByCity,
  ): Promise<void> {
    const language = this.translateService.currentLang;
    const geolocation = await this.geolocationService.getGeoLocationForCity(city, isoCode, language).toPromise();
    ctx.patchState({ geolocation, geocodeResults: null, sessionKey: v4() });
  }

  @Action(GetGeolocationByDescription)
  public async getGeolocationByDescription(
    ctx: StateContext<GeocoderStateModel>,
    { description }: GetGeolocationByDescription,
  ): Promise<void> {
    const language = this.translateService.currentLang;
    const geolocation = await this.geolocationService.getGeolocationByDescription(description, language).toPromise();
    ctx.patchState({ geolocation, geocodeResults: null, sessionKey: v4() });
  }
}
