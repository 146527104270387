import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserProfile } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@sentry/angular';

@Component({
  selector: 'app-mobile-talent-about',
  templateUrl: './mobile-talent-about.component.html',
  styleUrls: ['./mobile-talent-about.component.scss'],
})
export class MobileTalentAboutComponent implements OnChanges {
  @Input()
  public profile: UserProfile;

  public details = [];

  constructor(public translate: TranslateService) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('profile' in changes) {
      await this.createDetails(changes.profile.currentValue?.filters);
    }
  }

  public async createDetails(filters: any[]): Promise<void> {
    if (!filters) {
      return;
    }

    // const details = [...filters];
    const groupedDetails = [];

    for (const f of filters) {
      let value = f.value;
      if (f.filterOption != null) {
        value = await this.translate.get(f.filterOption.translateKey).toPromise();
      }

      let filterDetails = groupedDetails.find((d) => d.label === f.filter.label);

      if (!filterDetails) {
        filterDetails = {
          label: f.filter.label,
          translateKey: f.filter.translateKey,
          order: f.filter.order,
          type: f.filter.type,
          unit: f.filter.unit,
          values: [],
        };

        groupedDetails.push(filterDetails);
      }

      filterDetails.values.push(value);
    }

    // value and keys mapped by array
    this.details = groupedDetails.sort((a: any, b: any) => (a.order === b.order ? 0 : a.order > b.order ? 1 : -1));
  }
}
