<section class="talent">
  <div class="talent-vertical">
    <div class="row">
      <div class="col-6" *ngFor="let talent of talents" [id]="talent.userId === scrollToUser ? 'selectedTalent': ''">
        <div
          [ngClass]="(isTalentSelected(talent) && (showBulkMessage)) ? 'card-talent selected-talent' : 'card-talent'">
          <div *ngIf="showBulkMessage" class="custom-checkbox-mark" (click)="updateTalentToBulkMessage(talent)">
            <img *ngIf="isTalentSelected(talent)" src="/assets/img/mobile/badge.svg" alt="ratting" />
          </div>
          <div class="info" (click)="viewProfile(talent.user.id, talent.id)">
            <div class="wrap-info">
              <p class="price">
                <span class="label" translate>from</span>
                <span class="text-bold rate">€{{talent.rate}}</span>
                <span class="label">p/d</span>
              </p>
              <div class="name">
                <p>
                  {{talent.user.profileName | truncate: 13:false}}
                  <img src="/assets/img/mobile/badge.svg" alt="badge" *ngIf="talent.user.hasBadge" />
                </p>
              </div>
              <div class="ratting" *ngIf="talent?.averageReview">
                <p>
                  <img src="/assets/img/mobile/ratting.svg" alt="ratting" />
                  <span class="rate-number">{{talent?.averageReview | number: '1.1-1'}}</span>
                  ({{talent?.totalReviews}})
                </p>
              </div>
              <p>{{talent.user.city}},{{talent.user.country.isoCode}}</p>
              <p class="lang">{{ talent.category.label | translate}}</p>
            </div>
            <!-- <div class="addtional">
              <button class="btn btn-video" *ngIf="isHaveVideo(talent)" (click)="playVideo(isHaveVideo(talent))">
                <img src="/assets/img/mobile/play.svg" alt="" />
                Watch Video
              </button>
              <ng-container *ngIf="(activeRole !== null && activeRole !== 'talent') || !authenticated">
                <button
                  *ngIf="user$ | async as user"
                  class="btn btn-message"
                  (click)="sendMessage(talent.userId, user.id)">
                  Message
                </button>
              </ng-container>
              <button class="btn btn-profile" >View Profile</button>
            </div> -->
          </div>
          <div class="photo" [style.background-image]="'url('+talent.firstPhotoAlbum?.media[0]?.thumbUrl+')'"></div>
        </div>
      </div>
    </div>
  </div>
  <ng-container>
    <button
      [disabled]="!seletedTalents.length"
      (click)="openBulkMessageModal()"
      *ngIf="showBulkMessage"
      class="bulk-message-button">
      Bulk Message ({{seletedTalents.length}})
    </button>
  </ng-container>
  <app-mobile-talent-bulk-message
    #bulkMessageModal
    (resetSelection)="resetSelection()"></app-mobile-talent-bulk-message>
</section>
