import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenSizeDetectorComponent } from './components/screen-size-detector/screen-size-detector.component';

const components = [ScreenSizeDetectorComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components,
})
export class ScreenSizeModule {}
