import { IChat, IMessage, IUser } from '@core/interfaces';
import { Type } from 'class-transformer';
import { Message } from './message.model';
import { User } from './user.model';

export class Chat implements IChat {
  @Type(() => User)
  public sender: IUser;

  @Type(() => User)
  public recipient: IUser;

  @Type(() => Message)
  public messages?: IMessage[];

  @Type(() => Message)
  public lastMessage?: IMessage;

  public id: string;
  public senderId: string;
  public recipientId: string;
  public totalUnread?: number;
}
