<div [style]="'height:' + tileSize?.height + 'px'">
  <ng-container *ngIf="talents">
    <app-carousel [items]="talents" [options]="options" class="d-block">
      <ng-template #carouselSlideTpl let-item="item" let-visible="visible">
        <div [style]="{width: tileSize?.width + 'px', height: tileSize?.height + 'px'}">
          <app-talent-card *ngIf="visible" [talent]="item"></app-talent-card>
        </div>
      </ng-template>
    </app-carousel>
  </ng-container>
</div>
