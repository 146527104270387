import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Observable } from 'rxjs';
import { JobApplication, Media, TravelExpense } from '@core/models';
import { Store } from '@ngxs/store';
import {
  ApproveTravelExpenses,
  CreateTravelExpensePayment,
  FetchJobApplication,
  JobsState,
  RejectTravelExpenses,
} from '@core/states';
import { filter, take } from 'rxjs/operators';
import { JobApplicationStatus, TravelExpenseType } from '@core/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-detail-travel-cost-modal',
  templateUrl: './mobile-detail-travel-cost-modal.component.html',
  styleUrls: ['./mobile-detail-travel-cost-modal.component.scss'],
})
export class MobileDetailTravelCostModalComponent implements OnInit {
  @ViewChild('appDetailTravelCostModal') private modalComponent: MobileModalDialogComponent;

  public jobApplication$: Observable<JobApplication>;
  public jobApplication: JobApplication;
  public isBusy: boolean;
  public confirmApprove: boolean;
  public isSuccessType?: string;
  protected readonly JobApplicationStatus = JobApplicationStatus;
  protected readonly TravelExpenseType = TravelExpenseType;

  constructor(private store: Store, public translate: TranslateService) {}

  ngOnInit(): void {}

  public get totalPrice(): number {
    return this.jobApplication?.travelExpenses.reduce((prev: number, curr: TravelExpense) => prev + curr.price, 0);
  }

  public async openDialog(applicant: JobApplication): Promise<void> {
    this.jobApplication$ = this.store.select(JobsState.jobApplication);
    this.store.dispatch(new FetchJobApplication(applicant.id));
    this.jobApplication = await this.jobApplication$
      .pipe(
        filter((j: JobApplication) => !!j),
        take(1),
      )
      .toPromise();

    return await this.modalComponent.open().then(() => {
      this.jobApplication = null;
      this.jobApplication$ = null;
      this.isBusy = false;
      this.confirmApprove = false;
      this.isSuccessType = undefined;
    });
  }

  public close() {
    this.modalComponent.close().then(() => {
      window.location.replace(location.pathname);
    });
  }

  public async rejectTravelCosts(): Promise<void> {
    const translation = await this.translate.get('Please enter a reason for rejecting the travel expenses').toPromise();
    const reason: string = prompt(translation);
    if (!reason) {
      return;
    }
    this.isSuccessType = 'reject';
    this.store.dispatch(new RejectTravelExpenses(this.jobApplication.jobId, this.jobApplication.id, reason));
  }

  public async approveTravelCosts(): Promise<void> {
    this.isBusy = true;
    const translation = await this.translate.get('Weet je zeker dat je de reiskosten wilt goedkeuren?').toPromise();
    if (!confirm(translation)) {
      return;
    }

    if (!this.jobApplication.job?.bypassPayment) {
      this.store.dispatch(new CreateTravelExpensePayment(this.jobApplication.id, null));
      this.isSuccessType = 'approve';
      return;
    }

    this.store.dispatch(new ApproveTravelExpenses(this.jobApplication.jobId, this.jobApplication.id));
    this.isSuccessType = 'approve';
    this.confirmApprove = false;
    this.isBusy = false;
  }

  public confirmApproveTravelCost() {
    this.confirmApprove = !this.confirmApprove;
  }

  public download(receipt: Media): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', receipt.url);
    link.setAttribute('download', `${receipt.id}.jpg`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
