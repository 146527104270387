import { ITalentCategory } from '@core/interfaces';

export class TalentCategory implements ITalentCategory {
  public id: string;
  public label: string;
  public slug: string;

  get translateKey(): string {
    return 'TALENT_CATEGORY.' + this.slug;
  }
}
