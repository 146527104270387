import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Filter } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  public constructor(private http: HttpClient) {}

  public findAll(): Observable<Filter[]> {
    return this.http.get(`${environment.apiBaseUrl}/filters`).pipe(map((item: Filter[]) => plainToClass(Filter, item)));
  }

  public findByCategorySlug(categorySlug: string): Observable<Filter[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/filters/category/${categorySlug}`)
      .pipe(map((item: Filter[]) => plainToClass(Filter, item)));
  }

  public getById(id: string): Observable<Filter> {
    return this.http
      .get(`${environment.apiBaseUrl}/filters/${id}`)
      .pipe(map((data: Filter) => plainToClass(Filter, data)));
  }
}
