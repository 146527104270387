import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { PaginatedResults, Transaction } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  public constructor(private http: HttpClient) {}

  public getTransactions(
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'created',
    orderByDirection: string = 'desc',
  ): Observable<PaginatedResults<Transaction>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/transactions`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Transaction>) => {
          data.results = data.results.map((item: Transaction) => plainToClass(Transaction, item));
          return data;
        }),
      );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public async downloadCSV(transactionId: string): Promise<object> {
    return this.http.post(`${environment.apiBaseUrl}/transactions/${transactionId}/csv`, {}).toPromise();
  }
}
