import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { IUser } from '@core/interfaces';

enum contactModalType {
  CONFIRMATION = 'CONFIRMATION',
  REQUEST_DOCUMENTED = 'REQUEST_DOCUMENTED',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
}
@Component({
  selector: 'app-mobile-talent-contact-modal',
  templateUrl: './mobile-talent-contact-modal.component.html',
  styleUrls: ['./mobile-talent-contact-modal.component.scss'],
})
export class MobileTalentContactModalComponent implements OnInit {
  @Input()
  public talent: IUser;

  @ViewChild('appMobileTalentContactModal') private modalComponent: MobileModalDialogComponent;

  public contactModalType = contactModalType;
  public contactModal: contactModalType = contactModalType.CONFIRMATION;

  constructor() {}

  ngOnInit(): void {}

  async openDialog() {
    return await this.modalComponent.open().then(() => {
      this.contactModal = contactModalType.CONFIRMATION;
    });
  }

  public confirmContactDetails() {
    this.contactModal = contactModalType.REQUEST_DOCUMENTED;
    setTimeout(() => {
      this.contactModal = contactModalType.CONTACT_DETAILS;
    }, 2000);
  }
}
