<div class="time py-1" *ngIf="message">
  {{ transformTime(message.created) }}
  <app-icon
    *ngIf="showReadStatus && !message.read"
    icon="/assets/img/read-mark.svg"
    stroke="#4f4f4f"
    class="read-mark mx-auto my-auto px-1"></app-icon>
  <app-icon
    *ngIf="showReadStatus && message.read"
    icon="/assets/img/read-mark.svg"
    class="read-mark mx-auto my-auto px-1"></app-icon>
</div>
