import { IJobDate } from '@core/interfaces';
import { Transform } from 'class-transformer';
import * as moment from 'moment';

export class JobDate implements IJobDate {
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public start: Date;
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public end: Date;
  public id: string;
  public days?: number;
  public hours_per_day?: number;
}
