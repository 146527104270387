import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment/moment';
import { Observable } from 'rxjs';

import { User } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { SaveUser, UserState } from '@core/states';
import { IUser } from '@core/interfaces';
import { FileService } from '@src/ui/generic/images/services/file.service';
import { AuthService } from '@auth/services';

@Component({
  selector: 'app-upload-id',
  templateUrl: './upload-id.component.html',
  styleUrls: ['./upload-id.component.scss'],
})
export class UploadIDComponent implements OnInit {
  @Output()
  public triggerClose: EventEmitter<void> = new EventEmitter();

  @Select(UserState.user)
  public user$: Observable<User>;

  @ViewChild('picture')
  public pictureEl!: ElementRef;

  public inProgress = false;

  public form = new FormGroup({
    role: new FormControl('talent', [Validators.required]),
    profileName: new FormControl(null, [Validators.required]),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    gender: new FormControl('male', [Validators.required]),
    transgender: new FormControl(false, []),
    birthday: new FormControl(null, [Validators.required]),
    phonenumberCountryId: new FormControl(null, [Validators.required]),
    phonenumber: new FormControl(null, [Validators.required]),
    mobileVerificationCode: new FormControl(null, []),
    idVerificationFile: new FormControl(null, [
      (control: FormControl) => {
        if (control.value != null && control.value instanceof File) {
          if (!['image/png', 'image/x-png', 'image/gif', 'image/jpeg'].includes((control.value as File).type)) {
            return { invalid: true };
          } else {
            return null;
          }
        } else {
          return { invalide: true };
        }
      },
    ]),
    photoFile: new FormControl(null, []),
    street: new FormControl(null, [Validators.required]),
    houseNumber: new FormControl(null, [Validators.required]),
    postalCode: new FormControl(null, [Validators.required]),
    city: new FormControl(null, [Validators.required]),
    countryId: new FormControl(null, [Validators.required]),
    languages: new FormArray(
      [],
      [
        (control: FormArray) => {
          if (control.controls.length === 0) {
            return { required: true };
          }
          const counts = [];
          let hasDuplicates = false;

          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (const languageFormControl of control.controls) {
            if (counts[languageFormControl.value.id] === undefined) {
              counts[languageFormControl.value.id] = 1;
            } else {
              languageFormControl.get('id').setErrors({ duplicate: true });
              hasDuplicates = true;
            }
          }
          if (hasDuplicates) {
            return { duplicate: true };
          }

          return null;
        },
      ],
    ),
    vatNumberCountryId: new FormControl(null, []),
    vatNumber: new FormControl(null, []),
    vatCheckResult: new FormControl(null, []),
    coc_nr: new FormControl(null, []),
    companyName: new FormControl(null, []),
    coordinates: new FormGroup(
      {
        x: new FormControl(null, [Validators.required]),
        y: new FormControl(null, [Validators.required]),
      },
      [Validators.required],
    ),
  });

  public idImageURL: string;

  public user: IUser;

  public token: string;

  public isSaved = false;

  public constructor(private fileService: FileService, private store: Store, private authService: AuthService) {}

  public ngOnInit(): void {
    this.token = this.authService.getJwtToken().access_token;
    this.user$.subscribe((user: User): void => {
      this.user = user;
      this.setForm(user);
    });
  }

  public onValueChange(value: FileList): void {
    this.form.patchValue({ idVerificationFile: value[0] });
  }

  public chooseImage(): void {
    this.pictureEl.nativeElement.click();
  }

  public setForm(user: User): void {
    if (!user) {
      return;
    }

    if (user.idVerificationMedia) {
      this.idImageURL = `${user.idVerificationMedia.url}?token=${this.token}`;
    }

    this.form.patchValue({
      ...user,
      birthday: user.birthday ? moment(user.birthday).format('YYYY-MM-DD') : null,
    });
  }

  public async handleSave(): Promise<void> {
    this.inProgress = true;

    const formValue = {
      ...this.form.value,
      id: this.user.id,
      role: [this.form.value.role],
      email: this.user.email,
      mobileVerification: true,
      termsAndConditions: undefined,
      isTalent: undefined,
    };

    if (formValue.languages.length === 0) {
      delete formValue.languages;
    }

    const formData = {
      ...formValue,
      idVerificationFile: formValue.idVerificationFile
        ? await this.fileService.readFile(formValue.idVerificationFile)
        : null,
    };

    return await this.store.dispatch(new SaveUser(this.user.id, formData)).toPromise();
  }

  public async handleSubmit(): Promise<void> {
    if (this.inProgress) {
      return;
    }

    if (this.form.get('idVerificationFile').value !== null && this.user.id) {
      try {
        await this.handleSave();
        this.isSaved = true;
      } catch (e) {
        console.log('Failed to save profile', e);
        this.inProgress = false;
        alert('Oop something went wrong, try again');
      } finally {
        this.inProgress = false;
      }
    }
  }

  public handleClose(): void {
    this.triggerClose.emit();
  }
}
