import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthConfig, JwtToken } from '../models';
import { StorageService } from './storage.service';
import { MobileStorageKey, MobileStorageService } from '@shared/mobile-components/services/mobile-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public constructor(
    private http: HttpClient,
    private config: AuthConfig,
    private storage: StorageService,
    private mobileStorage: MobileStorageService,
  ) {}

  public getJwtToken(): JwtToken {
    const token = this.storage.get();

    if (!token) {
      return;
    }

    return new JwtToken(token);
  }

  public isAuthenticated(): boolean {
    const jwtToken = this.getJwtToken();
    return !!jwtToken;
  }

  public isDisabled(): boolean {
    return this.getJwtToken()?.getBool('disabled');
  }

  public userId(): string {
    if (!this.isAuthenticated) {
      return;
    }
    return this.getJwtToken().getClaim('sub');
  }

  public login(username: string, password: string): Observable<JwtToken> {
    return this.http.post(`${this.config.loginUrl}`, { username, password }).pipe(
      map((res: any) => new JwtToken(res.access_token)),
      tap((jwtToken: JwtToken) => this.storage.set(jwtToken.access_token)),
    );
  }

  public verify(verificationCode: string): Observable<JwtToken> {
    return this.http.post(`${this.config.verifyUrl}`, { verificationCode }).pipe(
      map((res: any) => new JwtToken(res.access_token)),
      tap((jwtToken: JwtToken) => this.storage.set(jwtToken.access_token)),
    );
  }

  public refreshToken(token: string): Observable<JwtToken> {
    return this.http.post(`${this.config.refreshUrl}`, { token }).pipe(
      map((res: any) => new JwtToken(res.access_token)),
      tap((jwtToken: JwtToken) => this.storage.set(jwtToken.access_token)),
    );
  }

  public logout(): void {
    this.storage.remove();
    this.mobileStorage.remove(MobileStorageKey.activeRole);
  }
}
