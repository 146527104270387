import { Component, Inject, Input, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RejectBookingJobApplication } from '@core/states';

import { Store } from '@ngxs/store';

@Component({
  selector: 'app-reject-booking',
  templateUrl: './reject-booking.component.html',
  styleUrls: ['./reject-booking.component.scss'],
})
export class RejectBookingComponent {
  @Input()
  public jobId: string;

  @Input()
  public jobApplicationId: string;

  @Input()
  public userId: string;

  public form = new FormGroup({
    reason: new FormControl(null, [Validators.required]),
  });

  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { jobApplicationId: string; jobId: string; userId: string },
    @Optional() private dialogRef: MatDialogRef<RejectBookingComponent>,
    private store: Store,
  ) {
    this.jobApplicationId = this.data.jobApplicationId;
    this.jobId = this.data.jobId;
    this.userId = this.data.userId;
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public async rejectBooking(): Promise<void> {
    this.isBusy = true;
    await this.store
      .dispatch(new RejectBookingJobApplication(this.userId, this.jobId, this.jobApplicationId, this.form.value.reason))
      .toPromise();

    this.isBusy = false;
    this.closeModal();
  }
}
