import { IJob } from './job';
import { IMedia } from './media';
import { IPayment } from './payment';
import { IUser } from './user';

export interface IJobApplication {
  id: string;
  jobId: string;
  talentVideoId?: string;
  status: JobApplicationStatus;
  isDirectOffer: boolean;
  job: IJob;
  user: IUser;
  talentVideo?: IMedia;
  payment?: IPayment;
  confirmationHash?: string;
  cancelHash?: string;
  casterRequestedVideo?: Date;
  costRejectionReason?: string;
  tcEmailTalentSent?: boolean;
  tcEmailCasterSent?: boolean;
}

export enum JobApplicationStatus {
  offered = 'offered',
  applied = 'applied',
  open = 'open',
  rejected = 'rejected',
  accepted = 'accepted',
  completed = 'completed',
  cancelled = 'cancelled',
  refunded = 'refunded',
  confirmed = 'confirmed',
  requesttobook = 'requesttobook',
  cancelbooking = 'cancelbooking',
  rejectbooking = 'rejectbooking',
  castercancelbooking = 'castercancelbooking',
  travelCostPending = 'travelCostPending',
  travelCostRejected = 'travelCostRejected',
  travelCostApproved = 'travelCostApproved',
}
