import { BaseOppObjectDTO } from './base-opp-object.dto';
import { ComplianceDTO } from './compliance.dto';
import { AddressDTO } from './address.dto';
import { TradingNameDTO } from './trading-name.dto';
import { ContactDTO } from './contact.dto';
import { ProfileDTO } from './profile.dto';
import { PaymentMethodDTO } from './payment-method.dto';
import { MerchantStatus } from '@core/interfaces/user';

export enum MerchantType {
  consumer = 'consumer',
  business = 'business',
}

export class MerchantDTO extends BaseOppObjectDTO {
  public status: MerchantStatus;
  public compliance: ComplianceDTO;
  public type: MerchantType;
  public coc_nr?: string;
  public name?: string;
  public phone?: string;
  public vat_nr?: string;
  public country: string;
  public sector: string;
  public addressses: AddressDTO[];
  public trading_names: TradingNameDTO[];
  public contacts: ContactDTO[];
  public profiles: ProfileDTO[];
  public payment_methods: PaymentMethodDTO[];
  public notify_url: string;
  public return_url: string;
  public metadata: any[];
}

export class CreateMerchantDTO {
  public country: string;
  public emailaddress: string;
  public phone: string;
  public type: MerchantType;
  public notify_url: string;
  public return_url: string;
  public locale?: string;
  public name_first?: string;
  public name_last?: string;
  public addressses?: AddressDTO[];
}

export class CreateBusinessMerchantDTO extends CreateMerchantDTO {
  public coc_nr: string;
  public vat_nr?: string;
  public legal_name?: string;
  public legal_entity?: string;
  public trading_names?: TradingNameDTO[];
}
