<div id="portfolio" class="card-wrapper d-flex flex-column">
  <ng-container class="break-after">
    <app-talent-pdf-card [talentProfile]="profile"></app-talent-pdf-card>
  </ng-container>
</div>

<div class="no-print print-button position-sticky">
  <button class="d-flex align-items-center justify-content-center" (click)="printPage()" [disabled]="isProcess">
    <ng-container *ngIf="!isProcess">{{ 'Download' | translate }}</ng-container>
    <ng-container *ngIf="isProcess">
      <app-spinner></app-spinner>
      &nbsp;&nbsp;{{ progressStatus | translate }}({{math.round(progressPercentage)}}%)
    </ng-container>
  </button>
</div>
