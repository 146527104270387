<section>
  <div class="mt-5 pt-5">
    <ng-container *ngIf="!isSent; else thankYou">
      <div class="container text-center">
        <h2 class="h3 text-white mb-3" translate>Casterbee is not available in your region</h2>
        <p class="text-white" translate>
          If you would like us to open up the platform in your region, please let us know.
        </p>
      </div>

      <div class="container">
        <form class="w-50 mx-auto" [formGroup]="form" (ngSubmit)="send()">
          <div class="form-group">
            <label for="name" translate>Your name</label>
            <input type="text" class="form-control" formControlName="name" id="name" />
          </div>
          <div class="form-group">
            <label for="email" translate>Your e-mailaddress</label>
            <input type="email" class="form-control" formControlName="email" id="email" />
          </div>

          <div class="form-group">
            <label for="message" translate>Your message</label>
            <textarea class="form-control" rows="5" formControlName="message" id="message"></textarea>
          </div>

          <div class="form-group">
            <app-button [isDisabled]="form.invalid">
              <button
                type="sumbit"
                [disabled]="form.invalid"
                class="w-100 h-100 bg-transparent border-0 text-white"
                translate>
                Send
              </button>
            </app-button>
          </div>
        </form>
      </div>
    </ng-container>

    <ng-template #thankYou>
      <div class="container text-center">
        <h2 class="h3 text-white mb-3" translate>Thank you</h2>
        <p class="text-white" translate>Thank you for your message.</p>
      </div>
    </ng-template>
  </div>
</section>
