<app-mobile-modal-dialog #appMobileCompleteJobEdit [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="invoice-job-form-container">
    <h5 *ngIf="!isEdit">Add Talent</h5>
    <h5 *ngIf="isEdit">Edit Invoice</h5>

    <form class="form-container" *ngIf="formBuilder" [formGroup]="formBuilder">
      <div *ngIf="!isEdit" class="control-container">
        <label class="form-label" translate>Talent Name :</label>
        <input *ngIf="!formValue.talentSelected" type="text" class="form-control" formControlName="talentSearch" />
        <div *ngIf="formValue.talentSelected" class="user-preview-field">
          <input readonly type="text" class="form-control" [value]="formValue.talentSelected.profileName" />
          <img (click)="selectUser()" src="assets/img/mobile/close.svg" />
        </div>

        <ng-container *ngIf="results$ | async as results">
          <ng-container *ngIf="!!results && results.length; else noResults">
            <div class="results">
              <ng-container *ngFor="let user of results">
                <div (click)="selectUser(user)" class="result" *ngIf="user.profiles.length">
                  <img
                    *ngIf="user.photo; else noPhoto"
                    class="profile-image rounded-circle mr-3"
                    [src]="user.photo.url" />
                  <ng-template #noPhoto>
                    <div class="profile-image bg-gray rounded-circle mr-3"></div>
                  </ng-template>
                  <div>
                    <div>{{ user.profileName }}</div>
                    <div class="text-gray">{{ user.city }}, {{ user.country.isoCode }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="isEdit" class="user-detail">
        <div class="profile-image-container">
          <img class="profile-image rounded-circle" [src]="formValue.talentSelected.photo.thumbUrl" />
        </div>
        {{formValue.talentSelected.profileName}}
      </div>
      <div class="control-group">
        <div class="control-container">
          <label class="form-label" translate>Total fee</label>
          <input type="text" class="form-control input-euro" appCommaToDot="numberAndDot" formControlName="totalFee" />
        </div>
        <div class="control-container">
          <label class="form-label" translate>Total buy-out</label>
          <input
            type="text"
            class="form-control input-euro"
            appCommaToDot="numberAndDot"
            formControlName="totalBuyout" />
        </div>
      </div>
      <div class="control-container">
        <label class="form-label" translate>Travel Cost</label>
        <div class="w-full">
          <div class="checkbox-group">
            <label (click)="onChangeTravelCost(false)">
              <app-radio class="mr-2" isReadOnly="true" [value]="false" formControlName="travelCost"></app-radio>
              <span translate>No</span>
            </label>
            <label (click)="onChangeTravelCost(true)">
              <app-radio class="mr-2" isReadOnly="true" [value]="true" formControlName="travelCost"></app-radio>
              <span translate>Yes</span>
            </label>
          </div>
        </div>
      </div>

      <div *ngIf="formValue?.travelCost" class="control-group">
        <div class="control-container">
          <label class="form-label" translate>Per KM</label>
          <input
            type="text"
            appCommaToDot="numberAndDot"
            class="form-control input-euro"
            formControlName="travelCostAmount" />
        </div>
        <div class="control-container">
          <div class="form-label">
            <label (click)="changeControlValue('travelCost')">
              <app-radio class="mr-2" [value]="true" formControlName="travelCostMax" isReadOnly="true"></app-radio>
              <span translate>Max ammount</span>
            </label>
          </div>
          <input
            type="text"
            appCommaToDot="numberAndDot"
            class="form-control input-euro"
            formControlName="travelCostMaxAmount" />
        </div>
      </div>
      <div class="control-container">
        <label class="form-label" translate>Parking Cost</label>
        <div class="checkbox-group">
          <label>
            <app-radio class="mr-2" [value]="false" formControlName="parkingCost"></app-radio>
            <span translate>No</span>
          </label>
          <label>
            <app-radio class="mr-2" [value]="true" formControlName="parkingCost"></app-radio>
            <span translate>Yes</span>
          </label>
        </div>
      </div>
      <div *ngIf="formValue?.parkingCost" class="control-group">
        <div class="control-container">
          <div class="form-label">
            <label (click)="changeControlValue('parkingCost')">
              <app-radio class="mr-2" [value]="true" formControlName="parkingCostMax" isReadOnly="true"></app-radio>
              <span translate>Max ammount</span>
            </label>
          </div>
          <input
            type="text"
            appCommaToDot="numberAndDot"
            class="form-control input-euro"
            formControlName="parkingCostMaxAmount" />
        </div>
      </div>
    </form>

    <div class="action-area">
      <button [disabled]="!formValue.talentSelected" type="submit" (click)="onSave(false)">Save Changes</button>
      <button type="button" (click)="close()">Cancel</button>
    </div>
  </div>
</app-mobile-modal-dialog>
