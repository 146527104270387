import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Deck, Membership, User, UserProfile } from '@core/models';
import { Select, Store } from '@ngxs/store';
import { DecksState, FetchCurrentUser, UpdateUserMembership, UserState } from '@core/states';
import { UpdateMembershipDto } from '@core/dto/membership.dto';
import { Observable } from 'rxjs';
import { combineLatest, filter } from 'rxjs/operators';
import { RemoveItemsComponent } from '@src/account/disabled/components/remove-items/remove-items.component';
import { MembershipsService } from '@core/services';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { MobileSuccessModalComponent } from '../mobile-success-modal/mobile-success-modal.component';

@Component({
  selector: 'app-mobile-account-membership-confirmation-modal',
  templateUrl: './mobile-account-membership-confirmation-modal.component.html',
  styleUrls: ['./mobile-account-membership-confirmation-modal.component.scss'],
})
export class MobileAccountMembershipConfirmationModalComponent implements OnInit {
  @Output()
  public cancelClicked = new EventEmitter();

  @Select(UserState.profiles)
  public profiles$: Observable<UserProfile[]>;

  @Select(DecksState.decks)
  public decks$: Observable<Deck[]>;

  @ViewChild('appMembershipConfirmation') private modalComponent: MobileModalDialogComponent;
  @ViewChild('accountMembershipNotification') private accountMembershipNotification: MobileSuccessModalComponent;

  public membership: Membership;

  public oldMembership: Membership;

  public freeMembership: Membership;

  public isBusy = false;

  public isMonthlyButtonProcessing = false;

  public isYearlyButtonProcessing = false;

  public user: User;

  public isShowWarningMessage = false;

  public selectedInterval: 'month' | 'year';

  public updateMembershipMessage = '';

  public allowMonthlyMembership = false;

  private profiles: UserProfile[];
  private decks: Deck[];

  public constructor(private dialog: MatDialog, private store: Store) {}

  public async ngOnInit(): Promise<void> {
    const user = this.store.selectSnapshot(UserState.user);

    if (!!user) {
      const monthlyMembershipDateLimit = moment('2024-09-11');
      const userRegisterDate = moment(this.user.created);
      this.allowMonthlyMembership = userRegisterDate.isBefore(monthlyMembershipDateLimit);
    }
  }

  public accept(interval: 'month' | 'year') {
    this.selectedInterval = interval;

    if (this.showWarningMessage()) this.isShowWarningMessage = true;
    else this.processMembership(interval);
  }

  public resetButtonLoader(): void {
    this.isBusy = false;
    this.isMonthlyButtonProcessing = false;
    this.isYearlyButtonProcessing = false;
  }

  public async processMembership(interval: 'month' | 'year'): Promise<void> {
    if (this.isBusy) return;

    const user = this.store.selectSnapshot(UserState.user);

    this.isBusy = true;
    this.isMonthlyButtonProcessing = interval === 'month';
    this.isYearlyButtonProcessing = interval === 'year';
    const membershipStatus = this.isUpgradeMembership() ? 'upgrading' : 'downgrade';

    if (MembershipsService.shouldDeleteItems(this.oldMembership, this.membership, this.profiles, this.decks)) {
      this.closeDialog(true, membershipStatus);

      const result = await this.dialog
        .open(RemoveItemsComponent, {
          data: {
            membershipId: this.membership.id,
          },
        })
        .afterClosed()
        .toPromise();

      if (!result?.success) {
        this.resetButtonLoader();
        return;
      }
    }

    if (!user) {
      this.closeDialog(true, membershipStatus);
      this.resetButtonLoader();
      return;
    }

    const updateMembershipDto: UpdateMembershipDto = {
      userId: user.id,
      membershipId: this.membership.id,
      interval,
      startDate: new Date(),
    } as UpdateMembershipDto;

    try {
      await this.store.dispatch(new UpdateUserMembership(user.id, updateMembershipDto)).toPromise();
      await this.store.dispatch(new FetchCurrentUser()).toPromise();
      //At this state, doesn't have to call this.resetButtonLoader();
      //After UpdateUserMembership, it should redirect the page to new payment method.
      this.closeDialog(true, membershipStatus);
      this.cancelClicked.emit();
    } catch (error) {
      this.resetButtonLoader();
    }
  }

  public getMembershipLevel(membership: Membership) {
    return membership.title.includes('Premium') ? 3 : membership.title.includes('Starter') ? 2 : 1;
  }

  public isMoveToFree() {
    return this.membership.price_month === 0 && this.membership.price_year === 0;
  }

  public isDowngradeMembership() {
    return this.membership.commission > this.oldMembership.commission;
  }

  public isUpgradeMembership() {
    return this.membership.commission < this.oldMembership.commission;
  }

  public isIntervalChange() {
    return this.selectedInterval !== this.oldMembership.interval;
  }

  public isChangeToDiscount() {
    return this.getMembershipLevel(this.membership) === this.getMembershipLevel(this.oldMembership);
  }

  public showWarningMessage(): boolean {
    if (this.sameMembership) {
      return this.isIntervalChange();
    }

    //moving from free membership doesn't have warning message
    if (!this.user) return;

    return (
      (this.user.mandate_uid && this.user.mandate_status === 'completed') ||
      (this.oldMembership.price_month !== 0 && this.oldMembership.price_year !== 0)
    );
  }

  public async cancelMembership(): Promise<void> {
    if (this.membership.price_month === 0) {
      return;
    }

    if (!!this.membership.price_month) {
      // this.dialog.open(CancelMembershipComponent, {
      //   data: {
      //     membership: this.membership,
      //     freeMembership: this.freeMembership,
      //   },
      // });

      const user = this.store.selectSnapshot(UserState.user);
      this.isBusy = true;

      if (MembershipsService.shouldDeleteItems(this.membership, this.freeMembership, this.profiles, this.decks)) {
        const result = await this.dialog
          .open(RemoveItemsComponent, {
            data: {
              membershipId: this.membership.id,
            },
          })
          .afterClosed()
          .toPromise();

        this.isBusy = false;

        if (!result?.success) {
          return;
        }
      }

      if (!user) {
        return;
      }

      const updateMembershipDto: UpdateMembershipDto = {
        userId: user.id,
        membershipId: this.freeMembership.id,
        interval: 'month',
        startDate: new Date(),
      } as UpdateMembershipDto;

      await this.store.dispatch(new UpdateUserMembership(user.id, updateMembershipDto)).toPromise();
      await this.store.dispatch(new FetchCurrentUser()).toPromise();

      this.closeDialog(true, 'canceled');
    }
  }

  public async openDialog(data: any) {
    this.membership = data.membership;
    this.oldMembership = data.oldMembership;
    this.freeMembership = data.freeMembership;

    this.user = this.store.selectSnapshot(UserState.user);
    this.profiles$
      .pipe(
        combineLatest(this.decks$),
        filter(([profiles, decks]) => !!profiles && !!decks),
      )
      .subscribe(([profiles, decks]) => {
        this.profiles = profiles;
        this.decks = decks;
      });

    return await this.modalComponent.open().then(() => {
      this.isShowWarningMessage = false;
      this.resetButtonLoader();
    });
  }

  public closeDialog(showSuccessDialog: boolean = false, successDialogStatus: string = ''): void {
    if (showSuccessDialog) {
      this.updateMembershipMessage = `You have successfuly ${successDialogStatus} your membership!`;
      this.accountMembershipNotification.openDialog();
    }

    this.modalComponent.close();
  }

  public get sameMembership(): boolean {
    return this.oldMembership.id === this.membership.id;
  }
}
