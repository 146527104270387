import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { TalentsStateModel } from './talents.state-model';
import { UserProfilesService, UsersService } from '@core/services';

import {
  LoadLatestTalent,
  LoadPromoted,
  LoadTalentProfileByProfileName,
  LoadTalentWithProfile,
  LoadTalents,
} from './talents.actions';
import { PaginatedResults, User, UserProfile } from '@core/models';
import { AddProfileToUser, UpdateUserDetails } from '../users';
import { Navigate } from '@ngxs/router-plugin';

@State<TalentsStateModel>({
  name: 'talents',
  defaults: {
    promoted: null,
    latest: null,
    talents: null,
    talent: null,
    talentProfile: null,
    talentProfiles: null,
  },
})
@Injectable()
export class TalentsState {
  public constructor(private userProfilesService: UserProfilesService, private usersService: UsersService) {}

  @Selector()
  static talents(state: TalentsStateModel): PaginatedResults<UserProfile> {
    return state.talents;
  }

  @Selector()
  static promoted(state: TalentsStateModel): PaginatedResults<UserProfile> {
    return state.promoted;
  }

  @Selector()
  static latest(state: TalentsStateModel): PaginatedResults<UserProfile> {
    return state.latest;
  }

  @Selector()
  static talent(state: TalentsStateModel): User {
    return state.talent;
  }

  @Selector()
  static talentProfile(state: TalentsStateModel): UserProfile {
    return state.talentProfile;
  }

  @Selector()
  static talentProfiles(state: TalentsStateModel): UserProfile[] {
    return state.talentProfiles;
  }

  @Action(LoadTalents)
  public async loadTalents(
    ctx: StateContext<TalentsStateModel>,
    { page, pageSize, sortColumn, sortDirection, filterFormData }: LoadTalents,
  ): Promise<void> {
    const talents = await this.userProfilesService
      .findAll(page, pageSize, sortColumn, sortDirection, filterFormData)
      .toPromise();

    const currentTalents = ctx.getState().talents;
    if (page > 0 && currentTalents != null) {
      talents.results.unshift(...currentTalents.results);
      ctx.patchState({ talents });
    } else {
      ctx.patchState({ talents });
    }
  }

  @Action(LoadPromoted)
  public async loadPromoted(
    ctx: StateContext<TalentsStateModel>,
    { page, pageSize, sortColumn, sortDirection, filterFormData }: LoadPromoted,
  ): Promise<void> {
    const promoted = await this.userProfilesService
      .findAll(page, pageSize, sortColumn, sortDirection, filterFormData)
      .toPromise();
    ctx.patchState({ promoted });
  }

  @Action(LoadLatestTalent)
  public async loadLatestTalent(
    ctx: StateContext<TalentsStateModel>,
    { page, pageSize, sortColumn, sortDirection, filterFormData }: LoadLatestTalent,
  ): Promise<void> {
    const latest = await this.userProfilesService
      .findAll(page, pageSize, sortColumn, sortDirection, filterFormData)
      .toPromise();
    ctx.patchState({ latest });
  }

  @Action(LoadTalentWithProfile)
  public async loadTalentWithProfile(
    ctx: StateContext<TalentsStateModel>,
    { userId, profileId }: LoadTalentWithProfile,
  ): Promise<void> {
    if (!!userId && !!profileId) {
      const talentProfiles = await this.userProfilesService.getByUserId(userId).toPromise();
      const talentProfile = await this.userProfilesService.getById(profileId).toPromise();
      if (!!talentProfile) {
        ctx.patchState({ talentProfile, talentProfiles, talent: talentProfile.user });
      } else {
        ctx.patchState({ talentProfile: talentProfiles[0], talentProfiles, talent: talentProfiles[0]?.user });
      }
    }
  }

  @Action(LoadTalentProfileByProfileName)
  public async loadTalentProfileByProifleName(
    ctx: StateContext<TalentsStateModel>,
    { profileName }: LoadTalentProfileByProfileName,
  ): Promise<void> {
    if (!!profileName) {
      const profiles = await this.userProfilesService.getByProfileName(profileName).toPromise();
      if (!!profiles && profiles?.length) {
        ctx.patchState({ talentProfile: profiles[0], talentProfiles: profiles, talent: profiles[0].user });
      }
    }
  }
}
