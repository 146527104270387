import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  AuthState,
  LoadTalentProfileByProfileName,
  LoadTalentWithProfile,
  TalentsState,
  UserState,
} from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Category, User, UserProfile } from '@core/models';
import { OfferJobModalComponent } from '@src/visitor/modals/components/offer-job-modal/offer-job-modal.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IPackage } from '@core/interfaces/package';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RouterHelperService } from '@core/services';
import { JoinModalComponent } from '@src/visitor/modals/components/join-modal/join-modal.component';
import { OfferJobThankYouModalComponent } from '@src/visitor/modals/components/offer-job-thank-you-modal/offer-job-thank-you-modal.component';
import { PaymentProcessModalComponent } from '@src/visitor/modals/components/payment-process-modal/payment-process-modal.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-talent-detail-page',
  templateUrl: './talent-detail-page.component.html',
  styleUrls: ['./talent-detail-page.component.scss'],
})
export class TalentDetailPageComponent implements OnInit, OnDestroy {
  @Select(UserState.user)
  public user$: Observable<User>;

  @Select(TalentsState.talentProfile)
  public profile$: Observable<UserProfile>;

  @Select(TalentsState.talentProfiles)
  public profiles$: Observable<UserProfile[]>;

  public isLoading = true;

  private componentDestroyed$: Subject<boolean> = new Subject();

  public constructor(
    private store: Store,
    private dialog: MatDialog,
    private routerHelper: RouterHelperService,
    private router: Router,
    private location: Location,
    public dialogRef: MatDialogRef<TalentDetailPageComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
  ) {}

  public async ngOnInit(): Promise<void> {
    if (window.innerWidth <= 500) {
      this.router.navigateByUrl(`talents${this.location.path()}`);
    }
    this.routerHelper.activeRoute$
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((route: ActivatedRoute) => route.snapshot.params),
        startWith(this.data),
        tap((): boolean => (this.isLoading = true)),
        switchMap((data: any) => {
          if (data?.profileName) {
            return this.store.dispatch([new LoadTalentProfileByProfileName(data.profileName)]);
          } else {
            return this.store.dispatch([new LoadTalentWithProfile(data.userId, data.profileId)]);
          }
        }),
        tap((): boolean => (this.isLoading = false)),
      )
      .subscribe();

    const activatedRoute: UrlSegment[] = await this.routerHelper.activeRoute$
      .pipe(
        first(),
        map((data) => data.snapshot?.url),
      )
      .toPromise();

    if (activatedRoute.map((u: UrlSegment) => u.path).includes('job-process-payment')) {
      const dialogRef: MatDialogRef<PaymentProcessModalComponent> = await this.dialog.open(
        PaymentProcessModalComponent,
        {
          data: {
            payment_uid: this.data.paymentId,
            isDirectOffer: true,
          },
        },
      );
      const result = await dialogRef.afterClosed().toPromise();
      if (result !== false) {
        if (result.success) {
          this.dialog.open(OfferJobThankYouModalComponent);
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public openOfferJobModal(p?: IPackage): void {
    if (this.store.selectSnapshot(AuthState.authenticated)) {
      if (this.store.selectSnapshot(AuthState.jwtToken).payload.roles.includes('caster')) {
        const profiles: UserProfile[] = this.store.selectSnapshot(TalentsState.talentProfiles);
        const profile: UserProfile = this.store.selectSnapshot(TalentsState.talentProfile);
        const talent: User = this.store.selectSnapshot(TalentsState.talent);
        const categories: Category[] = profiles.map((pkg: UserProfile) => pkg.category);
        this.dialog.open(OfferJobModalComponent, {
          data: {
            category: profile.category,
            categories,
            talent,
            package: p,
          },
        });
      }
    } else {
      this.dialog.open(JoinModalComponent, {});
    }
  }
}
