<div class="d-flex flex-column w-100 text-white p-5">
  <h2 translate>Reject applications?</h2>

  <ng-container *ngIf="!formSend">
    <p translate>Are you sure you want to reject these talents?</p>
    <form (ngSubmit)="reject()" [formGroup]="form" class="mt-5 d-flex flex-column">
      <div class="row mb-3">
        <div class="col-12">
          <label translate>Reason</label>
          <app-textarea
            class="bg-gray w-100 d-flex flex-fill border-0 rounded-sm mb-1"
            formControlName="reason"></app-textarea>
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col-5">
          <app-button [isBusy]="isBusy" buttonStyle="white-outline">
            <button
              (click)="closeModal()"
              class="w-100 h-100 bg-transparent border-0 text-white"
              translate
              type="button">
              Close
            </button>
          </app-button>
        </div>
        <div class="col-5">
          <app-button [isBusy]="isBusy" [isDisabled]="form.invalid" buttonStyle="orange">
            <button
              [disabled]="form.invalid"
              class="w-100 h-100 bg-transparent border-0 text-white"
              translate
              type="submit">
              Reject
            </button>
          </app-button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="formSend">
    <p translate>The job applications are rejected.</p>
    <app-button class="orange-bright gradient-x mb-4 text-center submit">
      <button (click)="closeModal()" class="w-100 h-100 bg-transparent border-0 text-white" translate type="button">
        Close
      </button>
    </app-button>
  </ng-container>
</div>
