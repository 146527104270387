<app-mobile-modal-dialog #appRequestVideoModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="modal-body">
    <app-mobile-success [message]="'Request video has been sent'"></app-mobile-success>
  </div>
  <div class="modal-footer">
    <div class="wrap-btn mb-2">
      <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
    </div>
  </div>
</app-mobile-modal-dialog>
