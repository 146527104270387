<section class="merchant-info">
  <div class="card-merchant-info" *ngIf="merchant$ | async as merchant">
    <p translate>Payment Platform Information</p>
    <ol>
      <li>{{'Account'|translate}}:{{ 'OPP_MERCHANT_STATUS.' + merchant.status | translate }}</li>
      <li>
        {{'Compliance status'|translate}}:{{ 'OPP_MERCHANT_COMPLIANCE.' + merchant.compliance.status | translate}}
      </li>
    </ol>
    <ng-container *ngIf="merchant?.compliance?.status != 'verified'">
      <p translate>To receive payouts you should meet the requirements below</p>
      <ol *ngIf="merchant?.compliance?.requirements">
        <li *ngFor="let requirement of merchant?.compliance?.requirements">
          {{ 'OPP_REQUIREMENT_TYPE.' + requirement.type | translate}}:{{ requirement.status | translate }}
        </li>
      </ol>
      <div class="text-center" *ngFor="let requirement of merchant?.compliance?.requirements; let i = index">
        <button
          *ngIf="requirement.status != 'pending'"
          (click)="redirect(requirement.object_redirect_url)"
          [ngClass]="i >= 1 ? 'btn-bg-transparent':''">
          {{ 'OPP_COMPLIANCE_REQUIREMENT_TYPE.' + requirement.type | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="bankAccount$ | async as bankAccount">
      <div class="col-12 col-sm-4 d-flex flex-column" *ngIf="bankAccount.status != 'new'">
        <ol>
          <li>{{'Bank account'|translate}}:{{ 'IBAN' | translate }} {{ bankAccount.account.account_iban }}</li>
          <li>{{'Account name'|translate}}:{{ bankAccount.account.account_name }}</li>
          <li>{{'Status'|translate}}:{{ 'OPP_BANK_ACCOUNT_STATUS.' + bankAccount.status | translate }}</li>
          <li>{{'Status'|translate}}:{{ 'OPP_BANK_ACCOUNT_STATUS.' + bankAccount.status | translate }}</li>
        </ol>
        <div class="text-center" *ngIf="['new', 'disapproved'].includes(bankAccount?.status)">
          <button (click)="redirect(bankAccount.verification_url)">{{'Verify bank account' | translate}}</button>
        </div>
        <div
          class="text-center"
          *ngIf="!['new', 'disapproved'].includes(bankAccount?.status) && talent$ | async as talent">
          <button (click)="createBankAccount(talent.id)">{{'Create new bankaccount' | translate}}</button>
        </div>
      </div>
    </ng-container>
  </div>
</section>
