<vg-player>
  <div (click)="play()" [ngClass]="{ playing: isPlaying$ | async }" class="overlay">
    <app-icon fill="white" icon="/assets/img/play.svg"></app-icon>
  </div>

  <vg-controls *ngIf="isPlaying$ | async" [vgAutohideTime]="5" [vgAutohide]="true" [vgFor]="identifier">
    <vg-play-pause [vgFor]="identifier"></vg-play-pause>
    <vg-scrub-bar [vgFor]="identifier" [vgSlider]="false">
      <vg-scrub-bar-buffering-time [vgFor]="identifier"></vg-scrub-bar-buffering-time>
      <vg-scrub-bar-current-time [vgFor]="identifier" [vgSlider]="true"></vg-scrub-bar-current-time>
    </vg-scrub-bar>
    <vg-mute [vgFor]="identifier"></vg-mute>
    <vg-fullscreen></vg-fullscreen>
  </vg-controls>
  <video #media [id]="identifier" [vgMedia]="media" preload="auto">
    <source [src]="src" [type]="type" />
  </video>
</vg-player>
