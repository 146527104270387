import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { PaginatedResults, UserReport } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class UserReportsService {
  public constructor(private http: HttpClient) {}

  public createReport(id: string, report: any): Observable<any> {
    return this.http.put(`${environment.apiBaseUrl}/user-reports/${id}`, report);
  }

  public findAll(
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<UserReport>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/user-reports`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<UserReport>) => {
          data.results = data.results.map((item: UserReport) => plainToClass(UserReport, item));
          return data;
        }),
      );
  }

  public async closeReport(id: string): Promise<void> {
    await this.http.put(`${environment.apiBaseUrl}/user-reports/${id}/closed`, {}).toPromise();
  }

  public async deleteTalentByReport(id: string, userId: any): Promise<void> {
    await this.http.post(`${environment.apiBaseUrl}/user-reports/${id}/user/${userId}`, {}).toPromise();
  }

  public async deleteJobByReport(id: string, jobId: any): Promise<void> {
    await this.http.post(`${environment.apiBaseUrl}/user-reports/${id}/job/${jobId}`, {}).toPromise();
  }
}
