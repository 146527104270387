import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-success',
  templateUrl: './mobile-success.component.html',
  styleUrls: ['./mobile-success.component.scss'],
})
export class MobileSuccessComponent implements OnInit {
  @Input()
  public message: string;

  @Input()
  public height = '400px';

  constructor() {}

  ngOnInit(): void {}
}
