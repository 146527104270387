import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Notification } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public constructor(private http: HttpClient) {}

  public fetchAll(): Observable<Notification[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/notifications`)
      .pipe(map((data: Notification[]) => plainToClass(Notification, data)));
  }

  public markNotificationAsRead(id: string): Observable<Notification[]> {
    return this.http
      .put(`${environment.apiBaseUrl}/notifications/${id}`, { read: true })
      .pipe(map((data: Notification[]) => plainToClass(Notification, data)));
  }

  public markAllNotificationAsRead(): Observable<Notification[]> {
    return this.http
      .put(`${environment.apiBaseUrl}/notifications/all`, { read: true })
      .pipe(map((data: Notification[]) => plainToClass(Notification, data)));
  }
}
