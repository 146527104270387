export class AddUserProfile {
  public static readonly type = '[UserProfile] Create new user profile';

  public constructor(public id: string, public profileData: any, public dontNavigate?: boolean) {}
}

export class LoadUserProfile {
  public static readonly type = '[UserProfile] Load user profile';

  public constructor(public id: string, public profileId: string) {}
}

export class SaveUserProfile {
  public static readonly type = '[UserProfile] Save user profile';

  public constructor(public profileId: string, public talentProfileData: any) {}
}

export class DeleteUserProfile {
  public static readonly type = '[UserProfile] Delete user profile';

  public constructor(public profileId: string) {}
}
