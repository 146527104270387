import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';

@Component({
  selector: 'app-mobile-complete-job-confirmation-modal',
  templateUrl: './mobile-complete-job-confirmation-modal.component.html',
  styleUrls: ['./mobile-complete-job-confirmation-modal.component.scss'],
})
export class MobileCompleteJobConfirmationModalComponent implements OnInit {
  @Output()
  public confirm = new EventEmitter<any>();

  @ViewChild('appMobileCompleteJobConfirmation') private modalComponent: MobileModalDialogComponent;

  public message = '';
  public title = '';

  constructor() {}

  ngOnInit(): void {}

  public async openDialog(message: string, title?: string) {
    this.message = message;
    if (title) {
      this.title = title;
    }

    return await this.modalComponent.open().then(() => {});
  }

  public confirmAction(): void {
    this.confirm.emit('');
  }

  public closeDialog(): void {
    this.modalComponent.close();
  }
}
