<app-mobile-modal-dialog #appMobileTalentBulkMessage [backgroundColor]="'rgba(0, 0, 0, 0.1)'">
  <div class="bulk-message-container">
    <ng-container *ngIf="!isBusy; else loaderTemplate">
      <ng-container *ngIf="!isSuccess; else successMessage">
        <h4>Bulk message</h4>
        <div class="message-warning">
          <img src="/assets/img/mobile/lamp.svg" alt="" />
          <p translate>
            This is a ‘notification’ message only meant to use to inform Talents if they are interested in applying to a
            certain job. Talents will not be able to reply your message. Misuse will have consequences.
          </p>
        </div>

        <div class="talent-bulk-message-container">
          <div *ngFor="let talent of seletedTalents" class="talent-bulk-message">
            <div class="profile-pic-container">
              <app-mobile-image-preview [image]="getThumbUrl(talent)"></app-mobile-image-preview>
            </div>
            <p>{{talent.user.profileName}}</p>
            <img src="/assets/img/mobile/button-delete-black.svg" alt="" (click)="updateTalentToBulkMessage(talent)" />
          </div>
        </div>

        <form [formGroup]="form">
          <input type="text" formControlName="message" placeholder="{{'write a message' | translate}}" />
          <button [disabled]="form.invalid">
            <img src="/assets/img/mobile/arrow-up.svg" alt="" (click)="submitBulkMessage()" />
          </button>
        </form>
      </ng-container>
      <ng-template #successMessage>
        <div class="success-submit-bulk-message">
          <img src="/assets/img/success.svg" alt="" />
          <h4 class="text-center mt-3">{{"Thank you" | translate}}</h4>
          <p class="text-center mt-2" translate>Bulk message successfully sent</p>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loaderTemplate>
      <div class="loader-container" style="text-align: center">
        <span class="spinner-border spinner-border-lg" aria-hidden="true"></span>
      </div>
    </ng-template>
  </div>
</app-mobile-modal-dialog>
