import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarouselComponent } from './components/carousel/carousel.component';
import { IconsModule } from '../icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';

const components = [CarouselComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, IconsModule, TranslateModule],
  exports: components,
})
export class CarouselsModule {}
