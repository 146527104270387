<div class="input-group">
  <div class="input-group-prepend">
    <ng-content select="[prepend]"></ng-content>
  </div>

  <textarea
    #input
    (input)="onValueChange($event.target.value)"
    *ngIf="!isReadOnly"
    [disabled]="isDisabled"
    [placeholder]="placeholder"
    class="form-control"
    [attr.maxLength]="maxlength"
    style="height: 100px"></textarea>
  <div *ngIf="isReadOnly" class="form-control readonly">{{ value }}</div>

  <div class="input-group-append">
    <span class="remaining" *ngIf="remainingText != undefined && maxlength">{{ remainingText }} / {{ maxlength }}</span>
    <ng-content select="[append]"></ng-content>
  </div>
</div>
