import { Component, Input } from '@angular/core';
import { ListResults, SettlementDTO, SettlementRowDTO } from '@core/dto';
import { IUser } from '@core/interfaces';
import { Select, Store } from '@ngxs/store';
import { FetchSettlementSpecifications, PaymentsState } from '@core/states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-settlements-list',
  templateUrl: './settlements-list.component.html',
  styleUrls: ['./settlements-list.component.scss'],
})
export class SettlementsListComponent {
  @Input()
  public settlements: ListResults<SettlementDTO>;

  @Input()
  public user: IUser;

  @Select(PaymentsState.settlementRows)
  public settlementRows$: Observable<ListResults<SettlementRowDTO>>;

  public settlementUid: string;

  public constructor(private store: Store) {}

  public redirect(url: string): void {
    window.open(url, '_blank');
  }

  public getSettlementSpecifications(settlementUid: string): void {
    if (this.settlementUid === settlementUid) {
      this.settlementUid = '';
    } else {
      this.settlementUid = settlementUid;
      this.store.dispatch(new FetchSettlementSpecifications(this.user.id, settlementUid));
    }
  }
}
