import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true,
    },
  ],
})
export class TextFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public placeholder = '';

  @Input()
  public maxlength: number;

  @Input()
  public isReadOnly = false;

  @Input()
  public type = 'text';

  @Input()
  public step = '1';

  @Input()
  public autofill: string = null;

  @Output()
  public blurred = new EventEmitter();

  @Output()
  public focussed = new EventEmitter();

  public value?: number | string;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public onValueChange(value: string): void {
    this.value = this.type === 'number' ? Number(value) : value;
    this.onChange(this.value);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setFocus(): void {
    this.inputEl.nativeElement.focus();
  }

  public onFocus(e: Event): void {
    this.focussed.emit(e);
  }

  public onBlur(e: Event): void {
    this.blurred.emit(e);
  }

  private updateField(value: string | number): void {
    if (!this.inputEl) {
      return;
    }
    this.inputEl.nativeElement.value = value;
  }
}
