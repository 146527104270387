import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngxs/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthState, RefreshToken, UpdateUserRoles, UserState } from '@core/states';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-roles-select',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent {
  public role: 'caster' | 'talent';

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: any,
    @Optional() public dialogRef: MatDialogRef<RolesComponent>,
  ) {
    this.role = data.role;
  }

  public async addRole(): Promise<void> {
    try {
      const { id, role } = this.store.selectSnapshot(UserState.user);
      const roles = [...role, this.role];
      await this.store.dispatch(new UpdateUserRoles(id, roles)).toPromise();
      const token = this.store.selectSnapshot(AuthState.jwtToken);
      await this.store.dispatch(new RefreshToken(token.access_token)).toPromise();
      this.dialogRef.close(true);
      if (this.role === 'talent') {
        this.store.dispatch(new Navigate(['/account/roles/add']));
      } else {
        this.store.dispatch(new Navigate(['/account/jobs/add']));
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
