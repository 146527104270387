import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IMedia } from '@core/interfaces';
import { User, UserProfile } from '@core/models';
import { Voice } from '@core/models/voice.model';
import { RouterHelperService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { first } from 'rxjs/operators';
import * as PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';

@Component({
  selector: 'app-talent-photoalbum-overview',
  templateUrl: './talent-photoalbum-overview.component.html',
  styleUrls: ['./talent-photoalbum-overview.component.scss'],
})
export class TalentPhotoalbumOverviewComponent implements AfterViewInit {
  @Input()
  public talent: User;

  @Input()
  public profiles: UserProfile[];

  @Input()
  public profile: UserProfile;

  public mediaTypes = [
    { title: 'Photo', key: 'photo', icon: '/assets/img/photo.svg' },
    { title: 'Video', key: 'video', icon: '/assets/img/video.svg' },
    { title: 'Voice', key: 'voice', icon: '/assets/img/voice.svg' },
  ];

  public selectedMediaType = this.mediaTypes[0];

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private routerHelper: RouterHelperService,
    private dialog: MatDialog,
  ) {}

  public ngAfterViewInit(): void {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '.photoswipe-gallery',
      children: 'a',
      history: false,
      focus: false,
      scaleUp: true,
      pswpModule: () => PhotoSwipe,
    });
    lightbox.init();
  }
  public openMedia(media: IMedia): void {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        media,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async selectProfile(profile: UserProfile): Promise<void> {
    const route = await this.routerHelper.activeRoute$.pipe(first()).toPromise();
    this.store.dispatch(
      new Navigate(['profile', this.talent.id, profile.id], this.route.snapshot.queryParams, {
        relativeTo: route.parent,
      }),
    );
  }

  public hasAlbumType(key: any): boolean {
    if (key === 'voice') {
      if (this.profile.user.voices && this.profile.user.voices.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.profile.albums.some((a) => a.albumType === key);
    }
  }

  public downloadVoice(voice: Voice): void {
    // Open the voice URL in a new tab/window
    window.open(voice.url, '_blank');
  }
}
