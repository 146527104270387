import { Component, OnInit } from '@angular/core';
import SwiperCore, { Autoplay, Controller, Mousewheel, Virtual } from 'swiper';

SwiperCore.use([Controller, Virtual, Autoplay, Mousewheel]);

@Component({
  selector: 'app-mobile-partner-slide',
  templateUrl: './mobile-partner-slide.component.html',
  styleUrls: ['./mobile-partner-slide.component.scss'],
})
export class MobilePartnerSlideComponent implements OnInit {
  public swiper: SwiperCore;

  constructor() {}

  ngOnInit(): void {
    this.swiper = new SwiperCore('.swiper-partner', {
      centeredSlides: true,
      speed: 6000,
      autoplay: {
        delay: 1,
      },
      loop: true,
      slidesPerView: 'auto',
      allowTouchMove: false,
    });
  }
}
