import { Injectable } from '@angular/core';

export enum MobileStorageKey {
  activeRole = 'active_role',
}

@Injectable({
  providedIn: 'root',
})
export class MobileStorageService {
  constructor() {}

  public get(key: MobileStorageKey): string {
    return localStorage.getItem(key);
  }

  public set(key: MobileStorageKey, value: any): void {
    localStorage.setItem(key, value);
  }

  public remove(key: MobileStorageKey): void {
    localStorage.removeItem(key);
  }
}
