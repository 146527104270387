import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { parse } from 'qs';
import { ResetPasswordModalComponent } from '@src/visitor/modals/components/reset-password-modal/reset-password-modal.component';
import { ChangeLanguageModalComponent } from '@src/visitor/modals/components/change-language-modal/change-language-modal.component';
import { TalentDetailPageComponent } from '@src/visitor/pages/components/talent-detail-page/talent-detail-page.component';
import { PaymentProcessModalComponent } from '@src/visitor/modals/components/payment-process-modal/payment-process-modal.component';
import { ConfirmJobApplicationModalComponent } from '@src/visitor/modals/components/confirm-job-applications-modal/confirm-job-applications-modal.component';
import { GettingStartedJoinModalComponent } from '@src/visitor/modals/components/getting-started-join-modal/getting-started-join-modal.component';
import { GettingStartedModalComponent } from '@src/visitor/modals/components/getting-started-modal/getting-started-modal.component';
import { UploadIDModalComponent } from '@src/visitor/modals/components/upload-id-modal/upload-id-modal.component';
import { JobRequestModalComponent } from '@src/visitor/modals/components/job-request-modal/job-request-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private router: Router, private route: ActivatedRoute, private dialog: MatDialog) {}

  public init(): void {
    const activationEnd$ = this.router.events.pipe(filter((e) => e instanceof ActivationEnd));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        withLatestFrom(activationEnd$),
        map<[NavigationEnd, ActivationEnd], any>(([_, activationEnd]) => parse(activationEnd.snapshot.fragment)),
        filter((fragment) => !!fragment.modal),
        tap((fragment) => this.openDialog(fragment)),
      )
      .subscribe();
  }

  public async routeTo(path: any[], usePath: boolean = false): Promise<void> {
    if (usePath) {
      await this.router.navigate(path, {
        relativeTo: this.getActiveRoute(),
      });
    } else {
      await this.router.navigate(['.'], {
        relativeTo: this.getActiveRoute(),
        fragment: `modal=${path.join('/')}`,
      });
    }
  }

  private getActiveRoute(): ActivatedRoute {
    let route = this.route;

    while (route.children?.length > 0) {
      route = route.firstChild;
    }

    return route;
  }

  private async openDialog(data: any): Promise<void> {
    const modalName = data.modal;
    const modalData = {
      component: null,
      data: null,
      options: null,
    };

    if (modalName === 'language') {
      modalData.component = ChangeLanguageModalComponent;
    }

    if (modalName.startsWith('resetPassword')) {
      modalData.component = ResetPasswordModalComponent;
      const regex = /resetPassword\/(.*)/gm;
      const [, hash] = regex.exec(modalName);
      modalData.data = { hash };
    }

    if (modalName.startsWith('confirmJobApplications')) {
      modalData.component = ConfirmJobApplicationModalComponent;
      const regex = /confirmJobApplications\/(.*)/gm;
      const [, hash] = regex.exec(modalName);
      modalData.data = { hash };
    }

    // if (modalName.startsWith('verifyEmail')) {
    //   modalData.component = VerifyEmailModalComponent;
    //   const regex = /verifyEmail\/(.*)/gm;
    //   const [, hash] = regex.exec(modalName);
    //   modalData.data = { hash };
    // }

    if (modalName.startsWith('paymentProcess')) {
      modalData.component = PaymentProcessModalComponent;
      const regex = /paymentProcess\/(.*)/gm;
      const [, payment_uid] = regex.exec(modalName);
      modalData.data = {
        payment_uid,
        reloadJobs: true,
      };
    }

    if (modalName.startsWith('profile/')) {
      modalData.component = TalentDetailPageComponent;
      const [, userId, profileId] = modalName.split('/');
      modalData.data = {
        userId,
        profileId,
        showJobThankYou: modalName.endsWith('job-thank-you'),
      };
    }

    if (modalName.startsWith('job-request')) {
      modalData.component = JobRequestModalComponent;
    }

    if (modalName.startsWith('login')) {
      modalData.component = GettingStartedJoinModalComponent;
      modalData.data = { form: 'signin' };
      modalData.options = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
      };
    }

    if (modalName.startsWith('signup')) {
      modalData.component = GettingStartedJoinModalComponent;
      modalData.data = { form: 'signup' };
      modalData.options = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
      };
    }

    if (modalName.startsWith('join')) {
      modalData.component = GettingStartedJoinModalComponent;
      modalData.data = { form: 'join' };
      modalData.options = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
      };
    }

    if (modalName.startsWith('verify-email')) {
      modalData.component = GettingStartedModalComponent;
      const regex = /verify-email\/(.*)/gm;
      const regexResult = regex.exec(modalName);
      if (regexResult && regexResult[1]) {
        const [, hash] = regexResult;
        modalData.data = { hash };
      } else {
        return;
      }
      modalData.options = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
      };
    }

    if (
      modalName.startsWith('getting-started') ||
      modalName.startsWith('mobile-create-profile') ||
      modalName.startsWith('mobile-edit-profile')
    ) {
      let regex = /getting-started\/(.*)/gm;

      if (modalName.startsWith('mobile-create-profile')) {
        regex = /mobile-create-profile\/(.*)/gm;
      }

      const regexResult = regex.exec(modalName);
      if (regexResult && regexResult[1]) {
        const [, hash] = regexResult;
        modalData.data = { hash };
      }
      modalData.component = GettingStartedModalComponent;
      modalData.options = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
      };
    }

    if (modalName.startsWith('upload-id')) {
      modalData.component = UploadIDModalComponent;
      modalData.options = {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal',
      };
    }

    if (!modalData.component) {
      return;
    }

    // Remove fragment after modal is closed
    const ref = this.dialog.open(modalData.component, { data: modalData.data, ...modalData.options });
    const result = await ref.afterClosed().toPromise();
    if (result !== false) {
      await this.router.navigate(result?.navigate?.commands || ['.'], {
        ...(result?.navigate?.extras || {}),
        relativeTo: !result?.navigate?.commands ? this.getActiveRoute() : null,
      });
    }
  }
}
