import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-join-modal',
  templateUrl: './join-modal.component.html',
  styleUrls: ['./join-modal.component.scss'],
})
export class JoinModalComponent {
  public form = 'sign-in';

  public constructor(@Inject(MAT_DIALOG_DATA) @Optional() private data: any) {
    this.setForm(this.data?.form || 'sign-in');
  }

  public setForm(form: string): void {
    this.form = form;
  }
}
