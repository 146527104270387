import { BaseModel } from './base-model.model';
import { Type } from 'class-transformer';
import { INote } from '@core/interfaces';
import { User } from '@core/models/user.model';

export class Note extends BaseModel implements INote {
  @Type(() => User)
  public user: User;
  @Type(() => User)
  public talent: User;

  public id: string;
  public comment: string;
}
