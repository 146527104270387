<div class="autocomplete-search w-100">
  <div class="input-group">
    <div class="input-group-prepend">
      <ng-content select="[prepend]"></ng-content>
    </div>

    <input
      #input
      (input)="onValueChange($event.target.value)"
      *ngIf="!isReadOnly"
      [attr.maxlength]="maxlength"
      [placeholder]="placeholder"
      [type]="type"
      class="form-control"
      [attr.aria-autocomplete]="autofill ? 'both' : 'none'"
      [autocomplete]="autofill"
      [attr.role]="autofill ? 'combobox' : 'none'"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)" />
    <div *ngIf="isReadOnly" class="form-control">{{ value }}</div>

    <div class="input-group-append">
      <ng-content select="[append]"></ng-content>
    </div>
  </div>
</div>
<div class="autocomplete-container bg-gray" *ngIf="geocodeResults?.length > 0">
  <div
    class="autocomplete-option"
    *ngFor="let geocodeResult of geocodeResults"
    (click)="selectGeocodeResult(geocodeResult)">
    {{ geocodeResult.description }}
  </div>
</div>
