<div class="d-flex align-items mb-4">
  <hr class="flex-fill" />
  <h2 class="h4 text-white mx-4" translate>Packages</h2>
  <hr class="flex-fill" />
</div>

<div *ngIf="profile?.packages?.length > 0" class="row flex-wrap">
  <app-package
    (packageSelected)="onPackageSelected($event)"
    *ngFor="let pkg of profile.packages"
    [package]="pkg"
    class="col-12 col-md-6 col-xl-4 d-flex"></app-package>
</div>
