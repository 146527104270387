import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: 'toggle-button.component.html',
  styleUrls: ['toggle-button.component.scss'],
})
export class ToggleButtonComponent<T = any> implements AfterViewInit {
  @Input() values: [val1: T, val2: T] = [false as unknown as T, true as unknown as T];
  @Input() state: T;
  @Input() backgroundColor = '#414349';
  @Input() switchColor = '#aaaaaa';
  @Output() changed = new EventEmitter<T>();
  @ViewChild('checkbox') public checkbox: ElementRef;

  public set value(value: T) {
    this.checkbox.nativeElement.checked = value !== this.values[0];
    this.state = value;
  }

  public ngAfterViewInit(): void {
    if (this.state) {
      this.value = this.state;
    }
  }

  public toggle(event: Event): void {
    this.changed.emit(this.values[event.target['checked'] ? 1 : 0]);
  }
}
