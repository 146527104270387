<div class="d-flex flex-column w-100 text-white">
  <div class="mx-5 mt-5" *ngIf="currentUser$ | async as currentUser">
    <ng-container *ngIf="!isSubmitted; else thankYou">
      <app-offer-job-form
        (submitted)="onSubmitted($event)"
        [categories]="data.categories"
        [category]="data.category"
        [package]="data.package"
        [isDirect]="true"
        [talent]="data.talent"
        [availableCountries]="(countries$ | async)?.results"
        [currentUser]="currentUser"></app-offer-job-form>
    </ng-container>
  </div>
</div>

<ng-template #thankYou>
  <ng-container *ngIf="needsPayment">
    <h1 class="h3 text-center" translate>Redirecting...</h1>
    <div class="my-5 text-center" translate>
      Your offer needs payment, you are being redirected to our payment provider.
    </div>
  </ng-container>
  <ng-container *ngIf="!needsPayment">
    <h1 class="h3 text-center" translate>Thank you</h1>
    <div class="my-5 text-center" translate>
      Your offer has been submitted. You will be informed when the talent accepts or rejects your offer.
    </div>
    <app-button (click)="closeModal()" buttonStyle="orange" class="mb-5">
      <span class="px-2" translate>OK</span>
    </app-button>
  </ng-container>
</ng-template>
