import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradientComponent } from './components/gradient/gradient.component';
import { GradientTextComponent } from './components/gradient-text/gradient-text.component';

@NgModule({
  declarations: [GradientComponent, GradientTextComponent],
  imports: [CommonModule],
  exports: [GradientComponent, GradientTextComponent],
})
export class GradientsModule {}
