<section class="category">
  <div class="horizontal">
    <ng-container *ngFor="let category of categories">
      <a (click)="setActiveCategory(category)" [class.active]="this.selectedCategory == category.slug ? 'active' : ''">
        <div class="category-item">
          <img class="image" src="/assets/img/mobile/category/{{ category.slug }}.png" alt="{{category.label}}" />
        </div>
        <div class="title">
          <p>
            {{ transformCategoryKey(category.translateKey) == 'CATEGORY.host-and-promotors' ? 'Presenter' :
            transformCategoryKey(category.translateKey) | translate }}
          </p>
        </div>
      </a>
    </ng-container>
  </div>
</section>
