import { IFeeType } from '@core/interfaces/fee-type';
import { IPackage } from '@core/interfaces/package';
import { IUserProfile } from '@core/interfaces/user-profile';
import { Type } from 'class-transformer';
import { FeeType } from './fee-type.model';
import { UserProfile } from './user-profile.model';

export class Package implements IPackage {
  @Type(() => FeeType)
  public feeType?: IFeeType;
  @Type(() => UserProfile)
  public userProfile?: IUserProfile;

  public id: string;
  public userProfileId: string;
  public feeTypeId: string;

  public extraHour?: number;

  public title: string;
  public description: string;
  public currency: string;
  public price: number;
  public time: number;
}
