<div class="d-flex flex-column w-100 text-white">
  <div class="wrapper">
    <app-icon icon="/assets/img/close.svg" class="close" (click)="closeModal()"></app-icon>
    <div class="success-create-job">
      <ng-container *ngIf="isSuccess">
        <h4 class="text-center">{{"Your application has been successfully canceled" | translate}}</h4>
      </ng-container>
      <ng-container *ngIf="!isSuccess">
        <h4 class="text-center">{{"You are already applied, do you wish to cancel your application?" | translate}}</h4>
      </ng-container>
      <ng-container *ngIf="!isSuccess">
        <div class="btn-wrap">
          <button (click)="agree()">{{'Yes' | translate}}</button>
          <button (click)="closeModal()">{{'Cancel' | translate}}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="isSuccess">
        <div class="btn-wrap">
          <button (click)="closeModal()">{{'Close' | translate}}</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
