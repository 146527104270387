import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services';
import { IInvoice } from '@core/interfaces';
import { UserState } from '@core/states';
import { environment } from '@environments/environment';
import { Select } from '@ngxs/store';
import { User } from '@sentry/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-invoice-detail',
  templateUrl: './mobile-invoice-detail.component.html',
  styleUrls: ['./mobile-invoice-detail.component.scss'],
})
export class MobileInvoiceDetailComponent implements OnInit {
  @Input()
  public invoice: IInvoice;

  @Input()
  public form: FormGroup = new FormGroup({});

  @Input()
  public message: { message: string; type: string };

  @Input()
  public onSubmit: () => Promise<void>;

  @Select(UserState.user)
  public talent$: Observable<User>;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  public downloadInvoice(): string {
    return (
      environment.apiBaseUrl + `/invoices/` + this.invoice.id + `/service-invoice/download?token=` + this.getTokenUser
    );
  }

  public downloadAgreement(): string {
    return environment.apiBaseUrl + `/invoices/` + this.invoice.id + `/agreement/download?token=` + this.getTokenUser;
  }

  get getTokenUser(): string {
    return this.authService.getJwtToken().access_token;
  }
}
