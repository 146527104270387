<div class="mobile-talent-photoalbum-preview-container">
  <div class="img-photoalbum-container" *ngFor="let media of data.mediaList; index as i" #itemElement>
    <ng-container *ngIf="i !== data.mediaPosition">
      <app-mobile-image-preview [image]="media.url"></app-mobile-image-preview>
    </ng-container>
    <div *ngIf="i === data.mediaPosition" id="selectedPhoto">
      <app-mobile-image-preview [image]="media.url"></app-mobile-image-preview>
    </div>
  </div>
</div>
<div class="close-button">
  <button (click)="closeDialog()">
    <img src="/assets/img/mobile/close.svg" alt="" />
  </button>
</div>
