import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  transform(dateRange: any, args?: any): any {
    if (dateRange) {
      const start = moment(dateRange.start).local();
      const end = moment(dateRange.end).local();
      let startDate = start.format(args?.dateFormat ?? 'DD-MM-YYYY');
      const endDate = end.format(args?.dateFormat ?? 'DD-MM-YYYY');

      if (!!args?.dateFormatStartDate) {
        startDate = start.format(args?.dateFormatStartDate);
      }

      if (args !== null && (args?.exact === false || args?.withTime === false)) {
        if (startDate === endDate || endDate === undefined) {
          return `${startDate}`;
        }
        return `${startDate} - ${endDate}`;
      } else {
        const startTime = start.format('HH:mm');
        const endTime = end.format('HH:mm');
        if (startDate === endDate || endDate === undefined) {
          return `${startDate} ${startTime} - ${endTime}`;
        }
        return `${startDate} ${startTime} - ${endDate} ${endTime}`;
      }
    } else {
      return null;
    }
  }
}
