<div class="d-flex flex-column w-100 text-white">
  <div class="mx-5 mt-5">
    <h1 class="h3 text-center" translate>Thank you</h1>
    <div class="my-5 text-center" translate>
      Your offer has been submitted. You will be informed when the talent accepts or rejects your offer.
    </div>
    <app-button (click)="closeModal()" buttonStyle="orange" class="mb-5">
      <span class="px-2" translate>OK</span>
    </app-button>
  </div>
</div>
