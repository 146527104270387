import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { IRegion } from '@core/interfaces/region';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  public constructor(private http: HttpClient) {}

  public fetch(): Observable<IRegion> {
    return this.http.get<IRegion>(`${environment.apiBaseUrl}/region`);
  }
}
