import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SseStateModel } from './sse.state-model';
import { SseEventReceived } from './sse.actions';
import { SseService } from '@core/services';
import { AuthState, RefreshToken } from '../auth';
import { filter, switchMap, throttleTime } from 'rxjs/operators';
import { JwtToken } from '@auth/models';
import { insertItem, patch } from '@ngxs/store/operators';

@State<SseStateModel>({
  name: 'sse',
  defaults: {
    events: [],
  },
})
@Injectable()
export class SseState implements NgxsOnInit {
  public constructor(private sseService: SseService, private store: Store) {}

  static event(): any {
    return createSelector<any>([SseState], (state: SseStateModel) => {
      if (state.events.length === 0) {
        return;
      }

      return state.events[0];
    });
  }

  @Selector()
  static events(state: SseStateModel): any[] {
    return state.events;
  }

  @Action(SseEventReceived)
  public sseEventReceived(ctx: StateContext<SseStateModel>, { event }: SseEventReceived): void {
    ctx.setState(
      patch({
        events: insertItem(event),
      }),
    );
  }

  public ngxsOnInit(ctx: StateContext<SseStateModel>): void {
    this.store
      .select(AuthState.jwtToken)
      .pipe(
        filter((token: JwtToken) => !!token),
        switchMap((token: JwtToken) => this.sseService.create(token)),
        switchMap((event: any) => ctx.dispatch(new SseEventReceived(event))),
      )
      .subscribe();

    this.sseService.errors$
      .pipe(
        filter((e: Error): boolean => e.message === 'Token expired'),
        throttleTime(5000),
        switchMap(() => this.store.selectOnce(AuthState.jwtToken)),
        switchMap((jwtToken: JwtToken) => this.store.dispatch(new RefreshToken(jwtToken.access_token))),
      )
      .subscribe();
  }
}
