import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Deck, Membership, UserAlbum, UserProfile } from '@core/models';
import { UpdateMembershipDto } from '@core/dto/membership.dto';
import { MediaType } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class MembershipsService {
  public constructor(private http: HttpClient) {}

  public static shouldDeleteItems(
    oldMembership: Membership,
    membership: Membership,
    profiles: UserProfile[],
    decks: Deck[],
  ): boolean {
    if (profiles.length > membership.talent_profiles) {
      return true;
    }

    if (decks.length > membership.decks) {
      return true;
    }

    const membershipHavePhotoLimits = membership.portfolio_pictures !== null;
    const membershipHaveVideoLimits = membership.videos !== null;

    const profileList = profiles.filter((profile: UserProfile) => {
      const photos = profile.albums.filter(
        (album: UserAlbum) =>
          album.albumType === MediaType.photo &&
          membershipHavePhotoLimits &&
          album.media.length > membership.portfolio_pictures,
      );
      const videos = profile.albums.filter(
        (album: UserAlbum) =>
          album.albumType === MediaType.video && membershipHaveVideoLimits && album.media.length > membership.videos,
      );
      const packages = profile.packages.length > membership.packages;

      return photos.length > 0 || videos.length > 0 || packages;
    });

    return profileList.length > 0;
  }

  public findAll(): Observable<Membership[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/memberships`)
      .pipe(map((data: Membership[]) => data.map((item: Membership) => plainToClass(Membership, item))));
  }

  public updateUserMembership(
    userId: string,
    membership: UpdateMembershipDto,
  ): Observable<{ redirect: boolean; data?: string }> {
    return this.http
      .put(`${environment.apiBaseUrl}/memberships/user/${userId}`, membership)
      .pipe(map((a) => (a['success'] ? { redirect: false, data: '' } : { redirect: true, data: a['redirectUrl'] })));
  }

  public getById(id: string): Observable<Membership> {
    return this.http
      .get(`${environment.apiBaseUrl}/memberships/${id}`)
      .pipe(map((data: Membership) => plainToClass(Membership, data)));
  }
}
