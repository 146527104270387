import { Action, State, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { JobRequestsService } from '@core/services';

import { LoadJobRequests } from './job-requests.actions';
import { JobRequest, PaginatedResults } from '@core/models';
import { JobRequestsStateModel } from './job-requests.state-model';

@State<JobRequestsStateModel>({
  name: 'jobRequests',
  defaults: {
    jobRequests: null,
  },
})
@Injectable()
export class JobRequestsState {
  public constructor(private jobRequestsService: JobRequestsService) {}

  @Selector()
  static jobRequests(state: JobRequestsStateModel): PaginatedResults<JobRequest> {
    return state.jobRequests;
  }

  @Action(LoadJobRequests)
  public async loadJobRequests(
    ctx: StateContext<JobRequestsStateModel>,
    { query, page, pageSize, sortColumn, sortDirection }: LoadJobRequests,
  ): Promise<void> {
    const jobRequests = await this.jobRequestsService
      .findAll(query, page, pageSize, sortColumn, sortDirection)
      .toPromise();
    ctx.setState({ jobRequests });
  }
}
