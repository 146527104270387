<app-mobile-modal-dialog #appCompleteJobNoteModal [backgroundColor]="'rgb(123 123 123 / 10%)'">
  <div class="invoice-job-note-form-container">
    <p translate>Add a note if there is anything else we need to pay attention to for this assignment</p>
    <p translate>Also fill in below the PO number if needed</p>

    <form [formGroup]="formBuilder">
      <app-textarea formControlName="note" minHeight="250"></app-textarea>
    </form>

    <div class="action-area">
      <button translate (click)="save()" [disabled]="!noteValue">Save</button>
      <button translate (click)="close()">Cancel</button>
    </div>
  </div>
</app-mobile-modal-dialog>
