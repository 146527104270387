import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { JobApplicationsService } from '@core/services';

@Component({
  selector: 'app-mobile-without-travel-cost',
  templateUrl: './mobile-without-travel-cost.component.html',
  styleUrls: ['./mobile-without-travel-cost.component.scss'],
})
export class MobileWithoutTravelCostComponent implements OnInit {
  @ViewChild('appWithoutTravelCostModal') private modalComponent: MobileModalDialogComponent;

  public jobApplicationId: string;
  public isSuccess: boolean;
  public isBusy: boolean;

  constructor(private jobApplicationService: JobApplicationsService) {}

  ngOnInit(): void {}

  async openDialog(jobApplicationId: string): Promise<void> {
    this.jobApplicationId = jobApplicationId;
    return await this.modalComponent.open().then(() => {
      this.jobApplicationId = '';
      this.isSuccess = false;
      this.isBusy = false;
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public agree() {
    this.isBusy = true;
    try {
      this.jobApplicationService.requestWithoutTravelCost(this.jobApplicationId).subscribe(() => {
        this.isSuccess = true;
        this.isBusy = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
}
