import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-error-modal',
  templateUrl: './payment-error-modal.component.html',
  styleUrls: ['./payment-error-modal.component.scss'],
})
export class PaymentErrorModalComponent {
  public constructor(
    @Optional() private dialogRef: MatDialogRef<PaymentErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
  ) {}

  public closeModal(): void {
    this.dialogRef.close();
  }
}
