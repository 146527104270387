<app-icon
  fill="white"
  stroke="white"
  icon="/assets/img/question-mark.svg"
  [ngbTooltip]="tipContent"
  container="body"></app-icon>

<ng-template #tipContent>
  <ng-content></ng-content>
</ng-template>
