import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AuthConfig, JwtToken } from '../models';
import { AuthState, RefreshToken, SignOut } from '@core/states';
import { Store } from '@ngxs/store';
import { environment } from '@environments/environment';
import { LoaderService } from '@core/services/loader.service';
import { ErrorsService } from '@core/services/errors.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private activeRequestCount = 0;

  constructor(
    private authConfig: AuthConfig,
    private store: Store,
    public loaderService: LoaderService,
    public errorService: ErrorsService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (window.innerWidth >= 500) {
      const whitelistedUrls = [this.authConfig.loginUrl, this.authConfig.refreshUrl];

      if (whitelistedUrls.includes(request.url) || !request.url.startsWith(environment.apiBaseUrl)) {
        return next.handle(request);
      }
    }

    const jwtToken = this.store.selectSnapshot(AuthState.jwtToken);

    if (!!jwtToken) {
      request = this.addToken(request, jwtToken.access_token);
    }

    if (this.activeRequestCount === 0) {
      this.loaderService.isLoading.next(true);
    }

    this.activeRequestCount++;

    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            this.loaderService.isLoading.next(true);
          }
        },
        error: (error) => {
          this.loaderService.isLoading.next(false);
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.handleUnauthorized();
          } else if (error instanceof HttpErrorResponse && error.status === 403) {
            this.handleForbiddenError(request, next);
          } else {
            this.errorService.isError.next(error);
          }

          return throwError(error);
        },
      }),
      finalize(() => {
        this.stopLoader();
      }),
    );
  }

  private stopLoader() {
    this.activeRequestCount--;
    if (this.activeRequestCount === 0) {
      this.loaderService.isLoading.next(false);
    }
  }

  private addToken(request: HttpRequest<any>, access_token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  private handleUnauthorized(): Observable<HttpEvent<unknown>> {
    return this.store.dispatch(new SignOut());
  }

  private handleForbiddenError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const jwtToken = this.store.selectSnapshot(AuthState.jwtToken);
      return this.store.dispatch(new RefreshToken(jwtToken?.access_token)).pipe(
        switchMap(() => this.store.selectOnce(AuthState.jwtToken)),
        switchMap((newJwtToken: JwtToken) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newJwtToken.access_token);
          return next.handle(this.addToken(request, newJwtToken.access_token));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => next.handle(this.addToken(request, jwt))),
      );
    }
  }
}
