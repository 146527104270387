import { IJobTalentCountry } from '@core/interfaces/job-talent-country';
import { Country } from '@core/models/country.model';
import { IPoint } from '@core/interfaces/point';
import { Type } from 'class-transformer';

export class JobTalentCountry implements IJobTalentCountry {
  @Type(() => Country)
  public country?: Country;

  public jobId: string;
  public countryId: string;
  public city?: string;
  public coordinates?: IPoint;
  public distance?: number;
}
