<app-mobile-modal-dialog [backgroundColor]="'rgb(123 123 123 / 10%)'" #mobileMembershipModal>
  <ng-container *ngIf="isBusy; else membershipConfirmation">
    <div class="mobile-membership-modal">
      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
      <p class="text-center mt-3">Creating role...</p>
    </div>
  </ng-container>
  <ng-template #membershipConfirmation>
    <div *ngIf="!showConfirmation; else familyConfirmation" class="mobile-membership-modal">
      <p translate>
        You've reached your max. Talent-role(s). The family-role can be added for free. Upgrade to add more.
      </p>
      <button (click)="isFamilyRoleExist()" class="btn-white" translate>Add Family role for FREE</button>
      <button (click)="goToMembershipPage()" class="btn-purple">Upgrade membership</button>
    </div>
    <ng-template #familyConfirmation>
      <div class="mobile-membership-modal">
        <ng-container *ngIf="!isFamilyExist; else familyExist">
          <p translate>
            Only for families, mother/father and kids or couples. Please refrain from mentioning last name. Misuse leads
            to ban
          </p>
          <button (click)="createFamilyRole()" class="btn-purple" translate>Understood</button>
          <button (click)="showConfirmation = false" class="btn-grey">Cancel</button>
        </ng-container>
        <ng-template #familyExist>
          <p translate>The selected role has already been created</p>
          <button (click)="closeDialog()" class="btn-purple" translate>Close</button>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</app-mobile-modal-dialog>
