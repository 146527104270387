<section class="invoice-detail">
  <ng-container *ngIf="invoice?.bookingsType==='CS'">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="card-invoice">
        <ol>
          <li translate>Invoice</li>
          <li>{{'Invoice number' | translate}} : {{form.get("formattedBookingsNumber").value}}</li>
          <li>
            {{'Date of invoice' | translate}}:
            <span class="text-bold">{{form.get("invoiceDate").value}}</span>
          </li>
        </ol>
        <ol>
          <li>
            {{'Service Detail' | translate}}:
            <span class="text-bold">{{form.get("serviceDetail").value}}</span>
          </li>
          <li>
            {{'VAT' | translate}}:
            <span class="text-bold">{{form.get("vat").value}}</span>
          </li>
          <li>
            {{'Total Price' | translate}}:
            <span class="text-bold">{{form.get("amount").value}}</span>
          </li>
        </ol>
      </div>
      <div class="card-invoice">
        <p class="mt-2">{{'Personal custom text field' | translate}}</p>
        <textarea
          class="textarea-control"
          style="height: 100px !important"
          formControlName="invoiceText"
          [placeholder]="'The info you place here is for your own administration, only you will see this on the invoice'
                | translate"></textarea>
        <div class="mb-4 mt-4" *ngIf="message?.type">
          <app-alert *ngIf="message" [type]="message?.type">{{ message?.message }}</app-alert>
        </div>
        <div class="text-center">
          <button type="submit" class="mt-2" [disabled]="form.invalid">Save</button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="invoice?.bookingsType==='CB'">
    <div class="card-talent" *ngIf="invoice.payment.user">
      <div class="wrap-photo">
        <div
          class="photo"
          [style]="{
                    'background-image':'url('+invoice.payment.user?.photo.url+')'
                }"></div>
      </div>
      <ol>
        <li>{{'Caster' | translate}}</li>
        <li>{{invoice.payment.user.firstName}} {{invoice.payment.user.lastName}}</li>
        <li *ngIf="invoice.payment.user.vatCheckResult; else address">
          {{invoice.payment.user.country.translateKey | translate}}, {{invoice.payment.user.vatCheckResult.address}}
        </li>
        <ng-template #address>
          <li>
            {{invoice.payment.user.city}}, {{invoice.payment.user.street}} {{invoice.payment.houseNumber}},
            {{invoice.payment.user.postalCode}} {{invoice.payment.user.country.translateKey | translate}}
          </li>
        </ng-template>
      </ol>
    </div>
    <div class="card-talent" *ngIf="invoice.payment.jobApplication.user">
      <div class="wrap-photo">
        <div
          class="photo"
          [style]="{
                    'background-image':'url('+invoice.payment.jobApplication.user?.photo.url+')'
                }"></div>
      </div>
      <ol>
        <li>{{'Talent' | translate}}</li>
        <li>{{invoice.payment.jobApplication.user.firstName}} {{invoice.payment.jobApplication.user.lastName}}</li>
        <li *ngIf="invoice.payment.jobApplication.user.vatCheckResult; else address">
          {{invoice.payment.jobApplication.user.country.translateKey | translate}},
          {{invoice.payment.jobApplication.user.vatCheckResult.address}}
        </li>
        <ng-template #address>
          <li>
            {{invoice.payment.jobApplication.user.city}}, {{invoice.payment.jobApplication.user.street}}
            {{invoice.payment.jobApplication.user.houseNumber}}, {{invoice.payment.jobApplication.user.postalCode}}
            {{invoice.payment.jobApplication.user.country.translateKey | translate}}
          </li>
        </ng-template>
      </ol>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="card-invoice">
        <ol>
          <li>{{'Invoice' | translate}}</li>
          <li>{{'Booking number' | translate}}: {{form.get("formattedBookingsNumber").value}}</li>
          <li>
            {{'Date of invoice' | translate}}e:
            <span class="text-bold">{{form.get("invoiceDate").value}}</span>
          </li>
        </ol>
      </div>
      <div class="card-invoice">
        <p class="mt-2">{{'Custom invoice number' | translate}}</p>
        <input type="text" class="form-control" formControlName="invoiceNumber" />
        <p class="text-info" translate>
          The Talent is allowed to change the invoice number. The invoice number will be shown on both your invoice as
          well as the Caster's invoice.
        </p>
      </div>
      <div class="card-invoice">
        <ol>
          <li>{{'Job Title' | translate}}</li>
          <li>{{invoice.payment.jobApplication?.job.title}}</li>
        </ol>
        <ol>
          <li>{{'Job date(s)' | translate}}</li>
          <ol class="list-inline">
            <ng-container *ngFor="let jobDate of invoice.payment.jobApplication?.job.dates">
              <li *ngIf="jobDate.days !== null">
                <span><img src="/assets/img/mobile/calendar.svg" alt="" /></span>
                {{ jobDate.start | date }}
              </li>
              <li *ngIf="jobDate.days !== null">
                <span><img src="/assets/img/mobile/calendar.svg" alt="" /></span>
                {{ jobDate.end | date }}
              </li>
              <li *ngIf="jobDate.days !== null">{{ jobDate.days }}</li>
              <li *ngIf="jobDate.days !== null">{{ jobDate.hours_per_day }}</li>
              <li *ngIf="jobDate.days === null">
                <span><img src="/assets/img/mobile/calendar.svg" alt="" /></span>
                {{ jobDate.start | date }}
              </li>
              <li *ngIf="jobDate.days === null">
                <span><img src="/assets/img/mobile/clock.svg" alt="" /></span>
                {{ jobDate.start | date: 'HH:mm' }}
              </li>
              <li *ngIf="jobDate.days === null">
                <span><img src="/assets/img/mobile/clock.svg" alt="" /></span>
                {{ jobDate.end | date: 'HH:mm' }}
              </li>
            </ng-container>
          </ol>
        </ol>
        <ol>
          <li translate>For a complete job agreement see the attached document: Job agreement</li>
          <li>
            {{'Fee excl VAT' | translate}}:
            <span class="text-bold">{{form.get("amountExclVat").value}}</span>
          </li>
          <li>
            {{'VAT' | translate}}:
            <span class="text-bold">{{form.get("vat").value}}</span>
          </li>
          <li *ngIf="form.value.isTalent == true">
            Commission fee
            <span *ngIf="talent$ | async as talent">({{ talent?.membership?.commission }} %)</span>
            :
            <span class="text-bold">{{form.get("commissionFee").value}}</span>
          </li>
        </ol>
        <ol>
          <li>
            {{'Earnings for talent' | translate}}:
            <span class="text-bold">{{form.value.amountMinFee}}</span>
          </li>
        </ol>
      </div>
      <div class="card-invoice">
        <p class="mt-2">{{'Personal custom text field' | translate}}</p>
        <textarea
          class="textarea-control"
          style="height: 100px !important"
          formControlName="invoiceText"
          [placeholder]="'The info you place here is for your own administration, only you will see this on the invoice'
                | translate"></textarea>
        <div class="mb-4 mt-4" *ngIf="message?.type">
          <app-alert *ngIf="message" [type]="message?.type">{{ message?.message }}</app-alert>
        </div>
        <div class="text-center">
          <button type="submit" class="mt-2" [disabled]="form.invalid">{{'Save'|translate}}</button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="invoice?.bookingsType==='TC'">
    <div class="card-talent" *ngIf="invoice.payment.user">
      <div class="wrap-photo">
        <div
          class="photo"
          [style]="{
                    'background-image':'url('+invoice.payment.user?.photo.url+')'
                }"></div>
      </div>
      <ol>
        <li translate>Caster</li>
        <li>{{invoice.payment.user.firstName}} {{invoice.payment.user.lastName}}</li>
        <li *ngIf="invoice.payment.user.vatCheckResult; else address">
          {{invoice.payment.user.country.translateKey | translate}}, {{invoice.payment.user.vatCheckResult.address}}
        </li>
        <ng-template #address>
          <li>
            {{invoice.payment.user.city}}, {{invoice.payment.user.street}} {{invoice.payment.houseNumber}},
            {{invoice.payment.user.postalCode}} {{invoice.payment.user.country.translateKey | translate}}
          </li>
        </ng-template>
      </ol>
    </div>
    <div class="card-talent" *ngIf="invoice.payment.jobApplication.user">
      <div class="wrap-photo">
        <div
          class="photo"
          [style]="{
                    'background-image':'url('+invoice.payment.jobApplication.user?.photo.url+')'
                }"></div>
      </div>
      <ol>
        <li translate>Talent</li>
        <li>{{invoice.payment.jobApplication.user.firstName}} {{invoice.payment.jobApplication.user.lastName}}</li>
        <li *ngIf="invoice.payment.jobApplication.user.vatCheckResult; else address">
          {{invoice.payment.jobApplication.user.country.translateKey | translate}},
          {{invoice.payment.jobApplication.user.vatCheckResult.address}}
        </li>
        <ng-template #address>
          <li>
            {{invoice.payment.jobApplication.user.city}}, {{invoice.payment.jobApplication.user.street}}
            {{invoice.payment.jobApplication.user.houseNumber}}, {{invoice.payment.jobApplication.user.postalCode}}
            {{invoice.payment.jobApplication.user.country.translateKey | translate}}
          </li>
        </ng-template>
      </ol>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="card-invoice">
        <ol>
          <li translate>Invoice</li>
          <li>{{'Booking number' | translate}}: {{form.get("formattedBookingsNumber").value}}</li>
          <li>
            {{'Date of invoice' | translate}}:
            <span class="text-bold">{{form.get("invoiceDate").value}}</span>
          </li>
        </ol>
        <ol>
          <li translate>Job Title</li>
          <li>{{invoice.payment.jobApplication?.job.title}}</li>
        </ol>
        <ol>
          <li translate>Job date(s)</li>
          <ol class="list-inline">
            <ng-container *ngFor="let jobDate of invoice.payment.jobApplication?.job.dates">
              <li *ngIf="jobDate.days !== null">
                <span><img src="/assets/img/mobile/calendar.svg" alt="" /></span>
                {{ jobDate.start | date }}
              </li>
              <li *ngIf="jobDate.days !== null">
                <span><img src="/assets/img/mobile/calendar.svg" alt="" /></span>
                {{ jobDate.end | date }}
              </li>
              <li *ngIf="jobDate.days !== null">{{ jobDate.days }}</li>
              <li *ngIf="jobDate.days !== null">{{ jobDate.hours_per_day }}</li>
              <li *ngIf="jobDate.days === null">
                <span><img src="/assets/img/mobile/calendar.svg" alt="" /></span>
                {{ jobDate.start | date }}
              </li>
              <li *ngIf="jobDate.days === null">
                <span><img src="/assets/img/mobile/clock.svg" alt="" /></span>
                {{ jobDate.start | date: 'HH:mm' }}
              </li>
              <li *ngIf="jobDate.days === null">
                <span><img src="/assets/img/mobile/clock.svg" alt="" /></span>
                {{ jobDate.end | date: 'HH:mm' }}
              </li>
            </ng-container>
          </ol>
        </ol>
        <ol *ngFor="let expense of invoice.payment.jobApplication?.travelExpenses">
          <li>
            {{'Type' | translate}}:
            <span class="text-bold">{{expense.type}}</span>
          </li>
          <li>
            {{'Price' | translate}}:
            <span class="text-bold">{{ expense.price | currency: 'EUR' }}</span>
          </li>
          <li><span class="text-bold">{{expense.summary}}</span></li>
        </ol>
        <ol>
          <li>
            {{'Total Price' | translate}}:
            <span class="text-bold">{{form.value.amount}}</span>
          </li>
        </ol>
      </div>
      <div class="card-invoice">
        <p class="mt-2">{{'Personal custom text field' | translate}}</p>
        <textarea
          class="textarea-control"
          style="height: 100px !important"
          formControlName="invoiceText"
          [placeholder]="'The info you place here is for your own administration, only you will see this on the invoice'
                | translate"></textarea>
        <div class="mb-4 mt-4" *ngIf="message?.type">
          <app-alert *ngIf="message" [type]="message?.type">{{ message?.message }}</app-alert>
        </div>
        <div class="text-center">
          <button type="submit" class="mt-2" [disabled]="form.invalid">{{'Save' | translate}}</button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="invoice?.bookingsType==='CR'">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="card-invoice">
        <ol>
          <li translate>Invoice</li>
          <li>{{'Invoice number' | translate}}: {{form.get("formattedBookingsNumber").value}}</li>
          <li>
            {{'Date of invoice' | translate}}:
            <span class="text-bold">{{form.get("invoiceDate").value}}</span>
          </li>
        </ol>
        <ol>
          <li>
            {{'Service Detail' | translate}}:
            <span class="text-bold">{{form.get("serviceDetail").value}}</span>
          </li>
          <li>
            {{'VAT' | translate}}:
            <span class="text-bold">{{form.get("vat").value}}</span>
          </li>
          <li>
            {{'Total Costs' | translate}}:
            <span class="text-bold">{{form.get("amount").value}}</span>
          </li>
        </ol>
      </div>
      <div class="card-invoice">
        <p class="mt-2">{{'Personal custom text field' | translate}}</p>
        <textarea
          class="textarea-control"
          style="height: 100px !important"
          formControlName="invoiceText"
          [placeholder]="'The info you place here is for your own administration, only you will see this on the invoice'
                | translate"></textarea>
        <div class="mb-4 mt-4" *ngIf="message?.type">
          <app-alert *ngIf="message" [type]="message?.type">{{ message?.message }}</app-alert>
        </div>
        <div class="text-center">
          <button type="submit" class="mt-2" [disabled]="form.invalid">{{'Save' | translate}}</button>
        </div>
      </div>
    </form>
  </ng-container>
  <div class="text-center">
    <a class="mt-4 btn" [href]="downloadInvoice()">{{'Download invoice' | translate}}</a>
  </div>
  <div class="text-center">
    <a class="mt-4 btn btn-solid-white" [href]="downloadAgreement()">{{'Download Agreement' | translate}}</a>
  </div>
</section>
