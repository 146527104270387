<div style="margin-bottom: 100px; display: flex; align-items: center; justify-content: center">
  <ng-container *ngIf="profile.user.voices.length > 0">
    <div *ngIf="!isBusy">
      <ng-container *ngFor="let voice of profile.user.voices">
        <div class="voice-item">
          <p>{{ voice.title }}</p>
          <div class="voice-element">
            <audio [src]="voice.url" controls></audio>
            <button *ngIf="enableDelete" (click)="deleteVoice(voice.id)">
              <img src="/assets/img/mobile/trash-mobile.svg" />
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div
      style="display: flex; align-items: center; justify-content: center; gap: 10px; flex-direction: column"
      *ngIf="isBusy">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <p>Deleting Your Voice...</p>
    </div>
  </ng-container>
</div>
