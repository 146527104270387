<div class="card d-flex flex-row">
  <div class="profile-details d-flex flex-column">
    <div class="d-flex flex-row">
      <div class="profile-picture">
        <div *ngIf="profilePicture" class="frame">
          <img [src]="profilePicture" class="profilePictureImg" />
        </div>
      </div>
      <div>
        <div class="username">{{ user?.profileName }}</div>
        <div translate>{{ profile?.category?.translateKeySingular }}</div>
        <div>
          <ng-container *ngIf="user?.city">{{ user?.city }},&nbsp;</ng-container>
          {{ user?.country?.alpha2Code }}
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex flex-row">
        <div class="label d-flex" translate>About Me</div>
        <div class="profile-details-value bio d-flex">{{ profile?.bio }}</div>
      </div>

      <div class="detail-container d-flex flex-row">
        <div class="label d-flex" translate>Age</div>
        <div class="profile-details-value d-flex">{{ user?.age }}</div>
      </div>

      <div class="detail-container d-flex flex-row">
        <div class="label d-flex" translate>Experience</div>
        <div *ngIf="profile?.yearsExperience" class="profile-details-value d-flex">
          {{ profile.yearsExperience }}
          <ng-container *ngIf="profile.yearsExperience > 1">{{ 'Years' | translate }}</ng-container>
          <ng-container *ngIf="profile.yearsExperience == 1">{{ 'Year' | translate }}</ng-container>
        </div>
      </div>

      <div class="detail-container d-flex flex-row">
        <div class="label d-flex" translate>Languages</div>
        <div class="profile-details-value d-flex">
          <ng-container *ngFor="let language of user.languages; let i = index">
            <ng-container *ngIf="i > 0" translate>,</ng-container>
            <ng-container>{{ language.translateKey | translate }}</ng-container>
          </ng-container>
        </div>
      </div>

      <div class="detail-container d-flex flex-row" *ngFor="let detail of details">
        <div class="label d-flex" translate>{{ detail.translateKey }}</div>
        <div *ngIf="detail.type !== 'color'" class="profile-details-value d-flex">
          {{ detail.values.join(', ') }} {{ detail.unit }}
        </div>
      </div>

      <ng-container *ngIf="videoAlbum && videoAlbum.media">
        <div *ngFor="let media of videoAlbum.media; let i = index" class="detail-container d-flex flex-row">
          <a class="video-link" *ngIf="media.link" [href]="media.link" target="_blank">Video link {{ i+1 }}</a>
          <a class="video-link" *ngIf="!media.link && media.url" [href]="media.url" target="_blank">
            Video link {{ i+1 }}
          </a>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="photo d-flex flex-row flex-wrap">
    <ng-container *ngIf="photos">
      <ng-container *ngFor="let photo of photos; let i = index">
        <div *ngIf="i <= 3" class="photo-frame d-flex w-50">
          <img [src]="photo.url" class="photoImg" />
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
