<div class="input-group">
  <input
    #input
    (input)="onValueChange($event.target.value)"
    (blur)="onValueChange($event.target.value)"
    *ngIf="!isReadOnly"
    [placeholder]="placeholder"
    class="form-control"
    type="time" />

  <div *ngIf="isReadOnly" class="form-control">{{ value.toDate() | date: 'HH:mm' }}</div>
</div>
