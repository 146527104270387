import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobApplication, Job } from '@core/models';
import { RejectJobApplications } from '@core/states';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { combineLatest, filter } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-reject',
  templateUrl: './bulk-reject.component.html',
  styleUrls: ['./bulk-reject.component.scss'],
})
export class BulkRejectComponent {
  @Input()
  public jobApplicationIds: string[];

  @Input()
  public jobId: string;

  public form = new FormGroup({
    reason: new FormControl(null, []),
  });

  public formSend = false;
  public isBusy = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { jobApplicationIds: string[]; jobId: string },
    @Optional() private dialogRef: MatDialogRef<BulkRejectComponent>,
    private dialog: MatDialog,
    private store: Store,
  ) {
    this.jobApplicationIds = this.data.jobApplicationIds;
    this.jobId = this.data.jobId;
  }

  public closeModal(): void {
    this.dialogRef.close(this.formSend);
  }

  public async reject(): Promise<void> {
    await this.store
      .dispatch(new RejectJobApplications(this.jobId, this.jobApplicationIds, this.form.value.reason))
      .toPromise();
    this.formSend = true;
  }
}
