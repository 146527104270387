import { IUser } from '@core/interfaces/user';

export class LoadPendingVerifications {
  public static readonly type = '[Verifications] load pending';

  public constructor() {}
}

export class MarkAsVerified {
  public static readonly type = '[Verifications] mark as verified';

  public constructor(public user: IUser) {}
}

export class MarkAsUnverified {
  public static readonly type = '[Verifications] mark as unverified';

  public constructor(public user: IUser) {}
}
