import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Invoice, PaginatedResults } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  public constructor(private http: HttpClient) {}

  public findAll(
    page: number = 0,
    limit: number = 25,
    orderBy: string = 'id',
    orderByDirection: string = 'asc',
  ): Observable<PaginatedResults<Invoice>> {
    const searchParams = {
      page: `${page}`,
      limit: `${limit}`,
      orderBy: `${orderBy}`,
      orderByDirection: `${orderByDirection}`,
    };
    return this.http
      .get(`${environment.apiBaseUrl}/invoices`, {
        params: searchParams,
      })
      .pipe(
        map((data: PaginatedResults<Invoice>) => {
          data.results = data.results.map((item: Invoice) => plainToClass(Invoice, item));
          return data;
        }),
      );
  }

  public getById(id: string): Observable<Invoice> {
    return this.http
      .get(`${environment.apiBaseUrl}/invoices/${id}`)
      .pipe(map((data: Invoice) => plainToClass(Invoice, data)));
  }

  public saveInvoice(invoice: Invoice): Observable<Invoice> {
    return this.http
      .put(`${environment.apiBaseUrl}/invoices/${invoice.id}`, invoice)
      .pipe(map((data: Invoice) => plainToClass(Invoice, data)));
  }

  public createJobInvoice(payload: any): Observable<void> {
    return this.http.post(`${environment.apiBaseUrl}/job-invoice`, payload).pipe(map((_) => {}));
  }

  public getInvoiceDetail(applicantId: any): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/job-invoice/applicant/${applicantId}`);
  }
}
