import { JobApplication } from '@core/models';

export interface ITravelExpense {
  id: string;
  jobApplicationId: string;
  jobApplication?: JobApplication;
  type: TravelExpenseType;
  description?: string;
  distance?: number;
  time?: number;
  price: number;
  currency: string;
  paid: boolean;
}

export enum TravelExpenseType {
  kilometers = 'kilometers',
  parking = 'parking',
  other = 'other',
}
