import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { combineLatest, filter } from 'rxjs/operators';

import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  DecksState,
  DeleteDeck,
  DeletePackage,
  DeleteUserProfile,
  FetchCurrentUser,
  LoadMembership,
  MembershipsState,
  SaveUser,
  UpdateUserMembership,
  UserState,
} from '@core/states';
import { Deck, Membership, Package, User, UserProfile } from '@core/models';
import { IDeck, IPackage, MediaType } from '@core/interfaces';
import { DeleteMedia } from '@core/states/media';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-account-remove-items',
  templateUrl: './remove-items.component.html',
  styleUrls: ['./remove-items.component.scss'],
})
export class RemoveItemsComponent implements OnInit {
  @Select(UserState.user)
  public user$: Observable<User>;

  @Select(UserState.profiles)
  public profiles$: Observable<UserProfile[]>;

  @Select(DecksState.decks)
  public decks$: Observable<Deck[]>;

  @Select(MembershipsState.membership)
  public membership$: Observable<Membership>;

  public isBusy = false;

  public form: FormGroup;

  public get profiles(): FormArray {
    return this.form?.get('profiles') as FormArray;
  }

  public get decks(): FormControl {
    return this.form?.get('decks') as FormControl;
  }

  private membership: Membership;
  private readonly membershipId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() private data: { membershipId: string },
    @Optional() private dialogRef: MatDialogRef<RemoveItemsComponent>,
    private store: Store,
    private route: ActivatedRoute,
  ) {
    this.membershipId = data?.membershipId;
  }

  public async ngOnInit(): Promise<void> {
    const membershipId = this.membershipId ?? this.route.snapshot.params.membershipId;
    this.store.dispatch(new LoadMembership(membershipId));

    this.membership$
      .pipe(
        combineLatest(this.profiles$, this.decks$),
        filter(([membership, profiles, decks]) => !!membership && !!profiles && !!decks),
      )
      .subscribe(([membership, profiles, decks]) => {
        this.initForm(membership, profiles, decks);
        this.membership = membership;
      });
  }

  public profile(i: number): FormControl {
    return this.profiles?.get(`${i}`) as FormControl;
  }

  public initForm(membership: Membership, userProfiles: UserProfile[], userDecks: Deck[]): void {
    const profiles = new FormArray([], this.maxLengthArray(membership.talent_profiles));
    for (const userProfile of userProfiles) {
      const photos = [];
      const videos = [];
      for (const album of userProfile.albums) {
        if (album.albumType === MediaType.photo) {
          photos.push(...album.media.map((m) => ({ value: m.id, checked: true })));
        }
        if (album.albumType === MediaType.video) {
          videos.push(...album.media.map((m) => ({ value: m.id, checked: true })));
        }
      }
      const profile = new FormGroup({
        id: new FormGroup({
          value: new FormControl(userProfile.id),
          checked: new FormControl(true),
        }),
        albums: new FormGroup({
          photo: new FormControl(photos, this.maxLengthArray(membership.portfolio_pictures)),
          video: new FormControl(videos, this.maxLengthArray(membership.videos)),
        }),
        packages: new FormControl(
          userProfile.packages?.map((p: Package) => ({ value: p.id, checked: true })),
          this.maxLengthArray(membership.packages),
        ),
      });
      profiles.push(profile);
    }
    this.form = new FormGroup({
      profiles,
      decks: new FormControl(
        userDecks.map((deck) => ({ value: deck.id, checked: true })),
        this.maxLengthArray(membership.decks),
      ),
    });

    // disable validation for children when checkbox is not checked
    this.profiles.controls.forEach((profile: FormGroup) => {
      profile.valueChanges.subscribe((_) => {
        if (!profile.get('id.checked').value) {
          profile.disable({ emitEvent: false });
        } else {
          profile.enable({ emitEvent: false });
        }
      });
    });
  }

  public async submit(): Promise<void> {
    if (this.isBusy) return;

    const data = this.form.getRawValue();
    this.isBusy = true;

    for (const deck of data.decks) {
      if (!deck.checked) {
        await this.store.dispatch(new DeleteDeck({ id: deck.value } as IDeck)).toPromise();
      }
    }

    for (const profile of data.profiles) {
      for (const pkg of profile.packages) {
        if (!pkg.checked || !profile.id.checked) {
          await this.store.dispatch(new DeletePackage({ id: pkg.value } as IPackage)).toPromise();
        }
      }

      for (const media of [...profile.albums.photo, ...profile.albums.video]) {
        if (!media.checked || !profile.id.checked) {
          await this.store.dispatch(new DeleteMedia(media.value)).toPromise();
        }
      }

      if (!profile.id.checked) {
        await this.store.dispatch(new DeleteUserProfile(profile.id.value)).toPromise();
      }
    }

    let user = this.store.selectSnapshot(UserState.user);
    if (!user) {
      await this.store.dispatch(new FetchCurrentUser()).toPromise();
      user = this.store.selectSnapshot(UserState.user);
    }
    await this.store
      .dispatch(
        new UpdateUserMembership(user.id, {
          userId: user.id,
          membershipId: this.membership.id,
          interval: 'month',
          startDate: new Date(),
          endDate: null,
        }),
      )
      .toPromise();
    // await this.store
    //   .dispatch(
    //     new SaveUser(user.id, {
    //       id: user.id,
    //       email: user.email,
    //       firstName: user.firstName,
    //       lastName: user.lastName,
    //       disabled: false,
    //     } as unknown as FormData),
    //   )
    //   .toPromise();

    if (!this.dialogRef) {
      this.store.dispatch(new Navigate(['/account/details/membership']));
    }
    this.isBusy = false;
    this.dialogRef?.close({ success: true });
  }

  public show(index: number): boolean {
    return this.profiles?.get(`${index}.id.checked`)?.value as boolean;
  }

  private maxLengthArray(max: number): (control: AbstractControl) => { [p: string]: any } {
    return (control: AbstractControl): { [key: string]: any } => {
      const checked = control.value.filter((c) => c.checked ?? c.id?.checked ?? false);

      if (checked.length <= max) {
        return null;
      }

      return { maxLengthArray: { valid: false } };
    };
  }
}
