import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { Size } from '../interfaces/size';

@Injectable({
  providedIn: 'root',
})
export class LayoutCalculatorService {
  public data: any;
  public layoutChanged$: Observable<unknown>;

  private renderer: Renderer2;
  private readonly containerEl: HTMLElement;
  private onLayoutChanged$ = new Subject();
  private containerWidth: number;
  private padding: number;
  private gap = 24;
  private breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1320,
  };

  public constructor(private rendererFactory: RendererFactory2) {
    this.layoutChanged$ = this.onLayoutChanged$
      .asObservable()
      .pipe(throttleTime(100, asyncScheduler, { leading: true, trailing: true }));

    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.containerEl = this.renderer.createElement('div');
    this.renderer.addClass(this.containerEl, 'container');
    this.renderer.appendChild(window.document.body, this.containerEl);

    this.renderer.listen(window, 'resize', (e: Event) => this.onWindowResize(e));
    this.checkLayout();
  }

  public getTileSize(perView: number, ratio?: number): Size {
    const tileSize = (this.data.containerWidth - this.gap * (perView - 1)) / perView;

    return {
      width: tileSize,
      height: tileSize / (ratio || 1),
    };
  }

  public getImageSize(tileSize: Size): Size {
    return {
      ...tileSize,
      height: tileSize.height - 68,
    };
  }

  public getTalentsPerRow(breakpointName: string): number {
    switch (breakpointName) {
      case 'sm':
        return 3;
      case 'md':
        return 4;
      case 'lg':
        return 5;
      case 'xl':
        return 6;
      default:
        return 2;
    }
  }

  private findBreakpointName(width: number): string {
    let lastMatch = Object.keys(this.breakpoints)[0];

    for (const breakpointName of Object.keys(this.breakpoints)) {
      const breakpoint = this.breakpoints[breakpointName];

      if (breakpoint > width) {
        break;
      }

      lastMatch = breakpointName;
    }

    return lastMatch;
  }

  private checkLayout(): void {
    // -30 for the padding
    const newContainerWidth = this.containerEl.clientWidth - 30;
    const newPadding = (window.innerWidth - newContainerWidth) / 2;

    if (this.containerWidth !== newContainerWidth || this.padding !== newPadding) {
      this.data = {
        containerWidth: newContainerWidth,
        padding: newPadding,
        gap: this.gap,
        breakpointName: this.findBreakpointName(window.innerWidth),
      };

      this.onLayoutChanged$.next(this.data);
    }
  }

  private onWindowResize(_: Event): void {
    this.checkLayout();
  }
}
