import { IUser } from '@core/interfaces/user';
import { IUserProfile } from '@core/interfaces/user-profile';

export class FetchCurrentUser {
  public static readonly type = '[User] Fetch current users details';
}

export class FetchAllCasters {
  public static readonly type = '[User] Fetch all casters';
}

export class FetchUserDetails {
  public static readonly type = '[User] Fetch user details';

  public constructor(public id: string) {}
}

export class UpdateUserDetails {
  public static readonly type = '[User] Update user details';

  public constructor(public user: IUser) {}
}

export class AddProfileToUser {
  public static readonly type = '[User] Add profile to user';

  public constructor(public userProfile: IUserProfile) {}
}

export class SaveUser {
  public static readonly type = '[User] Save user';

  public constructor(public id: string, public userData: FormData) {}
}

export class ResetCurrentUser {
  public static readonly type = '[User] Reset user';
}

export class UpdateUserRoles {
  public static readonly type = '[Users] Set user roles';
  public constructor(public userId: string, public roles: string[]) {}
}

export class SetActiveJobAlert {
  public static readonly type = '[Users] Set active job alert';
  public constructor(public userId: string, public activeJobAlert: boolean) {}
}

export class GetJobQuota {
  public static readonly type = '[Users] get job quota';
}

export class GetUserDetails {
  public static readonly type = '[User] get details';

  public constructor(public userId: string) {}
}
