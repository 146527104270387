import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { GeoLocation, GeocodingResultDTO } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationsService {
  public constructor(private http: HttpClient) {}

  public searchCity(
    search: string,
    isoCode: string,
    language: string,
    sessionKey: string,
  ): Observable<GeocodingResultDTO[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/geocoding/search`, {
        params: {
          search,
          country: isoCode,
          sessiontoken: sessionKey,
          language,
        },
      })
      .pipe(map((item: GeocodingResultDTO[]) => plainToClass(GeocodingResultDTO, item)));
  }

  public searchLocation(search: string, language: string, sessionKey: string): Observable<GeocodingResultDTO[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/geocoding/search`, {
        params: {
          search,
          language,
          sessiontoken: sessionKey,
        },
      })
      .pipe(map((item: GeocodingResultDTO[]) => plainToClass(GeocodingResultDTO, item)));
  }

  public getGeoLocationForCity(city: string, isoCode: string, language: string): Observable<GeoLocation> {
    return this.http
      .get(`${environment.apiBaseUrl}/geocoding`, {
        params: {
          city,
          country: isoCode,
          language,
        },
      })
      .pipe(map((item: GeoLocation) => plainToClass(GeoLocation, item)));
  }

  public getGeolocationByDescription(description: string, language: string): Observable<GeoLocation> {
    return this.http
      .get(`${environment.apiBaseUrl}/geocoding`, {
        params: {
          description,
          language,
        },
      })
      .pipe(map((item: GeoLocation) => plainToClass(GeoLocation, item)));
  }
}
