import { Component, Injectable, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mobile-modal-dialog',
  templateUrl: './mobile-modal-dialog.component.html',
  styleUrls: ['./mobile-modal-dialog.component.scss'],
})
export class MobileModalDialogComponent implements OnInit {
  @Input() public backgroundColor = `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  radial-gradient(159.52% 100% at 50% 0%, rgba(103, 16, 205, 0.2) 0%, rgba(86, 43, 140, 0.05) 100%)`;

  @Input() public disableCloseButton = false;

  @ViewChild('modal') private modalContent: TemplateRef<MobileModalDialogComponent>;
  public modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        windowClass: 'bottom',
        container: '#mobile-app',
        backdrop: 'static',
        keyboard: false,
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async close(): Promise<void> {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  async dismiss(): Promise<void> {
    this.modalRef.dismiss();
  }
}
