import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-create-job-modal',
  templateUrl: './success-create-job-modal.component.html',
  styleUrls: ['./success-create-job-modal.component.scss'],
})
export class SuccessCreateJobModalComponent implements OnInit {
  constructor(
    @Optional() private dialogRef: MatDialogRef<SuccessCreateJobModalComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: any,
    private route: Router,
  ) {}

  ngOnInit(): void {}

  public closeModal(): void {
    this.dialogRef.close();
  }

  public viewJob() {
    this.route.navigateByUrl(`find-jobs/job/${this.data.jobId}`, {
      replaceUrl: true,
    });
    this.closeModal();
  }

  public findTalent() {
    this.route.navigateByUrl('talents', {
      replaceUrl: true,
    });
    this.closeModal();
  }
}
