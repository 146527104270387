import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: any[], ...args: any[]): unknown {
    if (!value || !args || args?.length === 0) {
      return value;
    }

    const result = [];
    const propertyName = args[0];

    for (const item of value) {
      const existing = result.find((i: any) => i[propertyName] === item[propertyName]);

      if (existing) {
        continue;
      }

      result.push(item);
    }

    result.sort((i1: any, i2: any) => {
      if (i1[propertyName] < i2[propertyName]) {
        return -1;
      }
      if (i1[propertyName] > i2[propertyName]) {
        return 1;
      }
      return 0;
    });

    return result;
  }
}
