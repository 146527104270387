export interface IMedia {
  id: string;
  mediaType: MediaType;
  name?: string;
  path?: string;
  url: string;
  thumbUrl: string;
  link?: string;
  userId?: string;
  processed?: boolean;
  uploaded?: boolean;
  externalThumbUrl?: string;
  order?: number;
}

export enum MediaType {
  video = 'video',
  photo = 'photo',
  idverification = 'idverification',
}
