import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDeck } from '@core/interfaces';

@Component({
  selector: 'app-deck',
  templateUrl: './deck.component.html',
  styleUrls: ['./deck.component.scss'],
})
export class DeckComponent {
  @Output()
  public edit = new EventEmitter<IDeck>();

  @Input()
  public deck: IDeck;

  @Input()
  public editMode = false;
}
