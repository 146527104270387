import { Component, OnInit } from '@angular/core';
import { BankAccountDTO, MerchantDTO } from '@core/dto';
import { CreateBankAccount, PaymentsState } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-merchant-info',
  templateUrl: './mobile-merchant-info.component.html',
  styleUrls: ['./mobile-merchant-info.component.scss'],
})
export class MobileMerchantInfoComponent implements OnInit {
  @Select(PaymentsState.merchant)
  public merchant$: Observable<MerchantDTO>;

  @Select(PaymentsState.bankAccount)
  public bankAccount$: Observable<BankAccountDTO>;

  public constructor(private store: Store) {}

  ngOnInit(): void {}

  public redirect(url: string): void {
    window.open(url, '_blank');
  }

  public createBankAccount(talentId: string): void {
    this.store.dispatch(new CreateBankAccount(talentId));
  }
}
