import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-forgot-password-modal',
  templateUrl: './mobile-forgot-password-modal.component.html',
  styleUrls: ['./mobile-forgot-password-modal.component.scss'],
})
export class MobileForgotPasswordModalComponent implements OnInit {
  @ViewChild('appForgotPasswordModal') private modalComponent: MobileModalDialogComponent;

  public isSuccess: boolean;
  public message?: any;
  public isBusy: boolean;
  public form = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  constructor(public usersService: UsersService, private translateService: TranslateService) {}

  ngOnInit(): void {}

  async openDialog(): Promise<void> {
    return await this.modalComponent.open().then(() => {
      this.isBusy = false;
      this.isSuccess = false;
      this.message = undefined;
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public async onSubmit(): Promise<void> {
    try {
      this.message = undefined;
      this.isBusy = true;
      const data = { ...this.form.value };
      await this.usersService.forgotPassword(data).toPromise();
    } catch (e) {
      const lang = this.translateService.currentLang.toUpperCase();
      const message =
        lang === 'NL'
          ? 'Er is iets misgegaan, klopt het E-mailadres?'
          : 'Something went wrong, is your email spelled correctly?';
      this.message = { message, type: 'alert alert-danger' };
    } finally {
      this.isBusy = false;
      if (this.message === undefined) {
        this.isSuccess = true;
      }
    }
  }
}
