<div class="d-flex flex-column w-100 text-white">
  <h1 class="h4 mb-3" *ngIf="role === 'talent'" translate>Do you also want to apply to jobs?</h1>
  <h1 class="h4 mb-3" *ngIf="role === 'caster'" translate>Do you also want to post jobs?</h1>

  <div class="buttons">
    <app-button (click)="addRole()" class="mx-2"><span translate>Yes</span></app-button>
    <app-button (click)="this.dialogRef.close(false)" buttonStyle="white-outline" class="mx-2">
      <span translate>No</span>
    </app-button>
  </div>
</div>
