import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

import { environment } from '@environments/environment';
import { Media } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public constructor(private http: HttpClient) {}

  public delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/media/${id}`);
  }

  public info(id: string): Observable<Media> {
    return this.http
      .get(`${environment.apiBaseUrl}/media/info/${id}`)
      .pipe(map((data: Media) => plainToClass(Media, data)));
  }
}
