import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthenticatedLayoutComponent } from './components/unauthenticated-layout/unauthenticated-layout.component';
import { AuthenticatedLayoutComponent } from './components/authenticated-layout/authenticated-layout.component';
import { NavigationModule } from '../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '@src/directive/directive.module';
import { SharedMobileModule } from '@shared/mobile-components/shared-mobile.module';

@NgModule({
  declarations: [UnauthenticatedLayoutComponent, AuthenticatedLayoutComponent],
  imports: [CommonModule, NavigationModule, RouterModule, DirectiveModule, SharedMobileModule],
  exports: [UnauthenticatedLayoutComponent, AuthenticatedLayoutComponent],
})
export class LayoutsModule {}
