<div class="content rounded-sm panel d-flex flex-column mb-5 w-100">
  <h3 class="h5 mb-5">{{ package.title }}</h3>
  <h4 class="h4 mb-2">
    <ng-container *ngIf="package.feeType.slug === 'fee'; else showName">
      <app-money [amount]="package.price" [currency]="package.currency"></app-money>
    </ng-container>
    <ng-template #showName>{{ package.feeType.name }}</ng-template>
  </h4>
  <p class="mb-5">{{ package.description }}</p>

  <div *ngIf="package.feeType.slug === 'fee'" class="mb-3">
    <div translate>Shooting time</div>
    {{ package.time }} hours

    <div class="text-muted">
      <small translate>
        Price per additional hour:
        <app-money [amount]="package.extraHour" [currency]="package.currency"></app-money>
      </small>
    </div>
  </div>

  <app-button
    *ngIf="isCaster(token$ | async)"
    (click)="onPackageSelected(package)"
    buttonStyle="white-outline"
    class="mt-auto">
    <span translate>Select</span>
  </app-button>
</div>
