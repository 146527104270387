import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  InitiateChat,
  MarkAllNotificationAsRead,
  MarkNotificationAsRead,
  NotificationsState,
  UserState,
} from '@core/states';
import { Notification, User } from '@core/models';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { MessagingModalComponent } from '@src/ui/messaging/components/messaging-modal/messaging-modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications-badge',
  templateUrl: './notifications-badge.component.html',
  styleUrls: ['./notifications-badge.component.scss'],
})
export class NotificationsBadgeComponent {
  @Select(NotificationsState.notifications)
  public notifications$: Observable<Notification[]>;

  constructor(private store: Store, private dialog: MatDialog) {}

  public markAllAsReadClicked(): void {
    this.store.dispatch(new MarkAllNotificationAsRead());
  }

  public notificationClicked(notification: any): boolean {
    this.store.dispatch(new MarkNotificationAsRead(notification));
    switch (notification.type) {
      case 'chat':
        return this.openChatModal(notification.payload.message);
      case 'directOffer':
        return this.openDirectOffers();
      case 'talentAcceptedApplication':
      case 'talentRejectedApplication':
        return this.openOpenJobs();
    }
  }

  public openDirectOffers(): boolean {
    this.store.dispatch(new Navigate(['/account/job-applications/offered'])).subscribe();
    return false;
  }

  public openOpenJobs(): boolean {
    this.store.dispatch(new Navigate(['/account/jobs/status/open'])).subscribe();
    return false;
  }

  public openChatModal(message: any): boolean {
    const user: User = this.store.selectSnapshot(UserState.user);
    const senderId: string = user.id;
    const recipientId = user.id === message.senderId ? message.recipientId : message.senderId;
    if (recipientId != null) {
      this.store.dispatch(new InitiateChat(senderId, recipientId));
    }
    this.dialog.open(MessagingModalComponent, {
      data: {
        userId: user.id,
        recipientId,
      },
    });
    return false;
  }
}
