import { Injectable } from '@angular/core';
import { JwtToken } from '@auth/models';

import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  public events$: Observable<any>;
  public errors$: Observable<any>;

  private eventSource: EventSource;
  private eventsSubject$ = new Subject();
  private errorsSubject$ = new Subject();
  private token: JwtToken;

  public constructor() {
    this.events$ = this.eventsSubject$.asObservable();
    this.errors$ = this.errorsSubject$.asObservable();
  }

  public close(): void {
    // eslint-disable-next-line no-console
    console.debug(`SSE close`);
    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();
    this.eventSource = null;
  }

  public create(token: JwtToken): Observable<any> {
    // eslint-disable-next-line no-console
    console.debug(`SSE create`);
    this.token = token;
    this.connect();
    return this.events$;
  }

  private connect(): void {
    // eslint-disable-next-line no-console
    console.debug(`SSE connect`);
    this.close();
    this.eventSource = new EventSource(`${environment.apiBaseUrl}/sse/events?token=${this.token.access_token}`);
    this.eventSource.onopen = (ev: Event) => this.onOpen(ev);
    this.eventSource.onerror = (ev: Event) => this.onError(ev);
    this.eventSource.onmessage = (ev: MessageEvent) => this.onMessage(ev);
  }

  private onOpen(ev: Event): void {
    // eslint-disable-next-line no-console
    console.debug(`SSE on open`, ev);
  }

  private onMessage(ev: MessageEvent): void {
    try {
      // eslint-disable-next-line no-console
      console.debug(`SSE on message`, ev);
      const data = JSON.parse(ev.data);
      this.eventsSubject$.next(data);
    } catch (e) {
      console.error(e);
    }
  }

  private onError(ev: Event): void {
    // eslint-disable-next-line no-console
    console.debug(`SSE on error`, ev);
    if (this.token.isExpired) {
      // eslint-disable-next-line no-console
      console.debug(`SSE on error token expired`);
      this.errorsSubject$.next(new Error('Token expired'));
      return;
    }

    setTimeout(() => this.connect(), 5000);
  }
}
