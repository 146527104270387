import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DeleteNote, FindTalentsNote, NotesState, SaveNote, UserState } from '@core/states';
import { Observable } from 'rxjs';
import { Note, User } from '@core/models';
import { FormControl, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-talent-personal-notes',
  templateUrl: './talent-personal-notes.component.html',
  styleUrls: ['./talent-personal-notes.component.scss'],
})
export class TalentPersonalNotesComponent implements OnInit {
  @Input()
  public talent: User;

  @Select(NotesState.note)
  public note$: Observable<Note>;

  public editing = false;

  public form: FormGroup = new FormGroup({
    id: new FormControl(),
    userId: new FormControl(),
    talentId: new FormControl(),
    comment: new FormControl(),
  });

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new FindTalentsNote(this.talent?.id));

    this.form.patchValue({ talentId: this.talent?.id });

    this.note$.pipe(filter((a) => !!a)).subscribe((note: Note) => {
      this.form.patchValue(note);
    });
  }

  public async save(): Promise<void> {
    const note = this.form.value;
    this.editing = false;
    if (note.comment == null) {
      return;
    }
    if (note.id !== null && note.comment === '') {
      return await this.delete();
    }
    if (note.id === null) {
      note.id = uuid();
    }
    if (note.userId === null) {
      note.userId = this.store.selectSnapshot(UserState.user)?.id;
    }
    await this.store.dispatch(new SaveNote(note)).toPromise();
  }

  public async delete(): Promise<void> {
    await this.store.dispatch(new DeleteNote(this.form?.get('id').value)).toPromise();
  }
}
