<div class="d-flex flex-column w-100 text-white p-3">
  <div class="container">
    <div class="justify-content-center">
      <h5 class="text-center" translate>Rejecting booking may have consequences for your account</h5>
    </div>
    <div class="mt-3 text-center" translate>Write a message to the caster</div>

    <form (ngSubmit)="rejectBooking()" [formGroup]="form">
      <div class="mt-3">
        <app-textarea
          class="w-100 d-flex flex-fill border-0 rounded-sm mb-1"
          minHeight="250"
          formControlName="reason"></app-textarea>
      </div>
      <div class="mt-3 d-flex flex-column align-items-center">
        <app-button
          [isBusy]="isBusy"
          [isDisabled]="isBusy || form.invalid"
          buttonStyle="artyclick-purple"
          class="mb-3 w-50">
          <button class="w-100 h-100 px-2 bg-transparent border-0 text-white" translate type="submit">
            Reject Booking
          </button>
        </app-button>
      </div>
    </form>

    <div class="d-flex flex-column align-items-center">
      <app-button [isDisabled]="isBusy" (click)="closeModal()" buttonStyle="transparent-white" class="mb-3 w-50">
        <div class="px-2" translate>Cancel</div>
      </app-button>
    </div>
  </div>
</div>
