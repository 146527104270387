import { IFeeType } from '@core/interfaces/fee-type';

export class FeeType implements IFeeType {
  public id: string;
  public slug: string;
  public name: string;

  get translateKey(): string {
    return 'FEE_TYPE.' + this.slug;
  }
}
