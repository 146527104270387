import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['../text-field/text-field.component.scss', './time-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    },
  ],
})
export class TimeFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public placeholder = '';

  @Input()
  public isReadOnly = false;

  @Input()
  public iconColor = 'white';

  value: Moment;

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.uptimeField(this.value);
  }

  public onValueChange(value: string): void {
    const [hour, minute] = value.split(':');

    const newValue = moment(this.value)
      .local()
      .set({ hour: parseInt(hour, 10), minute: parseInt(minute, 10) })
      .utc();

    this.onChange(newValue);
  }

  public writeValue(value: Moment): void {
    this.value = moment(value?.utc().format())?.utc();
    this.uptimeField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private uptimeField(value: Moment): void {
    if (!this.inputEl) {
      return;
    }

    this.inputEl.nativeElement.value = moment(value).local().format('HH:mm');
  }
}
