import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { VgPlayerComponent } from '@videogular/ngx-videogular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { v4 } from 'uuid';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements AfterViewInit {
  @Input()
  public src: string;

  @Input()
  public type: string;

  @ViewChild(VgPlayerComponent)
  public videoPlayer: VgPlayerComponent;

  public isPlaying$ = new BehaviorSubject(false);

  public identifier = v4();

  public ngAfterViewInit(): void {
    const defaultMedia = this.videoPlayer.api.getDefaultMedia();
    defaultMedia.subscriptions.play.subscribe(() => this.isPlaying$.next(true));
    defaultMedia.subscriptions.pause.subscribe(() => this.isPlaying$.next(false));
  }

  public async play(): Promise<void> {
    const isPlaying = await this.isPlaying$.pipe(first()).toPromise();

    if (isPlaying) {
      this.videoPlayer.api.pause();
      return;
    }

    this.videoPlayer.api.play();
  }
}
