import { NgModule } from '@angular/core';
import { ScreenSizeDirective } from './screen-size.directive';
import { NavbarScrollDirective } from './navbar-scroll.directive';
import { AutoAnimateDirective } from './auto-animate.directive';
import { CommaToDotDirective } from './comma-replacer.directive';

@NgModule({
  declarations: [ScreenSizeDirective, NavbarScrollDirective, AutoAnimateDirective, CommaToDotDirective],
  exports: [ScreenSizeDirective, NavbarScrollDirective, AutoAnimateDirective, CommaToDotDirective],
})
export class DirectiveModule {}
