import { AfterViewInit, Directive, HostListener, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appNavbarScroll]',
})
export class NavbarScrollDirective implements AfterViewInit {
  public currentPosition: number = window.pageYOffset;

  constructor(private viewRef: ViewContainerRef) {}

  @HostListener('document:scroll', ['$event'])
  public onScroll(event: any) {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > this.currentPosition) {
      this.viewRef.element.nativeElement.setAttribute('style', 'visibility:hidden');
    } else {
      this.viewRef.element.nativeElement.setAttribute('style', 'visibility:visible');
    }
    this.currentPosition = scrollPosition;
  }

  @HostListener('document:scrollend', ['$event'])
  public onScrollEnd(event: any) {
    setTimeout(() => {
      this.viewRef.element.nativeElement.setAttribute('style', 'visibility:hidden');
    }, 5000);
  }

  ngAfterViewInit(): void {
    this.currentPosition = window.pageYOffset;
  }
}
