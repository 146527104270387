import { JwtHelperService } from '@auth0/angular-jwt';

export class JwtToken {
  public payload: any;
  private helper = new JwtHelperService();

  public constructor(public access_token: string) {
    this.payload = this.helper.decodeToken(access_token);
  }

  public get isExpired(): boolean {
    return this.helper.isTokenExpired(this.access_token);
  }

  public getClaim(name: string): string {
    return this.payload[name];
  }

  public getBool(name: string): boolean {
    return this.payload[name] as boolean;
  }
}
