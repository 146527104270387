import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesComponent } from '@src/ui/roles/components/roles.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@src/ui/generic/buttons/buttons.module';

const components = [RolesComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, TranslateModule, ButtonsModule],
  exports: components,
})
export class RolesModule {}
