import { Component, Optional } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-id-modal',
  templateUrl: './upload-id-modal.component.html',
  styleUrls: ['./upload-id-modal.component.scss'],
})
export class UploadIDModalComponent {
  public closeIcon = faTimes;

  public constructor(@Optional() private dialogRef: MatDialogRef<UploadIDModalComponent>) {}

  public closeModal(): void {
    this.dialogRef.close();
  }
}
