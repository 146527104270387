import { ICountry } from './country';
import { IMedia } from './media';
import { IReview } from './review';
import { ILanguage } from './language';
import { IMembership } from '@core/interfaces/membership';
import { IUserProfile } from './user-profile';
import { IPoint } from './point';
import { IVoice } from './voice';

export interface IUser {
  id: string;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  profileName?: string;
  city?: string;
  country?: ICountry;
  phonenumberCountry?: ICountry;
  gender: string;
  transgender?: boolean;
  phonenumber?: string;
  phonenumberCheck?: string;
  website?: string;
  isPro?: boolean;
  agreedToTerms?: Date;
  role: UserRole[];
  mobileVerification?: boolean;
  mobileVerificationCode?: string;
  emailVerification?: boolean;
  emailVerificationCode?: string;
  idVerification?: boolean;
  idVerificationMediaId?: boolean;
  photo?: IMedia;
  reviews?: IReview[];
  averageReview?: number;
  totalReviews?: number;
  birthday?: Date;
  newsletter?: boolean;
  idVerificationMedia?: IMedia;
  languages?: ILanguage[];
  hash?: string;
  coc_nr?: string;
  fullName: string;
  merchant_uid?: string;
  merchant_status?: MerchantStatus;
  compliance_level?: number;
  compliance_status?: ComplianceStatus;
  times_profile_changed?: number;
  membership?: IMembership;
  expiredMembership?: IMembership;
  userType: UserType;
  companyName?: string;
  position?: string;
  totalJobReactions?: number;
  totalActiveChats?: number;
  disabled: boolean;
  address: string;
  formatLanguages: string;
  isCaster: boolean;
  isAdmin: boolean;
  isTalent: boolean;
  profiles?: IUserProfile[];
  phonenumberWithCallingCode: string;
  hidden?: boolean;
  coordinates?: IPoint;
  vatNumberCountry?: ICountry;
  vatNumber?: string;
  vatCheckResult?: any;
  street?: string;
  houseNumber?: string;
  postalCode?: string;
  age: number;
  isCompleted: boolean;
  mandate_uid?: string;
  mandate_status?: string;
  hasBadge?: boolean;
  created: Date;
  voices?: IVoice[];
}

export enum UserType {
  private = 'private',
  company = 'company',
}

export enum UserRole {
  admin = 'admin',
  talent = 'talent',
  caster = 'caster',
}

export enum ComplianceStatus {
  unverified = 'unverified',
  pending = 'pending',
  verified = 'verified',
}

export enum MerchantStatus {
  new = 'new',
  pending = 'pending',
  live = 'live',
  suspended = 'suspended',
  terminated = 'terminated',
  blocked = 'blocked',
  unverified = 'unverified',
}
