import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewSwitcherComponent } from './components/view-switcher/view-switcher.component';
import { ViewSwitcherViewComponent } from './components/view-switcher-view/view-switcher-view.component';

const components = [ViewSwitcherComponent, ViewSwitcherViewComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components,
})
export class ViewSwitcherModule {}
