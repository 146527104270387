import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IFeeType } from '@core/interfaces/fee-type';
import {
  CountriesState,
  FeeTypesState,
  FetchAllCasters,
  FetchEditJob,
  GeocoderState,
  LanguagesState,
  LoadCategory,
  SaveJob,
  UserState,
} from '@core/states';
import { Select, Store } from '@ngxs/store';
import { EMPTY, Observable, of } from 'rxjs';
import moment, { invalid } from 'moment/moment';
import { v4 } from 'uuid';

import { map, switchMap, tap } from 'rxjs/operators';
import { Country, GeoLocation, Job, JobDate, JobTalentCountry, PaginatedResults, User } from '@core/models';
import { FileService } from '@src/ui/generic/images/services/file.service';
import {
  ICategory,
  ICountry,
  IFilter,
  IJob,
  IJobCountry,
  IJobTalentCountry,
  ILanguage,
  IMedia,
  IPackage,
  IUser,
  MediaType,
} from '@core/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { SuccessCreateJobModalComponent } from '@src/visitor/modals/components/success-create-job-modal/success-create-job-modal.component';

@Component({
  selector: 'app-offer-job-form',
  templateUrl: './offer-job-form.component.html',
  styleUrls: ['./offer-job-form.component.scss'],
})
export class OfferJobFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  public availableCountries: ICountry[];

  @Select(LanguagesState.languages)
  public languages$: Observable<PaginatedResults<ILanguage>>;

  @Select(GeocoderState.geolocation)
  public geolocation$: Observable<GeoLocation>;

  @Select(UserState.casters)
  public casters$: Observable<User[]>;

  @Output()
  public submitted: EventEmitter<any> = new EventEmitter();

  @Input()
  public categories: ICategory[];

  @Input()
  public category: ICategory;

  @Input()
  public job: Job;

  @Input()
  public talent: IUser;

  @Input()
  public package: IPackage;

  @Input()
  public isDirect: boolean;

  @Input()
  public currentUser: User;

  @Input()
  public isEdited?: boolean;

  public isExistingJob = false;
  public invalidDate = false;
  public packageFee$: Observable<any>;
  public feeTypes: IFeeType[];
  public message: { message: string; type: string };
  public form: FormGroup;
  public isBusy = false;
  public showAdditionalOptions = false;
  public totalCosts = 0;
  public directPaymentCosts = 0;
  public talentPaymentCosts = 0;
  public talentPaymentVAT = 0;
  public needsPayment = false;
  public isSubmitted = false;
  public workFromHomeText = 'Online (work from home)';

  private isInitialized = false;

  public constructor(
    private fb: FormBuilder,
    private store: Store,
    private fileService: FileService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.route.queryParamMap.subscribe(async (paramMap) => {
      if (paramMap.get('succes') === '1') {
        this.message = {
          message: await this.translate.get('De job is opgeslagen').toPromise(),
          type: 'success',
        };
        this.isExistingJob = true;
      }
    });
    this.isExistingJob = !!this.job;
    // filter to only show fee feeTypes
    this.feeTypes = this.store
      .selectSnapshot(FeeTypesState.feeTypes)
      .filter((feeTypes: IFeeType): boolean => feeTypes.slug === 'fee' || feeTypes.slug === 'freebie');
  }

  ngOnDestroy(): void {
    if (this.currentUser.isAdmin) {
      window.location.reload();
    }
  }

  public ngOnInit(): void {
    if (this.currentUser.isAdmin) {
      this.store.dispatch(new FetchAllCasters());
    }

    this.isExistingJob = !!this.job;

    this.createForm(this.job);
    this.translate
      .get('Online (work from home)')
      .toPromise()
      .then((value): void => {
        this.workFromHomeText = value;
        if (this.form != null) {
          this.form.patchValue({
            workFromHomeText: this.workFromHomeText,
          });
        }
      });

    this.geolocation$.subscribe((geolocation: GeoLocation): void => {
      if (geolocation != null) {
        let subForm: AbstractControl = (this.form.get('countries') as FormArray).controls.find(
          (c: AbstractControl): boolean => c.get('city').value === geolocation.description,
        );
        if (subForm == null) {
          subForm = (this.form.get('talentCountries') as FormArray).controls.find(
            (c: AbstractControl): boolean => c.get('city').value === geolocation.description,
          );
        }
        if (subForm != null) {
          subForm.patchValue({
            city: geolocation.city,
            coordinates: geolocation.coordinates,
          });
        }
      }
    });

    const selectedCountry: Country = this.currentUser?.country;
    if (selectedCountry != null && this.job == null) {
      this.patchCountryFields('talentCountries', this.currentUser.country);
      this.patchCountryFields('countries', this.currentUser.country);
    }

    this.form.get('categoryId').patchValue(this.categories?.length > 0 ? this.categories[0].id : null);

    if (!this.isDirect && this.category != null) {
      this.categories = [this.category];
      this.patchCategories(this.categories);
      this.patchFilters(this.category, this.job);
      this.form.get('categoryId').patchValue(this.category.id);
    }

    if (this.package != null) {
      this.form.get('packageId').patchValue(this.package?.id);
      this.form.get('feeType').patchValue(this.package?.feeType);
      this.form.get('rate').patchValue(this.package?.price || 0);
    }

    this.isInitialized = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitialized) {
      return;
    }

    if ('currentUser' in changes) {
      const selectedCountry: Country = this.currentUser?.country;
      if (selectedCountry != null && this.job == null) {
        this.patchCountryFields('talentCountries', this.currentUser.country);
        this.patchCountryFields('countries', this.currentUser.country);
      }
    }

    if ('job' in changes && changes.job.currentValue != null) {
      this.job = changes.job.currentValue;
      this.isExistingJob = !!this.job;
      this.createForm(this.job);
    }

    if ('categories' in changes) {
      const categories = changes.categories.currentValue;
      this.form.get('categoryId').patchValue(categories?.length > 0 ? categories[0].id : null);
    }

    if ('category' in changes) {
      if (!this.isDirect && changes.category.currentValue !== null) {
        this.categories = [changes.category.currentValue];
        this.patchCategories(this.categories);
      }
      this.patchFilters(this.category, this.job);
      this.form.get('categoryId').patchValue(changes.category.currentValue?.id);
    }

    if ('package' in changes && !!changes.package.currentValue) {
      this.form.get('packageId').patchValue(changes.package.currentValue?.id);
      this.form.get('feeType').patchValue(changes.package.currentValue?.feeType);
      this.form.get('rate').patchValue(parseFloat(changes.package?.currentValue?.price || 0));
    }
  }

  public async onSubmit(): Promise<void> {
    try {
      if (this.isBusy) {
        return;
      }
      this.isBusy = true;

      const data = {
        ...this.form.value,
        isDirectOffer: this.job !== null && this.job !== undefined ? this.job?.isDirectOffer : this.isDirect,
      };

      const categoryFilters: IFilter[] = this.category.filters;

      let filters: any[] = [];
      for (const slug of Object.keys(this.filters.value)) {
        const formValue = this.filters.value[slug];
        const categoryFilter: IFilter = categoryFilters.find((f: IFilter): boolean => f.slug === slug);
        if (categoryFilter?.type === 'checkbox') {
          const jobFilters = formValue
            .filter((c: any) => c.checked)
            .map((c: any) => ({
              id: v4(),
              filterId: categoryFilter.id,
              filterOptionId: c.value,
              jobId: data.id,
              lowValue: null,
              highValue: null,
            }));

          filters = filters.concat(jobFilters);
          delete data[slug];
          continue;
        }

        if (['number'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: parseInt(formValue, 10),
            highValue: null,
          });
          delete data[slug];
          continue;
        }
        if (['text'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: null,
            highValue: null,
            value: formValue,
          });
          delete data[slug];
          continue;
        }
        if (['slider', 'color'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: formValue[0],
            highValue: formValue[1],
          });
          delete data[slug];
          continue;
        }
        if (['text'].includes(categoryFilter?.type)) {
          filters.push({
            id: v4(),
            filterId: categoryFilter.id,
            filterOptionId: null,
            jobId: data.id,
            lowValue: null,
            highValue: null,
            value: formValue,
          });
          delete data[slug];
          continue;
        }

        data[slug] = formValue;
      }
      data.filters = filters;

      if (data.age.length === 2) {
        data.startAge = data.age[0];
        data.endAge = data.age[1];
      }
      delete data.age;
      delete data.workFromHomeText;
      delete data.haveTravelingCost;

      data.dates = data.dates.map((d: any) => {
        const dateObj = {
          id: d.id,
          start: this.getDateTimeAsMoment({ date: d.startDate, time: d.startTime }).utc().format('YYYY-MM-DD HH:mm:ss'),
          end: this.getDateTimeAsMoment({ date: d.endDate, time: d.endTime }).utc().format('YYYY-MM-DD HH:mm:ss'),
          days: d.days,
          hours_per_day: d.hours_per_day,
        };
        return dateObj;
      });

      data.publishingChannels = data.publishingChannels
        .filter((c: any) => c.checked)
        .map((c: any) => c.value)
        .join(',');

      data.countries = data.countries
        .filter((c: any) => c.checked === true)
        .map((c: any) => {
          delete c.checked;
          c.jobId = data.id;
          return c;
        });
      data.talentCountries = data.talentCountries
        .filter((c: any) => c.checked === true)
        .map((c: any) => {
          delete c.checked;
          c.jobId = data.id;
          return c;
        });

      data.keywords = data.keywords
        .filter((k: any) => k.keyword != null && k.keyword !== '')
        .map((k: any) => k.keyword)
        .join(';');

      data.gender =
        data.gender
          .filter((c: any) => c.checked)
          .map((c: any) => c.value)
          .join(';') + ';';

      data.countries = data.countries.filter(
        (test, index, array) =>
          index === array.findIndex((findTest) => findTest.countryId === test.countryId && findTest.city === test.city),
      );
      data.talentCountries = data.talentCountries.filter(
        (test, index, array) =>
          index === array.findIndex((findTest) => findTest.countryId === test.countryId && findTest.city === test.city),
      );

      //Remove talent countries only for actor jobs.
      if (this.category.slug === 'actors') {
        data.talentCountries = null;
      }

      data.media = data.media?.map((m: any) => {
        if (m.file !== null) {
          m.data = m.url;
        }
        delete m.file;
        delete m.url;
        return m;
      });

      data.licenceDuration = data.licenceDuration === 'other' ? data.licenceDurationCustom : data.licenceDuration;
      delete data.licenceDurationCustom;

      if (data.extraFees === '') {
        delete data.extraFees;
      }

      const jobNeedsPayment = !this.job && this.needsPayment;

      const cleanPath = window.location.pathname.replace('/job-thank-you', '');

      const returnUrl =
        this.isDirect && !cleanPath.startsWith('/account/')
          ? `${environment.baseUrl}${cleanPath}/job-process-payment/[PAYMENT_ID]${window.location.search}`
          : `${environment.baseUrl}/account/jobs/status/open`;
      await this.store.dispatch(new SaveJob(data, this.talent?.id, returnUrl, jobNeedsPayment)).toPromise();
      if (this.isDirect) {
        this.message = {
          message: await this.translate
            .get('Your offer needs payment, you are being redirected to our payment provider.')
            .toPromise(),
          type: 'success',
        };
      } else {
        this.store.dispatch(new FetchEditJob(data.id));
        await this.router.navigate([`edit/${data.id}`], { relativeTo: this.route.parent, queryParams: { succes: 1 } });
      }

      this.submitted.emit(jobNeedsPayment);
    } catch (e) {
      this.message = {
        message: await this.translate.get('Er is een onbekende fout opgetreden').toPromise(),
        type: 'error',
      };

      throw e;
    } finally {
      if (!this.currentUser.isAdmin) {
        this.dialog.open(SuccessCreateJobModalComponent, {
          data: {
            jobId: this.form.get('id').value,
          },
        });
      }
      this.isBusy = false;
      // this.isSubmitted = true;
    }
  }

  public get dates(): FormArray {
    return this.form.get('dates') as FormArray;
  }

  public get talentCountries(): FormArray {
    return this.form.get('talentCountries') as FormArray;
  }

  public get licenceCountries(): FormArray {
    return this.form.get('licenceCountries') as FormArray;
  }

  public get countries(): FormArray {
    return this.form.get('countries') as FormArray;
  }

  public get filters(): FormGroup {
    return this.form.get('filters') as FormGroup;
  }

  public get languages(): FormArray {
    return this.form.get('languages') as FormArray;
  }

  public get keywords(): FormArray {
    return this.form.get('keywords') as FormArray;
  }

  public get media(): FormArray {
    return this.form.get('media') as FormArray;
  }

  public get exactDate(): FormControl {
    return this.form.get('exactDate') as FormControl;
  }

  public get hideRate(): FormControl {
    return this.form.get('hideRate') as FormControl;
  }

  public get requestTalentVideo(): FormControl {
    return this.form.get('requestTalentVideo') as FormControl;
  }

  public toggleHideRate(): void {
    this.hideRate.setValue(!this.hideRate.value);
  }

  public range(i: number): FormGroup {
    return (this.form.get('dates') as FormArray).at(i).get('range') as FormGroup;
  }

  public toggleAdditionalOptions(): void {
    this.showAdditionalOptions = !this.showAdditionalOptions;
  }

  public otherBroadcastCountriesSelected(): boolean {
    return this.form?.get('broadcastCountries').value === 'other';
  }

  public otherLicenceDurationSelected(): boolean {
    return this.form?.get('licenceDuration').value === 'other';
  }

  public matcher(val: any, _: any): boolean {
    return val != null;
  }

  public getTotalPrice(): any {
    return (o: any) =>
      o.pipe(
        switchMap((data: any) => {
          const pkg = data.package;

          if (!pkg) {
            return EMPTY;
          }

          const totalHours = data.totalHours;
          const packageTime = pkg.time || 0;
          const extraHours = totalHours - packageTime;
          let totalPrice = parseFloat(pkg.price?.toString() || 0);
          if (extraHours > 0) {
            totalPrice += extraHours * (pkg.extraHour || 0);
          }
          return of(totalPrice);
        }),
      );
  }

  public getTotalHours(): any {
    return (o: any) =>
      o.pipe(
        switchMap((dates: any) =>
          of(dates).pipe(
            map((date) => {
              const start = this.getDateTimeAsMoment(date.startDate);
              const end = this.getDateTimeAsMoment(date.endDate);
              return moment.duration(end.diff(start)).asHours();
            }),
          ),
        ),
      );
  }

  public matchById(val1: any, val2: any): boolean {
    return val1?.id === val2?.id;
  }

  public getCountryCode(control: FormGroup): string {
    const countries = this.store.selectSnapshot(CountriesState.countries)?.results;
    return countries.find((c) => c.id === control.get('countryId').value)?.isoCode;
  }

  public addLanguage(): void {
    this.languages.push(this.getLanguageFormGroup());
  }

  public removeLanguage(i: number): void {
    this.languages.removeAt(i);
  }

  public addCountry(): void {
    this.countries.push(this.getCountryWithCityFormGroup());
  }

  public addTalentCountry(country?: ICountry): void {
    this.talentCountries.push(this.getCountryWithCityFormGroup(null, true));
  }

  public addLicenceCountry(): void {
    this.licenceCountries.push(this.getCountryFormGroup());
  }

  public removeTalentCountry(i: number): void {
    this.talentCountries.removeAt(i);
  }

  public removeCountry(i: number): void {
    this.countries.removeAt(i);
  }

  public removeLicenceCountry(i: number): void {
    this.licenceCountries.removeAt(i);
  }

  public addMedia(): void {
    this.media.push(this.getMediaFormGroup());
  }

  public async removeMedia(i: number): Promise<void> {
    const translation = await this.translate.get('Are you sure you want to delete this image?').toPromise();
    if (confirm(translation)) {
      this.media.removeAt(i);
    }
  }

  public addDate(): void {
    this.dates.push(this.getDateFormGroup(null, this.form.get('exactDate').value));
  }

  public removeDate(i: number): void {
    this.dates.removeAt(i);
  }

  public setWorkFromHome(): void {
    this.form.get('workFromHome').setValue(!this.form.get('workFromHome').value);
  }

  private patchCategories(categories: ICategory[]): void {
    this.form.get('categoryId').setValue(this.job?.category?.id || (categories?.length > 0 ? categories[0].id : null));
  }

  private patchFilters(category: ICategory, job?: IJob): void {
    this.form.setControl('filters', new FormGroup({}));
    for (const categoryFilter of category?.filters || []) {
      this.addCategoryFilterFormGroup(categoryFilter, job);
    }
  }

  private createForm(job?: IJob): void {
    const publishingChannels = job?.publishingChannels?.split(',') ?? [];
    const gender = job?.gender?.split(';') ?? [this.talent?.gender ?? ''];
    const keywords = job?.keywords.split(';') ?? [];
    for (let i = keywords.length; i < 3; i++) {
      keywords.push('');
    }
    const licenceDurationCustom = job == null ? '1 jaar' : job?.licenceDuration;

    this.form = new FormGroup({
      id: new FormControl(job?.id ?? v4(), [Validators.required]),
      title: new FormControl(job?.title, [Validators.required]),
      paymentTypeId: new FormControl('15a42529-95ef-4ca5-b923-97b70b456016', [Validators.required]),
      packageId: new FormControl(job?.packageId),
      categoryId: new FormControl(job?.category.id, [Validators.required]),
      userId: new FormControl(
        this.currentUser.isAdmin ? job?.user.id : this.currentUser.id,
        this.currentUser.isAdmin ? [Validators.required] : [],
      ),
      talentCountries: new FormArray(
        job?.talentCountries.map((c) => this.getCountryWithCityFormGroup(c, true)) ?? [
          this.getCountryWithCityFormGroup(null, true),
        ],
      ),
      countries: new FormArray(
        job?.countries.map((j) => this.getCountryWithCityFormGroup(j)) ?? [this.getCountryWithCityFormGroup()],
      ),
      workFromHome: new FormControl(job?.workFromHome),
      workFromHomeText: new FormControl('Online (work from home)'),
      exactDate: new FormControl(job?.exactDate ?? true, [Validators.required]),
      dates: new FormArray(
        job?.dates.map((d) => this.getDateFormGroup(d, true)) ?? [this.getDateFormGroup(null, true)],
      ),
      languages: new FormArray(job?.languages.map((l) => this.getLanguageFormGroup(l)) ?? []),
      media: new FormArray(job?.media.map((m) => this.getMediaFormGroup(m)) ?? []),
      feeType: new FormControl(job?.feeType ?? this.feeTypes[0], [Validators.required]),
      rate: new FormControl(job?.rate, []),
      extraFees: new FormControl(job?.extraFees, []),
      hideRate: new FormControl(job?.hideRate, []),
      commercialUse: new FormControl(job?.commercialUse ?? true, []),
      foodIncluded: new FormControl(
        job === null || job === undefined
          ? true
          : job?.foodIncluded !== null && job?.foodIncluded !== undefined
          ? job?.foodIncluded
          : job?.foodIncluded,
      ),
      negotiable: new FormControl(job?.negotiable),
      shortDescription: new FormControl(job?.shortDescription, [Validators.required]),
      gender: new FormControl(
        [
          { value: 'male', checked: gender.includes('male') },
          { value: 'female', checked: gender?.includes('female') },
          { value: 'transgender', checked: gender?.includes('transgender') },
        ],
        [
          (control: FormControl) => {
            let hasOneChecked = false;

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (const value of control.value) {
              hasOneChecked = hasOneChecked || value.checked;
            }
            if (!hasOneChecked) {
              return { required: true };
            }
          },
        ],
      ),
      age: new FormControl([job?.startAge ?? 0, job?.endAge ?? 100]),
      keywords: new FormArray(
        keywords.map((k) => this.getKeywordsFormGroup(k)) ?? [
          this.getKeywordsFormGroup(),
          this.getKeywordsFormGroup(),
          this.getKeywordsFormGroup(),
        ],
      ),
      publishingChannels: new FormControl(
        [
          { value: 'online', checked: publishingChannels?.includes('online') },
          { value: 'print', checked: publishingChannels?.includes('print') },
          { value: 'tv-film', checked: publishingChannels?.includes('tv-film') },
        ],
        [
          (control: FormControl) => {
            let hasOneChecked = false;

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (const value of control.value) {
              hasOneChecked = hasOneChecked || value.checked;
            }
            if (!hasOneChecked) {
              return { required: true };
            }

            return null;
          },
        ],
      ),
      broadcastCountries: new FormControl(
        job == null ? 'worldwide' : job?.broadcastCountries === 'other' ? 'other' : 'worldwide',
        [Validators.required],
      ),
      licenceCountries: new FormArray(job?.licenceCountries.map((l) => this.getCountryFormGroup(l)) ?? []),
      licenceDuration: new FormControl(
        job == null ? 'other' : job?.licenceDuration === 'forever' ? 'forever' : 'other',
        [Validators.required],
      ),
      licenceDurationCustom: new FormControl(licenceDurationCustom !== 'forever' ? licenceDurationCustom : null),
      onlyFiltered: new FormControl(job?.onlyFiltered),
      promoted: new FormControl(job?.promoted),
      customDesign: new FormControl(job?.customDesign),
      filters: new FormGroup({}),
      budgetCamOrderNumber: new FormControl(job?.budgetCamOrderNumber),
      requestTalentVideo: new FormControl(job?.requestTalentVideo),
      requestTalentVideoDescription: new FormControl(job?.requestTalentVideoDescription),
      travelingCost: new FormControl(
        job === null || job === undefined ? 0.23 : job?.travelingCost !== null ? job?.travelingCost : null,
      ),
      haveTravelingCost: new FormControl(
        job === null || job === undefined ? true : job?.travelingCost !== null ? true : false,
        [Validators.required],
      ),
      maxTravelingCost: new FormControl(job?.maxTravelingCost),
      parkingCost: new FormControl(job?.parkingCost && job?.parkingCost ? true : null),
      maxParkingCost: new FormControl(job?.maxParkingCost),
    });

    this.form.get('haveTravelingCost').valueChanges.subscribe((isHaveTravelingCost: boolean) => {
      if (isHaveTravelingCost) {
        this.form.get('travelingCost').setValue(0.23);
      } else {
        this.form.get('parkingCost').setValue(null);
        this.form.get('travelingCost').setValue(null);
      }
    });

    if (this.form.get('feeType').value.slug === 'fee') {
      this.form.get('rate').setValidators([Validators.required, Validators.min(15)]);
    }

    this.form.get('licenceDuration').valueChanges.subscribe((value) => {
      this.form.get('licenceDurationCustom').updateValueAndValidity();
    });
    this.form.get('broadcastCountries').valueChanges.subscribe((value) => {
      console.log(`value updated: ${value}`);
      this.form.get('broadcastCountries').updateValueAndValidity({ emitEvent: false });
    });

    this.form.get('feeType').valueChanges.subscribe((f: IFeeType) => {
      const priceControl = this.form.get('rate');
      if (f.slug === 'fee') {
        priceControl.setValidators([Validators.required, Validators.min(15)]);
        priceControl.updateValueAndValidity();
        return;
      }
      this.form.get('extraFees').setValue(null);
      priceControl.setValue(null);
      priceControl.clearValidators();
      priceControl.updateValueAndValidity();
    });

    this.form.get('exactDate').valueChanges.subscribe(() => {
      (this.form.get('dates') as FormArray).controls = [];
      this.addDate();
    });

    this.form.valueChanges
      .pipe(
        tap((data: any) => this.calculateTotalCosts(data)),
        tap((data: any) => this.setNeedsPayment(data)),
      )
      .subscribe();

    if (job != null) {
      this.store.dispatch(new LoadCategory(job.category.id));
    }
  }

  private calculateTotalCosts(data: any): void {
    const isFeeJob = data?.feeType?.slug === 'fee';
    const isPromoted = data?.promoted === true;
    const isCustomDesign = data?.customDesign === true;

    let totalCosts = 0;
    this.directPaymentCosts = 0;
    this.talentPaymentCosts = 0;

    if (!isFeeJob) {
      this.directPaymentCosts += 5;
      totalCosts += 5;
    }

    if (isPromoted) {
      this.directPaymentCosts += 9;
      totalCosts += this.directPaymentCosts;
    }

    if (isCustomDesign) {
      this.directPaymentCosts += 25;
      totalCosts += this.directPaymentCosts;
    }

    if (isFeeJob) {
      this.talentPaymentCosts = parseFloat(data?.rate || '0');
      this.talentPaymentCosts += parseFloat(data?.extraFees || '0');
      this.talentPaymentVAT = this.talentPaymentCosts * environment.vat;
      this.talentPaymentCosts += this.talentPaymentVAT;
      totalCosts += this.talentPaymentCosts;
    }

    this.totalCosts = totalCosts;
  }

  private setNeedsPayment(data: any): void {
    if (this.job?.payment !== null && this.job?.payment !== undefined) {
      this.needsPayment = false;
      return;
    }
    if (this.totalCosts <= 0) {
      this.needsPayment = false;
      return;
    }

    if (this.isDirect && !this.currentUser.bypassPayment) {
      this.needsPayment = true;
      return;
    }
    this.needsPayment = this.directPaymentCosts > 0 || data.feeType?.slug !== 'fee';
  }

  private getKeywordsFormGroup(k?: string): FormGroup {
    return this.fb.group({
      keyword: [k || '', []],
    });
  }

  private getCountryWithCityFormGroup(c?: IJobCountry | IJobTalentCountry, withDistance: boolean = false): FormGroup {
    const countries = this.store.selectSnapshot(CountriesState.countries)?.results;
    const selectedCountry = c?.country || this.currentUser?.country || countries[0];

    const countryForm = this.fb.group({
      checked: [true],
      countryId: [selectedCountry?.id, Validators.required],
      city: [c?.city ?? ''],
      coordinates: this.fb.group({
        x: [selectedCountry.coordinates?.x, Validators.required],
        y: [selectedCountry.coordinates?.y],
      }),
    });
    if (withDistance) {
      let distance = 0;
      if (c != null) {
        distance = (c as JobTalentCountry).distance ?? 0;
      }
      countryForm.addControl('distance', new FormControl(`${distance}`, Validators.required));
    }
    countryForm.get('checked').valueChanges.subscribe((value: boolean) => {
      if (value === true) {
        countryForm.get('countryId').setValidators([Validators.required]);
        countryForm.get('countryId').updateValueAndValidity();
        if (withDistance) {
          countryForm.get('distance').setValidators([Validators.required]);
          countryForm.get('distance').updateValueAndValidity();
        }
        countryForm.get('coordinates.x').setValidators([Validators.required]);
        countryForm.get('coordinates.x').updateValueAndValidity();
      } else {
        countryForm.get('countryId').clearValidators();
        countryForm.get('countryId').updateValueAndValidity();
        if (withDistance) {
          countryForm.get('distance').clearValidators();
          countryForm.get('distance').updateValueAndValidity();
        }
        countryForm.get('coordinates.x').clearValidators();
        countryForm.get('coordinates.x').updateValueAndValidity();
      }
    });
    return countryForm;
  }

  private getCountryFormGroup(c?: ICountry): FormGroup {
    const countries = this.store.selectSnapshot(CountriesState.countries)?.results;
    return this.fb.group({
      id: [c?.id || this.currentUser?.country.id || countries[0]?.id, Validators.required],
    });
  }

  private getDateFormGroup(d?: JobDate, exactDate: boolean = true): FormGroup {
    let id = v4();
    let startDate = moment().local(true).set('hour', 9).set('minute', 0);
    let endDate = moment().local(true).set('hour', 17).set('minute', 0);

    if (!!d) {
      id = d.id;

      startDate = moment(d.start).local();
      endDate = moment(d.end).local();
    }

    const dateForm = this.fb.group({
      id: new FormControl(id, [Validators.required]),
      startDate: new FormControl(startDate, [Validators.required]),
      startTime: new FormControl(startDate, [Validators.required]),
      endDate: new FormControl(endDate, [Validators.required]),
      endTime: new FormControl(endDate, [Validators.required]),
      days: new FormControl(d?.days, exactDate ? [] : [Validators.required, Validators.min(1), Validators.max(100)]),
      hours_per_day: new FormControl(
        d?.hours_per_day,
        exactDate ? [] : [Validators.required, Validators.min(1), Validators.max(24)],
      ),
    });
    dateForm.get('startDate').valueChanges.subscribe((_) => {
      if (this.form.get('exactDate').value) {
        dateForm.get('endDate').setValue(dateForm.get('startDate').value);
      }
    });
    dateForm.valueChanges.subscribe((_) => this.validateStartAndEnd(dateForm, this.form.get('exactDate').value));

    if (!exactDate) {
      dateForm.get('endDate').valueChanges.subscribe((_) => {
        if (dateForm.get('endDate').value === null) {
          this.invalidDate = true;
        } else {
          this.invalidDate = false;
        }
      });
    }
    return dateForm;
  }

  private validateStartAndEnd(date: AbstractControl, exactDate: boolean): void {
    if (!exactDate) {
      return;
    }
    const start = this.getDateTimeAsMoment({ date: date.get('startDate').value, time: date.get('startTime').value });
    const end = this.getDateTimeAsMoment({ date: date.get('endDate').value, time: date.get('endTime').value });

    if (!start.isSameOrBefore(end, 'second')) {
      date.get('startDate').setErrors({ invalid: true });
      date.get('startTime').setErrors({ invalid: true });
      date.get('endDate').setErrors({ invalid: true });
      date.get('endTime').setErrors({ invalid: true });
      return;
    }

    date.get('startDate').setErrors(null);
    date.get('startTime').setErrors(null);
    date.get('endDate').setErrors(null);
    date.get('endTime').setErrors(null);
  }

  private getDateTimeAsMoment(value: { date: Moment; time: Moment }): Moment {
    return moment(value.date.local()).set({
      hour: value.time.local().get('hour'),
      minute: value.time.local().get('minute'),
      second: 0,
    });
  }

  private getLanguageFormGroup(l?: ILanguage): FormGroup {
    const languages = this.store.selectSnapshot(LanguagesState.languages)?.results;

    return this.fb.group({
      id: [l?.id || languages[0]?.id, Validators.required],
    });
  }

  private getMediaFormGroup(media?: IMedia): FormGroup {
    const mediaFileForm = this.fb.group({
      id: [media?.id || v4(), []],
      mediaType: [media?.mediaType || MediaType.photo, []],
      path: [media?.path, []],
      url: [media?.url, []],
      file: [null, media == null ? [Validators.required] : []],
    });

    mediaFileForm.get('file').valueChanges.subscribe(async (data) => {
      mediaFileForm.get('url').setValue(await this.fileService.readFile(data));
    });

    return mediaFileForm;
  }

  private addCategoryFilterFormGroup(filter: IFilter, job?: IJob): void {
    const jobFilters = job?.filters.filter((f: any) => f.filterId === filter.id) ?? [];
    const formControl = new FormControl();

    if (filter.type === 'checkbox') {
      const value = [];
      for (const talentProfileFilter of jobFilters) {
        value.push({
          value: talentProfileFilter.filterOption?.id,
          checked: true,
        });
      }

      formControl.setValue(value);
    }

    if (filter.type === 'slider' || filter.type === 'color') {
      const value = [jobFilters[0]?.lowValue, jobFilters[0]?.highValue];
      formControl.setValue(value);
    }
    if (filter.type === 'number') {
      const value = [jobFilters[0]?.lowValue];
      formControl.setValue(value);
    }
    if (filter.type === 'text') {
      const value = [jobFilters[0]?.value];
      formControl.setValue(value);
    }

    this.filters.addControl(filter.slug, formControl);
  }

  private conditionalValidator(predicate: any, validator: any): ValidationErrors {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return validator(formControl);
      }
      return null;
    };
  }

  private patchCountryFields(field: string, selectedCountry: ICountry): void {
    for (let i = 0; i < (this.form.get(field) as FormArray).length; i++) {
      (this.form.get(field) as FormArray).at(i).patchValue({
        countryId: selectedCountry.id,
        coordinates: {
          x: selectedCountry.coordinates?.x,
          y: selectedCountry.coordinates?.y,
        },
      });
    }
  }
}
