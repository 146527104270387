import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-job-note-detail',
  templateUrl: './mobile-job-note-detail.component.html',
  styleUrls: ['./mobile-job-note-detail.component.scss'],
})
export class MobileJobNoteDetailComponent implements OnInit {
  @ViewChild('noteDetailModal') private modalComponent: MobileModalDialogComponent;

  public noteValue = '';

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: { note: string }) {}

  ngOnInit(): void {
    console.log(this.data);
    if (this.data?.note) {
      this.noteValue = this.data.note;
    }
  }

  async openDialog(note: string) {
    this.noteValue = note;

    return await this.modalComponent.open().then(() => {
      this.noteValue = '';
    });
  }

  async closeDialog() {
    return await this.modalComponent.close();
  }
}
