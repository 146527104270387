<app-mobile-modal-dialog #appLoginModal>
  <!-- Form signIn -->
  <ng-container *ngIf="!showSignUpForm; else signUp">
    <div class="modal-body" *ngIf="!showSignUpForm; else signUp">
      <form style="text-align: center" *ngIf="signInForm" (ngSubmit)="onSubmitSignIn()" [formGroup]="signInForm">
        <div class="wrap-alert" *ngIf="message?.type">
          <app-alert [type]="message?.type">{{ message?.message }}</app-alert>
        </div>
        <input
          class="form-control"
          style="height: 50px; margin-bottom: 42px"
          type="email"
          placeholder="{{ 'Email address' | translate }}"
          formControlName="username"
          autocomplete="username" />
        <input
          class="form-control"
          style="height: 50px; margin-bottom: 42px"
          type="password"
          placeholder="{{ 'Password' | translate }}"
          formControlName="password"
          autocomplete="current-password" />
        <a (click)="forgotPassword()">{{ 'Forgot password?' | translate }}</a>
        <button type="submit" class="btn-submit" [disabled]="!signInForm.valid">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ 'Login' | translate }}</span>
        </button>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="switchFormSignUp()" class="btn btn-block">{{ 'Sign Up' | translate }}</button>
    </div>
  </ng-container>
  <!-- Form signUp -->
  <ng-template #signUp>
    <ng-container *ngIf="showSignUpSuccess">
      <div class="modal-body" style="height: 400px">
        <div class="signup-success">
          <h6 translate>Confirm your email:</h6>
          <p>{{this.signUpForm.get('email')?.value}}</p>
          <p translate>Check your email and click the 'verify' link-button to activate your account.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="close()" class="btn btn-block">{{'Close' | translate}}</button>
      </div>
    </ng-container>
    <form
      style="text-align: center"
      *ngIf="signUpForm && !showSignUpSuccess"
      (ngSubmit)="onSubmitSignUp()"
      [formGroup]="signUpForm">
      <div class="wrap-alert" *ngIf="message?.type">
        <app-alert [type]="message?.type">{{ message?.message }}</app-alert>
      </div>
      <div class="modal-body">
        <div style="height: 360px">
          <h5 class="title-sign-up"><span translate>Sign up for free</span></h5>
          <input
            class="form-control"
            style="height: 50px; margin-bottom: 42px"
            type="email"
            placeholder="{{ 'Email address' | translate }}"
            formControlName="email"
            autocomplete="username" />
          <input
            class="form-control"
            style="height: 50px; margin-bottom: 42px"
            type="password"
            placeholder="{{ 'Password' | translate }}"
            formControlName="password"
            autocomplete="current-password" />
          <div class="terms">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" formControlName="agree" class="custom-control-input custom-control" id="terms" />
              <label class="custom-control-label" for="terms">
                <span translate>By selecting Agree and continue below, I agree to Casterbee's</span>
                <a href="https://helpdesk.casterbee.nl/terms-and-conditions" target="_blank" translate>
                  Terms of Service
                </a>
                ,
                <a href="https://helpdesk.casterbee.nl/betalingsvoorwaarden" target="_blank" translate>
                  Payments Terms of Service
                </a>
                ,
                <a href="https://helpdesk.casterbee.nl/privacy-policy" target="_blank" translate>Privacy Policy</a>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn-submit" [disabled]="!signUpForm.valid">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isBusy"></span>
          <span *ngIf="!isBusy">{{ "Agree and continue" | translate }}</span>
        </button>
      </div>
    </form>
  </ng-template>
</app-mobile-modal-dialog>
<app-mobile-forgot-password-modal #forgotPasswordModal></app-mobile-forgot-password-modal>
