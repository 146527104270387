import { ICreditTransaction, IPayment, PaymentStatus } from '@core/interfaces';
import { Job, User, JobApplication, PaymentType, Invoice } from '@core/models';
import { Transform, TransformFnParams, Type } from 'class-transformer';
import { CreateTransactionDTO } from '@core/dto';
import * as moment from 'moment';
import { CreditTransaction } from './credit-transaction.model';

export class Payment implements IPayment {
  @Type(() => User)
  public user: User;
  @Type(() => JobApplication)
  public jobApplication?: JobApplication;
  @Type(() => Job)
  public job?: Job;
  @Type(() => Invoice)
  public invoice?: Invoice;
  @Type(() => PaymentType)
  public paymentType: PaymentType;
  @Transform((paymentStatus: TransformFnParams) => PaymentStatus[paymentStatus.value])
  public paymentStatus?: PaymentStatus;
  @Type(() => CreateTransactionDTO)
  public transactionDetails?: CreateTransactionDTO;
  @Type(() => CreditTransaction)
  public creditTransaction?: ICreditTransaction;
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;

  public id: string;
  public amount: number;
  public currency: string;
  public paymentDetails?: string;
  public transactionUid?: string;
  public refundRequested?: boolean;
  public refundUid?: string;
  public refundStatus?: string;

  get translatePaymentStatusKey(): string {
    return `PAYMENT_STATUS.${this.paymentStatus}`;
  }
}
