<ng-container *ngIf="showNavbar() || conditionalNavbar">
  <div class="space"></div>
  <div id="bottom-nav">
    <button (click)="openFormJob()" *ngIf="showCreateJob" class="btn-post-job">
      <img src="/assets/img/mobile/add.svg" alt="" />
    </button>
    <ul>
      <li *ngFor="let item of menus">
        <button [ngClass]="item.active ? 'active':''" *ngIf="item.type !== 'url'" (click)="actionIdentifier(item)">
          <img src="/assets/img/mobile/{{item.icon}}{{item.active?'-active':''}}.svg" alt="{{item.name}}" />
          <small translate>{{item.name}}</small>
        </button>
        <a [ngClass]="item.active ? 'active':''" *ngIf="item.type === 'url'" href="{{item.value}}">
          <img
            src="/assets/img/mobile/{{item.icon}}{{item.active?'-active':''}}.svg"
            alt="{{item.name}}"
            [ngStyle]="{'margin-bottom':item.marginBottom}" />
          <small translate>{{item.name}}</small>
        </a>
      </li>
    </ul>
  </div>
  <app-mobile-auth-modal #loginModal></app-mobile-auth-modal>
  <app-mobile-post-job-modal
    *ngIf="showCreateJob"
    (isLoading)="isLoading($event)"
    #postJobModal></app-mobile-post-job-modal>
</ng-container>
