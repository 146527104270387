import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { IUser, IUserAlbum, IUserProfile, MediaType } from '@core/interfaces';
import { Media, UserAlbum } from '@core/models';
import { plainToClass } from 'class-transformer';
import { v4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mobile-account-profile-albums',
  templateUrl: './mobile-account-profile-albums.component.html',
  styleUrls: ['./mobile-account-profile-albums.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobileAccountProfileAlbumsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MobileAccountProfileAlbumsComponent),
      multi: true,
    },
  ],
})
export class MobileAccountProfileAlbumsComponent implements ControlValueAccessor, Validator {
  @Input()
  public talentProfile: IUserProfile;

  @Input()
  public user: IUser;

  @Input()
  public modalType: string;

  public noPhotoAlbum = true;
  public noVideoAlbum = true;

  public form: FormGroup;

  public constructor(private fb: FormBuilder, private translateService: TranslateService) {}

  public get albums(): FormArray {
    return this.form.get('albums') as FormArray;
  }

  public get media(): FormArray {
    return this.albums.get('media') as FormArray;
  }

  public onChange = (_: any) => {};

  public onTouched = () => {};

  public writeValue(albums: IUserAlbum[]): void {
    const albumControls = albums.map((v: any) => this.fb.control(v));
    this.form = this.fb.group({
      albums: this.fb.array(albumControls),
    });
    if (this.form.value.albums.length === 0) {
      this.addAlbum('photo');
      this.addAlbum('video');
    } else {
      if (this.form.value.albums.filter((value) => value.albumType !== 'video')?.length <= 0) {
        this.addAlbum('video');
      }
      if (this.form.value.albums.filter((value) => value.albumType !== 'photo')?.length <= 0) {
        this.addAlbum('video');
      }
    }
    this.form.valueChanges.subscribe((data) => {
      this.onChange(data.albums);
      this.updateAlbumsButtons();
    });
    this.updateAlbumsButtons();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { invalid: true };
    }

    for (const album of this.form.value.albums) {
      for (const media of album.media) {
        if (media.link === '' && media.uploaded !== true) {
          return { uploaded: false };
        }
      }
    }
  }

  public async deleteAlbum(albumIndex: number): Promise<void> {
    const translation = await this.translateService.get('Are you sure you want to delete this album?').toPromise();
    if (confirm(translation)) {
      this.albums.removeAt(albumIndex);
      this.updateAlbumsButtons();
    }
  }

  public addAlbum(albumType: string): void {
    const album = plainToClass(UserAlbum, {
      id: v4(),
      userProfileId: this.talentProfile.id,
      title: '',
      albumType,
      media: [],
    });

    if (albumType === 'photo') {
      // album.media.push(
      //   plainToClass(Media, {
      //     id: v4(),
      //     mediaType: albumType,
      //     processed: false,
      //     uploaded: false,
      //   }),
      //   plainToClass(Media, {
      //     id: v4(),
      //     mediaType: albumType,
      //     processed: false,
      //     uploaded: false,
      //   }),
      //   plainToClass(Media, {
      //     id: v4(),
      //     mediaType: albumType,
      //     processed: false,
      //     uploaded: false,
      //   }),
      // );
    }

    this.albums.push(this.fb.control(album));

    this.updateAlbumsButtons();
  }

  private updateAlbumsButtons(): void {
    this.noVideoAlbum = this.albums.value.every((v: IUserAlbum) => v.albumType !== MediaType.video);
    this.noPhotoAlbum = this.albums.value.every((v: IUserAlbum) => v.albumType !== MediaType.photo);
  }
}
