import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-solid-button',
  templateUrl: './solid-button.component.html',
  styleUrls: ['./solid-button.component.scss'],
})
export class SolidButtonComponent implements OnInit, OnChanges {
  @Input()
  @HostBinding('class.is-busy')
  public isBusy = false;

  @Input()
  public type = 'submit';

  @Input()
  public buttonStyle:
    | 'white'
    | 'purple'
    | 'transparent'
    | 'transparent-white'
    | 'purple-blue'
    | 'gray'
    | 'orange'
    | 'black'
    | 'pink-purple-gradient'
    | 'artyclick-purple'
    | 'no-border-white'
    | 'outline-purple'
    | 'outline-black'
    | 'dark-gunmetal' = 'white';

  @Input()
  public buttonSize: 'small' | 'medium' | 'large' = 'large';

  @Input()
  @HostBinding('class.disabled')
  public isDisabled = false;

  @Output()
  public btnClick: EventEmitter<Event> = new EventEmitter();

  public buttonClass = 'button-white';

  public ngOnInit(): void {
    this.updateButtonStyle(this.buttonStyle);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('buttonStyle' in changes) {
      this.updateButtonStyle(changes.buttonStyle.currentValue);
    }
  }

  public updateButtonStyle(buttonStyle: string): any {
    switch (buttonStyle) {
      case 'purple':
        this.buttonClass = 'button-purple';
        break;
      case 'transparent':
        this.buttonClass = 'button-transparent';
        break;
      case 'transparent-white':
        this.buttonClass = 'button-transparent-white';
        break;
      case 'purple-blue':
        this.buttonClass = 'button-purple-blue';
        break;
      case 'gray':
        this.buttonClass = 'button-gray';
        break;
      case 'dark-gray':
        this.buttonClass = 'button-dark-gray';
        break;
      case 'orange':
        this.buttonClass = 'button-orange';
        break;
      case 'black':
        this.buttonClass = 'button-black';
        break;
      case 'artyclick-purple':
        this.buttonClass = 'button-artyclick-purple';
        break;
      case 'pink-purple-gradient':
        this.buttonClass = 'button-pink-purple-gradient';
        break;
      case 'artyclick-purple':
        this.buttonClass = 'button-artyclick-purple';
        break;
      case 'dark-gunmetal':
        this.buttonClass = 'button-dark-gunmetal';
        break;
      case 'no-border-white':
        this.buttonClass = 'button-no-border-white';
        break;
      case 'outline-purple':
        this.buttonClass = 'button-outline-purple';
        break;
      case 'outline-black':
        this.buttonClass = 'button-outline-black';
        break;
      case 'white':
      default:
        this.buttonClass = 'button-white';
        break;
    }
  }

  public handleClick(event: Event): void {
    if (!this.isBusy && !this.isDisabled) {
      this.btnClick.emit(event);
    }
  }
}
