import { IUserFilter } from '@core/interfaces';
import { Filter, FilterOption } from '@core/models';
import { Type } from 'class-transformer';

export class UserFilter implements IUserFilter {
  @Type(() => Filter)
  public filter: Filter;
  @Type(() => FilterOption)
  public id: string;
  public value?: string;
  public filterOption?: FilterOption;
}
