import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersService } from '@core/services';

import { SignUpStateModel } from './sign-up.state-model';

import { StartSignUp } from './sign-up.actions';
import { IUser } from '@core/interfaces/user';

@State<SignUpStateModel>({
  name: 'signUp',
  defaults: {
    user: null,
    inProgress: false,
  },
})
@Injectable()
export class SignUpState {
  public constructor(private usersService: UsersService) {}

  @Selector()
  static inProgress(state: SignUpStateModel): boolean {
    return state.inProgress;
  }

  @Selector()
  static user(state: SignUpStateModel): IUser {
    return state.user;
  }

  @Action(StartSignUp)
  public async startSignUp(ctx: StateContext<SignUpStateModel>, { user }: StartSignUp): Promise<void> {
    ctx.patchState({ user: null, inProgress: true });
    const result = await this.usersService.signUp(user).toPromise();
    ctx.patchState({ user: result, inProgress: false });
  }
}
