<section class="invoice" *ngIf="invoices !== undefined && invoices.length > 0">
  <div class="invoice-vertical">
    <a [href]="'/account/invoices/'+invoice.id" class="card-invoice" *ngFor="let invoice of invoices">
      <div class="content">
        <div class="header">
          <img [src]="previewImage(invoice)" alt="#" />
          <div>
            <p
              class="text-bold mb-1"
              *ngIf="invoice.bookingsType === 'CR' ||
                        invoice.bookingsType === 'CS'">
              {{invoice.service}}
            </p>
            <p>{{invoice.title | truncate: 100:true}}</p>
          </div>
        </div>
        <div class="body">
          <ol>
            <li>
              <span class="dot" [ngClass]="invoice.paymentColor"></span>
              {{invoice.paymentStatus | translate | truncate: 14:false}}
            </li>
            <li>{{invoice.service}}</li>
          </ol>
          <ol>
            <li *ngIf="previewJobCountry(invoice)">{{previewJobCountry(invoice)}}</li>
            <li>{{invoice.invoiceDate | date }}</li>
          </ol>
        </div>
      </div>
    </a>
  </div>
</section>
