<div class="mb-3">
  <div [formGroup]="formGroup">
    <span class="heading-section">{{type}}</span>
    <div class="input-group rounded-input" [ngClass]="disabled?'disabled':''">
      <input
        class="form-control"
        placeholder="{{
          (
            type == 'kilometers'
              ? 'Ex. Driving to & from location'
              : type == 'parking'
                ? 'Ex. How long your parked the car'
                : 'Ex. Transportation by train'
          )
          | translate
        }}"
        formControlName="description" />
    </div>

    <div style="display: flex; gap: 20px; margin-top: 20px">
      <div *ngIf="type === 'kilometers' || type === 'parking'">
        <div class="input-group rounded-input" [ngClass]="disabled?'disabled':''" *ngIf="type == 'kilometers'">
          <input class="form-control" placeholder="km" type="number" step="0.1" formControlName="distance" />
        </div>
        <div class="input-group rounded-input" [ngClass]="disabled?'disabled':''" *ngIf="type == 'parking'">
          <input
            class="form-control"
            placeholder="{{'EST Hours > Duration' | translate}}"
            type="number"
            step="0.1"
            formControlName="time" />
        </div>
      </div>
      <div style="max-width: 48%">
        <div class="input-group rounded-input" [ngClass]="{disabled:disabled, readOnly:type == 'kilometers'}">
          <input
            type="number"
            [ngClass]="{'input-euro':!disabled}"
            class="form-control"
            formControlName="price"
            [readonly]="type == 'kilometers'" />
        </div>
      </div>
    </div>
    <div *ngIf="!disabled">
      <div class="action">
        <button class="remove-button" *ngIf="removeBtn" type="button" (click)="remove.emit(formGroup.get('id').value)">
          Remove
        </button>
        <button class="add-more-button" *ngIf="addBtn" type="button" (click)="copyForm.emit(formGroup)">
          + Add More
        </button>
      </div>
    </div>
  </div>
</div>
