import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { Job, JobApplication, TravelExpense } from '@core/models';
import { IInvoiceUserList } from '@core/interfaces/invoice-job';
import { JobApplicationStatus } from '@core/interfaces';
import { InvoicesService, JobApplicationsService } from '@core/services';
import { MobileCompleteJobEditModalComponent } from '../mobile-complete-job-edit-modal/mobile-complete-job-edit-modal.component';
import { Store } from '@ngxs/store';
import { MarkJobCompleted } from '@core/states';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileCompleteJobNoteModalComponent } from '../mobile-complete-job-note-modal/mobile-complete-job-note-modal.component';
import { MobileCompleteJobConfirmationModalComponent } from '../mobile-complete-job-confirmation-modal/mobile-complete-job-confirmation-modal.component';

@Component({
  selector: 'app-mobile-complete-job-modal',
  templateUrl: './mobile-complete-job-modal.component.html',
  styleUrls: ['./mobile-complete-job-modal.component.scss'],
})
export class MobileCompleteJobModalComponent implements OnInit {
  @ViewChild('appCompleteJobModal') private modalComponent: MobileModalDialogComponent;
  @ViewChild('editInvoiceModal') private editInvoice: MobileCompleteJobEditModalComponent;
  @ViewChild('editNoteModal') private editNote: MobileCompleteJobNoteModalComponent;
  @ViewChild('confirmationDeleteInvoiceModal')
  private confirmationDeleteModal: MobileCompleteJobConfirmationModalComponent;
  @ViewChild('confirmationSubmitInvoiceModal')
  private confirmationSubmitModal: MobileCompleteJobConfirmationModalComponent;

  public showSuccessMessage = false;
  userInvoiceList: IInvoiceUserList[] = [];
  loader = true;
  public jobData: Job;
  public noteValue = '';
  public isBusy = false;
  public selectedInvoiceTmp: IInvoiceUserList | null;
  protected readonly JobApplicationStatus = JobApplicationStatus;

  constructor(
    private jobsApplicationsService: JobApplicationsService,
    private invoiceService: InvoicesService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  get getTotalFee() {
    let total = 0;
    for (const element of this.userInvoiceList) {
      if (!element.deleted) {
        total += element.totalFee;
        total += element.totalBuyout;
      }
    }
    return total;
  }
  get getTotalTravelCost() {
    let total = 0;
    for (const element of this.userInvoiceList) {
      if (!element.deleted) {
        if (element.isPending) {
          return 'Pending';
        } else {
          total += element.travelCostAmount;
        }
      }
    }
    return `€${total}`;
  }

  get isSubmitDisabled() {
    // return this.userInvoiceList.filter((value) => !value.deleted).length <= 0;
    return false;
  }

  async openDialog(jobData: Job): Promise<void> {
    this.jobData = jobData;
    if (this.jobData?.jobApplications?.length >= 1) {
      this.loader = true;

      const lastIndex = this.jobData.jobApplications.length - 1;
      for (const [index, element] of this.jobData.jobApplications.entries()) {
        this.showRequestedTravelCosts(element);
        if (lastIndex === index) {
          this.loader = false;
        }
      }
    } else {
      this.loader = false;
    }

    return await this.modalComponent.open().then(() => {
      this.loader = true;
      this.userInvoiceList = [];
    });
  }

  public close(goBack?: boolean) {
    this.modalComponent.close();

    if (goBack) {
      this.goBack();
    }
  }

  public openInvoiceForm(data?: any, index?: number) {
    // const dialogRef = this.dialog.open(InvoiceJobFormComponent, { data: { ...data, index } });
    this.editInvoice.openDialog({ ...data, index, jobData: this.jobData });
  }

  public openNoteForm() {
    // const dialogRef = this.dialog.open(InvoiceJobFormComponent, { data: { ...data, index } });
    this.editNote.openDialog(this.noteValue);
  }

  public saveNote(value) {
    this.noteValue = value;
  }

  public insertInvoice(value: any) {
    if (value?.data) {
      const isUserExist = this.userInvoiceList.find((filteredData) => {
        return filteredData.talentSelected?.id === value?.data?.talentSelected?.id;
      });
      if (isUserExist) {
        const indexPosition = this.userInvoiceList.indexOf(isUserExist);
        this.userInvoiceList[indexPosition] = value?.data;
      } else {
        if (value?.index) {
          this.userInvoiceList[value?.index] = value?.data;
        } else {
          this.userInvoiceList.push({ ...value?.data, isPending: true });
        }
      }
    }
  }

  public deleteUserInvoice() {
    this.confirmationDeleteModal.closeDialog();
    this.insertInvoice({
      data: { ...this.selectedInvoiceTmp, deleted: true },
    });
    this.selectedInvoiceTmp = null;
  }

  public deleteUserConfirmation(data: IInvoiceUserList) {
    this.selectedInvoiceTmp = data;
    this.confirmationDeleteModal.openDialog(
      'Are you sure you want to delete this talent? Please only delete when Talent did not showed up/sick or this was already manually invoiced by Casterbee.',
    );
  }

  public submitConfirmation() {
    this.confirmationSubmitModal.openDialog(
      'When travel cost is enabled, Talents get a notification to fill this in. You get a notification to approve/reject the travel cost. After that the invoice is send to you.',
      'Is everything checked?',
    );
  }

  public onSubmit() {
    this.confirmationSubmitModal.closeDialog();
    this.isBusy = true;
    const invoiceItem = this.userInvoiceList.map((value) => {
      return {
        applicantId: value.applicantDataTemp?.id || null,
        talentName: value.talentSelected.profileName,
        fee: value.totalFee,
        buyOut: value.totalBuyout,
        tc: value.travelCost,
        tcAmount: value.travelCostAmount,
        tcMaxAmount: value.travelCostMaxAmount,
        pc: value.parkingCost,
        // pcAmount: value.parkingCostAmount,
        pcMaxAmount: value.parkingCostMaxAmount,
        userId: value.talentSelected?.id,
        reason: null,
        reason_type: value.deleted ? 'Other' : null,
      };
    });
    const payload = {
      jobId: this.jobData.id,
      invoiceItems: invoiceItem,
      notes: this.noteValue,
    };
    this.invoiceService.createJobInvoice(payload).subscribe((resp) => {
      this.completeJob();
    });
  }

  public async completeJob(): Promise<void> {
    this.isBusy = false;
    this.showSuccessMessage = true;

    try {
      await this.store.dispatch(new MarkJobCompleted(this.jobData.id)).toPromise();
      this.showSuccessMessage = true;
      this.isBusy = false;
    } catch (error) {
      console.error('Completed jobs failed', error);
    }
  }

  public async goBack(): Promise<void> {
    const status = this.route.snapshot.paramMap.get('status');
    await this.router.navigate([`/account/jobs/status/${status}`]);
  }

  public async showRequestedTravelCosts(applicantData: JobApplication) {
    const jobApplication: JobApplication = await this.jobsApplicationsService
      .getJobApplication(applicantData.id)
      .toPromise();

    switch (jobApplication.status) {
      case JobApplicationStatus.completed:
      case JobApplicationStatus.travelCostRejected:
      case JobApplicationStatus.travelCostPending:
      case JobApplicationStatus.travelCostApproved:
      case JobApplicationStatus.confirmed:
      case JobApplicationStatus.open:
      case JobApplicationStatus.refunded:
        this.userInvoiceList.push({
          applicantDataTemp: jobApplication,
          talentSelected: applicantData.user,
          totalFee: this.jobData?.rate || 0,
          totalBuyout: this.jobData?.extraFees || 0,
          travelCost: this.jobData.hasTravelCosts && this.jobData.travelingCost > 0,
          travelCostAmount:
            this.jobData.hasTravelCosts && this.jobData.travelingCost > 0 ? this.totalPrice(jobApplication) || 0.23 : 0,
          travelCostMax: false,
          travelCostMaxAmount: 0,
          parkingCost: this.jobData.parkingCost || false,
          parkingCostAmount: 0,
          parkingCostMax: false,
          parkingCostMaxAmount: 0,
          deleted: false,
          isPending:
            (jobApplication.status === JobApplicationStatus.confirmed && this.totalPrice(jobApplication) <= 0) ||
            (this.jobData.hasTravelCosts &&
              this.jobData.travelingCost > 0 &&
              jobApplication.status === JobApplicationStatus.travelCostRejected),
        });
        break;

      default:
        break;
    }
  }

  public totalPrice(jobApplication: JobApplication): number {
    return jobApplication?.travelExpenses.reduce((prev: number, curr: TravelExpense) => prev + curr.price, 0);
  }
}
