<div class="d-flex flex-column w-100 text-white p-5">
  <h2 class="mb-2" translate>Write a review?</h2>
  <p translate>Tell us about your experience.</p>

  <form [formGroup]="form">
    <label class="d-block">
      <span class="d-inline-block w-50" translate>Communication</span>
      <ngb-rating [max]="5" [formControl]="form.get('communication')"></ngb-rating>
    </label>
    <label class="d-block">
      <span class="d-inline-block w-50" translate>Attitude</span>
      <ngb-rating [max]="5" [formControl]="form.get('attitude')"></ngb-rating>
    </label>
    <label class="d-block">
      <span class="d-inline-block w-50" translate>Overall rating</span>
      <ngb-rating [max]="5" [formControl]="form.get('overallRating')"></ngb-rating>
    </label>
    <app-textarea
      class="bg-gray w-100 d-flex flex-fill border-0 rounded-sm my-3"
      placeholder="Comment"
      formControlName="comment"></app-textarea>
  </form>

  <app-button (click)="saveReview()" [isDisabled]="!this.form.valid" buttonStyle="purple" class="mb-3">
    <div class="px-2" translate>Save</div>
  </app-button>
  <app-button (click)="closeModal()" buttonStyle="white-outline" class="mb-3">
    <div class="px-2" translate>Cancel</div>
  </app-button>
  <app-button (click)="deleteReview()" buttonStyle="red" class="mb-3" *ngIf="review">
    <div class="px-2" translate>Delete review</div>
  </app-button>
</div>
