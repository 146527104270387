import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeroCarouselComponent } from './components/hero-carousel/hero-carousel.component';
import { CategoriesCarouselComponent } from './components/categories-carousel/categories-carousel.component';
import { CategoryTalentsCarouselComponent } from './components/category-talents-carousel/category-talents-carousel.component';
import { GenericModule } from '../generic/generic.module';
import { CardsModule } from '../cards/cards.module';
import { TalentsCarouselComponent } from './components/talents-carousel/talents-carousel.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [
  HeroCarouselComponent,
  CategoriesCarouselComponent,
  CategoryTalentsCarouselComponent,
  TalentsCarouselComponent,
];

@NgModule({
  declarations: components,
  imports: [CommonModule, RouterModule, GenericModule, CardsModule, TranslateModule],
  exports: components,
})
export class CarouselsModule {}
