import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { ViewSwitcherViewComponent } from '../view-switcher-view/view-switcher-view.component';

@Component({
  selector: 'app-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss'],
})
export class ViewSwitcherComponent implements AfterContentInit {
  @Output()
  public switched = new EventEmitter();

  @ContentChildren(ViewSwitcherViewComponent)
  public views: QueryList<ViewSwitcherViewComponent>;

  @Input()
  public selectedIndex: number;

  public activeIndex: number;

  public buttons = [];

  public ngAfterContentInit(): void {
    this.setupButtons();
    this.views?.changes.subscribe(() => this.setupButtons());
  }

  public activateNext(): void {
    let newIndex;
    if (this.activeIndex == null && this.selectedIndex != null) {
      newIndex = this.activeIndex = this.selectedIndex;
    } else {
      newIndex = this.activeIndex < this.buttons.length - 1 ? this.activeIndex + 1 : 0;
    }

    this.activeIndex = newIndex;
    const views = this.views?.toArray() || [];
    for (const i in views) {
      if (views[i] != null) {
        views[i].hidden = parseInt(i, 10) !== newIndex;
      }
    }

    this.switched.emit(this.activeIndex);
  }

  private setupButtons(): void {
    this.buttons = (this.views.toArray() || []).map((view) => view.title);
    this.activateNext();
  }
}
