import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TransactionsService } from '@core/services';

import { FetchTransactions } from './transactions.actions';
import { TransactionsStateModel } from './transactions.state-model';
import { PaginatedResults, Transaction } from '@core/models';

@State<TransactionsStateModel>({
  name: 'transactions',
  defaults: {
    transactions: null,
  },
})
@Injectable()
export class TransactionsState {
  public constructor(private transactionsService: TransactionsService) {}

  @Selector()
  static transactions(state: TransactionsStateModel): PaginatedResults<Transaction> {
    return state.transactions;
  }

  @Action(FetchTransactions)
  public async fetchTransactions(
    ctx: StateContext<TransactionsStateModel>,
    { page, pageSize, sortColumn, sortDirection }: FetchTransactions,
  ): Promise<void> {
    const transactions = await this.transactionsService
      .getTransactions(page, pageSize, sortColumn, sortDirection)
      .toPromise();
    ctx.patchState({ transactions });
  }
}
