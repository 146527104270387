import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('loaded', [
      state(
        'false',
        style({
          opacity: 0,
        }),
      ),
      state(
        'true',
        style({
          opacity: 1,
        }),
      ),
      transition('0 => 1', animate('600ms')),
    ]),
  ],
})
export class ImageComponent implements OnChanges, AfterViewInit {
  @ViewChild('final')
  public finalEl: ElementRef;

  @Input()
  public image: string | File;

  @Input()
  public lazy = true;

  public inputImage: string;
  private isFile = false;

  constructor(private cd: ChangeDetectorRef, private fileService: FileService) {}

  ngAfterViewInit(): void {
    this.setSource(this.image as string);
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if ('image' in changes) {
      this.isFile = changes.image.currentValue instanceof File;
      this.image = changes.image.currentValue;

      if (this.isFile) {
        const base64 = await this.readFile(changes.image.currentValue);
        if (base64) {
          this.setSource(base64);
          return;
        }
      }

      if (!this.lazy) {
        const source = this.getSizedUrl(this.image.toString());
        this.loadImage(source);
      }
    }
  }

  private setSource(source: string): void {
    if (!this.finalEl) {
      return;
    }

    this.finalEl.nativeElement.loading = this.lazy ? 'lazy' : 'eager';
    this.finalEl.nativeElement.src = source;
    this.cd.detectChanges();
  }

  private loadImage(source: string): void {
    if (!this.image) {
      return;
    }

    this.setSource(source);
  }

  private getSizedUrl(url: string, size?: number): string {
    try {
      const result = new URL(url);
      result.searchParams.delete('size');

      if (size) {
        result.searchParams.set('size', `${size}`);
      }

      return result.toString();
    } catch {
      return url;
    }
  }

  private readFile(file: File): Promise<string> {
    return this.fileService.readFile(file);
  }
}
