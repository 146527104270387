import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from '@core/models';
import { UserProfilesService } from '@core/services';
import { VoiceService } from '@core/services/voice.service';

@Component({
  selector: 'app-mobile-account-profile-voice',
  templateUrl: './mobile-account-profile-voice.component.html',
  styleUrls: ['./mobile-account-profile-voice.component.scss'],
})
export class MobileAccountProfileVoiceComponent implements OnInit {
  @Input()
  public profile: UserProfile;

  @Output()
  public updateProfile = new EventEmitter<any>(null);

  public isBusy = false;

  public enableDelete = false;

  constructor(
    private voiceService: VoiceService,
    private userProfileService: UserProfilesService,
    private router: Router,
  ) {
    if (this.router.url.includes('/account/roles')) {
      this.enableDelete = true;
    }
  }

  ngOnInit(): void {}

  public deleteVoice(voiceId: string): void {
    this.isBusy = true;
    this.voiceService.deleteVoice(voiceId).subscribe(() => {
      this.userProfileService.getEditProfileById(this.profile.id).subscribe((response) => {
        this.updateProfile.emit(response);
        this.isBusy = false;
      });
    });
  }
}
