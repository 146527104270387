import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { RoleGuard } from './role-guard';
import { Router } from '@angular/router';

@Injectable()
export class CasterGuard extends RoleGuard {
  constructor(store: Store, router: Router) {
    super(['caster'], store, router);
  }
}
