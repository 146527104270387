<app-mobile-modal-dialog #appLanguangeModal>
  <div class="modal-body">
    <ng-container *ngFor="let language of languages">
      <div class="text-center">
        <button type="button" class="btn btn-transprent" (click)="changeLanguage(language)">{{language.label}}</button>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="close()" class="btn btn-block">Close</button>
  </div>
</app-mobile-modal-dialog>
