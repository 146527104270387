import { Review } from '@core/models';

export class LoadReviews {
  public static readonly type = '[Reviews] Load reviews';

  public constructor(
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'asc',
  ) {}
}

export class FindReview {
  public static readonly type = '[Reviews] Find review by id';

  public constructor(public talentId: string) {}
}

export class FindReviewByJob {
  public static readonly type = '[Reviews] Find review by job';

  public constructor(public talentId: string, public reviewerId: string, public jobId: string) {}
}

export class SaveReview {
  public static readonly type = '[Reviews] Save review';

  public constructor(public review: Review) {}
}

export class DeleteReview {
  public static readonly type = '[Reviews] Delete review';

  public constructor(public id: string) {}
}

export class DeleteAdminReview {
  public static readonly type = '[Reviews] Delete review by admin';

  public constructor(
    public id: string,
    public page: number = 0,
    public pageSize: number = 25,
    public sortColumn: string = 'created',
    public sortDirection: string = 'asc',
  ) {}
}
