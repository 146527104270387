<div class="input-group">
  <input
    #input
    (click)="pickerDate.open()"
    (dateChange)="onValueChange($event.target.value)"
    (blur)="onBlur()"
    *ngIf="!isReadOnly"
    [matDatepicker]="pickerDate"
    [placeholder]="placeholder"
    class="form-control"
    readonly
    matInput />

  <div *ngIf="isReadOnly" class="form-control">{{ value.toDate() | date: 'dd-MM-YYYY' }}</div>

  <div class="input-group-append">
    <mat-datepicker-toggle *ngIf="!isReadOnly" [for]="pickerDate" class="d-flex align-items-center" matSuffix>
      <app-icon
        [fill]="iconColor"
        [stroke]="iconColor"
        icon="/assets/img/calendar.svg"
        matDatepickerToggleIcon></app-icon>
    </mat-datepicker-toggle>
    <div *ngIf="isReadOnly" class="d-flex align-items-center mr-3">
      <app-icon [fill]="iconColor" [stroke]="iconColor" icon="/assets/img/calendar.svg"></app-icon>
    </div>
  </div>

  <mat-datepicker #pickerDate></mat-datepicker>
</div>
