<div class="p-3 custom-border rounded container text-white" *ngIf="membership$ | async as membership">
  <ng-container *ngIf="form">
    <form (ngSubmit)="submit()" [formGroup]="form" class="form">
      <h1 class="text-center mb-4" translate>Remove items</h1>
      <p class="text-center" translate>
        In order to downgrade your membership, you need to remove the surplus items from your profile
      </p>

      <div class="row my-4" *ngIf="profiles$ | async as userProfiles" formArrayName="profiles">
        <div class="col-2">
          <h2 translate>Profiles</h2>
          <small [ngStyle]="{ color: profiles.invalid ? 'red' : 'white' }">
            {{ 'maxProfiles' | translate: { max: membership.talent_profiles } }}
          </small>
        </div>
        <div class="col-10">
          <div class="custom-border p-3 rounded">
            <div
              *ngFor="let userProfile of userProfiles; let i = index"
              [formGroupName]="i"
              class="container-fluid mb-4">
              <div class="row mb-3">
                <label class="d-flex" formGroupName="id">
                  <app-checkbox formControlName="checked" class="my-auto mr-2"></app-checkbox>
                  <h3>
                    {{ userProfile.category.translateKey | translate }}&nbsp;
                    <span translate>Role</span>
                  </h3>
                </label>
              </div>
              <ng-container formGroupName="albums" *ngIf="show(i)">
                <ng-container *ngFor="let profileAlbum of userProfile.albums">
                  <app-checkbox-group [formControlName]="profileAlbum.albumType" class="row">
                    <strong class="col-2">
                      <span class="text-capitalize">{{ profileAlbum.albumType | translate }}</span>
                      <br />
                      <small
                        *ngIf="profileAlbum.albumType == 'photo'"
                        [ngStyle]="{ color: profile(i).get('albums').invalid ? 'red' : 'white' }">
                        {{ 'maxItems' | translate: { max: membership.portfolio_pictures } }}
                      </small>
                      <small
                        *ngIf="profileAlbum.albumType == 'video'"
                        [ngStyle]="{ color: profile(i).get('albums').invalid ? 'red' : 'white' }">
                        {{ 'maxItems' | translate: { max: membership.videos } }}
                      </small>
                    </strong>
                    <div class="col-10 mb-3">
                      <div class="container-fluid">
                        <div class="bg-gray rounded custom-border row pt-3">
                          <div *ngFor="let media of profileAlbum.media" class="col-4 mb-3">
                            <label class="h-100">
                              <app-image [image]="media.thumbUrl" class="flex-grow-1 d-block rounded h-100"></app-image>
                              <app-checkbox
                                [value]="media.id"
                                class="mx-auto d-block mt-n4 position-relative"></app-checkbox>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </app-checkbox-group>
                </ng-container>
              </ng-container>
              <div class="row" *ngIf="userProfile.packages.length && show(i)">
                <strong class="col-2">
                  <span translate>Packages</span>
                  <br />
                  <small [ngStyle]="{ color: profile(i).get('packages').invalid ? 'red' : 'white' }">
                    {{ 'maxPackages' | translate: { max: membership.packages } }}
                  </small>
                </strong>
                <div class="col-10">
                  <app-checkbox-group formControlName="packages" class="d-block">
                    <div class="row">
                      <div class="mb-3 col-6" *ngFor="let package of userProfile.packages">
                        <label class="p-3 custom-border rounded h-100 bg-gray d-block">
                          <div>
                            <h3 class="h5 mb-2">{{ package.title }}</h3>
                            <div class="float-right mt-1 mr-1">
                              <app-checkbox [value]="package.id" class="mr-2"></app-checkbox>
                            </div>
                          </div>
                          <h4 class="h4 mb-2">
                            <ng-container *ngIf="package.feeType.slug === 'fee'; else showName">
                              <app-money [amount]="package.price" [currency]="package.currency"></app-money>
                            </ng-container>
                            <ng-template #showName>{{ package.feeType.translateKey | translate }}</ng-template>
                          </h4>
                          <p class="mb-2">{{ package.description }}</p>

                          <div *ngIf="package.feeType.slug === 'fee'">
                            <div translate>Shooting time</div>
                            {{ package.time }} hours

                            <div class="text-muted">
                              <small translate>
                                Price per additional hour:
                                <app-money [amount]="package.extraHour" [currency]="package.currency"></app-money>
                              </small>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </app-checkbox-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2">
          <h2 translate>Decks</h2>
          <small [ngStyle]="{ color: decks.invalid ? 'red' : 'white' }">
            {{ 'maxDecks' | translate: { max: membership.decks } }}
          </small>
        </div>
        <div class="col-10">
          <div class="custom-border p-3 rounded">
            <app-checkbox-group formControlName="decks" class="row" *ngIf="decks$ | async as decks">
              <div *ngFor="let deck of decks" class="col-4">
                <label class="bg-gray p-3 custom-border rounded position-relative">
                  <strong class="d-inline-block mb-2">{{ deck.title }}</strong>
                  <app-checkbox
                    [value]="deck.id"
                    class="d-inline-block position-absolute"
                    style="right: 1rem"></app-checkbox>
                  <app-image class="flex-grow-1 d-block rounded h-100" [image]="deck.cover?.url"></app-image>
                </label>
              </div>
            </app-checkbox-group>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4 mx-auto">
          <app-button buttonStyle="orange" [isDisabled]="form.invalid" [isBusy]="isBusy">
            <button
              type="submit"
              [disabled]="form.invalid"
              [isBusy]="isBusy"
              class="w-100 h-100 bg-transparent border-0 text-white"
              translate>
              Update membership
            </button>
          </app-button>
        </div>
      </div>
    </form>
  </ng-container>
</div>

<div
  *ngIf="isBusy"
  class="spinner-overlay position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
  <app-spinner></app-spinner>
</div>
