import { Component, OnInit, ViewChild } from '@angular/core';
import { MobileModalDialogComponent } from '../mobile-modal-dialog/mobile-modal-dialog.component';
import { CreditState, FetchCreditPackages } from '@core/states';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { JobQuotaDTO } from '@core/dto';
import { CreditPackage } from '@core/models';
import { PaymentsService, UsersService } from '@core/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-mobile-credit-modal',
  templateUrl: './mobile-credit-modal.component.html',
  styleUrls: ['./mobile-credit-modal.component.scss'],
})
export class MobileCreditModalComponent implements OnInit {
  @ViewChild('mobileCreditModal') public modalComponent: MobileModalDialogComponent;

  @Select(CreditState.packages)
  public creditPackages$: Observable<CreditPackage[]>;

  public isSuccess: boolean;
  public packageSelected?: CreditPackage;
  public jobQuota?: JobQuotaDTO;

  constructor(private store: Store, private paymentService: PaymentsService, private quotaService: UsersService) {}

  ngOnInit(): void {}

  public async openDialog() {
    this.quotaService.fetchJobQuota().subscribe((res) => {
      this.jobQuota = res;
    });

    this.store.dispatch(new FetchCreditPackages());
    return await this.modalComponent.open().then(() => {
      this.isSuccess = false;
      this.jobQuota = undefined;
    });
  }

  public close() {
    this.modalComponent.close();
  }

  public async buyCredit(creditPackage: CreditPackage): Promise<void> {
    const paymentResult = await this.paymentService
      .createCreditPayment(creditPackage.id, `${environment.baseUrl}/account/details/dashboard`)
      .toPromise();
    if (!!paymentResult) {
      window.location.href = paymentResult.oppTransaction.redirect_url;
    }

    this.packageSelected = creditPackage;
    this.isSuccess = true;
  }
}
