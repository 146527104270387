import { UpdateMembershipDto } from '@core/dto/membership.dto';

export class LoadMemberships {
  public static readonly type = '[Membership] Find memberships';

  public constructor() {}
}

export class LoadMembership {
  public static readonly type = '[Membership] Load membership';

  public constructor(public membershipId: string) {}
}

export class UpdateUserMembership {
  public static readonly type = '[Membership] Update a users membership';

  public constructor(public userId: string, public membership: UpdateMembershipDto) {}
}
