import Splide from '@splidejs/splide';

export const slideAndScaleTransition = (splide: Splide, components: any) => {
  let list: HTMLElement;
  let endCallback: any;

  const applyStyle = (elements: any[], styles: any) => {
    for (const el of elements) {
      if (!el) {
        continue;
      }
      for (const styleName of Object.keys(styles)) {
        el.style[styleName] = styles[styleName];
      }
    }
  };

  const getSlides = (index: number, includeClones?: boolean) => {
    const clones = components.Elements.getSlides(true).filter((s: any) => s.isClone);
    const destSlide = components.Elements.getSlide(index);

    if (!includeClones) {
      return [destSlide];
    }

    const destClones = clones.filter((c: any) => c.realIndex === destSlide.index);
    return [...destClones, destSlide];
  };

  return {
    mount: () => {
      list = components.Elements.list;

      splide.on(
        'transitionend',
        (e: any) => {
          if (e.target === list && endCallback) {
            components.Elements.track.parentNode.classList.remove('animating');
            endCallback();
            return;
          }

          applyStyle([e.target], {
            transform: '',
            transition: '',
          });
        },
        list,
      );
    },
    start: (destIndex: number, newIndex: number, prevIndex: number, coord: any, done: any) => {
      const options = splide.options;
      const edgeIndex = components.Controller.edgeIndex;
      let speed = options.speed;
      endCallback = done;

      if (splide.is('slide')) {
        if ((prevIndex === 0 && newIndex >= edgeIndex) || (prevIndex >= edgeIndex && newIndex === 0)) {
          speed = options.rewindSpeed || speed;
        }
      }

      const destSlides = getSlides(destIndex, true);
      const prevSlides = getSlides(prevIndex, true);

      components.Elements.track.parentNode.classList.add('animating');

      applyStyle(
        prevSlides.map((s) => s.slide),
        {
          transition: `transform ${speed}ms ${options.easing}`,
          transform: `scaleY(.9)`,
        },
      );

      applyStyle(
        destSlides.map((s) => s.slide),
        {
          transition: `transform ${speed}ms ${options.easing}`,
          transform: `scaleY(1)`,
        },
      );

      applyStyle([list], {
        transition: `transform ${speed}ms ${options.easing}`,
        transform: `translate(${coord.x}px,${coord.y}px)`,
      });
    },
  };
};
