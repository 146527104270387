<div [ngStyle]="{ 'height.px': tileSize?.height }">
  <app-carousel *ngIf="categories" [items]="categories" [options]="options" class="d-block">
    <ng-template #carouselSlideTpl let-item="item" let-visible="visible">
      <a
        [queryParams]="{ categorySlug: item.slug }"
        [routerLink]="['/talents']"
        class="d-block"
        [ngStyle]="{ 'width.px': tileSize?.width, 'height.px': tileSize?.height }">
        <app-image
          [ngStyle]="{ 'width.px': imageSize?.width, 'height.px': imageSize?.height }"
          class="cover w-100 h-100 position-absolute rounded-sm"
          image="/assets/img/categories/square-tiles/{{ item.slug }}.jpg"></app-image>
        <div class="slide-content d-flex justify-content-center">
          <h2 class="mt-auto h5 px-2 pb-2">{{ item.translateKey | translate }}</h2>
        </div>
      </a>
    </ng-template>
  </app-carousel>
</div>
