import { BaseModel } from './base-model.model';

import { Type } from 'class-transformer';
import { IInvoiceText } from '@core/interfaces';
import { User } from '@core/models/user.model';
import { Invoice } from '@core/models/invoice.model';

export class InvoiceText extends BaseModel implements IInvoiceText {
  @Type(() => User)
  public user?: User;

  @Type(() => Invoice)
  public invoice?: Invoice;

  public id: string;
  public userId: string;
  public invoiceId: string;
  public invoiceText?: string;
}
