<div *ngIf="job" class="wrapper rounded text-white px-2 px-md-5 py-4 d-flex" [ngClass]="getGradientBg()">
  <div style="display: flex; justify-content: space-between; gap: 200px" class="mx-0 w-100">
    <div class="mb-4 mb-lg-0 cursor-pointer job-desc d-flex flex-column" (click)="openModal()">
      <div *ngIf="theme !== 0" style="display: flex; gap: 5px; align-items: center; margin-bottom: 10px">
        <img style="width: 18px" src="/assets/img/star-icon.svg" alt="" />
        <span class="font-22" style="margin-bottom: 0">Promoted</span>
      </div>
      <p class="font-22 job-title">{{ job.title | truncate: 120:true }}</p>
      <div style="display: flex; gap: 10px; align-items: center">
        <p class="font-22">
          <ng-container *ngIf="job.feeType.slug === 'fee'">
            <span *ngIf="job.hideRate" translate>{{ job.hideRateFee }}</span>
            <!-- <app-money *ngIf="!job.hideRate" [amount]="job.totalRate"></app-money> -->
            <span *ngIf="!job.hideRate" class="price-tag">€</span>
            &nbsp;
            <span *ngIf="!job.hideRate">&nbsp;{{ job.totalRate | currency: ' ' : 'symbol' : '1.0-0' }}</span>
            &nbsp;
          </ng-container>
        </p>
        <p translate class="font-22 font-regular" *ngIf="job.negotiable">| &nbsp; {{ 'Negotiable' | translate }}</p>
        <p translate class="font-22 font-regular">| &nbsp; {{ job.category.translateKey | translate}}</p>
      </div>
      <div
        class="d-flex flex-row font-22 font-regular"
        style="margin-bottom: 20px; margin-top: 10px; font-size: 20px !important">
        <div *ngFor="let gender of job?.formattedGenders; let i = index" translate>
          <ng-container *ngIf="i > 0">,</ng-container>
          {{ gender }}
          <ng-container *ngIf="i+1 === job?.formattedGenders.length">|&nbsp;</ng-container>
        </div>
        <div>
          {{ job.startAge }} - {{ job.endAge }}
          <span translate>Years old</span>
        </div>
      </div>
      <div class="font-22 font-regular d-flex flex-grow-1" style="font-size: 20px !important">
        {{ job.shortDescription | truncate: 120:true }}
      </div>
      <div class="footer d-flex font-regular" style="margin-top: 20px; font-size: 20px; font-weight: 300">
        {{ job.firstDate | dateRange: { exact: job.exactDate, withTime: false, dateFormat: "DD MMM 'YY" } }} |
        <ng-container *ngIf="job.countries.length > 0">
          <ng-container *ngIf="job.countries[0].city">{{ job.countries[0].city }},</ng-container>
          {{ job.countries[0].country.isoCode }}
        </ng-container>
      </div>
    </div>
    <div
      style="max-width: 220px; height: fit-content"
      class="mt-4 mt-md-0 d-flex align-items-center justify-content-center">
      <div
        style="border: 3px solid white; border-radius: 20px"
        class="d-block align-middle w-100 w-md-auto"
        *ngIf="job.media.length == 0">
        <div class="job-title-img-container alt-img-container">
          <img [src]="('/assets/img/categories/icons/' + job.category.slug + '.svg')" alt="" />
        </div>
      </div>
      <div *ngIf="job.media.length > 0" class="job-banner-container">
        <app-image [image]="job.media[0].url" class="job-banner-image cover d-block rounded-sm"></app-image>
      </div>
    </div>
  </div>
</div>
