<div class="d-flex flex-column w-100 text-white">
  <app-sign-in-form (forgotPasswordClicked)="setForm('forgot-password')" *ngIf="form == 'sign-in'"></app-sign-in-form>
  <app-sign-up-form *ngIf="form == 'sign-up'"></app-sign-up-form>
  <app-forgot-password-form *ngIf="form == 'forgot-password'"></app-forgot-password-form>

  <app-gradient
    class="d-flex justify-content-center py-3 px-2"
    gradientDirection="horizontal"
    gradientName="purple-bright">
    <h2 class="h4">
      <span *ngIf="form === 'sign-in'" translate>
        Create FREE account.
        <a (click)="setForm('sign-up')" class="text-orange"><u translate>Sign Up</u></a>
      </span>
      <span *ngIf="form === 'sign-up'" translate>
        Already have an account?
        <a (click)="setForm('sign-in')" class="text-orange"><u translate>Log in</u></a>
      </span>
      <span *ngIf="form === 'forgot-password'">
        <a (click)="setForm('sign-in')"><u translate>Cancel</u></a>
      </span>
    </h2>
  </app-gradient>
</div>
