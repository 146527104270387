<header class="sticky-top">
  <nav class="row">
    <div class="col-2 no-padding">
      <div class="logo">
        <a href="/">
          <img src="/assets/img/mobile/logo.svg" alt="logo" />
        </a>
      </div>
    </div>
    <div class="col">
      <input
        class="form-control"
        type="search"
        [formControl]="query"
        (blurred)="onBlur()"
        (focussed)="onFocus()"
        [placeholder]="'Search' | translate" />
    </div>
    <div class="col-">
      <ol>
        <li *ngIf="!showFilter && activeRole === null">
          <button (click)="changeLanguange()" class="language">
            <ul>
              <li><img src="/assets/img/mobile/world.svg" alt="lang" /></li>
              <li>{{ currentLanguage() }}</li>
            </ul>
          </button>
        </li>
        <li *ngIf="showFilter">
          <div class="filter">
            <button type="button" class="btn" (click)="filterData()">
              <div class="alert" *ngIf="totalNotification>0">{{totalNotification}}</div>
              <img src="/assets/img/mobile/filter.svg" alt="filter" />
            </button>
          </div>
        </li>
        <li *ngIf="activeRole !== null && activeRole === 'talent' && authService?.getJwtToken()?.access_token !== null">
          <div [class.active-profile]="isProfilePage" class="profile-talent" (click)="navigateToProfile()">
            <img
              *ngIf="photo$ | async as img; else loading"
              [src]="(img == null || img === undefined || img === '') ? '/assets/img/mobile/profile.svg' : img"
              alt="" />
            <ng-template #loading>
              <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
            </ng-template>
          </div>
        </li>
        <li *ngIf="authService?.getJwtToken()?.access_token !== null">
          <div [class.setting-unactive]="!isSettingPage" class="setting">
            <button (click)="openSetting()" class="btn">
              <img src="/assets/img/mobile/setting-active.svg" alt="setting" />
            </button>
          </div>
        </li>
      </ol>
    </div>
  </nav>
  <ng-container *ngIf="results$ | async as results">
    <ng-container *ngIf="!!results && results.length; else noResults">
      <div id="data-result">
        <div class="results">
          <ng-container *ngFor="let user of results">
            <div (click)="openUser(user)" class="result" *ngIf="user.profiles.length">
              <div *ngIf="user.photo; else noPhoto" class="profile-image-container">
                <img class="profile-image rounded-circle" [src]="user.photo.thumbUrl" />
              </div>
              <ng-template #noPhoto>
                <div class="profile-image bg-gray rounded-circle mr-3"></div>
              </ng-template>
              <div>
                <div>{{ user.profileName }}</div>
                <div class="text-gray">{{ user.city }}, {{ user.country.isoCode }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</header>
<app-mobile-languange-modal #langModal></app-mobile-languange-modal>
<app-mobile-filter-modal #filterModal *ngIf="showFilter" [dataFilter]="dataFilter"></app-mobile-filter-modal>
