import { IPackage } from '@core/interfaces/package';

export class DeletePackage {
  public static readonly type = '[Packages] Delete package';

  public constructor(public p: IPackage) {}
}

export class SavePackage {
  public static readonly type = '[Packages] Save package';

  public constructor(public p: IPackage) {}
}
