import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input')
  public inputEl: any;

  @Input()
  public placeholder = '';

  @Input()
  public isReadOnly = false;

  @Input()
  public isDisabled = false;

  @Input()
  public maxlength?: number;

  @Input()
  public minHeight: number;

  public remainingText;

  value = '';

  public onChange = (_: any) => {};
  public onTouched = () => {};

  public ngAfterViewInit(): void {
    this.updateField(this.value);
  }

  public onValueChange(value: string): void {
    this.value = value;
    this.remainingText = this.maxlength - this.value.length;
    // this.resize();
    this.onChange(value);
  }

  public writeValue(value: string): void {
    this.value = value;
    this.updateField(this.value);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public focus(): void {
    this.inputEl.nativeElement.focus();
  }

  private updateField(value: string): void {
    if (!this.inputEl) {
      return;
    }
    setTimeout(() => {
      if (this.maxlength != null && this.value != null) {
        this.remainingText = this.maxlength - this.value.length;
      }
    }, 0);
    // if (this.inputEl.nativeElement.scrollHeight) {
    //   setTimeout(() => this.resize());
    // }
    this.inputEl.nativeElement.value = value;
  }

  // private resize(): void {
  //   this.inputEl.nativeElement.style.height = '0';
  //   let height = this.minHeight ?? 0;
  //   if (this.inputEl.nativeElement.scrollHeight > height) {
  //     height = this.inputEl.nativeElement.scrollHeight;
  //   }
  //   this.inputEl.nativeElement.style.height = height + 'px';
  // }
}
