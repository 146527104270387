import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
})
export class MoneyComponent {
  @Input()
  public amount: number;

  @Input()
  public currency: string;

  @Input()
  public format = '1.0-0';
}
