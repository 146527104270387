import { Action, State, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DiscountCodesService } from '@core/services';
import { IDiscountCode } from '@core/interfaces';
import { DiscountCodesStateModel } from './discount-codes.state-model';

import { VerifyDiscountCode } from './discount-codes.actions';
import { Observable } from 'rxjs';

@State<DiscountCodesStateModel>({
  name: 'discountCodes',
  defaults: {
    discountCode: null,
  },
})
@Injectable()
export class DiscountCodesState {
  public constructor(private discountCodesService: DiscountCodesService) {}

  @Selector()
  static discountCode(state: DiscountCodesStateModel): IDiscountCode {
    return state.discountCode;
  }

  @Action(VerifyDiscountCode)
  public async verifyDiscountCode(
    ctx: StateContext<DiscountCodesStateModel>,
    { code }: VerifyDiscountCode,
  ): Promise<void> {
    const discountCode = await this.discountCodesService.findByCode(code).toPromise();
    ctx.patchState({ discountCode });
  }
}
