import { IMembership, ITransaction, IUser } from '@core/interfaces';
import { Transform, Type } from 'class-transformer';
import { Membership, User, Invoice } from '@core/models';
import { MandateTransactionDTO } from '@core/dto';
import * as moment from 'moment';

export class Transaction implements ITransaction {
  @Type(() => Date)
  public date: Date;
  @Type(() => Membership)
  public membership: IMembership;
  @Type(() => User)
  public user: IUser;
  @Type(() => MandateTransactionDTO)
  public transactionDetails?: MandateTransactionDTO;
  @Transform(({ value }) => moment(value).toDate(), { toClassOnly: true })
  public created: Date;
  @Type(() => Invoice)
  public invoice: Invoice;

  public id: string;
  public userId: string;
  public membershipId: string;
  public userMembershipId: string;

  public mandate_token: string;
  public transaction_uid: string;
  public completed: boolean;
  public bookingsNumber?: number;

  get formattedBookingsNumber(): string {
    return `CS${`${this.bookingsNumber}`.padStart(4, '0')}`;
  }
}
